

.carousel-inner2{
  width: 85%;
  height: 350px;

}
.servicesslide2{
  width: 100%;
  display: "flex";
  flex-direction: "row";
  margin: "20px auto";
  height: 100%;
 
}
.carousel-item {
  height: 100%;
}


@media (max-width: 768px) {
  .carousel-inner2{
      width: 100%;
      height: 200px;
  }
  .servicesslide2{
      display: "flex";
      flex-direction: "column";
  }
  .buttonno {
    background-color: #002347;
    margin-top: 5px;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 15rem;
  } 
}
.form-group {
  margin-bottom: 20px;
} 
.buttonno {
  background-color: #002347;
  margin-top: 5px;
  color: #fff;
  padding: 8px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 15rem;
} 
.buttonno:hover {
  background-color: #415f7e;
  color: #fff;
}
.error-message {
  color: #ff0000;
  font-size: 12px;
  margin-top: 10px;
}
  


.custom-banner2 {
  height: 340px;
  width: 12%;
  background-color: #f0f0f0; 
  border: 2px double gray;
 border-radius: 0px 20px;
}
@media (max-width : 875px) {
  .carousel-inner2{
    width: 100%;
    height: 300px;
  }
  .custom-banner2 {
    display: none;
  }
}

.custom-banner-content2 {
  text-align: center;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.custom-banner-content2 h3 {
  font-size: 23px; /* Adjust the font size as needed */
  color: #333; /* Text color */
  /* margin-bottom: 20px; */
}

.custom-banner-content2 p {
  font-size: 16px; /* Adjust the font size as needed */
  color: #014175; /* Text color */
  /* margin-bottom: 20px; */
}

.postnowbtn1 {
  display: inline-block;
  padding: 10px 20px; /* Adjust padding as needed */

  background-color: #0056b3;  /* Button background color */
  color: #fff !important; /* Button text color */
  text-decoration: none;
  border-radius: 5px; /* Add rounded corners to the button */
  transition: background-color 0.3s ease-in-out; /* Smooth hover effect */
}

.postnowbtn1:hover {
  background-color: #007bff;
  color: #f0f0f0;
  border: 2px solid white;
}
