/*! CSS Used from: https://mironmahmud.com/classicads/assets/ltr/fonts/font-awesome/fontawesome.css */
.fas {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
}

.fa-cog:before {
    content: "\f013";
}

.fa-envelope-open:before {
    content: "\f2b6";
}

.fa-trash-alt:before {
    content: "\f2ed";
}

.fas {
    font-family: "Font Awesome 5 Free";
}

.fas {
    font-weight: 900;
}

/*! CSS Used from: https://mironmahmud.com/classicads/assets/ltr/css/vendor/nice-select.min.css */
.nice-select {
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    clear: both;
    cursor: pointer;
    display: block;
    float: left;
    font-weight: normal;
    font-family: inherit;
    padding-right: 15px;
    outline: none;
    position: relative;
    text-transform: capitalize;
    text-align: left !important;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
    width: auto;
}

.nice-select:hover {
    border-color: #dbdbdb;
}

.nice-select:active,
.nice-select:focus {
    border-color: #999;
}

.nice-select:after {
    border-bottom: 2px solid #999;
    border-right: 2px solid #999;
    content: '';
    display: block;
    height: 6px;
    width: 6px;
    margin-top: -4px;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform-origin: 66% 66%;
    -ms-transform-origin: 66% 66%;
    transform-origin: 66% 66%;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: all .15s ease-in-out;
    transition: all .15s ease-in-out;
}

.nice-select:hover:after {
    border-color: var(--primary);
    transition: all linear .3s;
    -webkit-transition: all linear .3s;
    -moz-transition: all linear .3s;
    -ms-transition: all linear .3s;
    -o-transition: all linear .3s;
}

.nice-select:hover .current {
    color: var(--primary);
    transition: all linear .3s;
    -webkit-transition: all linear .3s;
    -moz-transition: all linear .3s;
    -ms-transition: all linear .3s;
    -o-transition: all linear .3s;
}

.nice-select .list {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 15px 35px 0 rgb(0 0 0 / 10%);
    box-sizing: border-box;
    opacity: 0;
    overflow: hidden;
    padding: 8px;
    pointer-events: none;
    position: absolute;
    top: 35px;
    left: 0;
    -webkit-transform-origin: 50% 0;
    -ms-transform-origin: 50% 0;
    transform-origin: 50% 0;
    -webkit-transform: scale(.75) translateY(-21px);
    -ms-transform: scale(.75) translateY(-21px);
    transform: scale(.75) translateY(-21px);
    -webkit-transition: all .2s cubic-bezier(.5, 0, 0, 1.25), opacity .15s ease-out;
    transition: all .2s cubic-bezier(.5, 0, 0, 1.25), opacity .15s ease-out;
    z-index: 2;
}

.nice-select .option {
    cursor: pointer;
    font-weight: 400;
    line-height: 38px;
    list-style: none;
    min-height: 38px;
    outline: none;
    padding-left: 18px;
    padding-right: 29px;
    text-align: left;
    -webkit-transition: all .2s;
    transition: all .2s;
}

.nice-select .option:hover {
    background-color: #f6f6f6;
}

.nice-select .option.selected {
    font-weight: bold;
}

/*! CSS Used from: https://mironmahmud.com/classicads/assets/ltr/css/vendor/bootstrap.min.css */
*,
::after,
::before {
    box-sizing: border-box;
}

section {
    display: block;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

ul {
    margin-top: 0;
    margin-bottom: 1rem;
}

a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

a:hover {
    color: #0056b3;
    text-decoration: underline;
}

img {
    vertical-align: middle;
    border-style: none;
}

select {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

select {
    text-transform: none;
}

select {
    word-wrap: normal;
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width:576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width:768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width:992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width:1200px) {
    .container {
        max-width: 1140px;
    }
}

@media (min-width:576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width:768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width:992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width:1200px) {
    .container {
        max-width: 1140px;
    }
}

.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.col-lg-12 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width:992px) {
    .col-lg-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.mx-auto {
    margin-right: auto !important;
}

.mx-auto {
    margin-left: auto !important;
}

@media print {

    *,
    ::after,
    ::before {
        text-shadow: none !important;
        box-shadow: none !important;
    }

    a:not(.btn) {
        text-decoration: underline;
    }

    img {
        page-break-inside: avoid;
    }

    p {
        orphans: 3;
        widows: 3;
    }

    .container {
        min-width: 992px !important;
    }
}

/*! CSS Used from: https://mironmahmud.com/classicads/assets/ltr/css/custom/main.css */
* {
    margin: 0px;
    padding: 0px;
    outline: 0px;
}

a {
    text-decoration: none;
    display: inline-block;
}

a:hover {
    text-decoration: none;
}

ul {
    list-style: none;
}

p,
ul,
li,
span {
    margin-bottom: 0px;
}

.notify-list {
    height: 400px;
    overflow-y: scroll;
}

.notify-item {
    position: relative;
    border-top: 1px solid var(--border);
    transition: all linear .3s;
    -webkit-transition: all linear .3s;
    -moz-transition: all linear .3s;
    -ms-transition: all linear .3s;
    -o-transition: all linear .3s;
}

.notify-item:hover {
    background: var(--chalk);
}

.notify-item.active {
    background: #e6f1f9;
}

.notify-link {
    padding: 12px 15px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.notify-img {
    border-radius: 50%;
    margin-right: 12px;
    border: 2px solid var(--primary);
}

.notify-img img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid transparent;
}

.notify-text {
    font-size: 15px;
    line-height: 20px;
    color: var(--text);
}

.notify-text span {
    font-weight: 500;
    text-transform: capitalize;
}

.notify-time {
    font-size: 13px;
    line-height: 20px;
    color: var(--primary);
}

/*! CSS Used from: https://mironmahmud.com/classicads/assets/ltr/css/custom/notification.css */
.notify-part {
    width: 100%;
    height: 100vh;
    padding-top: 30px;
    background: var(--chalk);
}

.notify-body {
    padding: 20px;
    background: var(--white);
    border-radius: 8px 8px 0px 0px;
}

.notify-filter {
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.notify-action a {
    width: 38px;
    height: 38px;
    font-size: 14px;
    line-height: 38px;
    border-radius: 50%;
    margin-left: 12px;
    text-align: center;
    color: var(--gray);
    background: var(--chalk);
    transition: all linear .3s;
    -webkit-transition: all linear .3s;
    -moz-transition: all linear .3s;
    -ms-transition: all linear .3s;
    -o-transition: all linear .3s;
}

.notify-action a:first-child {
    margin-left: 0px;
}

.notify-action a:hover {
    color: var(--white);
    background: var(--primary);
}

.notify-scroll {
    height: calc(100vh - 210px);
}

@media (max-width: 575px) {
    .notify-filter {
        flex-direction: column;
        justify-content: center;
    }

    .notify-select {
        margin-bottom: 15px;
    }
}

/*! CSS Used fontfaces */
@font-face {
    font-family: "Font Awesome 5 Free";
    font-style: normal;
    font-weight: 400;
    font-display: auto;
    src: url(https://mironmahmud.com/classicads/assets/ltr/fonts/font-awesome/fa-regular-400.eot);
    src: url(https://mironmahmud.com/classicads/assets/ltr/fonts/font-awesome/fa-regular-400.eot#iefix) format("embedded-opentype"), url(https://mironmahmud.com/classicads/assets/ltr/fonts/font-awesome/fa-regular-400.woff2) format("woff2"), url(https://mironmahmud.com/classicads/assets/ltr/fonts/font-awesome/fa-regular-400.woff) format("woff"), url(https://mironmahmud.com/classicads/assets/ltr/fonts/font-awesome/fa-regular-400.ttf) format("truetype"), url(https://mironmahmud.com/classicads/assets/ltr/fonts/font-awesome/fa-regular-400.svg#fontawesome) format("svg");
}

@font-face {
    font-family: "Font Awesome 5 Free";
    font-style: normal;
    font-weight: 900;
    font-display: auto;
    src: url(https://mironmahmud.com/classicads/assets/ltr/fonts/font-awesome/fa-solid-900.eot);
    src: url(https://mironmahmud.com/classicads/assets/ltr/fonts/font-awesome/fa-solid-900.eot#iefix) format("embedded-opentype"), url(https://mironmahmud.com/classicads/assets/ltr/fonts/font-awesome/fa-solid-900.woff2) format("woff2"), url(https://mironmahmud.com/classicads/assets/ltr/fonts/font-awesome/fa-solid-900.woff) format("woff"), url(https://mironmahmud.com/classicads/assets/ltr/fonts/font-awesome/fa-solid-900.ttf) format("truetype"), url(https://mironmahmud.com/classicads/assets/ltr/fonts/font-awesome/fa-solid-900.svg#fontawesome) format("svg");
}