@media (max-width: 450px) {
  .filetr22{
    transform: translate(-0%, -300%);
    justify-content: center;
    display: flex;
}

}
.pricep.ovenType{
  background-color: rgb(9, 25, 56);
  border-color:rgb(9, 25, 56);
  color:white;
  font-weight:"bold";
}

.act{
  background-color: rgb(9, 25, 56);
  border: rgb(9, 25, 56);
  color: #fff;
}
/* brand input start */
#Km123{
  width: 34%;
  display: flex;
  justify-content: center;
  margin: auto;  
  background-color: aliceblue;
  height: 30%;
}

#Km123 select{
  width: 60%;
  border: 1px solid#484747;
}

/* brand input end */
.price.brand{
  border:1px solid black;
  padding:10px;
  border-radius:10px;
}

.pricep.capacity{
  background-color:  rgb(9, 25, 56);
  border-color: rgb(9, 25, 56);
  color:white;
  font-weight:"bold";
}

.filimg.selected{
  border: 1px solid black;
}

.pricep.budget{
  background-color: rgb(9, 25, 56);
  border-color: rgb(9, 25, 56);
  color:white;
  font-weight:"bold";
}
.pricep.bikeType{
  background-color: rgb(9, 25, 56);
  border-color: rgb(9, 25, 56);
  color:white;
  font-weight:"bold";
}
.pricep.bikeType1{
  background-color: rgb(9, 25, 56);
  border-color: rgb(9, 25, 56);
  color:white;
  font-weight:"bold";
}
.mainfilbtn{
  text-align: center;
  align-items: center;
  margin: auto;
}
.caption{
  display: flex;
  align-items: center;
  width: 50%;
  text-align: center;
  margin: auto;
  justify-content: center;
  justify-content: space-between;
}
.caption button{
  border-radius: 10px;
}
.brandName{
  border: 1px solid black;
}

.pricep.price{
  background-color:  rgb(9, 25, 56);
  border-color: rgb(9, 25, 56);
  color:white;
  font-weight:"bold";
}

.pricep.condition{
  background-color:  rgb(9, 25, 56);
  border-color: rgb(9, 25, 56);
  color:white;
  font-weight:"bold";
}

.pricep.Model{
  background-color:  rgb(9, 25, 56);
  border-color: rgb(9, 25, 56);
  color:white;
  font-weight:"bold";
}

.brandimage.brand{
  border:1px solid black;
  
}

.pricep.budget{
  background-color:  rgb(9, 25, 56);
  border-color: rgb(9, 25, 56);
  color:white;
  font-weight:"bold";
}

.pricep.type{
  background-color:  rgb(9, 25, 56);
  border-color: rgb(9, 25, 56);
  color:white;
  font-weight:"bold";
}

.pricep.careerStream{
  background-color:  rgb(9, 25, 56);
  border-color: rgb(9, 25, 56);
  color:white;
  font-weight:"bold";
}

.pricep.deliveryMode{
  background-color: rgb(9, 25, 56);
  border-color: rgb(9, 25, 56);
  color:white;
  font-weight:"bold";
}

.pricep.listedBy{
  background-color: rgb(9, 25, 56);
  border-color: rgb(9, 25, 56);
  color:white;
  font-weight:"bold";
}

.pricep123.types{
  background-color:  rgb(9, 25, 56);
  border-color: rgb(9, 25, 56);
  color:white;
  font-weight:"bold";
}

.pricep.budget{
  background-color:  rgb(9, 25, 56);
  border-color: rgb(9, 25, 56);
  color:white;
  font-weight:"bold";
}

.pricep.condition{
  background-color:  rgb(9, 25, 56);
  border-color: rgb(9, 25, 56);
  color:white;
  font-weight:"bold";
}
.pricep.brand{
  background-color: rgb(9, 25, 56);
  border-color: rgb(9, 25, 56);
  color:white;
  font-weight:"bold";
}

.pricep.acType{
  background-color:  rgb(9, 25, 56);
  border-color: rgb(9, 25, 56);
  color:white;
  font-weight:"bold";
}

.pricep.condition{
  background-color:  rgb(9, 25, 56);
  border-color: rgb(9, 25, 56);
  color:white;
  font-weight:"bold";
}

.pricep.capacity{
  background-color: rgb(9, 25, 56);
  border-color: rgb(9, 25, 56);
  color:white;
  font-weight:"bold";

}

.pricep.brand{
  background-color:  rgb(9, 25, 56);
  border-color: rgb(9, 25, 56);
  color:white;
  font-weight:"bold";
}

.pricep.productType{
  background-color:  rgb(9, 25, 56);
  border-color: rgb(9, 25, 56);
  color:white;
  font-weight:"bold";
}

.price.condition{
  border:1px solid black;
}

.brand{
  border:1px solid black;
}

.speakerType{
  border: 1px solid black;
}

.condition{
  border: 1px solid black;
}

.doorStyle{
  border: 1px solid black;
}

.productType{
  border: 1px solid black;
}

@media (min-width: 450px) and (max-width: 1991px) {
  .filetr24{
    transform: translate(-16%, -300%);
    justify-content: center;
    display: flex;
}
}
@media (max-width: 450px) {
  .filetr24{
    transform: translate(-0%, );
    justify-content: center;
    display: flex;
}

}
@media (min-width: 1000px) and (max-width: 1991px) {
  .filetr2234{
    transform: translate(0%, -600%);
    justify-content: center;
    background-color: white;
    border-radius: 20px;
    /* width: 40%; */
    padding: 10px 20px;
    display: flex;
}
}
@media (max-width: 999px) {
  .filetr2234{
    justify-content: center;
    background-color: white;
    border-radius: 20px;
    transform: translate(0%,-380% );
    padding: 10px 20px;
    display: flex;
  }
}
@media (max-width: 600px) {
  .filetr2234{
    background-color: white;
    border-radius: 20px;
    transform: translate(-0%,-205% );
    justify-content: center;
    display: flex;
}

}

@media (max-width: 480px) {
  .filetr2234{
    background-color: white;
    border-radius: 20px;
    transform: translate(-0%, -150%);
    justify-content: center;
    display: flex;
}

}

@media (min-width: 450px) and (max-width: 1991px) {
  .filetr25{
    transform: translate(-10%, -1100%);
    justify-content: center;
    display: flex;
}
}
@media (max-width: 450px) {
  .filetr25{
    transform: translate(-0%, );
    justify-content: center;
    display: flex;
}

}

@media (min-width: 450px) and (max-width: 1991px) {
  .filetr22{
    transform: translate(-0%, -550%);
    justify-content: center;
    display: flex;
}

}
/* .wholefil{
    transform: translate(-0%, -5%);
} */
.wholemid{
  justify-content: center;
  display: flex;
}
.brandimg{
    width:80%;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    border: solid 2px rgb(104, 104, 104);
  
    text-align: center;
}

/* car filter btn css start */
.brandimgcar{
  width:80%;
  display: grid;
  grid-template-columns: repeat(8,1fr);
  border: solid 2px rgb(104, 104, 104);
}
/* car filter btn css end */
.pricep{
    margin-left: 5px;
  margin-top: 10px;
    padding: 15px;
    border: gray solid 1px;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: rgb(219, 110, 21);
    font-family: 'Times New Roman', Times, serif;
}
@media (min-width: 450px) and (max-width: 1991px) {
  .biky{
  display: flex;
  flex-direction: row;
  width: 100%;
  }
  .brandimgcar img, .brandimg img{
    margin-left: 20%;
  }
  
  .brandimgcar{
    width:80%;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    border: solid 2px rgb(104, 104, 104);
  }

}
@media(max-width: 450px) {
  .biky{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
  }
  .brandimgcar{
    width:80%;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    border: solid 2px rgb(104, 104, 104);
  }
}
.pricep123{
    margin-left: 5px;
    margin-top: 10px;
    padding: 15px;
    border: gray solid 1px;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: rgb(107, 21, 219);
    font-family: 'Times New Roman', Times, serif;
}
@media (max-width: 450px)  {
  .pricep123{
    font-size: 9px;
  }
  .pricep{
    
    font-size: 9px;

  }
  .brandimg{
    color: rgb(41, 21, 219);
    gap: 4px;

}
  
}
.button123{
    padding: 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-bottom: 2px red solid;
}
.button321{
  padding: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 2px rgb(66, 66, 70) solid;
  width: 150px;
  gap: 15px;
}
.Button231{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 40px;
    justify-content: space-between;
   margin-right: -50px;
 
}
@media (min-width: 768px) and (max-width: 1575px) {



/* .sea-but-tog-awi{
display: none;

}
@media (max-width:450px){
  .sea-but-tog-awi{
    display: flex;
    
    }
} */
}


@media (min-width: 450px) and (max-width: 1575px) {
    .bikefilcon{
        margin-left: 200px;
        margin-right: 200px;
    }
    .brandimg{
    padding: 0px 10px;
  }
  
}
@media (min-width: 450px) and (max-width: 1575px) {
  .bikefilcon123{
    margin-left: 200px;
    margin-right: 200px;
    display: flex;
    flex-direction: row;
}
@media (max-width: 768px) {
  .bikefilcon{
      width: 95%;
      margin: auto;
  }
}
}
.buttondiv123{
  display: flex;
  flex-direction: column;
}
@media(max-width: 450px){
  .button123{
    font-size: 70%;
    display: flex;
  }
  .buttondiv123{
    display: flex;
    flex-direction: row;
  }
  .pricep{
    padding: 4px;
    font-size: 10px;
  }
  .Button231{
    display: flex;
    flex-direction: column;
  }
  #serbtn{
    /* width: 50%; */
    /* margin-left: -1cm; */
  }
  .brandimg{
    padding: 5px;
  }
  .Button231{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 14px;
  
 
}
@media(max-width:480px){
  .mainfilbtn{
    display: grid;
    grid-template-columns:repeat(3,1fr);
    
  }
  .caption{
      flex-direction: column;
  }
.caption h3{
  font-size: 13px;
  width: 100%;
  text-align: start;
}
.caption button{
  border-radius: 10px;
}

}



.button123{
  font-size: 15px;
  padding: 15px;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 2px solid red;
}
.button321{
  font-size: 16px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 2px rgb(66, 66, 70) solid;
  width: 120px;
  
}

}


@media (max-width:780px){
  .caption{
    flex-direction: column;
    justify-content: center;
    align-items: center;
  } 
}
.furnifil{
  display: flex;
  justify-content: center;
  top: 10%;
 
}
.fusion-builder-row {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  margin: 20px 0;
}
.fusion-column {
  flex: 1;
  margin: 0 10px;
}
.furniture-item {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
}
.furniture-item img {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}
.furniture-item h2 {
  font-size: 1.2rem;
  margin: 0;
}
.furniture-item p {
  font-size: 1rem;
  color: #666;
}
/*3. FurnitureCat.css */


@media (max-width: 450px) {
  .furniture-cat-slider {
    width: 85%;
    margin: 0 auto;
    margin-top: -90px;
  }
  .furniture-item {
    border: unset;
    padding: 10px;
    text-align: center;
  }
}

@media (min-width: 450px) and (max-width: 1991px) {
  .furniture-cat-slider {
    width: 80%;
    margin: 0 auto;
    margin-top: -20%;
  }
}
.furniture-item {
  text-align: center;
  padding: -20px;
  /* background-color: rgb(1, 9, 11); */
}
.furniture-item img {
  max-width: 100%;
  height: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}
.furniture-item h2 {
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
}
.brandimage{
  width: 100px;
  height: 100px ;

  
}
.custom-select2{
  height: 47px;
  width:50%;
  border: solid black 1.5px;
  border-radius: 25px;
}
.custom-select2-awi{
  padding: 24px;
}
.custom-select2-awi::placeholder{
padding: 6px;

}
.button-CARCAT-24{
  margin-bottom: 10px;
padding: 10px;
background-color: white;
border: 1.5px solid blue;
color: rgb(9, 25, 56);



}




/* Jobscat.css */


/* .jobscat-container {
  text-align: center;
  padding: 20px;
  margin-top: -20px;
}

.image-section img {
  width: 100%;
  max-height: 100px;
  object-fit: cover;
  margin-bottom: 20px;
  margin-top: -20px;
 
} */


.jobscat-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (max-width:450px){
  .jobimg99{
    width: 100%;
  height:200px;
  }
}
@media (min-width: 450px) and (max-width: 1991px) {
.jobimg99{
  width: 1400px;;
  height:500px;
}

}

.dropdown-section {
  margin-top: 20px;
  text-align: center;
  margin-right: 900px;
}

.toggle-dropdown {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.toggle-dropdown label {
  display: block;
  margin: 10px;
  cursor: pointer;
}

.toggle-dropdown label.active {
  font-weight: bold;
}
@media (max-width:450px){
 .head1{
    font-size: 6px;
    margin-top: 5px;
  }
  .brandimg{
    width:100%;
    padding-left: 25px;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    /* row-gap: 10px; */
    border: solid 2px rgb(104, 104, 104);
}
.brandimage{
  width: 50px;
  height: 50px ;

  
}
.headdingforfind{
  font-size: 10px;
}
.custom-select2{
  height: 47;
  width:80%;
  border: solid black 1.5px;
  border-radius: 5px;
}
}


/* fixed search box of city and subcategory */
.search-box {
  position: relative;
  width: 320px; /* Adjust width as needed */
}

.search-bar {
  width: 100%;
  padding: 8px;
}

.filtered-options {
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  width: 100%;
  z-index: 1;
}

.filtered-options li {
  cursor: pointer;
  padding: 8px;
}

.filtered-options li:hover {
  background-color: #f0f0f0;
}

/* @media (max-width: 767px) {
  .form-group99 {
    width: 100%; 
  }
} */

@media (max-width: 768px) {
  .dropdown99 {
    width: 150px; /* Adjust the width as needed for mobile view */
  }
}





/* SearchAndPostForm.css */


@media (min-width: 450px) and (max-width: 1575px) {
  .search-and-post-form99 {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 25px;
    background-color: #fdfdfd;
  }
}
  

.form-group99 {
  margin-bottom: 15px;
}

.label {
  font-weight: bold;
}

.dropdown99 {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 16px;
}

.search-button99 {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 3px;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
}

.search-button:hover {
  background-color: #0056b3;
}


 

/* job sub cat section  */

/*! CSS Used from: Embedded */
body {
  margin: 0px;
  font-family: arial;
  font-size: 12px;
  color: #444;
  background-color: #fff;
}

.clear {
  clear: both;
}

A:link {
  text-decoration: none;
  color: #a2a2a2;
}

.grey-light-top {
  margin-top: 40px;
  width: 100%;
  min-height: 279px;
  background: #ededed;
  font-size: 18px;
  line-height: 26px;
}

.inner-wrapper {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.inner-wrapper-l-l {
  padding: 25px 0px 25px 0px;
  width: 35%;
  float: left;
}

.inner-wrapper-l-r {
  padding: 25px 0px 25px 5%;
  width: 60%;
  float: left;
}

.inner-wrapper-l-1 {
  padding: 25px 0px 25px 0px;
  width: 35%;
  float: left;
  
}


.inner-wrapper-l-2 {
  padding: 25px 0px 25px 0px;
  width: 35%;
  float: right;
}
.inner-wrapper-l-3 {
  padding: 25px 0px 25px 0px;
  width: 35%;
  float: left;
  
};



@media (min-width: 574px) {
  .example{
    width:100%;
  }
  
}



.mobilecontainer {
  display: flex;
  width: 100%;
}
.mobileslider {
width: 85%;
margin: auto;
}
.mobilesliderimage {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobilesliderimage img{
  max-width: cover !important;
  max-height: 400px;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.mobile-slide1:hover img {
  transform: scale(1.05);
}

.brand-tags1 {
  width: 10%;
  background-color: #ffffff;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
  margin-right: 10px;
}
.brand-tags1 button {
  width: 8rem;
  display: block;
  padding: 5px 10px;
  border: 1px solid gray;
  background-color: rgb(255, 255, 255);
  cursor: pointer;
  transition: background-color 0.3s;
  opacity: 0.7;
  color: #011d3a;
  font-weight: bold;
 margin-bottom: 5px;
}

.brand-tags1 button:hover {
  background-color: #ebebeb;
  opacity: 1;
}

.brand-tags1 button.active {
  background-color: #003a77;
  color: #fff;
  opacity: 1;
}
.brand-card {
  width: 100px;
  height: 120px;
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.brand-card.active {
  background-color: #f0f0f0;
  transform: scale(1.05);
}



.custom-button11 {
  background-color: #3498db; /* Button background color */
  color: #ffffff; /* Button text color */
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin-left: 50px;
}



/* 
  .fildi22 {
    background-color: #f0f0f0;
    padding: 10px;
    border: 1px solid #ccc;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 999;
  }
  
  .fildi22 button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
  }
   */

/* NEW CSS --28-3-24 ------------------------------------------------ */

.custom-select2-awi2::placeholder{
  padding-left: 6px;
  
  }
.search-and-post-form-awi-23-2{
  display: none;
} 
@media (min-width: 1100px) and (max-width: 1575px) {
  .search-and-post-form-awi-23{
    position: absolute;
    top:5%;
    z-index: 2;
    right: 2%;
  }
  .text99-awi{
    position: absolute;
    bottom: 0.251%;
    left: 25%;
    z-index: 2;
  }
}

  
@media (min-width: 900px) and (max-width: 1100px) {
  .search-and-post-form-awi-23{
    position: absolute;
    top:5%;
    z-index: 2;
    right: 2%;
  }
  .text99-awi{
    position: absolute;
    bottom: 0.251%;
    left: 15%;
    z-index: 2;
  
  }

}
@media (min-width: 650px) and (max-width: 900px) {
  .search-and-post-form-awi-23{
    position: absolute;
    top:5%;
    z-index: 2;
    right: 20%;
  }
  .text99-header-awi-24{
    font-size: 28px;
    padding: 6px;
  }
}
@media (max-width: 650px) {
 
  .text99-header-awi-24{
    font-size: 20px;
    padding: 6px;
  }
}


@media (max-width: 500px) {
  .search-and-post-form-awi-23{
display: none;
  }
  .search-and-post-form-awi-23-2{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 25px;
    background-color: #fdfdfd;
  }

}
.but-BIKE-whole-cont{
  margin-top: -3%;
}




.Fcsearch-class{
  font-size: 18px;
 
}
.Fcsearch-class:hover{
  font-size: 18px;
}
.button-BIKECAT-24 {
  background-color: rgb(47, 72, 123);
  color: white;
  transition: transform 0.3s ease;
}

.button-BIKECAT-24:hover {
  transform: scale(1.1);
}
      .caption-CARCAT-24{
        display: none;
       }
       .caption-BIKECAT-24{
        display: none;
       }
    
    
    @media (max-width:1085px){
      .caption-BIKECAT-24{
        display: none;
       }
    }
    /* @media (max-width:1050px){
       .button-BIKECAT-24{
        display: flex;
        font-size: 16px;
        padding: 10px;
        background-color: white;
        border: 2px rgb(66, 66, 70) solid;
        width: 150px; 
        color: rgb(9, 25, 56);
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top: 10px;
        } 
     
    } */
    
    @media (max-width:450px){
      /* .button-BIKECAT-24{
        display: flex;
        font-size: 16px;
        padding: 8px;
        background-color: white;
        border: 2px rgb(66, 66, 70) solid;
        width: 120px; 
        color: rgb(9, 25, 56);
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top: 9px;
        } */
    
    }
    @media (max-width:400px){
      .but-BIKE-whole-cont{
      margin-left: -8.5%;
      margin-top: -5%;
    }
    
    }


   @media(min-width: 450px) and (max-width: 700px) {

    .brandimgcar{
      width:90%;
      display: grid;
      grid-template-columns: repeat(4,1fr);
      border: solid 2px rgb(104, 104, 104);
    }
    .brandimgcar img, .brandimg img{
      margin-left: 0%;
    }
  }






.Fil-BIKE-maisearch-awi{
  position: relative;
  top: 225px;
}


@media (max-width:450px){
  .Fil-BIKE-maisearch-awi{
    position: relative;
    top: 120px;
  }
}

.Fil-BIKE-maisearch-awi2{
  position: relative;
  z-index: 2;
  top: -100px;
}

@media (min-width:500px) and (max-width:900px){
  .Fil-BIKE-maisearch-awi2{
    position: relative;
    top: -140px;
  }
}
@media (max-width:500px){
  .Fil-BIKE-maisearch-awi2{
    position: relative;
    top: -180px;
  }
}

   .regest-BIKE-fil-but{
   width:30%;
   border-radius: 10px;
   padding:0.75%;
   font-weight: bolder!important;
   display: flex;
   flex-direction: row;
   justify-content: space-evenly;
padding-top: 0.75%;
   }
.regest-BIKE-fil-SELECT{
  width:100%;
  padding:.5%;
  margin: -1% 0 0 3%;
  border:1px solid #ccc;
  border-radius: 4px;
  box-sizing :border-box;
  justify-content:end;

}
   
@media (min-width:1150px) and (max-width:1500px){
  .regest-BIKE-fil-header{
    margin-top: 10px;
   }
   .regest-BIKE-fil-opt{
    margin-top: 16%;
   }
}



@media (min-width:650px) and (max-width:950px){
  #Km123{
    width: 60%;
    display: flex;
    justify-content: center;
    margin: auto;  
    background-color: aliceblue;
    height: 30%;
  }
  .regest-BIKE-fil-but{
    width:100%;
   border-radius: 10px;
    padding:0.75%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
   
    }
 .regest-BIKE-fil-SELECT{
   width:100%;
   padding:0.5%;
  margin: .11% 0 0 3%;
   border:1px solid #ccc;
   border-radius: 4px;
   box-sizing :border-box;
   justify-content:end;
 
 } 
 .regest-BIKE-fil-header{
  margin-top: 10px;
 }
 .regest-BIKE-fil-opt{
  margin-top: 16%;
 }
}

@media (min-width:500px) and (max-width:650px){
  #Km123{
    width: 70%;
    display: flex;
    justify-content: center;
    margin: auto;  
    background-color: aliceblue;
    height: 30%;
  }
  .regest-BIKE-fil-but{
    width:100%;
   border-radius: 10px;
    padding:0.75%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    }
 .regest-BIKE-fil-SELECT{
   width:100%;
   padding:0.5%;
  margin: .51% 0 0 3%;
   border:1px solid #ccc;
   border-radius: 4px;
   box-sizing :border-box;
   justify-content:end;
 
 } 
 .regest-BIKE-fil-header{
  margin-top: 10px;
 }
 .regest-BIKE-fil-opt{
  margin-top: 16%;
 }
}
@media (max-width:500px){
  #Km123{
    width: 90%;
    display: flex;
    justify-content: center;
    margin: auto;  
    background-color: aliceblue;
    height: 10%;
  }
  .regest-BIKE-fil-but{
    width:100%;
   border-radius: 10px;
    padding:0.5%;
    margin-top: 0.5%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    }
 .regest-BIKE-fil-SELECT{
   width:100%;
   padding:0.15%;
  margin: 0.51% 0 0 3%;
   border:1px solid #ccc;
   border-radius: 4px;
   box-sizing :border-box;
   justify-content:end;
 
 } 
 .regest-BIKE-fil-header{
  margin-top: 10px;
 }
 .regest-BIKE-fil-opt{
  margin-top: 16%;
 }
}

@media (min-width: 450px) and (max-width: 850px) {
  .fildi2{
      max-width: 100%;
    }

  }
  @media (max-width: 650px) {
.avinash-close-mainfilt{
  display: none;
}}




   