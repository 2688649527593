/* 
*{
    margin: 0;
    padding: 0;
}
.image-box {
    width: 98%; 
    height: 500px; 
    overflow: hidden; 
  }
  
  .image-box img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    margin: 30px;
  
  }
  
.leftctr{

    margin-top: 20px;
    height: auto;
    width: 340px;
    border: 1px solid gray;
    margin-bottom: 20px;

}
.rightctr{
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-left: 20px;
    height: auto;
    width: 940px;
    border: 1px solid gray;
    margin-bottom: 20px;
}
.box1{
  
    height: 200px;
    width: 200px;
    border: 1px solid gray;
    margin: 20px;
}
.Categories{
height: auto;
width: 300px;
background-color: rgb(233, 246, 246);
margin: 20px;

padding: 20px;


}
.Categories ul li{
    color: black;
    cursor: pointer;
}

.lbl{
    margin-right: 30px;
}
.inputbgt{
    margin-left: 20px;
    height: 20px;
    width: 120px;
    border-radius: 50px;
}
.apply-btn{
    color: black;
    height: 30px;
    width: 70px;
    border-radius: 50px;
    background-color: rgb(219, 219, 236);
    margin-left: 80px;
}

.scrollable-list {
    max-height: 150px; 
    overflow-y: auto;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
    list-style-type: none;
    margin: 0;
  }
  
  .scrollable-list li {
    padding: 8px 12px;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
  }
  
  .scrollable-list li:last-child {
    border-bottom: none;
  }
  
  .scrollable-list li:hover {
    background-color: #f1f1f1;
  }
  
  .main {
    display: flex;
  }
  
  .left {
    flex: 1;
  }
  
  .right {
    flex: 1;
  }

body {
    font-family: Arial, sans-serif;
  }


  .main-content {
    width: 100%;
    margin-left: 1.5rem;
    margin: 20px;
  }
  
  .sorting {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .ad-count {
    color: #4a5568;
  }
  
  .sort-options {
    color: #4a5568;
  }
  
  .sort-active {
    font-weight: 600;
  }
  
  .ads-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
  }
  
  .ad-card {
    background-color: rgb(208, 205, 205);
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
    position: relative;
  }
  
  .featured-badge {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    background-color: #d69e2e;
    color: white;
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
  }
  
  .ad-image {
    width: 100%;
    height: 10rem;
    object-fit: cover;
    border-radius: 0.5rem;
  }
  
  .ad-price {
    margin-top: 0.5rem;
    font-size: 1rem;
    font-weight: 600;
  }
  
  .ad-title {
    font-size: 0.875rem;
    color: #4a5568;
  }
  
  .ad-details {
    font-size: 0.75rem;
    color: #07336c;
  }
  .galleryhb{
    height: 500px;
    width: 98%;
    border: 1px solid gray;
    margin: 20px;
  }
  .headinghb{
    display: flex;
    justify-content: center; 
    color: black;
 }




@media (max-width: 768px) {
  .main {
    flex-direction: column;
  }

  .leftctr {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .ads-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

@media (max-width: 480px) {
  .headinghb {
    font-size: 1rem;
  }

  .ad-card {
    font-size: 0.9rem;
  }

  .ad-price {
    font-size: 1.2rem;
  }

  .apply-btn {
    padding: 8px 10px;
    font-size: 0.9rem;
  }
} */




/* Global Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
}

/* Full-width Image Box */
.image-box {
  width: 100%;
  height: 400px; /* Adjusted height for smaller screens */
  overflow: hidden;
}

.image-box img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintains aspect ratio while filling the box */
  margin: 0; /* Removed margin for better responsiveness */
}

/* Main Layout */
.main {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
  margin: 20px;
  gap: 20px; /* Add spacing between sections */
}


.leftctr {
  flex: 1;
  max-width: 340px;
  min-width: 300px; /* Ensure a minimum size on smaller screens */
  border: 1px solid gray;
  padding: 10px;
  background-color: #f9f9f9;
}
.category-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
 
}

.category-button {
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
  
}


.rightctr {
  flex: 2;
  width: 100%; /* Full width for smaller screens */
  border: 1px solid gray;
  padding: 10px;
  background-color: #fff;
}

/* Categories Section */
.Categories {
  margin-bottom: 20px;
}

.Categories ul {
  list-style: none;
  padding: 0;
}

.Categories ul li {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}

.Categories ul li:hover {
  background-color: #f1f1f1;
}

.scrollable-list {
  max-height: 150px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Budget Inputs */
.input-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.lbl {
  margin-right: 10px;
}

.inputbgt {
  flex: 1;
  padding: 5px;
  border-radius: 5px;
}

.apply-btn {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #d9e0f7;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.apply-btn:hover {
  background-color: #b9c9f5;
}

/* Ads Grid */
.ads-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr)); /* Responsive columns */
  gap: 20px;
}

.ad-card {
  background-color: #e0e0e0;
  padding: 10px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.featured-badge {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #ffcc00;
  color: white;
  padding: 5px;
  border-radius: 3px;
  font-size: 12px;
}

.ad-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 10px;
}

.ad-price {
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
}

.ad-title {
  font-size: 14px;
  margin-top: 5px;
}

.ad-details {
  font-size: 12px;
  color: #555;
}

/* Responsive Design */
@media (max-width: 768px) {
  .main {
    flex-direction: column;
  }

  .leftctr,
  .rightctr {
    width: 100%; /* Full width on smaller screens */
  }

  .ads-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  .ad-image {
    height: 120px; /* Smaller image height for smaller screens */
  }

  .apply-btn {
    margin-top: 10px;
    width: 100%;
  }
}

@media (max-width: 480px) {
  .image-box {
    height: 200px; /* Further reduced height for mobile devices */
  }

  .inputbgt {
    width: 100%;
  }

  .apply-btn {
    font-size: 14px;
  }
}
