.cardcomp2{
    display: flex;
    justify-content: center;
    padding-bottom: 0.5cm;
    padding-top: 1cm;
}


.wrappercard2 {
    width: 1000px;
    background: #ffffff;
    border-radius: 16px;
    /* border: 1px solid #0f081a; */
    padding: 10px;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.05);
  }
  
  .wrappercard2 header {
    height: 45px;
    display: flex;
    align-items: center;
    border: 1px solid #dedbe2;
    border-radius: 30px;
    position: relative;
  }
  
  header label {
    height: 100%;
    z-index: 2;
    width: 50%;
    display: flex;
    cursor: pointer;
    font-size: 18px;
    position: relative;
    align-items: center;
    justify-content: center;
    transition: color 0.3s ease;
  }
  
  #tab-111:checked header .tab-111,
  #tab-211:checked header .tab-211 {
    color: #fff;
  }
  
  header label:nth-child(2) {
    width: 50%;
  }
  header label{
    padding-top: 14px ;
    font-size: small;
    font-family: Georgia, 'Times New Roman', Times, serif;
  }
  header .slider1122{
    position: absolute;
    /* padding-top: 10px; */
    height: 75%;
    border-radius: inherit;
    background: linear-gradient(145deg, #e4de27 0%, #a2c072 100%);
    transition: all 0.3s ease;
    color: #15101b;
  }
  header .slider11{
    position: absolute;
    /* padding-top: 10px; */
    height: 75%;
    border-radius: inherit;
    background: linear-gradient(145deg, #160e3d 0%, #c3c5ca 100%);
    transition: all 0.3s ease;
    color: #dedbe2;
  }
  
  #tab-111:checked ~ header .slider11{
    left: 0%;
    width: 50%;
    transform: translateX(5%);
  }
  
  #tab-211:checked ~ header .slider11{
    left: 50%;
    width: 50%;
    transform: translateX(-4%);
  }
  
  
  .wrappercard2 input[type="radio"] {
    display: none;
  }
  
  .card-area1 {
    width:100%;
    overflow: hidden;
  }
  
  .card-area1 .cardsd1{
    display: flex;
    width: 200%;
  }
  
  .cardsd1 .rowsd1{
    width: 50%;
    gap: 20px;
    /* margin-right: 3cm; */
  }
  
  .cardsd1 .row-1{
    transition: all 0.3s ease;
  }
  
  #tab-111:checked ~ .card-area1 .cardsd1 .row-1{
    margin-left: 0%;
  }
  
  #tab-211:checked ~ .card-area1 .cardsd1 .row-1 {
    margin-left: -50%;
  }
  
  .rowsd1 .price-details1 {
    text-align: center;
    border-bottom: 1px solid rgb(40, 211, 40);
  }
  
  .price-details1 .price1 {
    font-size: 40px;
    font-weight: 600;
    position: relative;
    font-family: "Noto Sans", sans-serif;
    color: black;
  }
  .rowsd1.features li{
    display: flex;
    font-size: 15px;
    list-style-type: none;
    margin-bottom: 10px;
    align-items: center;
  }
 
  
  .detail li i {
    padding-right: 10px;
  }
  
  .wrappercard2 button {
    width: 100%;
    border-radius: 25px;
    border: none;
    outline: none;
    height: 50px;
    font-size: 18px;
    font-weight: 900;
    color: #080606;
    cursor: pointer;
    margin-top: 20px;
    background-image:linear-gradient(145deg, #37e046 0%, #9fcab4 100%);
    transition:transform 0.3s ease;
  }
  
  .wrappercard2 button:hover {
    transform: scale(0.98);
  }
  .wrappercard2 h5{
    text-align: center;
    font-size: medium;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: 800;
  }
  .vis h6{
    font-size: medium;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: 200;
  }
  .gap-div{
    width: 30px;
  }

  .planfa{
    justify-content: center;
  } 
  