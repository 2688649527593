/* 
  .our-team {
    padding: 5px 0 10px;
    margin-bottom: 10px;
    background-color: #f8f8f8;
    text-align: center;
    overflow: hidden;
    position: relative;
    height: 240px;
    width: 160px;
    border: 1px solid gray;
  }
  
  .our-team .picture {
    display: inline-block;
    height: 120px;
    width: 120px;
    margin-bottom: 20px;
    margin-top: 20px;
    z-index: 1;
    position: relative;
  }
  
  .our-team .picture::before {
    content: "";
    width: 100%;
    height: 0;
    border-radius: 80%;
    background-color: #001d3f;
    position: absolute;
    bottom: 125%;
    right: 0;
    left: 0;
    opacity: 0.9;
    transform: scale(2);
    transition: all 0.3s linear 0s;
  }
  
  .our-team:hover .picture::before {
    height: 100%;
  }
  
  .our-team .picture::after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #79b4f7;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  
  .our-team .picture img {
    width: 100%;
    height: 120px;
    border-radius: 50%;
    transform: scale(1);
    transition: all 0.9s ease 0s;
  }
  
  .our-team:hover .picture img {
    box-shadow: 0 0 0 5px hsl(39, 100%, 50%);
    transform: scale(1.1);
  }
  
  .our-team .title {
    display: block;
    font-size: 15px;
    color: #1e2f3f;
    text-transform: capitalize;
  }
  
  .our-team .social {
    width: 100%;
    padding: 0;
    margin: 0;
    background-color: #69adff;
    position: absolute;
    bottom: -100px;
    left: 0;
    transition: all 0.5s ease 0s;
  }
  
  .our-team:hover .social {
    bottom: 0;
  }
  
  .our-team .social li {
    display: inline-block;
  }
  
  .our-team .social li a {
    display: block;
    padding: 5px;
    font-size: 12px;
    transition: all 0.3s ease 0s;
    background: rgb(231, 231, 231);
    justify-content: center;
 
  }
  
  .our-team .social li a:hover {
    background-color: #f7f5ec;
  } 


/* App.css */



.body1 {
  height: 50vh;
  width: 50%;
  overflow: hidden;
  background: linear-gradient(18deg, #79e5f8, #9e7eff);
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.hexagon-wrapper {
  margin: auto;
  display: flex;
  text-align: initial;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.hexagon {
  position: relative;
  width: 46%;
  height: 80%;
  margin: auto;
  color: white;
  background: linear-gradient(-180deg, rgb(255, 255, 255), #fabfc9);
  display: flex;
  align-content: center;
  justify-content: center;
  transition: 0.5s;
}

.hexagon i {
  z-index: 1;
  margin: auto;
  font-size: 28px;
  color: transparent;
  background: linear-gradient( #011224, #ff3c53);
  background-clip: text;
  -webkit-background-clip: text;
}

.hexagon:before,
.hexagon:after {
  position: absolute;
  content: "";
  background: inherit;
  height: 100%;
  width: 100%;
  border-radius: 0;
  transition: 0.5s;
  transform-origin: center;
}
.hexagon:before {
  transform: rotateZ(60deg);
}
.hexagon:after {
  transform: rotateZ(-60deg);
}
.hexagon:hover {
  border-radius: 50px;
  transition: 0.5s;
}
.hexagon:hover:before {
  border-radius: 50px;
  transition: 0.5s;
}
.hexagon:hover:after {
  border-radius: 50px;
  transition: 0.5s;
} 

/* ServicesSlider.css */
.services-slider {
  position: relative;
  width: 100%;
  height: 400px; /* Set the desired height */
  overflow: hidden;
}

.slide {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: opacity 1s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slide.active {
  opacity: 1;
}

.slide-content {
  text-align: center;
  color: #fff;
  padding: 20px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 5px;
}

.slide-title {
  font-size: 24px;
}



/* FilteringData.css */



/* Container styles */
.filtering-data-container {
  max-width: 250px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #b6c5ce;
  font-family: Arial, sans-serif;
  border-radius: 4px;
}

/* Form styles */
.filter-form {
  display: flex;
  flex-direction: column;
}

/* Select styles */
.dropdropdrop {
  width: 210px;
  margin-bottom: 15px;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
}

/* Checkbox container styles */
.checkbox-container {
  margin-top: 15px;
}

/* Checkbox row styles */
.checkbox-row {
  display: flex;

  align-items: center;
  margin-bottom: 10px;
}


/* Submit button styles */
.submit-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #0056b3;
}

/* You can add more styles as needed */



/* FilterPopup.css */

.filter-popup {
  text-align: center;
}

.filter-button {
  padding: 10px 20px;
  background-color: #007BFF;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.popup-content {
  background-color: #fff;
  width: 70%;
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.tabs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.tab-button {
  padding: 10px 20px;
  background-color: #007BFF;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.tab-content {
  text-align: left;
}

.section-heading {
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
  
}

/* Example styles for dropdowns, checkboxes, and radio buttons */
.select-dropdown {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
}

.checkbox-label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.checkbox-input {
  margin-right: 5px;
}

.radio-label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.radio-input {
  margin-right: 5px;
}

.close-button {
  background-color: #ccc;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.close-button:hover {
  background-color: #999;
}


.red-option {
  color: red !important;
}