/*! CSS Used from: https://hola9.com/static/mainClassified/libs/bootstrap/css/bootstrap.min.css */
.button {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
  padding: 5px 10px;
  border: 1px solid #aaa;
  background-color: #eee;
  background-image: linear-gradient(top, #fff, #f0f0f0);
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  color: #666;
  text-decoration: none;
  text-shadow: 0 1px 0 #fff;
  cursor: pointer;
  transition: all 0.2s ease-out;
  &:hover {
    border-color: #999;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
  }
  &:active {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25) inset;
  }
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 200ms;
  visibility: hidden;
  opacity: 0;
  &.light {
    background: rgba(255, 255, 255, 0.5);
  }
  .cancel {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: default;
  }
  &:target {
    visibility: visible;
    opacity: 1;
  }
}

.popup {
  border-radius: 5px;
  margin: 10px auto;
  padding: 20px;
  background: #fff;
  border: 1px solid #666;
  width: 100%;
  height: 470px;
  scroll-behavior: auto;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
  position: relative;
  .light & {
    border-color: #aaa;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
  }
  h2 {
    margin-top: 0;
    color: #666;
    font-family: "Trebuchet MS", Tahoma, Arial, sans-serif;
  }
  .close {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 20px;
    right: 20px;
    opacity: 0.8;
    transition: all 200ms;
    font-size: 24px;
    font-weight: bold;
    text-decoration: none;
    color: #666;
    &:hover {
      opacity: 1;
    }
  }
  .content {
    height: 400px;
    position: relative;
    overflow: auto;
  }
  p {
    margin: 0 0 1em;
    &:last-child {
      margin: 0;
    }
  }
}

*,
::after,
::before {
  box-sizing: border-box;
}
h1,
h2,
h3 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1 {
    font-size: 2.5rem;
  }
}
h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2 {
    font-size: 2rem;
  }
}
h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3 {
    font-size: 1.75rem;
  }
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
a {
  color: #0d6efd;
  text-decoration: underline;
}
a:hover {
  color: #0a58ca;
}
img {
  vertical-align: middle;
}
::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
.container {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
}
.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
}
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.btn:disabled {
  pointer-events: none;
  opacity: 0.65;
}
/*! CSS Used from: https://hola9.com/static/mainClassified/css/style.css */
div {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
span {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
h1 {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
  clear: both;
  color: #2d2d2d;
  font-family: "Jost";
  font-weight: 700;
  font-size: 24px;
}
h2 {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
  clear: both;
  color: #2d2d2d;
  font-family: "Jost";
  font-weight: 700;
  font-size: 20px;
}
h3 {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
  clear: both;
  color: #2d2d2d;
  font-family: "Jost";
  font-weight: 700;
  font-size: 18px;
}
p {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  line-height: 1.44;
  vertical-align: baseline;
}
a {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
  color: #2d2d2d;
  text-decoration: none;
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
}
a:hover {
  color: #23d3d3;
  text-decoration: none;
}
a:focus {
  text-decoration: none;
  color: inherit;
  outline: none;
}
img {
  border: none;
  max-width: 100%;
  height: auto;
}
.align-center {
  text-align: center !important;
}
.align-left {
  text-align: left !important;
}
.title {
  font-size: 24px;
  font-weight: 500;
  opacity: 0.9;
  margin-bottom: 20px;
}
.btn {
  border-radius: 20px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  background-color: #1ccaca;
  height: 40px;
  line-height: 40px;
  font-weight: 500;
  padding: 0 25px;
  color: #ffffff;
  outline: 0;
  outline-offset: 0;
}
.btn:hover {
  color: #ffffff;
  opacity: 0.7;
}
.btn:focus {
  box-shadow: none;
  color: #ffffff;
}
.hover__box__thumb {
  overflow: hidden;
  display: block;
  position: relative;
}
.hover__box img {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  transform: scale(1);
}
.hover__box:hover img {
  transform: scale(1.1);
}
.page-title {
  position: relative;
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.page-title__content {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  right: 15px;
}
.page-title__content > * {
  color: #ffffff;
}
.page-title__content h1 {
  font-size: 64px;
  font-weight: 500;
}
.page-title__content .page-title__slogan {
  font-size: 18px;
  font-style: italic;
}
.btn {
  border-radius: 20px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  background-color: #23d3d3;
  height: 40px;
  line-height: 40px;
  font-weight: 500;
  padding: 0 25px;
  color: #ffffff;
  outline: 0;
  outline-offset: 0;
}
.btn:hover {
  color: #ffffff;
}
.btn:focus {
  box-shadow: none;
  color: #ffffff;
}
.page-title--small {
  height: 120px;
  background-color: #23d3d3;
  margin-bottom: 60px;
  background-repeat: no-repeat;
  background-position: center right;
  background-size: contain;
}
.page-title--small .container {
  position: relative;
  height: 100%;
}
.page-title--small .page-title__content {
  text-align: left;
}
.page-title--small .page-title__content h1 {
  color: #ffffff;
  font-size: 40px;
  margin-bottom: 5px;
}
.page-title--small .page-title__content p {
  color: #ffffff;
  font-size: 16px;
  font-style: normal;
}
.flex-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
}
.grid .grid-item {
  padding-left: 15px;
  padding-right: 15px;
}
.company-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}
.company-info > img {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 570px;
  flex: 0 0 570px;
  max-width: 570px;
}
.company-info .ci-content {
  padding: 0 60px;
}
.company-info .ci-content span {
  font-family: "Jost-Book";
  font-size: 16px;
  line-height: 23px;
  color: #5d5d5d;
  margin-bottom: 10px;
}
.company-info .ci-content h2 {
  font-family: "Jost";
  font-size: 28px;
  color: #2d2d2d;
  font-weight: 500;
  margin-bottom: 30px;
  line-height: 39px;
}
.company-info .ci-content p {
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 15px;
}
.company-info .ci-content p:last-child {
  margin-bottom: 0;
}
.our-team {
  padding: 60px 0;
}
.ot-item {
  position: relative;
}
.ot-item .ot-info {
  position: absolute;
  left: 35px;
  bottom: 20px;
}
.ot-item .ot-info h3 {
  font-size: 20px;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 5px;
}
.ot-item .ot-info span.job {
  font-family: "Jost-Book";
  font-style: italic;
  font-size: 16px;
  color: #ffffff;
}
.join-team {
  padding-bottom: 60px;
}
.join-team .jt-content p {
  margin-bottom: 40px;
}
.join-team .jt-content a {
  padding: 0 48px;
  height: 50px;
  line-height: 50px;
  border-radius: 25px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  font-size: 16px;
}
.grid.grid-4 .grid-item {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}
/*! CSS Used from: https://hola9.com/static/mainClassified/css/responsive.css */
@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}
@media only screen and (max-width: 1200px) {
  .company-info > img {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media only screen and (max-width: 992px) {
  .container {
    padding-left: 25px;
    padding-right: 25px;
  }
  .page-title--small {
    background-position: center right -480px;
    background-size: cover;
  }
  .company-info {
    display: block;
  }
  .company-info > img {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }
  .company-info .ci-content {
    padding: 0;
  }
}
@media only screen and (max-width: 767px) {
  .page-title.page-title--small {
    background-image: none !important;
  }
  .grid.grid-4 {
    flex-wrap: wrap;
  }
  .grid.grid-4 .grid-item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 30px;
  }
  .our-team {
    padding: 30px 0 0;
  }
  .join-team {
    padding-bottom: 30px;
  }
}
@media only screen and (max-width: 575px) {
  .ot-item .ot-info {
    left: 15px;
    right: 15px;
    bottom: 10px;
  }
  .ot-item .ot-info h3,
  .ot-item .ot-info span.job {
    font-size: 14px;
    margin-bottom: 0;
  }
  .grid.grid-4 {
    margin-left: -5px;
    margin-right: -5px;
  }
  .grid.grid-4 .grid-item {
    padding-left: 5px;
    padding-right: 5px;
  }
}
@media only screen and (max-width: 375px) {
  .grid.grid-4 .grid-item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .page-title--small {
    margin-bottom: 30px;
  }
}
/*! CSS Used from: https://hola9.com/static/css/bootstrap.min.css */
*,
::after,
::before {
  box-sizing: border-box;
}
main {
  display: block;
}
h1,
h2,
h3 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}
img {
  vertical-align: middle;
  border-style: none;
}
h1,
h2,
h3 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}
h1 {
  font-size: 2.5rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.75rem;
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:focus,
.btn:hover {
  text-decoration: none;
}
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn:disabled {
  opacity: 0.65;
}
@media print {
  *,
  ::after,
  ::before {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  img {
    page-break-inside: avoid;
  }
  h2,
  h3,
  p {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  .container {
    min-width: 992px !important;
  }
}
/*! CSS Used from: https://hola9.com/static/css/main.css */
h1,
h2,
h3 {
  font-size: 36px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
}
a {
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
a:hover {
  text-decoration: none;
}
p {
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  margin: 0px;
  font-size: 14px;
}
.page-title {
  font-size: 62px;
  font-weight: 700;
  color: #fff;
}
.btn {
  font-size: 14px;
  padding: 7px 20px;
  cursor: pointer;
  font-weight: 400;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  display: inline-block;
}
.btn:focus,
.btn:active {
  box-shadow: none;
  outline: none;
  color: #fff;
}
/*! CSS Used fontfaces */
@font-face {
  font-family: "Jost";
  src: url("https://hola9.com/static/mainClassified/fonts/jost/Jost-Light.woff2")
      format("woff2"),
    url("https://hola9.com/static/mainClassified/fonts/jost/Jost-Light.woff")
      format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Jost";
  src: url("https://hola9.com/static/mainClassified/fonts/jost/Jost-Bold.woff2")
      format("woff2"),
    url("https://hola9.com/static/mainClassified/fonts/jost/Jost-Bold.woff")
      format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Jost";
  src: url("https://hola9.com/static/mainClassified/fonts/jost/Jost-Medium.woff2")
      format("woff2"),
    url("https://hola9.com/static/mainClassified/fonts/jost/Jost-Medium.woff")
      format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Jost-Book";
  src: url("https://hola9.com/static/mainClassified/fonts/jost/Jost-BookItalic.woff2")
      format("woff2"),
    url("https://hola9.com/static/mainClassified/fonts/jost/Jost-BookItalic.woff")
      format("woff");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Jost-Book";
  src: url("https://hola9.com/static/mainClassified/fonts/jost/Jost-Book.woff2")
      format("woff2"),
    url("https://hola9.com/static/mainClassified/fonts/jost/Jost-Book.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.gstatic.com/s/opensans/v28/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4taVIGxA.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.gstatic.com/s/opensans/v28/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4kaVIGxA.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.gstatic.com/s/opensans/v28/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4saVIGxA.woff2)
    format("woff2");
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.gstatic.com/s/opensans/v28/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4jaVIGxA.woff2)
    format("woff2");
  unicode-range: U+0370-03FF;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.gstatic.com/s/opensans/v28/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4iaVIGxA.woff2)
    format("woff2");
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.gstatic.com/s/opensans/v28/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4vaVIGxA.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.gstatic.com/s/opensans/v28/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4uaVIGxA.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.gstatic.com/s/opensans/v28/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4gaVI.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
