/*! CSS Used from: https://mironmahmud.com/classicads/assets/ltr/fonts/font-awesome/fontawesome.css */
.btn-sm{
    color: rgb(6, 14, 131);
    background: linear-gradient(270deg,#0f1c54,#2b224c) !important;
    border:1px solid rgb(6 14 131 / 35%);
    font-weight: 600;
    border-radius: 3px;
    font-size: 13px;
    padding: 6px 10px;
    font-family: "Poppins", sans-serif !important;
    height:35px;
}
.btn-sm:hover{
    box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%) !important;
}

.far,.fas{-moz-osx-font-smoothing:grayscale;-webkit-font-smoothing:antialiased;display:inline-block;font-style:normal;font-variant:normal;text-rendering:auto;line-height:1;}
.fa-clock:before{content:"\f017";}
.fa-compress:before{content:"\f066";}
.fa-eye:before{content:"\f06e";}
.fa-fire:before{content:"\f06d";}
.fa-heart:before{content:"\f004";}
.fa-long-arrow-alt-left:before{content:"\f30a";}
.fa-long-arrow-alt-right:before{content:"\f30b";}
.fa-map-marker-alt:before{content:"\f3c5";}
.fa-mouse:before{content:"\f8cc";}
.fa-star:before{content:"\f005";}
.fa-tags:before{content:"\f02c";}
.far{font-weight:400;}
.far,.fas{font-family:"Font Awesome 5 Free";}
.fas{font-weight:900;}
/*! CSS Used from: https://mironmahmud.com/classicads/assets/ltr/css/vendor/bootstrap.min.css */
*,::after,::before{box-sizing:border-box;}
section{display:block;}
h5{margin-top:0;margin-bottom:.5rem;}
p{margin-top:0;margin-bottom:1rem;}
ol,ul{margin-top:0;margin-bottom:1rem;}
a{color:#007bff;text-decoration:none;background-color:transparent;}
a:hover{color:#0056b3;text-decoration:underline;}
img{vertical-align:middle;border-style:none;}
label{display:inline-block;margin-bottom:.5rem;}
button{border-radius:0;}
button:focus{outline:1px dotted;outline:5px auto -webkit-focus-ring-color;}
button,select{margin:0;font-family:inherit;font-size:inherit;line-height:inherit;}
button{overflow:visible;}
button,select{text-transform:none;}
select{word-wrap:normal;}
[type=button],button{-webkit-appearance:button;}
[type=button]::-moz-focus-inner,button::-moz-focus-inner{padding:0;border-style:none;}
h5{margin-bottom:.5rem;font-weight:500;line-height:1.2;}
h5{font-size:1.25rem;}
.container{width:100%;padding-right:15px;padding-left:15px;margin-right:auto;margin-left:auto;}
@media (min-width:576px){
.container{max-width:540px;}
}
@media (min-width:768px){
.container{max-width:720px;}
}
@media (min-width:992px){
.container{max-width:960px;}
}
@media (min-width:1200px){
.container{max-width:1140px;}
}
@media (min-width:576px){
.container{max-width:540px;}
}
@media (min-width:768px){
.container{max-width:720px;}
}
@media (min-width:992px){
.container{max-width:960px;}
}
@media (min-width:1200px){
.container{max-width:1140px;}
}
.row{display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;margin-right:-15px;margin-left:-15px;}
.col-lg-12,.col-lg-4,.col-lg-6,.col-md-6,.col-sm-6,.col-xl-3{position:relative;width:100%;padding-right:15px;padding-left:15px;}
@media (min-width:576px){
.col-sm-6{-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
}
@media (min-width:768px){
.col-md-6{-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
}
@media (min-width:992px){
.col-lg-4{-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%;}
.col-lg-6{-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
.col-lg-12{-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
}
@media (min-width:1200px){
.col-xl-3{-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%;}
}
.custom-select{display:inline-block;width:100%;height:calc(1.5em + .75rem + 2px);padding:.375rem 1.75rem .375rem .75rem;font-size:1rem;font-weight:400;line-height:1.5;color:#495057;vertical-align:middle;background:#fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px;border:1px solid #ced4da;border-radius:.25rem;-webkit-appearance:none;-moz-appearance:none;appearance:none;}
.custom-select:focus{border-color:#80bdff;outline:0;box-shadow:0 0 0 .2rem rgba(0,123,255,.25);}
.custom-select:disabled{color:#6c757d;background-color:#e9ecef;}
.custom-select::-ms-expand{display:none;}
.custom-select:-moz-focusring{color:transparent;text-shadow:0 0 0 #495057;}
.custom-select{transition:background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;}
@media (prefers-reduced-motion:reduce){
.custom-select{transition:none;}
}
.breadcrumb{display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;padding:.75rem 1rem;margin-bottom:1rem;list-style:none;background-color:#e9ecef;border-radius:.25rem;}
.breadcrumb-item+.breadcrumb-item{padding-left:.5rem;}
.breadcrumb-item+.breadcrumb-item::before{display:inline-block;padding-right:.5rem;color:#6c757d;content:"/";}
.breadcrumb-item+.breadcrumb-item:hover::before{text-decoration:underline;}
.breadcrumb-item+.breadcrumb-item:hover::before{text-decoration:none;}
.breadcrumb-item.active{color:#6c757d;}
.pagination{display:-ms-flexbox;display:flex;padding-left:0;list-style:none;border-radius:.25rem;}
.page-link{position:relative;display:block;padding:.5rem .75rem;margin-left:-1px;line-height:1.25;color:#007bff;background-color:#fff;border:1px solid #dee2e6;}
.page-link:hover{z-index:2;color:#0056b3;text-decoration:none;background-color:#e9ecef;border-color:#dee2e6;}
.page-link:focus{z-index:3;outline:0;box-shadow:0 0 0 .2rem rgba(0,123,255,.25);}
.page-item:first-child .page-link{margin-left:0;border-top-left-radius:.25rem;border-bottom-left-radius:.25rem;}
.page-item:last-child .page-link{border-top-right-radius:.25rem;border-bottom-right-radius:.25rem;}
@media print{
*,::after,::before{text-shadow:none!important;box-shadow:none!important;}
a:not(.btn){text-decoration:underline;}
img{page-break-inside:avoid;}
p{orphans:3;widows:3;}
.container{min-width:992px!important;}
}
/*! CSS Used from: https://mironmahmud.com/classicads/assets/ltr/css/custom/main.css */
*{margin:0px;padding:0px;outline:0px;}
a{text-decoration:none;display:inline-block;}
a:hover{text-decoration:none;}
ul,ol{list-style:none;}
h5,p,ul,ol,li,span,label{margin-bottom:0px;}
h5{color:var(--heading);}
h5{font-size:18px;line-height:26px;font-weight:500;}
button{border:none;outline:none;background:none;}
button:focus{outline:none;}
.breadcrumb{align-items:center;background:none;padding:0px;margin:0px;}
.breadcrumb li{text-transform:capitalize;}
.breadcrumb li a{color:var(--chalk);text-shadow:var(--primary-tshadow);}
.breadcrumb li a:hover{color:var(--primary-light);text-decoration:underline;}
.breadcrumb .active{color:var(--primary-light);text-shadow:var(--primary-tshadow);}
.breadcrumb-item+.breadcrumb-item::before{color:var(--primary-light);text-shadow:var(--primary-tshadow);}
.sale{background:var(--sale);}
.rent{background:var(--rent);}
.booking{background:var(--booking);}
.flat-badge{color:var(--white);padding:2px 8px;border-radius:3px;font-size:13px;line-height:18px;letter-spacing:0.3px;text-transform:capitalize;}
.cross-vertical-badge{position:relative;display:inline-block;}
.cross-vertical-badge:hover span{visibility:visible;opacity:1;left:35px;}
.cross-vertical-badge i{font-size:16px;padding:8px 8px 12px;color:var(--white);text-align:center;background:var(--primary);border-bottom-left-radius:3px;border-bottom-right-radius:3px;clip-path:polygon(0 0, 53% 0, 100% 0, 100% 100%, 50% 85%, 0 100%);}
.cross-vertical-badge span{font-size:15px;padding:3px 10px;border-radius:5px;letter-spacing:0.3px;color:var(--white);background:var(--dark);text-transform:capitalize;white-space:nowrap;position:absolute;top:50%;left:20px;z-index:2;visibility:hidden;opacity:0;transform:translateY(-50%);transition:all linear .3s;-webkit-transition:all linear .3s;-moz-transition:all linear .3s;-ms-transition:all linear .3s;-o-transition:all linear .3s;}
.cross-vertical-badge span::before{position:absolute;content:"";width:8px;height:8px;border-radius:1px;background:var(--dark);left:-4px;top:50%;transform:translateY(-50%) rotate(45deg);}
.pagination{align-items:center;justify-content:center;}
.page-item{margin:0px 3px;}
.page-item:first-child .page-link,.page-item:last-child .page-link{border-radius:50%;}
.page-item .active{color:var(--white);background:var(--primary);}
.page-link{width:40px;height:40px;line-height:40px;text-align:center;border-radius:50%;font-weight:500;border:none;padding:0px;color:var(--gray);background:var(--white);}
.page-link:hover{color:var(--white);background:var(--primary);}
.page-link:focus{box-shadow:none;}
@media (max-width: 991px){
.page-link{width:35px;height:35px;line-height:35px;}
}
.product-card{border-radius:8px;margin-bottom:30px;background:gray;overflow:hidden;border:1px solid var(--border);transition:all linear .3s;-webkit-transition:all linear .3s;-moz-transition:all linear .3s;-ms-transition:all linear .3s;-o-transition:all linear .3s;}
.product-card:hover{background:var(--white);box-shadow:0px 10px 25px 0px rgba(0,0,0,0.1);}
.product-card:hover .product-media::before{visibility:visible;opacity:1;}
.product-card:hover .product-img img{transform:scale(1.08);}
.product-card:hover .product-action{visibility:visible;opacity:1;}
.product-media{position:relative;}
.product-media::before{position:absolute;content:"";top:0px;left:0px;width:100%;height:100%;z-index:1;background:linear-gradient(rgba(0,0,0,0) 65%, rgba(0,0,0,0.8) 90%);visibility:hidden;opacity:0;transition:all linear .3s;-webkit-transition:all linear .3s;-moz-transition:all linear .3s;-ms-transition:all linear .3s;-o-transition:all linear .3s;}
.product-img{overflow:hidden;height:170px;}
.product-img img{width:100%;transition:all linear .5s;}
.product-badge{position:absolute;top:0px;left:15px;z-index:2;}
.product-type{position:absolute;top:15px;right:15px;z-index:2;}
.product-action{position:absolute;left:0px;bottom:0px;z-index:2;width:100%;padding:15px 15px;visibility:hidden;opacity:0;display:flex;align-items:center;justify-content:space-between;transition:all linear .3s;-webkit-transition:all linear .3s;-moz-transition:all linear .3s;-ms-transition:all linear .3s;-o-transition:all linear .3s;}
.product-action li i{margin-right:5px;margin-bottom:1px;font-size:14px;}
.product-action li span{font-size:14px;color:var(--chalk);}
.product-action .view i{color:var(--view);}
.product-action .click i{color:var(--click);}
.product-action .rating i{color:var(--rating);}
.product-content{padding:0px 15px;}
.product-category{margin-bottom:12px;padding:10px 0px 8px;border-bottom:1px solid var(--border);}
.product-category li i{font-size:13px;color:#999999;margin-right:8px;}
.product-category .breadcrumb-item{font-size:13px;color:var(--gray);white-space:nowrap;}
.product-category .breadcrumb-item::before{color:var(--gray);}
.product-category .breadcrumb-item a{color:var(--gray);}
.product-category .breadcrumb-item a:hover{color:var(--primary);}
.product-title{margin-bottom:5px;}
.product-title a{color:var(--heading);transition:all linear .3s;-webkit-transition:all linear .3s;-moz-transition:all linear .3s;-ms-transition:all linear .3s;-o-transition:all linear .3s;}
.product-title a:hover{color:var(--primary);}
.product-meta{margin-bottom:12px;}
.product-meta span{font-size:13px;margin-right:15px;white-space:nowrap;}
.product-meta i{margin-right:5px;color:#DD4B3E;}
.product-info{padding:10px 0px;display:flex;align-items:center;justify-content:space-between;border-top:1px solid var(--border);}
.product-price{font-weight:700;font-family:sans-serif;}
.product-price span{font-size:12px;font-weight:400;margin-left:3px;color:var(--gray);white-space:nowrap;text-transform:capitalize;}
/* .product-btn a,.product-btn button{margin-left:8px;padding-left:5px;color:white;border-left:1px solid var(--border);transition:all linear .3s;-webkit-transition:all linear .3s;-moz-transition:all linear .3s;-ms-transition:all linear .3s;-o-transition:all linear .3s;} */
/* .product-btn a:hover,.product-btn button:hover{color:var(--white);} */
@media (min-width: 380px) and (max-width: 575px){
.product-card{width:350px;margin:0px auto 30px;}
}
.header-filter{display:flex;align-items:center;justify-content:space-between;margin-bottom:30px;}
.filter-short{width:220px;display:flex;align-items:center;justify-content:center;}
.filter-show{width:120px;display:flex;align-items:center;justify-content:center;}
.filter-label{font-size:14px;font-weight:500;margin-right:8px;white-space:nowrap;text-transform:uppercase;}
.filter-select{height:40px;font-size:14px;border-radius:3px;text-transform:capitalize;cursor:pointer;}
.filter-select:focus{border-color:#ced4da;box-shadow:none;}
@media (max-width: 575px){
.filter-label{display:none;}
.filter-show{width:80px;}
.filter-short{width:130px;}
}
.footer-pagection{padding-top:30px;border-top:1px solid var(--border);display:flex;align-items:center;justify-content:space-between;}
.page-info{font-weight:500;color:var(--gray);}
@media (max-width: 575px){
.footer-pagection{margin-bottom:30px;flex-direction:column;}
.page-info{margin-bottom:15px;}
}
@media (min-width: 576px) and (max-width: 991px){
.footer-pagection{margin-bottom:30px;}
}
/*! CSS Used from: https://mironmahmud.com/classicads/assets/ltr/css/custom/my-ads.css */
.myads-part{padding:50px 0px 70px;}
@media (max-width: 767px){
.myads-part{padding:50px 0px 30px;}
}
@media (min-width: 768px) and (max-width: 991px){
.myads-part{padding:50px 0px 50px;}
}
/*! CSS Used fontfaces */
@font-face{font-family:"Font Awesome 5 Free";font-style:normal;font-weight:400;font-display:auto;src:url(https://mironmahmud.com/classicads/assets/ltr/fonts/font-awesome/fa-regular-400.eot);src:url(https://mironmahmud.com/classicads/assets/ltr/fonts/font-awesome/fa-regular-400.eot#iefix) format("embedded-opentype"),url(https://mironmahmud.com/classicads/assets/ltr/fonts/font-awesome/fa-regular-400.woff2) format("woff2"),url(https://mironmahmud.com/classicads/assets/ltr/fonts/font-awesome/fa-regular-400.woff) format("woff"),url(https://mironmahmud.com/classicads/assets/ltr/fonts/font-awesome/fa-regular-400.ttf) format("truetype"),url(https://mironmahmud.com/classicads/assets/ltr/fonts/font-awesome/fa-regular-400.svg#fontawesome) format("svg");}
@font-face{font-family:"Font Awesome 5 Free";font-style:normal;font-weight:900;font-display:auto;src:url(https://mironmahmud.com/classicads/assets/ltr/fonts/font-awesome/fa-solid-900.eot);src:url(https://mironmahmud.com/classicads/assets/ltr/fonts/font-awesome/fa-solid-900.eot#iefix) format("embedded-opentype"),url(https://mironmahmud.com/classicads/assets/ltr/fonts/font-awesome/fa-solid-900.woff2) format("woff2"),url(https://mironmahmud.com/classicads/assets/ltr/fonts/font-awesome/fa-solid-900.woff) format("woff"),url(https://mironmahmud.com/classicads/assets/ltr/fonts/font-awesome/fa-solid-900.ttf) format("truetype"),url(https://mironmahmud.com/classicads/assets/ltr/fonts/font-awesome/fa-solid-900.svg#fontawesome) format("svg");}

/* active ads */
.myadgrid{
    display: grid;
    grid-template-columns: repeat(2,1fr);

}

.bytn1 {
    position: relative;
    padding: 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 16px;
    transition: color 0.3s ease-in-out;
    color: rgb(2, 2, 2);
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  }
  
  
  
  /* Add a class to indicate the active state (e.g., your "active" class) */
  .active {
    color: rgb(55, 44, 80) !important; /* Change text color when active */
  }
  
  /* Style the ::before pseudo-element for the active button */
  .active::before {
    width: 100%; /* Expand the width to create the sliding effect */
    left: 0; /* Reset the left position */
  }
















  .ad-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
  }
  