
.card {
    flex: 0 0 calc(30% - 20px); /* Adjust the width of the cards as needed */
    margin: 10px; /* Add margin to create space between cards */
    border: 1px solid #e1e4e8;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    background-color: #fff;
    position: relative;
    max-height: 300px;
    overflow: hidden;
  }
  
  
  .card img {
    width: 100%;
    height: auto;
  }
  
  /* .card-bodyyy {
  } */
  
  .card-titlee {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .card-text_text {
    font-size: 16px;
    color: #000000;
    margin-bottom: 15px;
  }
  .text-mutedd {
    position: absolute;
    bottom: 15px;
    right: 10px;
    color: #111010;
  }
  
  @media (max-width:'750px'){
    .card img {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
      }
  }
  