/*! CSS Used from: https://templates.scriptsbundle.com/carspot/demos/css/bootstrap.css */
/* //plan table css */

.loader  {
    border: 2px solid #f3f3f3;
    border-radius: 50%;
    border-top: 2px solid #3498db;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 500ms linear infinite; /* Safari */
    animation: spin 500ms linear infinite;
  }
  
  
 /* Circular progressbar & profile icon */

 .dp-profile {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-shadow: 0 0 5px black;
 }

 .progress-circle-1 {
    fill: none;
    stroke: #ddd;
 }
 .progress-circle-2 {
    fill: none;
    stroke: #3ce772;
    stroke-linecap: round;
 }
 .progress-circle-text {
    background-color: #0085db;
 }


  /* UserDashPlanDetails */
  .dp-ud-b {
    background-color: rgb(0, 0, 0, 0.1);
    border-radius: 5px;
  }



  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

 th , td {
    text-align: left;
    padding-right: 5px;
    color:white
  }

section {
    display: block;
}

a {
    background-color: transparent;
}
.inlineAds{
    display: flex;
}
a:active,
a:hover {
    outline: 0;
}

b,
strong {
    font-weight: bold;
}

small {
    font-size: 80%;
}

img {
    border: 0;
}

button,
input,
select,
textarea {
    margin: 0;
    font: inherit;
    color: inherit;
}

button {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

button {
    -webkit-appearance: button;
    cursor: pointer;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    padding: 0;
    border: 0;
}

input {
    line-height: normal;
}

input[type="checkbox"] {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
}

textarea {
    overflow: auto;
}

@media print {

    *,
    *:before,
    *:after {
        color: #000 !important;
        text-shadow: none !important;
        background: transparent !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    a[href]:after {
        content: " (" attr(href) ")";
    }

    a[href^="#"]:after,
    a[href^="javascript:"]:after {
        content: "";
    }

    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    p,
    h2 {
        orphans: 3;
        widows: 3;
    }

    h2 {
        page-break-after: avoid;
    }

    .label {
        border: 1px solid #000;
    }
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

input,
button,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

a {
    color: #337ab7;
    text-decoration: none;
}

a:hover,
a:focus {
    color: #23527c;
    text-decoration: underline;
}

a:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

img {
    vertical-align: middle;
}

.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
}

h2,
h4 {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
}

h2 {
    margin-top: 20px;
    margin-bottom: 10px;
}

h4 {
    margin-top: 10px;
    margin-bottom: 10px;
}

h2 {
    font-size: 30px;
}

h4 {
    font-size: 18px;
}

p {
    margin: 0 0 10px;
}

small {
    font-size: 85%;
}

.text-right {
    text-align: right;
}

ul {
    margin-top: 0;
    margin-bottom: 10px;
}

dl {
    margin-top: 0;
    margin-bottom: 20px;
}

dt,
dd {
    line-height: 1.42857143;
}

dt {
    font-weight: bold;
}

dd {
    margin-left: 0;
}

@media (min-width: 768px) {
    .dl-horizontal dt {
        float: left;
        width: 160px;
        overflow: hidden;
        clear: left;
        text-align: right;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .dl-horizontal dd {
        margin-left: 180px;
    }
        .container {
            /* max-width: 100% !important; */
            margin: 0;
        }
    
}

.container {
    padding-top:30px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}

@media (min-width: 992px) {
    .container {
        width: 970px;
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}
.search-result-item {
    background-image: linear-gradient(to left, #3e95f1, #f8f4f4);
    box-shadow: "2px 20px 8px 5px gray",




}

.row {
    margin-right: -15px;
    margin-left: -15px;
}

.col-md-3,
.col-sm-4,
.col-md-4,
.col-md-5,
.col-sm-6,
.col-md-6,
.col-md-7,
.col-sm-8,
.col-md-8,
.col-md-9,
.col-xs-12,
.col-sm-12,
.col-md-12 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.col-xs-12 {
    float: left;
}

.col-xs-12 {
    width: 100%;
}

@media (min-width: 768px) {

    .col-sm-4,
    .col-sm-6,
    .col-sm-8,
    .col-sm-12 {
        float: left;
    }

    .col-sm-12 {
        width: 100%;
    }

    .col-sm-8 {
        width: 66.66666667%;
    }

    .col-sm-6 {
        width: 50%;
    }

    .col-sm-4 {
        width: 33.33333333%;
    }
}

@media (min-width: 992px) {

    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-12 {
        float: left;
    }

    .col-md-12 {
        width: 100%;
    }

    .col-md-9 {
        width: 75%;
    }

    .col-md-8 {
        width: 66.66666667%;
    }

    .col-md-7 {
        width: 58.33333333%;
    }

    .col-md-6 {
        width: 50%;
    }

    .col-md-5 {
        width: 41.66666667%;
    }

    .col-md-4 {
        width: 33.33333333%;
    }

    .col-md-3 {
        width: 25%;
    }
}

label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: bold;
}

input[type="checkbox"] {
    margin: 4px 0 0;
    margin-top: 1px \9;
    line-height: normal;
}

input[type="file"] {
    display: block;
}

input[type="file"]:focus,
input[type="checkbox"]:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

.form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.form-control:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
}

.form-control::-moz-placeholder {
    color: #999;
    opacity: 1;
}

.form-control:-ms-input-placeholder {
    color: #999;
}

.form-control::-webkit-input-placeholder {
    color: #999;
}

.form-control::-ms-expand {
    background-color: transparent;
    border: 0;
}

.form-control[readonly] {
    background-color: #eee;
    opacity: 1;
}

textarea.form-control {
    height: auto;
}

.form-group {
    margin-bottom: 15px;
}

.btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}

.btn:focus,
.btn:active:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

.btn:hover,
.btn:focus {
    color: #333;
    text-decoration: none;
}

.btn:active {
    background-image: none;
    outline: 0;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}

.btn-default {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
}

.btn-default:focus {
    color: #333;
    background-color: #e6e6e6;
    border-color: #8c8c8c;
}

.btn-default:hover {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
}

.btn-default:active {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
}

.btn-default:active:hover,
.btn-default:active:focus {
    color: #333;
    background-color: #d4d4d4;
    border-color: #8c8c8c;
}

.btn-default:active {
    background-image: none;
}

.btn-sm {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}

.fade {
    opacity: 0;
    -webkit-transition: opacity .15s linear;
    -o-transition: opacity .15s linear;
    transition: opacity .15s linear;
}

.fade.in {
    opacity: 1;
}

.input-group {
    position: relative;
    display: table;
    border-collapse: separate;
}

.input-group .form-control {
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0;
}

.input-group .form-control:focus {
    z-index: 3;
}

.input-group-btn,
.input-group .form-control {
    display: table-cell;
}

.input-group-btn {
    width: 1%;
    white-space: nowrap;
    vertical-align: middle;
}

.input-group-btn:first-child>.btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group .form-control:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group-btn {
    position: relative;
    font-size: 0;
    white-space: nowrap;
}

.input-group-btn>.btn {
    position: relative;
}

.input-group-btn>.btn:hover,
.input-group-btn>.btn:focus,
.input-group-btn>.btn:active {
    z-index: 2;
}

.input-group-btn:first-child>.btn {
    margin-right: -1px;
}

.nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.nav>li {
    position: relative;
    display: block;
}

.nav>li>a {
    position: relative;
    display: block;
    padding: 10px 15px;
}

.nav>li>a:hover,
.nav>li>a:focus {
    text-decoration: none;
    background-color: #eee;
}

.nav-tabs {
    border-bottom: 1px solid #ddd;
}

.nav-tabs>li {
    float: left;
    margin-bottom: -1px;
}

.nav-tabs>li>a {
    margin-right: 2px;
    line-height: 1.42857143;
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0;
}

.nav-tabs>li>a:hover {
    border-color: #eee #eee #ddd;
}

.nav-tabs>li.active>a,
.nav-tabs>li.active>a:hover,
.nav-tabs>li.active>a:focus {
    color: #555;
    cursor: default;
    background-color: #fff;
    border: 1px solid #ddd;
    border-bottom-color: transparent;
}

.nav-tabs.nav-justified {
    width: 100%;
    border-bottom: 0;
}

.nav-tabs.nav-justified>li {
    float: none;
}

.nav-tabs.nav-justified>li>a {
    margin-bottom: 5px;
    text-align: center;
}

@media (min-width: 768px) {
    .nav-tabs.nav-justified>li {
        display: table-cell;
        width: 1%;
    }

    .nav-tabs.nav-justified>li>a {
        margin-bottom: 0;
    }
}

.nav-tabs.nav-justified>li>a {
    margin-right: 0;
    border-radius: 4px;
}

.nav-tabs.nav-justified>.active>a,
.nav-tabs.nav-justified>.active>a:hover,
.nav-tabs.nav-justified>.active>a:focus {
    border: 1px solid #ddd;
}

@media (min-width: 768px) {
    .nav-tabs.nav-justified>li>a {
        border-bottom: 1px solid #ddd;
        border-radius: 4px 4px 0 0;
    }

    .nav-tabs.nav-justified>.active>a,
    .nav-tabs.nav-justified>.active>a:hover,
    .nav-tabs.nav-justified>.active>a:focus {
        border-bottom-color: #fff;
    }
}

.nav-justified {
    width: 100%;
}

.nav-justified>li {
    float: none;
}

.nav-justified>li>a {
    margin-bottom: 5px;
    text-align: center;
}

@media (min-width: 768px) {
    .nav-justified>li {
        display: table-cell;
        width: 1%;
    }

    .nav-justified>li>a {
        margin-bottom: 0;
    }
}

.tab-content>.tab-pane {
    display: none;
}

.tab-content>.active {
    display: block;
}

.label {
    display: inline;
    padding: 5px;
    /* margin: 1px; */
    font-size: 14px;
    font-weight: bold;
    line-height: 1;
    color: #0085db;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
    
    
}

.label:empty {
    display: none;
}

.label-success {
    background-color: #5cb85c;
}

.label-warning {
    background-color: #f0ad4e;
}

.clearfix:before,
.clearfix:after,
.dl-horizontal dd:before,
.dl-horizontal dd:after,
.container:before,
.container:after,
.row:before,
.row:after,
.nav:before,
.nav:after {
    display: table;
    content: " ";
}

.clearfix:after,
.dl-horizontal dd:after,
.container:after,
.row:after,
.nav:after {
    clear: both;
}

.center-block {
    display: block;
    margin-right: auto;
    margin-left: auto;
}

/*! CSS Used from: https://templates.scriptsbundle.com/carspot/demos/css/style.css */
a {
    -webkit-transition: all .25s ease-in-out;
    -moz-transition: all .25s ease-in-out;
    -ms-transition: all .25s ease-in-out;
    -o-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out;
    cursor: pointer;
}

a:hover,
a:focus {
    text-decoration: none;
    o-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

img {
    max-width: 100%;
}

a,
b,
div,
ul,
li {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    -moz-outline-: none;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

b,
strong {
    font-weight: 600;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-top-20 {
    margin-top: 20px;
}

.margin-top-40 {
    margin-top: 40px;
}

h2,
h4 {
    position: relative;
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
    margin: 0px;
    background: none;
    line-height: 1.6em;
}

h2 {
    font-size: 24px;
}

h4 {
    font-size: 16px;
}

p {
    line-height: 1.6em;
    font-size: 16px;
}

.btn {
    font-size: 14px;
    letter-spacing: 1px;
    padding: 10px 15px;
    border-radius: 1px;
    -webkit-transition: all .25s ease-in-out;
    -moz-transition: all .25s ease-in-out;
    -ms-transition: all .25s ease-in-out;
    -o-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out;
    font-weight: 600;
    font-family: 'Source Sans Pro', sans-serif;
    text-transform: uppercase;
}

.form-control {
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 1px;
    box-shadow: none;
    color: #555;
    font-size: 14px;
    height: auto;
    padding: 15px 12px;
    transition: all 0.2s cubic-bezier(0, 0, 0.58, 1) 0s;
}

ul {
    margin-left: 0;
}

.btn-sm {
    padding: 8px 16px;
    font-size: 12px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
}

.skin-minimal .list li label {
    padding-left: 10px;
    font-weight: 500;
    color: #232323;
    font-size: 16px;
}

.skin-minimal .list li label:hover {
    text-decoration: none;
}

label {
    font-size: 14px;
    font-weight: 600;
    color: #232323;
}

.text-right {
    text-align: right;
    float: right;
}

@media (min-width:767px) {

    .col-md-4:nth-child(3n+1),
    .col-md-6:nth-child(2n+1) {
        clear: none;
    }

    .col-sm-4:nth-child(3n+1),
    .col-sm-6:nth-child(2n+1) {
        clear: left;
    }
}

@media (min-width:992px) {

    .col-sm-4:nth-child(3n+1),
    .col-sm-6:nth-child(2n+1) {
        clear: none;
    }

    .col-md-4:nth-child(3n+1),
    .col-md-6:nth-child(2n+1) {
        clear: left;
    }
}

@media (min-width:1200px) {

    .col-md-4:nth-child(3n+1),
    .col-md-6:nth-child(2n+1),
    .col-sm-4:nth-child(3n+1),
    .col-sm-6:nth-child(2n+1) {
        clear: none;
    }
}

.form-control:focus {
    border-color: inherit;
    box-shadow: none;
}

.label.label-success {
    background: white none repeat scroll 0 0;
    border: 2px solid #0085db;
    
}

.label.label-warning {
    background: green none repeat scroll 0 0;
    color: white;
    
}

.btn-file {
    position: relative;
    overflow: hidden;
    padding: 15px 30px !important;
}

.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}

#img-upload {
    width: 100%;
}

.profile-edit {
    background: #fff none repeat scroll 0 0;
    padding: 45px;
}

.profile-edit h2 {
    font-weight: 500;
}

.profile-edit dt {
    text-align: inherit;
}

.profile-edit dt strong {
    color: #232323;
    font-weight: 600;
}

.tab-content {
    padding: 0;
}

.profile-edit h2 {
    color: #323232;
    font-size: 18px;
    font-weight: 500;
    margin-top: 20px;
    text-transform: capitalize;
}

@media (max-width: 768px) {
    .profile-tabs .nav-tabs {
        border-bottom: medium none;
    }
}

.nav-tabs>li>a,
.nav-tabs.nav-justified>li>a {
    border-radius: 0;
}

.profile-tabs .nav-tabs {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border-image: none;
    border-style: none none solid;
    border-width: medium medium 2px;
}

.profile-tabs .nav-tabs a {
    color: #232323;
    font-size: 16px;
    padding: 16px 15px;
}

.profile-tabs .nav-tabs>.active>a,
.profile-tabs .nav-tabs>.active>a:hover,
.profile-tabs .nav-tabs>.active>a:focus {
    background: #f1f1f1 none repeat scroll 0 0;
    border: medium none;
    color: #232323;
}

.profile-tabs .nav-tabs>li>a {
    border: medium none;
}

.profile-tabs .nav-tabs>li>a:hover {
    background: #f1f1f1 none repeat scroll 0 0;
    color: #232323;
}

.profile-tabs .tab-content img {
    margin-bottom: 15px;
    margin-top: 4px;
}

.profile-section .skin-minimal .list li label {
    color: #777;
}

.profile-section .skin-minimal ul.list li {
    margin-bottom: 10px;
    padding: 10px 0;
    border-bottom: 1px solid #eee;
}

.ad-history {
    margin: 30px 0 0 0;
}

.user-stats {
    border-radius: 2px;
    padding: 15px;
}

.col-md-4:nth-child(1) .user-stats {
    background: #34495e;
}

.col-md-4:nth-child(2) .user-stats {
    background: #3498db;
}

.col-md-4:nth-child(3) .user-stats {
    background: #1abc9c;
}

.user-stats>h2 {
    color: #fff;
    font-weight: 500;
    margin: 0;
}

.user-stats>small {
    color: rgba(255, 255, 255, 0.7);
    display: block;
    margin-top: 1px;
    text-transform: uppercase;
}

.dashboard-menu-container {
    background-color: #fff;
    border-top: 1px solid #f1f1f1;
    overflow: hidden;
    position: relative;
}

.dashboard-menu-container ul {
    margin-bottom: 0;
}

.dashboard-menu-container ul li {
    border-right: 1px solid #f1f1f1;
    display: inline-block;
    text-align: center;
    width: 14%;
}

.dashboard-menu-container ul li:last-child {
    border-right: medium none;
}

.dashboard-menu-container ul li .menu-name {
    color: #555;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 0;
    text-transform: uppercase;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.dl-horizontal dd {
    border-bottom: 1px solid #f4f4f4;
    margin-left: 0;
    margin-bottom: 5px;
    margin-top: 5px;
    padding: 10px 0;
}

.profile-edit .dl-horizontal dd:last-child {
    border-bottom: medium none;
}

.profile-edit dl:last-child {
    margin-bottom: 0;
}

.dl-horizontal dt {
    padding: 10px 0;
}

.search-result-item {
    padding: 20px;
    background-color: #fff;
    border-radius: 4px;
}

.search-result-item:after,
.search-result-item:before {
    content: " ";
    display: table;
}

.search-result-item:after {
    clear: both;
}

.search-result-item .image-link {
    display: block;
    overflow: hidden;
}

@media (min-width:768px) {
    .search-result-item .image-link {
        display: inline-block;
        margin: -20px 0 -20px -20px;
        float: left;
        width: 200px;
    }
}

@media (max-width:767px) {
    .search-result-item .image-link {
        max-height: 200px;
    }
}

.search-result-item .image {
    max-width: 100%;
}

.search-result-item .info {
    margin-top: 2px;
    font-size: 14px;
}

.search-result-item .info span {
    border-right: 1px solid #777;
    padding-right: 10px;
    padding-left: 10px;
    line-height: 2px;
    display: inline-block;
}

.search-result-item .info span a {
    color: inherit;
}

.search-result-item .info span i {
    padding-right: 5px;
}

.search-result-item .info span:first-child {
    padding-left: 0;
}

.search-result-item .info span:last-child {
    border-right: medium none;
}

.search-result-item .description {
    font-size: 14px;
}

@media (min-width:768px) {
    .search-result-item-body {
        margin-left: 200px;
    }
}

.search-result-item-heading {
    color: #323232;
    font-size: 22px;
    font-weight: 500;
    margin-top: 20px;
    text-transform: capitalize;
}

.search-result-item-heading a {
    color: #323232;
}
.search-result-item-heading-div {
    & h4, p {

        margin: 0 !important;
    }
            }

@media (min-width:768px) {
    .search-result-item-heading {
        margin: 0;
    }
}
@media (max-width : 768px) {
    .search-result-item-heading-div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        & h4, p {

            margin: 0 !important;
        }
                }
}

/*! CSS Used from: https://templates.scriptsbundle.com/carspot/demos/css/font-awesome.css */
.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fa-user:before {
    content: "\f007";
}

.fa-edit:before {
    content: "\f044";
}

/*! CSS Used from: https://templates.scriptsbundle.com/carspot/demos/css/select2.min.css */
.select2-container {
    box-sizing: border-box;
    display: inline-block;
    margin: 0;
    position: relative;
    vertical-align: middle;
}

.select2-container .select2-selection--single {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    height: 28px;
    user-select: none;
    -webkit-user-select: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
    display: block;
    padding-left: 8px;
    padding-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.select2-hidden-accessible {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
}

.select2-container--default .select2-selection--single {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 0px;
    height: 55px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 50px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 50px;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 20px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: #888 transparent transparent transparent;
    border-style: solid;
    border-width: 5px 4px 0 4px;
    height: 0;
    left: 50%;
    margin-left: -4px;
    margin-top: -2px;
    position: absolute;
    top: 50%;
    width: 0;
}

/*! CSS Used from: https://templates.scriptsbundle.com/carspot/demos/skins/minimal/minimal.css */
.icheckbox_minimal {
    display: inline-block;
    /* *display: inline; */
    vertical-align: middle;
    margin: 0;
    padding: 0;
    width: 18px;
    height: 18px;
    background: url(https://templates.scriptsbundle.com/carspot/demos/skins/minimal/minimal.png) no-repeat;
    border: none;
    cursor: pointer;
}

.icheckbox_minimal {
    background-position: 0 0;
}

@media (-o-min-device-pixel-ratio: 5/4),
(-webkit-min-device-pixel-ratio: 1.25),
(min-resolution: 120dpi),
(min-resolution: 1.25dppx) {
    .icheckbox_minimal {
        background-image: url(https://templates.scriptsbundle.com/carspot/demos/skins/minimal/minimal@2x.png);
        -webkit-background-size: 200px 20px;
        background-size: 200px 20px;
    }
}

/*! CSS Used from: https://templates.scriptsbundle.com/carspot/demos/css/responsive-media.css */
@media (min-width: 768px) and (max-width: 1279px) {
    .search-result-item .search-result-item-body .ad-history .col-md-4 {
        padding: 0;
    }

    .search-result-item .search-result-item-body .ad-history .user-stats {
        border-radius: 0;
        padding: 5px 15px;
    }

    .select2-container--default .select2-selection--single {
        margin-bottom: 20px !important;
    }

    .col-sm-4:nth-child(3n+1) {
        clear: none;
    }

    .profile-tabs .nav-tabs a {
        text-align: left;
    }

    .dashboard-menu-container ul li {
        width: 19%;
    }
}

@media (min-width: 320px) and (max-width: 767px) {
    .select2-container--default .select2-selection--single .select2-selection__arrow {
        height: 50px;
        right: 10px;
    }

    .select2-container--default .select2-selection--single {
        margin-bottom: 20px !important;
    }

    .profile-tabs .nav-tabs a {
        text-align: left !important;
    }

    .profile-edit h2 {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .profile-section .skin-minimal .list li label {
        margin-top: -30px;
        padding-left: 35px;
    }

    .search-result-item .image-link {
        max-height: 100%;
    }

    .user-stats {
        margin-bottom: 10px;
    }

    .dashboard-menu-container ul li {
        width: 50%;
        float: left;
        border-bottom: 1px solid #f1f1f1;
    }

    .search-result-item .search-result-item-body .ad-history .col-md-4 {
        padding: 0;
    }

    .search-result-item .search-result-item-body .ad-history .user-stats {
        border-radius: 0;
        padding: 5px 15px;
    }
}

/*! CSS Used from: https://templates.scriptsbundle.com/carspot/demos/css/colors/defualt.css */
a:hover,
a:focus {
    color: #E52D27;
}

a,
.skin-minimal .list li label:hover,
.dashboard-menu-container ul li.active .menu-name,
.dashboard-menu-container ul li:hover .menu-name,
.search-result-item .info span a:hover {
    color: #E52D27;
}

.btn-theme {
    color: #ffffff;
    background-color: #E52D27;
    border-color: #E52D27;
}

.btn-theme:hover,
.btn-theme:focus,
.btn-theme:active {
    color: #ffffff;
    background-color: #B31217;
    border-color: #B31217;
}

.btn-theme:active {
    background-image: none;
}

.profile-tabs .nav-tabs {
    background-color: #fff;
    border-color: #E52D27;
}

/*! CSS Used fontfaces */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJbecmNE.woff2) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJnecmNE.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJfecg.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z11lFc-K.woff2) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1JlFc-K.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1xlFQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z11lFc-K.woff2) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1JlFc-K.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1xlFQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPZ7qsDJT9g.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPZ7jsDJT9g.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPZ7rsDJT9g.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPZ7ksDJT9g.woff2) format('woff2');
    unicode-range: U+0370-03FF;
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPZ7osDJT9g.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPZ7psDJT9g.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPZ7nsDI.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK3dSBYKcSV-LCoeQqfX1RYOo3qNa7lqDY.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK3dSBYKcSV-LCoeQqfX1RYOo3qPK7lqDY.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK3dSBYKcSV-LCoeQqfX1RYOo3qNK7lqDY.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK3dSBYKcSV-LCoeQqfX1RYOo3qO67lqDY.woff2) format('woff2');
    unicode-range: U+0370-03FF;
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK3dSBYKcSV-LCoeQqfX1RYOo3qN67lqDY.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK3dSBYKcSV-LCoeQqfX1RYOo3qNq7lqDY.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK3dSBYKcSV-LCoeQqfX1RYOo3qOK7l.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwmhduz8A.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwkxduz8A.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwmxduz8A.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwlBduz8A.woff2) format('woff2');
    unicode-range: U+0370-03FF;
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwmBduz8A.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwmRduz8A.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwlxdu.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'FontAwesome';
    src: url('https://templates.scriptsbundle.com/carspot/demos/fonts/fontawesome-webfont.eot?v=4.7.0');
    src: url('https://templates.scriptsbundle.com/carspot/demos/fonts/fontawesome-webfont.eot#iefix&v=4.7.0') format('embedded-opentype'), url('https://templates.scriptsbundle.com/carspot/demos/fonts/fontawesome-webfont.woff2?v=4.7.0') format('woff2'), url('https://templates.scriptsbundle.com/carspot/demos/fonts/fontawesome-webfont.woff?v=4.7.0') format('woff'), url('https://templates.scriptsbundle.com/carspot/demos/fonts/fontawesome-webfont.ttf?v=4.7.0') format('truetype'), url('https://templates.scriptsbundle.com/carspot/demos/fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
