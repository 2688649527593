/*! CSS Used from: https://mironmahmud.com/classicads/assets/ltr/fonts/font-awesome/fontawesome.css */
.fas {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
}

.fa-home:before {
    content: "\f015";
}

.fas {
    font-family: "Font Awesome 5 Free";
}

.fas {
    font-weight: 900;
}

/*! CSS Used from: https://mironmahmud.com/classicads/assets/ltr/css/vendor/bootstrap.min.css */
*,
::after,
::before {
    box-sizing: border-box;
}

section {
    display: block;
}

h1,
h2 {
    margin-top: 0;
    margin-bottom: .5rem;
}

a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

a:hover {
    color: #0056b3;
    text-decoration: underline;
}

h1,
h2 {
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
}

h1 {
    font-size: 2.5rem;
}

h2 {
    font-size: 2rem;
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width:576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width:768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width:992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width:1200px) {
    .container {
        max-width: 1140px;
    }
}

@media (min-width:576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width:768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width:992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width:1200px) {
    .container {
        max-width: 1140px;
    }
}

.btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: linear-gradient(270deg,#0f1c54,#2b224c);
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion:reduce) {
    .btn {
        transition: none;
    }
}

.btn:hover {
    color: #212529;
    text-decoration: none;
}

.btn:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25);
}

.btn:disabled {
    opacity: .65;
}

@media print {

    *,
    ::after,
    ::before {
        text-shadow: none !important;
        box-shadow: none !important;
    }

    h2 {
        orphans: 3;
        widows: 3;
    }

    h2 {
        page-break-after: avoid;
    }

    .container {
        min-width: 992px !important;
    }
}

/*! CSS Used from: https://mironmahmud.com/classicads/assets/ltr/css/custom/main.css */
* {
    margin: 0px;
    padding: 0px;
    outline: 0px;
}

a {
    text-decoration: none;
    display: inline-block;
}

a:hover {
    text-decoration: none;
}

h1,
h2,
span {
    margin-bottom: 0px;
}

h1,
h2 {
    color: var(--heading);
}

h1 {
    font-size: 40px;
    line-height: 48px;
    font-weight: 700;
}

h2 {
    font-size: 38px;
    line-height: 46px;
    font-weight: 700;
}

.btn {
    border: none;
    font-size: 14px;
    font-weight: 500;
    border: 2px solid;
    padding: 14px 32px;
    border-radius: 8px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    text-shadow: var(--primary-tshadow);
    transition: all linear .3s;
    -webkit-transition: all linear .3s;
    -moz-transition: all linear .3s;
    -ms-transition: all linear .3s;
    -o-transition: all linear .3s;
}

.btn:focus {
    box-shadow: none !important;
}

.btn i {
    margin-top: -1px;
    margin-right: 5px;
}

.btn-outline {
    color: var(--primary);
    background: var(--white);
    border-color: var(--primary);
}

.btn-outline:hover {
    color: var(--white);
    background: var(--primary);
    border-color: var(--primary);
}

@media (max-width: 575px) {
    h2 {
        font-size: 28px;
        line-height: 36px;
    }
}

/*! CSS Used from: https://mironmahmud.com/classicads/assets/ltr/css/custom/404.css */
.error-part {
    padding: 100px 0px;
    background: var(--chalk);
}

.error {
    text-align: center;
}

.error h1 {
    font-size: 350px;
    line-height: 280px;
    letter-spacing: -10px;
    margin-bottom: 25px;
    color: var(--primary);
}

.error h2 {
    margin-bottom: 25px;
}

@media (max-width: 767px) {
    .error-part {
        padding: 60px 0px;
    }

    .error h1 {
        font-size: 160px;
        line-height: 90px;
        letter-spacing: -5px;
        margin-bottom: 45px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .error-part {
        padding: 80px 0px;
    }
}

/*! CSS Used fontfaces */
@font-face {
    font-family: "Font Awesome 5 Free";
    font-style: normal;
    font-weight: 400;
    font-display: auto;
    src: url(https://mironmahmud.com/classicads/assets/ltr/fonts/font-awesome/fa-regular-400.eot);
    src: url(https://mironmahmud.com/classicads/assets/ltr/fonts/font-awesome/fa-regular-400.eot#iefix) format("embedded-opentype"), url(https://mironmahmud.com/classicads/assets/ltr/fonts/font-awesome/fa-regular-400.woff2) format("woff2"), url(https://mironmahmud.com/classicads/assets/ltr/fonts/font-awesome/fa-regular-400.woff) format("woff"), url(https://mironmahmud.com/classicads/assets/ltr/fonts/font-awesome/fa-regular-400.ttf) format("truetype"), url(https://mironmahmud.com/classicads/assets/ltr/fonts/font-awesome/fa-regular-400.svg#fontawesome) format("svg");
}

@font-face {
    font-family: "Font Awesome 5 Free";
    font-style: normal;
    font-weight: 900;
    font-display: auto;
    src: url(https://mironmahmud.com/classicads/assets/ltr/fonts/font-awesome/fa-solid-900.eot);
    src: url(https://mironmahmud.com/classicads/assets/ltr/fonts/font-awesome/fa-solid-900.eot#iefix) format("embedded-opentype"), url(https://mironmahmud.com/classicads/assets/ltr/fonts/font-awesome/fa-solid-900.woff2) format("woff2"), url(https://mironmahmud.com/classicads/assets/ltr/fonts/font-awesome/fa-solid-900.woff) format("woff"), url(https://mironmahmud.com/classicads/assets/ltr/fonts/font-awesome/fa-solid-900.ttf) format("truetype"), url(https://mironmahmud.com/classicads/assets/ltr/fonts/font-awesome/fa-solid-900.svg#fontawesome) format("svg");
}