.stylo {
    overflow: hidden;
    position: relative;
    display: inline-block;
  }
  
  .stylo::before,
  .stylo::after {
   content: '';
    position: absolute;
    width: 100%;
    left: 0;
  }
  .stylo::before {
    background-color: #54b3d6;
    height: 2px;
    bottom: 0;
    transform-origin: 100% 50%;
    transform: scaleX(0);
    transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
  }
  .stylo::after {
    content: attr(data-replace);
    height: 100%;
    top: 0;
    transform-origin: 100% 50%;
    transform: translate3d(200%, 0, 0);
    transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
    color: #54b3d6;
  }
  
  .stylo:hover::before {
    transform-origin: 0% 50%;
    transform: scaleX(1);
  }
  .stylo:hover::after {
    transform: translate3d(0, 0, 0);
  }
  
  .stylo span {
    display: inline-block;
    transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
  }
  
  .stylo:hover span {
    transform: translate3d(-200%, 0, 0);
  }
  