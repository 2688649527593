.business-profile-form {
  display: flex;
  flex-direction: column;
  /* max-width: 500px; */
  max-height: auto;
  margin: 0 auto;
  background: rgb(236, 232, 220);
  /* padding: 15px 50px; */
  border-radius: 5px;
  margin-bottom: 20px;
  font-size: 12px;
}

.business-profile-form label {
  display: block;
  margin-bottom: 0px;
  font-weight: bold;
  font-size: 14px;
  margin-top: -10px;
}

.business-profile-form input[type="text"],
.business-profile-form input[type="tel"],
.business-profile-form input[type="email"],
.business-profile-form input[type="file"] {
  display: block;
  /* width: 400px; */ 
  font-size: 14px;
  padding: 5px;
  margin-bottom: 20px;
  border: 1px solid #cacaca;
  border-radius: 5px;
  box-sizing: border-box;
}

.business-profile-form button[type="submit"] {
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
}

/* .business-profile-form button[type="submit"]:hover {
  background-color: #3e8e41;
} */
 

.profileadd {
  position: relative;
  width: 4cm;
  height: 5cm;
  max-width: 400px;
  margin: 1%;
  padding:2%;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.profileadd .profimg {
  width: 4cm;
  height: 4cm;
  height: auto;
}
.profimg{
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}


.profileadd .camb {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  /* background-color: #555; */
  color: navy;
  height: 1cm;
  width: 1.5cm;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
}

.profileadd .camb:hover {
  color: black;
}

 .profile-picture {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  
  .profile-img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
  }
  
  .upload-input {
    margin-top: 10px;
  }
  