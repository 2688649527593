#wrapper {
	width: 100px;
	margin: 0 auto;
	background: #fff;
	padding: 20px;
	border: 10px solid #aaa;
	border-radius: 15px;
	background-clip: padding-box;
	text-align: center;
}

.button {
	font-family: Helvetica, Arial, sans-serif;
	font-size: 13px;
	padding: 5px 10px;
	border: 1px solid #aaa;
	background-color: #eee;
	background-image: linear-gradient(top, #fff, #f0f0f0);
	border-radius: 2px;
	box-shadow: 0 1px 3px rgba(0,0,0,0.15);
	color: #666;
	text-decoration: none;
	text-shadow: 0 1px 0 #fff;
	cursor: pointer;
	transition: all 0.2s ease-out;
  &:hover {
    border-color: #999;
    box-shadow: 0 1px 3px rgba(0,0,0,0.25);
  }
  &:active {
    box-shadow: 0 1px 3px rgba(0,0,0,0.25) inset;
  }
}

.overlay {
	position: absolute;
	top: 0;
	bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.5);
	transition: opacity 200ms;
  visibility: hidden;
	opacity: 0;
	&.light {
    background: rgba(255,255,255,0.5);
  }
  .cancel {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: default;
  }
  &:target {
    visibility: visible;
    opacity: 1;
  }
}

.popup {
  z-index: +2;
	margin: 75px auto;
	padding: 40px;
	background: #fff;
	border: 1px solid #666;
	width: 100%;
    height:300px;
	box-shadow: 0 0 50px rgba(0,0,0,0.5);
	position: relative;
  .light & {
    border-color: #aaa;
    box-shadow: 0 2px 10px rgba(0,0,0,0.25);
  }
  h2 {
    margin-top: 0;
    color: black!important;
    font-family: "Trebuchet MS", Tahoma, Arial, sans-serif;
  }
  .close {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 20px;
    right: 20px;
    opacity: 0.8;
    transition: all 200ms;
    font-size: 24px;
    font-weight: bold;
    text-decoration: none;
    color: #666;
    &:hover {
      opacity: 1;
    }
  }
  .content {
    max-height: 400px;
    overflow: auto;
  }
  p {
    margin: 0 0 1em;
    &:last-child {
      margin: 0;
    }
  }
}