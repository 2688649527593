
a,.header-top ul li a:hover,.header-top ul.dropdown-menu li a:hover{background:linear-gradient(to left, #0f3854 , #2b224c)}

.topLink{
    font-family: "Poppins", sans-serif !important;

}

/*! CSS Used from: https://templates.scriptsbundle.com/carspot/demos/css/bootstrap.css */
a{background-color:transparent;}
a:active,a:hover{outline:0;}
img{border:0;}
@media print{
*,*:before,*:after{color:#000!important;text-shadow:none!important;background:transparent!important;-webkit-box-shadow:none!important;box-shadow:none!important;}
a,a:visited{text-decoration:underline;}
a[href]:after{content:" (" attr(href) ")";}
a[href^="#"]:after{content:"";}
img{page-break-inside:avoid;}
img{max-width:100%!important;}
}
*{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;}
*:before,*:after{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;}
a{color:#337ab7;text-decoration:none;}
a:hover,a:focus{color:#23527c;text-decoration:underline;}
a:focus{outline:5px auto -webkit-focus-ring-color;outline-offset:-2px;}
img{vertical-align:middle;}
.img-circle{border-radius:50%;}
[role="button"]{cursor:pointer;}
ul{margin-top:0;margin-bottom:10px;}
ul ul{margin-bottom:0;}
.container{padding-right:15px;padding-left:15px;margin-right:auto;margin-left:auto;}
@media (min-width: 768px){
.container{width:750px;}
}
@media (min-width: 992px){
.container{width:970px;}
}
@media (min-width: 1200px){
.container{width:1170px;}
}
.row{margin-right:-15px;margin-left:-15px;}
.col-sm-6,.col-md-6,.col-xs-12{position:relative;min-height:1px;padding-right:15px;padding-left:15px;}
.col-xs-12{float:left;}
.col-xs-12{width:100%;}
@media (min-width: 768px){
.col-sm-6{float:left;}
.col-sm-6{width:50%;}
}
@media (min-width: 992px){
.col-md-6{float:left;}
.col-md-6{width:50%;}
}
.btn{display:inline-block;padding:6px 12px;margin-bottom:0;font-size:14px;font-weight:normal;line-height:1.42857143;text-align:center;white-space:nowrap;vertical-align:middle;-ms-touch-action:manipulation;touch-action:manipulation;cursor:pointer;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;background-image:none;border:1px solid transparent;border-radius:4px;}
.btn:focus,.btn:active:focus{outline:5px auto -webkit-focus-ring-color;outline-offset:-2px;}
.btn:hover,.btn:focus{color:#333;text-decoration:none;}
.btn:active{background-image:none;outline:0;-webkit-box-shadow:inset 0 3px 5px rgba(0, 0, 0, .125);box-shadow:inset 0 3px 5px rgba(0, 0, 0, .125);}
.caret{display:inline-block;width:0;height:0;margin-left:2px;vertical-align:middle;border-top:4px dashed;border-top:4px solid \9;border-right:4px solid transparent;border-left:4px solid transparent;}
.dropdown{position:relative;}
.dropdown-toggle:focus{outline:0;}
.dropdown-menu{position:absolute;top:100%;left:0;z-index:1000;display:none;float:left;min-width:160px;padding:5px 0;margin:2px 0 0;font-size:14px;text-align:left;list-style:none;background-color:#fff;-webkit-background-clip:padding-box;background-clip:padding-box;border:1px solid #ccc;border:1px solid rgba(0, 0, 0, .15);border-radius:4px;-webkit-box-shadow:0 6px 12px rgba(0, 0, 0, .175);box-shadow:0 6px 12px rgba(0, 0, 0, .175);}
.dropdown-menu > li > a{display:block;padding:3px 20px;clear:both;font-weight:normal;line-height:1.42857143;color:#333;white-space:nowrap;}
.dropdown-menu > li > a:hover,.dropdown-menu > li > a:focus{color:#262626;text-decoration:none;background-color:#f5f5f5;}
.container:before,.container:after,.row:before,.row:after{display:table;content:" ";}
.container:after,.row:after{clear:both;}
.pull-right{float:right!important;}
@media (max-width: 767px){
.hidden-xs{display:none!important;}
}
@media (min-width: 768px) and (max-width: 991px){
.hidden-sm{display:none!important;}
}
/*! CSS Used from: https://templates.scriptsbundle.com/carspot/demos/css/style.css */
a{-webkit-transition:all .25s ease-in-out;-moz-transition:all .25s ease-in-out;-ms-transition:all .25s ease-in-out;-o-transition:all .25s ease-in-out;transition:all .25s ease-in-out;cursor:pointer;}
a:hover,a:focus{text-decoration:none;o-:all 0.3s ease-out;-ms-transition:all 0.3s ease-out;-moz-transition:all 0.3s ease-out;-webkit-transition:all 0.3s ease-out;transition:all 0.3s ease-out;}
img{max-width:100%;}
a,div,ul,li{-webkit-tap-highlight-color:rgba(0, 0, 0, 0);-webkit-tap-highlight-color:transparent;-moz-outline-:none;}
ul{list-style:none;padding:0;margin:0;}
.btn{font-size:14px;letter-spacing:1px;padding:10px 15px;border-radius:1px;-webkit-transition:all .25s ease-in-out;-moz-transition:all .25s ease-in-out;-ms-transition:all .25s ease-in-out;-o-transition:all .25s ease-in-out;transition:all .25s ease-in-out;font-weight:600;font-family:'Source Sans Pro', sans-serif;text-transform:uppercase;}
ul{margin-left:0;}
.header-top:after{content:'';display:block;clear:both;}
.header-right{padding:0;}
.header-top ul{margin-bottom:0;}
.header-top ul.listnone li{display:inline-block;line-height:39px;padding:0 10px;position:relative;}
.header-top ul li a{color:#fff;text-transform:capitalize;font-size:14px;position:relative;}
/* .header-top ul.listnone li a.btn{text-transform:uppercase;} */
.header-top ul.listnone li a.btn:hover{color:#fff;}
.header-top ul li i{margin-right:5px;font-size:14px;}
.header-top ul li ul.dropdown-menu li{display:block;border-left:medium none;padding-left:0;color:black;}
.header-top ul.dropdown-menu li:first-child{padding-right:0;}
.header-top .dropdown-menu li:last-child{border-bottom:medium none;}
.header-top ul li ul.dropdown-menu li{border-left:medium none;display:block;padding-left:0;padding-right:0;}
.header-top ul.dropdown-menu li{width:100%;border-bottom:1px solid #f3f3f3;}
.header-top ul.dropdown-menu li a{color:#232323;width:100%;font-size:14px;text-transform:uppercase;}
.header-top .dropdown-menu> li> a:hover,.header-top .dropdown-menu> li> a:focus{background:transparent;}
.header-top .dropdown-menu> li> a{box-sizing:border-box;font-size:14px;font-weight:600;padding:15px 20px;text-transform:capitalize;width:100%;}
.header-top ul.dropdown-menu{border-radius:0;margin-top:0;z-index:999;padding:0;}
.header-top img.resize{height:26px;width:26px;margin-top:-1px;}
img.resize{height:45px;width:45px;}
.myname{padding-left:10px;}
@media (min-width:767px){
.col-md-6:nth-child(2n+1){clear:none;}
.col-sm-6:nth-child(2n+1){clear:left;}
}
@media (min-width:992px){
.col-sm-6:nth-child(2n+1){clear:none;}
.col-md-6:nth-child(2n+1){clear:left;}
}
@media (min-width:1200px){
.col-md-6:nth-child(2n+1),.col-sm-6:nth-child(2n+1){clear:none;}
}
/*! CSS Used from: https://templates.scriptsbundle.com/carspot/demos/css/font-awesome.css */
.fa{display:inline-block;font:normal normal normal 14px/1 FontAwesome;font-size:inherit;text-rendering:auto;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;}
.pull-right{float:right;}
.fa-heart-o:before{content:"\f08a";}
.fa-sign-in:before{content:"\f090";}
.fa-unlock:before{content:"\f09c";}
.fa-globe:before{content:"\f0ac";}
.fa-folder-open-o:before{content:"\f115";}
/*! CSS Used from: https://templates.scriptsbundle.com/carspot/demos/css/responsive-media.css */
@media (min-width: 768px) and (max-width: 1279px){
.header-top .dropdown-menu{right:0;left:auto;}
}
@media (min-width: 320px) and (max-width: 767px){
.header-top .dropdown-menu{right:0;left:auto;}
}
/*! CSS Used from: https://templates.scriptsbundle.com/carspot/demos/css/colors/defualt.css */
a:hover,a:focus{color:rgba(245, 245, 245, 0.822);}
a,.header-top ul li a:hover,.header-top ul.dropdown-menu li a:hover{color:rgba(245, 245, 245, 0.822);}
.header-top ul.dropdown-menu{border-top:2px solid rgba(245, 245, 245, 0.822);color:black ;}
.btn-theme{color:#ffffff;background-color:white;border-color:white;}
.btn-theme:hover,.btn-theme:focus,.btn-theme:active{color:black;background-color:white;border-color:white;}
.btn-theme:active{background-image:none;}
.header-top{background:#2193b0;background:-moz-linear-gradient(to left,#0f3854 , #2b224c);background:-webkit-linear-gradient(to left, #0f3854 , #2b224c);background:-webkit-gradient(linear, 0 0, 100% 0, from(#0f3854), to(#2b224c));background:-o-linear-gradient(to left, #0f3854 , #2b224c);background:linear-gradient(to left, #0f3854 , #2b224c);}
/*! CSS Used fontfaces */
@font-face{font-family:'Source Sans Pro';font-style:italic;font-weight:400;src:url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPZ7qsDJT9g.woff2) format('woff2');unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:'Source Sans Pro';font-style:italic;font-weight:400;src:url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPZ7jsDJT9g.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:'Source Sans Pro';font-style:italic;font-weight:400;src:url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPZ7rsDJT9g.woff2) format('woff2');unicode-range:U+1F00-1FFF;}
@font-face{font-family:'Source Sans Pro';font-style:italic;font-weight:400;src:url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPZ7ksDJT9g.woff2) format('woff2');unicode-range:U+0370-03FF;}
@font-face{font-family:'Source Sans Pro';font-style:italic;font-weight:400;src:url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPZ7osDJT9g.woff2) format('woff2');unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:'Source Sans Pro';font-style:italic;font-weight:400;src:url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPZ7psDJT9g.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Source Sans Pro';font-style:italic;font-weight:400;src:url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPZ7nsDI.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:'Source Sans Pro';font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK3dSBYKcSV-LCoeQqfX1RYOo3qNa7lqDY.woff2) format('woff2');unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:'Source Sans Pro';font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK3dSBYKcSV-LCoeQqfX1RYOo3qPK7lqDY.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:'Source Sans Pro';font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK3dSBYKcSV-LCoeQqfX1RYOo3qNK7lqDY.woff2) format('woff2');unicode-range:U+1F00-1FFF;}
@font-face{font-family:'Source Sans Pro';font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK3dSBYKcSV-LCoeQqfX1RYOo3qO67lqDY.woff2) format('woff2');unicode-range:U+0370-03FF;}
@font-face{font-family:'Source Sans Pro';font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK3dSBYKcSV-LCoeQqfX1RYOo3qN67lqDY.woff2) format('woff2');unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:'Source Sans Pro';font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK3dSBYKcSV-LCoeQqfX1RYOo3qNq7lqDY.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Source Sans Pro';font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK3dSBYKcSV-LCoeQqfX1RYOo3qOK7l.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:'Source Sans Pro';font-style:normal;font-weight:600;src:url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwmhduz8A.woff2) format('woff2');unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:'Source Sans Pro';font-style:normal;font-weight:600;src:url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwkxduz8A.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:'Source Sans Pro';font-style:normal;font-weight:600;src:url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwmxduz8A.woff2) format('woff2');unicode-range:U+1F00-1FFF;}
@font-face{font-family:'Source Sans Pro';font-style:normal;font-weight:600;src:url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwlBduz8A.woff2) format('woff2');unicode-range:U+0370-03FF;}
@font-face{font-family:'Source Sans Pro';font-style:normal;font-weight:600;src:url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwmBduz8A.woff2) format('woff2');unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:'Source Sans Pro';font-style:normal;font-weight:600;src:url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwmRduz8A.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Source Sans Pro';font-style:normal;font-weight:600;src:url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwlxdu.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:'FontAwesome';src:url('https://templates.scriptsbundle.com/carspot/demos/fonts/fontawesome-webfont.eot?v=4.7.0');src:url('https://templates.scriptsbundle.com/carspot/demos/fonts/fontawesome-webfont.eot#iefix&v=4.7.0') format('embedded-opentype'),url('https://templates.scriptsbundle.com/carspot/demos/fonts/fontawesome-webfont.woff2?v=4.7.0') format('woff2'),url('https://templates.scriptsbundle.com/carspot/demos/fonts/fontawesome-webfont.woff?v=4.7.0') format('woff'),url('https://templates.scriptsbundle.com/carspot/demos/fonts/fontawesome-webfont.ttf?v=4.7.0') format('truetype'),url('https://templates.scriptsbundle.com/carspot/demos/fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');font-weight:normal;font-style:normal;}