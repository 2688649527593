.categoryHover{
    height: 90px;
    width: 90px;
    border-radius: 25px 25px 25px 25px;
}
.enquiry{
    background: rgb(255, 255, 255);
    display: flex;
    justify-content: center;

}
.enquiry-form{
    padding-right: 20px;
    background: white;
    padding-top: 20px;
    padding-left: 10px;
    padding-bottom: 5px;
    border-radius: 10px;
}
.allcat{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: none;
}
@media(max-width:420px) {
    .allcat{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: none;
    }
}
.allct{
    display: flex;
    justify-content: center;
}









/* AllCategory.css */
.category-card {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px;
    background: #112f35;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }
  
  .category {
    width: calc(20% - 20px);
    margin-bottom: 20px;
    padding: 10px;
    background-color: #fff; /* Background color for individual category cards */
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    text-align: center;
    transition: box-shadow 0.3s;
  }
  
  .category img {
    max-width: 100%;
    height: auto;
  }
  
  .category p {
    font-size: 14px;
    margin: 0;
    padding-top: 10px;
  }
  
  .category:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
  


  .maincard{
    background-color:#a6b0bf ;
    border: 7px solid #142120 ;
    margin: 40;
    margin-top: 10px;
    position: auto;
    width: 1200;
    margin-left: 50px;
  }
.text-center{
  color: black;
}







/* Define styles for desktop view */
.category-card {
  display: flex;
  justify-content: space-between; /* Create gaps between cards */
}

/* Define styles for mobile view (when the screen width is less than a certain value, e.g., 768px) */
@media (max-width: 768px) {
  .category-card {
    flex-wrap: wrap; /* Allow cards to wrap onto the next line */
  }
    
#real1{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space:nowrap;
}
  .category {
    width: calc(50% - 10px); /* Each card takes 50% of the width with a 10px gap */
    margin: 5px; /* Add a 5px gap between rows of cards */
   

  }
}
