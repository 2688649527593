/*! CSS Used from: https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css */
*,::after,::before{box-sizing:border-box;}
h1{margin-top:0;margin-bottom:.5rem;font-weight:500;line-height:1.2;}
h1{font-size:calc(1.375rem + 1.5vw);}
@media (min-width:1200px){
h1{font-size:2.5rem;}
}
::-moz-focus-inner{padding:0;border-style:none;}
.container{width:100%;padding-right:var(--bs-gutter-x,.75rem);padding-left:var(--bs-gutter-x,.75rem);margin-right:auto;margin-left:auto;}
@media (min-width:576px){
.container{max-width:540px;}
}
@media (min-width:768px){
.container{max-width:720px;}
}
@media (min-width:992px){
.container{max-width:960px;}
}
@media (min-width:1200px){
.container{max-width:1140px;}
}
@media (min-width:1400px){
.container{max-width:1320px;}
}
/*! CSS Used from: https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css */
*,::after,::before{box-sizing:border-box;}
h1{margin-top:0;margin-bottom:.5rem;}
h1{margin-bottom:.5rem;font-weight:500;line-height:1.2;}
h1{font-size:2.5rem;}
.container{width:100%;padding-right:15px;padding-left:15px;margin-right:auto;margin-left:auto;}
@media (min-width:576px){
.container{max-width:540px;}
}
@media (min-width:768px){
.container{max-width:720px;}
}
@media (min-width:992px){
.container{max-width:960px;}
}
@media (min-width:1200px){
.container{max-width:1140px;}
}
@media print{
*,::after,::before{text-shadow:none!important;box-shadow:none!important;}
.container{min-width:992px!important;}
}
/*! CSS Used from: https://hola9.com/static/css/main.4ec425c6.css */
@media print{
*,:after,:before{background:transparent!important;box-shadow:none!important;color:#000!important;text-shadow:none!important;}
}
h1{line-height:1.2;margin-bottom:.5rem;margin-top:0;}
@media only screen and (max-width:992px){
.container{padding-left:25px;padding-right:25px;}
}
@media print{
*,:after,:before{background:0 0!important;box-shadow:none!important;color:#000!important;text-shadow:none!important;}
}
h1{color:inherit;font-family:inherit;font-weight:500;line-height:1.1;}
h1{margin-top:20px;}
h1{margin-bottom:10px;}
.container:after,.container:before{content:" ";display:table;}
.container:after{clear:both;}
h1{color:#2b2d42;font-weight:700;margin:0 0 10px;}
@media all{
@media print{
*,:after,:before{background:transparent!important;box-shadow:none!important;color:#000!important;text-shadow:none!important;}
}
*,:after,:before{box-sizing:border-box;}
.container{margin-left:auto;margin-right:auto;padding-left:15px;padding-right:15px;}
@media (min-width:768px){
.container{width:750px;}
}
@media (min-width:992px){
.container{width:970px;}
}
@media (min-width:1200px){
.container{width:1170px;}
}
.container:after,.container:before{content:" ";display:table;}
.container:after{clear:both;}
*{margin:0;padding:0;}
}
@media all{
@media screen and (min-width:1199px){
.container{width:1200px;}
}
}
div{font-display:swap;font-family:Quicksand;font-size:14px;font-style:normal;}
div{color:#7c7c7c;font-weight:400;line-height:24px;}
@media all{
*,:after,:before{box-sizing:border-box;}
}
@media all{
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
}
}
h1{color:inherit;font-family:inherit;font-size:2em;line-height:1.1;margin:.67em 0;margin-bottom:10px;margin-top:20px;}
h1{background:none;font-family:Poppins,sans-serif;font-size:36px;line-height:1.6em;position:relative;}
@media only screen and (max-width:992px){
.container{padding-left:25px;padding-right:25px;}
}
@media print{
*,:after,:before{background:0 0!important;box-shadow:none!important;color:#000!important;text-shadow:none!important;}
}
@media print{
*,:after,:before{background:transparent!important;box-shadow:none!important;color:#000!important;text-shadow:none!important;}
}
.container:after,.container:before{content:" ";display:table;}
.container:after{clear:both;}
.page-title--small{height:150px;}
@media only screen and (max-width:992px){
.container{padding-left:25px;padding-right:25px;}
.page-title--small{background-position:center right -480px;background-size:cover;}
}
@media only screen and (max-width:767px){
.page-title.page-title--small{background-image:none!important;}
}
@media only screen and (max-width:375px){
.page-title--small{margin-bottom:30px;}
}
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
.container{min-width:992px!important;}
}
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
}
.align-left{text-align:left!important;}
.page-title{background-position:50%;background-repeat:no-repeat;background-size:cover;height:400px;position:relative;}
.page-title__content{left:15px;position:absolute;right:15px;text-align:center;top:50%;-webkit-transform:translateY(-50%);transform:translateY(-50%);}
.page-title__content>*{color:#fff;}
.page-title__content h1{font-size:64px;font-weight:500;}
.page-title--small{background-color:#23d3d3;background-position:100%;background-repeat:no-repeat;background-size:contain;height:120px;margin-bottom:60px;}
.page-title--small .container{height:100%;position:relative;}
.page-title--small .page-title__content{text-align:left;}
.page-title--small .page-title__content h1{color:#fff;font-size:40px;margin-bottom:5px;}
@media only screen and (max-width:992px){
.container{padding-left:25px;padding-right:25px;}
.page-title--small{background-position:center right -480px;background-size:cover;}
}
@media only screen and (max-width:767px){
.page-title.page-title--small{background-image:none!important;}
}
@media only screen and (max-width:375px){
.page-title--small{margin-bottom:30px;}
}
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
.container{min-width:992px!important;}
}
.page-title{color:#fff;font-size:62px;font-weight:700;}
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
}
@media all{
@media print{
*,:after,:before{background:0 0!important;box-shadow:none!important;color:#000!important;text-shadow:none!important;}
}
*,:after,:before{box-sizing:border-box;}
.container{margin-left:auto;margin-right:auto;padding-left:15px;padding-right:15px;}
@media (min-width:768px){
.container{width:750px;}
}
@media (min-width:992px){
.container{width:970px;}
}
@media (min-width:1200px){
.container{width:1170px;}
}
.container:after,.container:before{content:" ";display:table;}
.container:after{clear:both;}
}
@media all{
*{margin:0;padding:0;}
@media screen and (min-width:1199px){
.container{width:1200px;}
}
}
div{font-display:swap;font-size:14px;font-style:normal;font-weight:400;line-height:24px;}
div{color:#6c6c6c;font-family:Open Sans;}
@media print{
*,:after,:before{background:transparent!important;box-shadow:none!important;color:#000!important;text-shadow:none!important;}
}
div{border:0;font-size:100%;margin:0;outline:0;padding:0;vertical-align:baseline;}
div{background:transparent;}
.listingpolicy-scroll ::-webkit-scrollbar{width:8px;}
.listingpolicy-scroll ::-webkit-scrollbar-thumb{background-color:#c8c8c8;border:2px solid #fff;}
.listingpolicy-scroll ::-webkit-scrollbar-track{background-color:#fff;border:2px solid #fff;}
.listingpolicy-scroll ::-webkit-scrollbar{background-color:#fff;border-radius:5px;width:5px;}
.listingpolicy-scroll ::-webkit-scrollbar-thumb{background-color:#999;border-bottom:0;border-left:0;border-radius:5px;border-top:0;}
.listingpolicy-scroll ::-webkit-scrollbar-track{background-color:#ddd;border-left:0;border-radius:5px;}
::-webkit-input-placeholder{color:#c8c8c8;font-weight:400;opacity:1;}
:-moz-placeholder,::-moz-placeholder{color:#c8c8c8;font-weight:400;opacity:1;}
:-ms-input-placeholder{color:#c8c8c8;font-weight:400;opacity:1;}
@media screen and (-webkit-min-device-pixel-ratio:0){
*{-webkit-font-smoothing:antialiased;}
}
@media print{
*,:after,:before{background:transparent!important;box-shadow:none!important;color:#000!important;text-shadow:none!important;}
}
div{-webkit-tap-highlight-color:rgba(0,0,0,0);-webkit-tap-highlight-color:transparent;-moz-outline-:none;}
@media all{
@media print{
*,:after,:before{background:0 0!important;box-shadow:none!important;color:#000!important;text-shadow:none!important;}
}
*,:after,:before{box-sizing:border-box;}
div{border:0;font-size:100%;margin:0;padding:0;vertical-align:baseline;}
}
@media all{
@media print{
*{background:none!important;box-shadow:none!important;color:#000!important;-webkit-filter:grayscale(100%);-moz-filter:grayscale(100%);-ms-filter:grayscale(100%);-o-filter:grayscale(100%);text-shadow:none!important;}
}
*,:after,:before{box-sizing:border-box;}
*{-moz-box-sizing:border-box;-webkit-box-sizing:border-box;}
}
::-webkit-selection{background-color:#5580ff;color:#fff;}
::selection{background-color:#5580ff;color:#fff;}
@media all{
::-webkit-selection{background-color:#5580ff;color:#fff;}
::selection{background-color:#5580ff;color:#fff;}
}
@media all{
@media print{
*,:after,:before{background:transparent!important;box-shadow:none!important;color:#000!important;text-shadow:none!important;}
}
*,:after,:before{box-sizing:border-box;}
}
@media all{
*{margin:0;padding:0;}
}
div{color:#7c7c7c;font-display:swap;font-family:Quicksand;font-size:14px;font-style:normal;font-weight:400;line-height:24px;}
@media all{
*,:after,:before{box-sizing:border-box;}
}
@media all{
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
}
}
@media all{
*,:after,:before{box-sizing:border-box;}
}
@media print{
*,:after,:before{background:0 0!important;box-shadow:none!important;color:#000!important;text-shadow:none!important;}
}
@media all{
@media print{
*,:after,:before{background:0 0!important;box-shadow:none!important;color:#000!important;text-shadow:none!important;}
}
*,:after,:before{box-sizing:border-box;}
*{margin:0;padding:0;}
}
div{color:#6c6c6c;font-display:swap;font-family:Open Sans;font-size:14px;font-style:normal;font-weight:400;line-height:24px;}
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
}
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
}
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
}
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
}
@media print{
*,:after,:before{background:transparent!important;box-shadow:none!important;color:#000!important;text-shadow:none!important;}
}
div{-webkit-tap-highlight-color:rgba(0,0,0,0);-webkit-tap-highlight-color:transparent;-moz-outline-:none;}
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
.container{min-width:992px!important;}
}
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
.container{min-width:992px!important;}
}
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
.container{min-width:992px!important;}
}
h1{font-weight:500;line-height:1.2;margin-bottom:.5rem;margin-top:0;}
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
.container{min-width:992px!important;}
}
h1{margin-bottom:0;}
h1{color:var(--heading);}
h1{font-size:40px;line-height:48px;}
h1{clear:both;color:#2d2d2d;font-family:Jost;font-size:100%;font-size:24px;font-weight:700;}
h1{border:0;margin:0;outline:0;padding:0;vertical-align:baseline;}
@media only screen and (max-width:992px){
.container{padding-left:25px;padding-right:25px;}
}
h1{margin-top:0;}
h1{font-family:inherit;}
h1{color:inherit;font-weight:500;line-height:1.2;margin-bottom:.5rem;}
h1{font-size:2.5rem;}
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
.container{min-width:992px!important;}
}
h1{font-family:Montserrat,sans-serif;font-size:36px;font-weight:700;}
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
.container{min-width:992px!important;}
}
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
.container{min-width:992px!important;}
}
*{margin:0;outline:0;padding:0;}
@media (min-width:1280px){
.container{max-width:1200px;}
}
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
.container{min-width:992px!important;}
}
@media print{
.container{max-width:none;}
}
:focus{outline:0!important;}
*{-webkit-touch-callout:none;-webkit-user-select:none;-ms-user-select:none;user-select:none;}
.container{padding-left:.75rem;padding-left:var(--bs-gutter-x,.75rem);padding-right:.75rem;padding-right:var(--bs-gutter-x,.75rem);}
@media (min-width:1400px){
.container{max-width:1320px;}
}
div{border:0;font-size:100%;margin:0;outline:0;padding:0;vertical-align:baseline;}
@media only screen and (min-width:1200px){
.container{max-width:1200px;}
}
@media only screen and (max-width:992px){
.container{padding-left:25px;padding-right:25px;}
}
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
.container{min-width:992px!important;}
}
@media print{
*,:after,:before{background:transparent!important;box-shadow:none!important;color:#000!important;text-shadow:none!important;}
}
*,:after,:before{box-sizing:border-box;}
.container{padding-top:30px;}
@media (min-width:768px){
.container{width:750px;}
}
@media (min-width:992px){
.container{width:970px;}
}
@media (min-width:1200px){
.container{width:1170px;}
}
.container:after,.container:before{content:" ";display:table;}
.container:after{clear:both;}
div{-webkit-tap-highlight-color:rgba(0,0,0,0);-webkit-tap-highlight-color:transparent;-moz-outline-:none;}
h1{font-size:calc(1.375rem + 1.5vw);font-weight:500;line-height:1.2;margin-bottom:.5rem;margin-top:0;}
@media (min-width:1200px){
h1{font-size:2.5rem;}
}
h1{color:rgba(0,0,0,.8);font-weight:400;line-height:1.5;}
@media all{
*,:after,:before{box-sizing:border-box;}
}
@media all{
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
}
}
@media all{
*,:after,:before{box-sizing:border-box;}
}
@media all{
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
}
}
h1{margin-top:0;}
h1{font-size:calc(1.375rem + 1.5vw);}
@media (min-width:1200px){
h1{font-size:2.5rem;}
}
::-moz-focus-inner{border-style:none;padding:0;}
.container{margin-left:auto;margin-right:auto;padding-left:.75rem;padding-left:var(--bs-gutter-x,.75rem);padding-right:.75rem;padding-right:var(--bs-gutter-x,.75rem);width:100%;}
@media (min-width:1400px){
.container{max-width:1320px;}
}
*,:after,:before{box-sizing:border-box;}
h1{margin-bottom:.5rem;margin-top:0;}
h1{font-weight:500;line-height:1.2;margin-bottom:.5rem;}
h1{font-size:2.5rem;}
.container{margin-left:auto;margin-right:auto;padding-left:15px;padding-right:15px;width:100%;}
@media (min-width:576px){
.container{max-width:540px;}
}
@media (min-width:768px){
.container{max-width:720px;}
}
@media (min-width:992px){
.container{max-width:960px;}
}
@media (min-width:1200px){
.container{max-width:1140px;}
}
@media (min-width:576px){
.container{max-width:540px;}
}
@media (min-width:768px){
.container{max-width:720px;}
}
@media (min-width:992px){
.container{max-width:960px;}
}
@media (min-width:1200px){
.container{max-width:1140px;}
}
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
.container{min-width:992px!important;}
}
:disabled{pointer-events:none!important;}
h1{font-weight:300;}
/*! CSS Used from: Embedded */
*,*::before,*::after{-webkit-box-sizing:border-box;box-sizing:border-box;}
h1{margin-top:0;margin-bottom:0.5em;color:rgba(0, 0, 0, 0.85);font-weight:500;}
::-moz-selection{color:#fff;background:#1890ff;}
::selection{color:#fff;background:#1890ff;}
/*! CSS Used from: Embedded */
.listingpolicy-scroll ::-webkit-scrollbar{width:8px;}
.listingpolicy-scroll ::-webkit-scrollbar-track{background:#f1f1f1;}
.listingpolicy-scroll ::-webkit-scrollbar-thumb{background:#888;}
.listingpolicy-scroll ::-webkit-scrollbar-thumb:hover{background:#555;}
/*! CSS Used from: https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css */
*,::after,::before{box-sizing:border-box;}
h1{margin-top:0;margin-bottom:.5rem;font-weight:500;line-height:1.2;}
h1{font-size:calc(1.375rem + 1.5vw);}
@media (min-width:1200px){
h1{font-size:2.5rem;}
}
::-moz-focus-inner{padding:0;border-style:none;}
.container{width:100%;padding-right:var(--bs-gutter-x,.75rem);padding-left:var(--bs-gutter-x,.75rem);margin-right:auto;margin-left:auto;}
@media (min-width:576px){
.container{max-width:540px;}
}
@media (min-width:768px){
.container{max-width:720px;}
}
@media (min-width:992px){
.container{max-width:960px;}
}
@media (min-width:1200px){
.container{max-width:1140px;}
}
@media (min-width:1400px){
.container{max-width:1320px;}
}
/*! CSS Used fontfaces */
@font-face{font-display:swap;font-family:Quicksand;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hJFQNcOM.woff2) format("woff2");unicode-range:U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01a0-01a1,U+01af-01b0,U+1ea0-1ef9,U+20ab;}
@font-face{font-display:swap;font-family:Quicksand;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hJVQNcOM.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-display:swap;font-family:Quicksand;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hK1QN.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-display:swap;font-family:Quicksand;font-style:normal;font-weight:500;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hJFQNcOM.woff2) format("woff2");unicode-range:U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01a0-01a1,U+01af-01b0,U+1ea0-1ef9,U+20ab;}
@font-face{font-display:swap;font-family:Quicksand;font-style:normal;font-weight:500;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hJVQNcOM.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-display:swap;font-family:Quicksand;font-style:normal;font-weight:500;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hK1QN.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-display:swap;font-family:Quicksand;font-style:normal;font-weight:700;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hJFQNcOM.woff2) format("woff2");unicode-range:U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01a0-01a1,U+01af-01b0,U+1ea0-1ef9,U+20ab;}
@font-face{font-display:swap;font-family:Quicksand;font-style:normal;font-weight:700;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hJVQNcOM.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-display:swap;font-family:Quicksand;font-style:normal;font-weight:700;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hK1QN.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-display:swap;font-family:Quicksand;font-style:normal;font-weight:700;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkBgv58m-wi40.woff2) format("woff2");unicode-range:U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01a0-01a1,U+01af-01b0,U+1ea0-1ef9,U+20ab;}
@font-face{font-display:swap;font-family:Quicksand;font-style:normal;font-weight:700;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkBgv58i-wi40.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-display:swap;font-family:Quicksand;font-style:normal;font-weight:700;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkBgv58a-wg.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-family:Quicksand;font-style:normal;font-weight:300;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hJFQNcOM.woff2) format("woff2");unicode-range:U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01a0-01a1,U+01af-01b0,U+1ea0-1ef9,U+20ab;}
@font-face{font-family:Quicksand;font-style:normal;font-weight:300;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hJVQNcOM.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-family:Quicksand;font-style:normal;font-weight:300;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hK1QN.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-family:Quicksand;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hJFQNcOM.woff2) format("woff2");unicode-range:U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01a0-01a1,U+01af-01b0,U+1ea0-1ef9,U+20ab;}
@font-face{font-family:Quicksand;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hJVQNcOM.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-family:Quicksand;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hK1QN.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-family:Quicksand;font-style:normal;font-weight:500;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hJFQNcOM.woff2) format("woff2");unicode-range:U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01a0-01a1,U+01af-01b0,U+1ea0-1ef9,U+20ab;}
@font-face{font-family:Quicksand;font-style:normal;font-weight:500;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hJVQNcOM.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-family:Quicksand;font-style:normal;font-weight:500;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hK1QN.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-family:Quicksand;font-style:normal;font-weight:600;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hJFQNcOM.woff2) format("woff2");unicode-range:U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01a0-01a1,U+01af-01b0,U+1ea0-1ef9,U+20ab;}
@font-face{font-family:Quicksand;font-style:normal;font-weight:600;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hJVQNcOM.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-family:Quicksand;font-style:normal;font-weight:600;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hK1QN.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-family:Quicksand;font-style:normal;font-weight:700;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hJFQNcOM.woff2) format("woff2");unicode-range:U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01a0-01a1,U+01af-01b0,U+1ea0-1ef9,U+20ab;}
@font-face{font-family:Quicksand;font-style:normal;font-weight:700;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hJVQNcOM.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-family:Quicksand;font-style:normal;font-weight:700;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hK1QN.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-display:swap;font-family:Poppins;font-style:normal;font-weight:300;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z11lFc-K.woff2) format("woff2");unicode-range:U+0900-097f,U+1cd0-1cf6,U+1cf8-1cf9,U+200c-200d,U+20a8,U+20b9,U+25cc,U+a830-a839,U+a8e0-a8fb;}
@font-face{font-display:swap;font-family:Poppins;font-style:normal;font-weight:300;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1JlFc-K.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-display:swap;font-family:Poppins;font-style:normal;font-weight:300;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1xlFQ.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-display:swap;font-family:Poppins;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJbecmNE.woff2) format("woff2");unicode-range:U+0900-097f,U+1cd0-1cf6,U+1cf8-1cf9,U+200c-200d,U+20a8,U+20b9,U+25cc,U+a830-a839,U+a8e0-a8fb;}
@font-face{font-display:swap;font-family:Poppins;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJnecmNE.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-display:swap;font-family:Poppins;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJfecg.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-display:swap;font-family:Poppins;font-style:normal;font-weight:500;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z11lFc-K.woff2) format("woff2");unicode-range:U+0900-097f,U+1cd0-1cf6,U+1cf8-1cf9,U+200c-200d,U+20a8,U+20b9,U+25cc,U+a830-a839,U+a8e0-a8fb;}
@font-face{font-display:swap;font-family:Poppins;font-style:normal;font-weight:500;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1JlFc-K.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-display:swap;font-family:Poppins;font-style:normal;font-weight:500;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1xlFQ.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-display:swap;font-family:Poppins;font-style:normal;font-weight:600;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z11lFc-K.woff2) format("woff2");unicode-range:U+0900-097f,U+1cd0-1cf6,U+1cf8-1cf9,U+200c-200d,U+20a8,U+20b9,U+25cc,U+a830-a839,U+a8e0-a8fb;}
@font-face{font-display:swap;font-family:Poppins;font-style:normal;font-weight:600;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1JlFc-K.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-display:swap;font-family:Poppins;font-style:normal;font-weight:600;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1xlFQ.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-display:swap;font-family:Poppins;font-style:normal;font-weight:700;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z11lFc-K.woff2) format("woff2");unicode-range:U+0900-097f,U+1cd0-1cf6,U+1cf8-1cf9,U+200c-200d,U+20a8,U+20b9,U+25cc,U+a830-a839,U+a8e0-a8fb;}
@font-face{font-display:swap;font-family:Poppins;font-style:normal;font-weight:700;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1JlFc-K.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-display:swap;font-family:Poppins;font-style:normal;font-weight:700;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1xlFQ.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-family:Poppins;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJbecmNE.woff2) format("woff2");unicode-range:U+0900-097f,U+1cd0-1cf6,U+1cf8-1cf9,U+200c-200d,U+20a8,U+20b9,U+25cc,U+a830-a839,U+a8e0-a8fb;}
@font-face{font-family:Poppins;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJnecmNE.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-family:Poppins;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJfecg.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-family:Poppins;font-style:normal;font-weight:500;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z11lFc-K.woff2) format("woff2");unicode-range:U+0900-097f,U+1cd0-1cf6,U+1cf8-1cf9,U+200c-200d,U+20a8,U+20b9,U+25cc,U+a830-a839,U+a8e0-a8fb;}
@font-face{font-family:Poppins;font-style:normal;font-weight:500;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1JlFc-K.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-family:Poppins;font-style:normal;font-weight:500;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1xlFQ.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-family:Poppins;font-style:normal;font-weight:600;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z11lFc-K.woff2) format("woff2");unicode-range:U+0900-097f,U+1cd0-1cf6,U+1cf8-1cf9,U+200c-200d,U+20a8,U+20b9,U+25cc,U+a830-a839,U+a8e0-a8fb;}
@font-face{font-family:Poppins;font-style:normal;font-weight:600;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1JlFc-K.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-family:Poppins;font-style:normal;font-weight:600;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1xlFQ.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v28/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4taVIGxA.woff2) format("woff2");unicode-range:U+0460-052f,U+1c80-1c88,U+20b4,U+2de0-2dff,U+a640-a69f,U+fe2e-fe2f;}
@font-face{font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v28/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4kaVIGxA.woff2) format("woff2");unicode-range:U+0400-045f,U+0490-0491,U+04b0-04b1,U+2116;}
@font-face{font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v28/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4saVIGxA.woff2) format("woff2");unicode-range:U+1f??;}
@font-face{font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v28/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4jaVIGxA.woff2) format("woff2");unicode-range:U+0370-03ff;}
@font-face{font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v28/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4iaVIGxA.woff2) format("woff2");unicode-range:U+0590-05ff,U+200c-2010,U+20aa,U+25cc,U+fb1d-fb4f;}
@font-face{font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v28/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4vaVIGxA.woff2) format("woff2");unicode-range:U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01a0-01a1,U+01af-01b0,U+1ea0-1ef9,U+20ab;}
@font-face{font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v28/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4uaVIGxA.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v28/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4gaVI.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-display:swap;font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4taVIGxA.woff2) format("woff2");unicode-range:U+0460-052f,U+1c80-1c88,U+20b4,U+2de0-2dff,U+a640-a69f,U+fe2e-fe2f;}
@font-face{font-display:swap;font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4kaVIGxA.woff2) format("woff2");unicode-range:U+0301,U+0400-045f,U+0490-0491,U+04b0-04b1,U+2116;}
@font-face{font-display:swap;font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4saVIGxA.woff2) format("woff2");unicode-range:U+1f??;}
@font-face{font-display:swap;font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4jaVIGxA.woff2) format("woff2");unicode-range:U+0370-03ff;}
@font-face{font-display:swap;font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4iaVIGxA.woff2) format("woff2");unicode-range:U+0590-05ff,U+200c-2010,U+20aa,U+25cc,U+fb1d-fb4f;}
@font-face{font-display:swap;font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4vaVIGxA.woff2) format("woff2");unicode-range:U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01a0-01a1,U+01af-01b0,U+1ea0-1ef9,U+20ab;}
@font-face{font-display:swap;font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4uaVIGxA.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-display:swap;font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4gaVI.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4taVIGxA.woff2) format("woff2");unicode-range:U+0460-052f,U+1c80-1c88,U+20b4,U+2de0-2dff,U+a640-a69f,U+fe2e-fe2f;}
@font-face{font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4kaVIGxA.woff2) format("woff2");unicode-range:U+0400-045f,U+0490-0491,U+04b0-04b1,U+2116;}
@font-face{font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4saVIGxA.woff2) format("woff2");unicode-range:U+1f??;}
@font-face{font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4jaVIGxA.woff2) format("woff2");unicode-range:U+0370-03ff;}
@font-face{font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4iaVIGxA.woff2) format("woff2");unicode-range:U+0590-05ff,U+200c-2010,U+20aa,U+25cc,U+fb1d-fb4f;}
@font-face{font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4vaVIGxA.woff2) format("woff2");unicode-range:U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01a0-01a1,U+01af-01b0,U+1ea0-1ef9,U+20ab;}
@font-face{font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4uaVIGxA.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4gaVI.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-family:Jost;font-style:normal;font-weight:300;src:url(https://hola9.com/static/mainClassified/fonts/jost/Jost-Light.woff2) format("woff2"),url(https://hola9.com/static/mainClassified/fonts/jost/Jost-Light.woff) format("woff");}
@font-face{font-family:Jost;font-style:normal;font-weight:700;src:url(https://hola9.com/static/mainClassified/fonts/jost/Jost-Bold.woff2) format("woff2"),url(https://hola9.com/static/mainClassified/fonts/jost/Jost-Bold.woff) format("woff");}
@font-face{font-family:Jost;font-style:normal;font-weight:500;src:url(https://hola9.com/static/mainClassified/fonts/jost/Jost-Medium.woff2) format("woff2"),url(https://hola9.com/static/mainClassified/fonts/jost/Jost-Medium.woff) format("woff");}
@font-face{font-family:Montserrat;font-style:normal;font-weight:500;src:url(https://fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2) format("woff2");unicode-range:U+0460-052f,U+1c80-1c88,U+20b4,U+2de0-2dff,U+a640-a69f,U+fe2e-fe2f;}
@font-face{font-family:Montserrat;font-style:normal;font-weight:500;src:url(https://fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2) format("woff2");unicode-range:U+0400-045f,U+0490-0491,U+04b0-04b1,U+2116;}
@font-face{font-family:Montserrat;font-style:normal;font-weight:500;src:url(https://fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2) format("woff2");unicode-range:U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01a0-01a1,U+01af-01b0,U+1ea0-1ef9,U+20ab;}
@font-face{font-family:Montserrat;font-style:normal;font-weight:500;src:url(https://fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-family:Montserrat;font-style:normal;font-weight:500;src:url(https://fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-family:Montserrat;font-style:normal;font-weight:700;src:url(https://fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2) format("woff2");unicode-range:U+0460-052f,U+1c80-1c88,U+20b4,U+2de0-2dff,U+a640-a69f,U+fe2e-fe2f;}
@font-face{font-family:Montserrat;font-style:normal;font-weight:700;src:url(https://fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2) format("woff2");unicode-range:U+0400-045f,U+0490-0491,U+04b0-04b1,U+2116;}
@font-face{font-family:Montserrat;font-style:normal;font-weight:700;src:url(https://fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2) format("woff2");unicode-range:U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01a0-01a1,U+01af-01b0,U+1ea0-1ef9,U+20ab;}
@font-face{font-family:Montserrat;font-style:normal;font-weight:700;src:url(https://fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-family:Montserrat;font-style:normal;font-weight:700;src:url(https://fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}


