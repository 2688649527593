/* Make the image fully responsive */
.btn-sm {
  color: rgb(6, 14, 131);
  background: linear-gradient(270deg, #0f1c54, #2b224c);
  border: 1px solid rgb(6 14 131 / 35%);
  font-weight: 600;
  border-radius: 3px;
  font-size: 13px;
  padding: 6px 10px;
  font-family: "Poppins", sans-serif !important;
  height: 35px;
}

.btn-sm:hover {
  box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%) !important;
}

.autoFont {
  font-family: "Poppins", sans-serif !important;

}

.locality {
  white-space: nowrap;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;

}

.carousel-inner img {
  width: 100%;
  height: 100%;
}

.title {
  margin-bottom: 20px;
  padding: 20px;
}

.img {
  max-height: 120px;
  width: 100%;
  /* border: 1px solid rgba(128, 128, 128, 0.11); */
  object-fit: contain;
  border-radius: 5px;
}



.owl1 {
  height: auto;
  box-shadow: 0 0 5px #999;
  border: 1px solid rgba(128, 128, 128, 0.185);
  margin-right: 20px;
  border-radius: 5px;

}

.text {
  font-size: 14px;
  font-family: sans-serif;
  color: grey;
  width: 70%;

}

.text-center {
  font-size: 24px;
  font-weight: bold;
  margin-top: 0px;
}



.example {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  /* background: rgba(0, 0, 0, 0.05); */
  border-radius: 4px;
}

.sliderow3 {
  padding: 20px;
  margin-bottom: -40px;
  margin-left: -2.5%;
  margin-right: -4.2%;

}

.product-content {
  background-color: #e6f8f8;
  /* padding: 0%; */
}

/* src/App.css */

.AutoPlay {
  text-align: center;
  padding: 20px;
}

@media (max-width: 480px) {
  .AutoPlay h2 {
    font-size: 20px;
  }
}

.descard-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.descard {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 10px;
  padding: 10px;
  width: 250px;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.descard-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}



.descard-body {
  text-align: center;
}

.descard:hover {
  transform: scale(1.05);
}


.sliderautop {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  /* Hide scrollbar */
  -ms-overflow-style: none;
  /* Hide scrollbar (IE and Edge) */
}

.sliderautop::-webkit-scrollbar {
  display: none;
  /* Hide scrollbar for Chrome, Safari, and Opera */
}

.sliderautop .descard {
  flex: 0 0 auto;
  margin: 10px;
  scroll-snap-align: start;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 250px;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  scroll-behavior: smooth;
}

/* src/App.css */

/* ... Previous CSS ... */

.sliderautop-container {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  /* Hide scrollbar */
  -ms-overflow-style: none;
  /* Hide scrollbar (IE and Edge) */
  width: 100%;
  /* Ensure full-width container */
  margin-bottom: 10px;
  /* Add some margin for better styling */
  padding: 0 10px;
  /* Add padding to prevent cutting off the first card */
}

.sliderautop-container::-webkit-scrollbar {
  display: none;
  /* Hide scrollbar for Chrome, Safari, and Opera */
}

.sliderautop {
  display: flex;
  flex-wrap: nowrap;
}

.cardfmob {
  flex: 0 0 auto;
  margin: 10px;
  scroll-snap-align: start;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 250px;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  scroll-behavior: smooth;
}

/* Banner Container */
.banner-container {
  display: flex;
  flex-wrap: wrap;
  background-color: transparent;
  border: 1px solid navy;
  border-radius: 20px;
  color: #fff;
  padding: 20px;
}

/* Banner Content */
.banner-content {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1200px;
  /* Adjust as needed */
  margin: 0 auto;
}

/* Left Side */
.left-side {
  flex: 1;
  text-align: left;
  padding: 20px;
}

.left-side h2 {
  font-size: 28px;
}

.left-side p {
  font-size: 16px;
}

/* Icon Text */
.icon-text {
  margin-top: 20px;
}

.icon-text p {
  display: flex;
  align-items: center;
}

.icon-text p svg {
  margin-right: 10px;
  font-size: 24px;
}

/* Right Side - Image Slider */
.right-side {
  flex: 1;
  text-align: right;
  padding: 20px;
  position: relative;
}

.image-slider {
  position: relative;
  overflow: hidden;
}

.image-slider img {
  max-width: 100%;
  height: auto;
  transition: transform 0.3s ease-in-out;
}

.image-slider button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
}

.image-slider button:first-child {
  left: 0;
}

.image-slider button:last-child {
  right: 0;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .banner-content {
    flex-direction: column;
    align-items: center;
  }

  .left-side,
  .right-side {
    flex: 1;
    text-align: center;
  }

  .icon-text p {
    justify-content: center;
  }

  .icon-text p svg {
    margin-right: 5px;
  }

  .image-slider {
    text-align: center;
  }

  .image-slider button {
    width: 50px;
    font-size: 14px;
  }

  .right-side {
    display: none;
  }
}

/* card overflow-y :"hidden" */
.sliderautop-container {
  overflow-x: hidden;
  /* Hide horizontal overflow */
}

.sliderautop {
  display: flex;
  overflow-x: auto;
  /* Enable horizontal scrolling */
  scrollbar-width: thin;
  /* Customize scrollbar width as needed */
  scrollbar-color: #ddd transparent;
  /* Customize scrollbar color as needed */
}

.cardfmob {
  width: 300px;
  /* Set the fixed width for each card */
  border: 1px solid #ddd;
  /* Add a border for better visibility */
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  margin-right: 16px;
  /* Adjust the margin between cards as needed */
  transition: transform 0.2s ease-in-out;
}

.cardfmob:hover {
  transform: scale(1.05);
  /* Adjust the scale factor on hover */
}

.cardfmob .card-body {
  height: 150px;
  /* Set the fixed height for the card body */
  overflow: hidden;
}