

/* Global Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: Arial, sans-serif;
  }
  
  /* Full-width Image Box */
  .image-box {
    width: 100%;
    height: 500px; /* Adjusted height for smaller screens */
    overflow: hidden;
    margin: 50px;
    margin-right: 100px;
  }
  
  .image-box img {
    width: 95%;
    height: 100%;
    object-fit: cover; /* Maintains aspect ratio while filling the box */
    margin: 0; /* Removed margin for better responsiveness */
  }
  
  /* Main Layout */
  .main {
    display: flex;
    flex-wrap: wrap; /* Allow wrapping for smaller screens */
    margin: 20px;
    gap: 20px; /* Add spacing between sections */
  }
  
  
  .leftctr {
    flex: 1;
    max-width: 340px;
    min-width: 300px; /* Ensure a minimum size on smaller screens */
    border: 1px solid gray;
    padding: 10px;
    background-color: #f9f9f9;
  }  .category-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
   
  }
  
  .category-button {
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    cursor: pointer;
    
  }
  
  
  .rightctr {
    flex: 2;
    width: 100%; /* Full width for smaller screens */
    border: 1px solid gray;
    padding: 10px;
    background-color: #fff;
  }
  
  /* Categories Section */
  .Categories {
    margin-bottom: 20px;
  }
  
  .Categories ul {
    list-style: none;
    padding: 0;
  }
  
  .Categories ul li {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
  }
  
  .Categories ul li:hover {
    background-color: #f1f1f1;
  }
  
  .scrollable-list {
    max-height: 150px;
    overflow-y: auto;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* Budget Inputs */
  .input-group {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .lbl {
    margin-right: 10px;
  }
  
  .inputbgt {
    flex: 1;
    padding: 5px;
    border-radius: 5px;
  }
  
  .apply-btn {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #d9e0f7;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .apply-btn:hover {
    background-color: #b9c9f5;
  }
  
  /* Ads Grid */
  .ads-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr)); /* Responsive columns */
    gap: 20px;
  }
  
  .ad-card {
    background-color: #e0e0e0;
    padding: 10px;
    border-radius: 10px;
    position: relative;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .featured-badge {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #ffcc00;
    color: white;
    padding: 5px;
    border-radius: 3px;
    font-size: 12px;
  }
  
  .ad-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
  }
  
  .ad-price {
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
  }
  
  .ad-title {
    font-size: 14px;
    margin-top: 5px;
  }
  
  .ad-details {
    font-size: 12px;
    color: #555;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .main {
      flex-direction: column;
    }
  
    .leftctr,
    .rightctr {
      width: 100%; /* Full width on smaller screens */
    }
  
    .ads-grid {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
  
    .ad-image {
      height: 120px; /* Smaller image height for smaller screens */
    }
  
    .apply-btn {
      margin-top: 10px;
      width: 100%;
    }
  }
  
  @media (max-width: 480px) {
    .image-box {
      height: 200px; /* Further reduced height for mobile devices */
    }
  
    .inputbgt {
      width: 100%;
    }
  
    .apply-btn {
      font-size: 14px;
    }
  }
  