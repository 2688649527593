/* App.css */
.jcar-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .jcar {
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 300px;
    background-color: #fff;
    margin-bottom: 20px;
  }
  
  .jcar h2 {
    font-size: 20px;
    margin: 0;
  }
  
  .jcar p {
    margin: 5px 0;
  }
  
  .jcar .doc-viewer {
    margin-top: 10px;
    text-align: center;
  }
  
  .jcar a {
    display: block;
    text-align: center;
    text-decoration: none;
    margin-top: 10px;
    color: #007bff;
  }
  
  .jcar a:hover {
    text-decoration: underline;
  }
  