/* Container Styling */
.container-estate {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin: 20px;
}

/* Card Styling */
.card-estate {
    position: relative;
    width: 300px;
    height: 350px;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.card-img-top1 {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.card-estate:hover .card-img-top1 {
    transform: scale(1.1);
    /* Zoom effect on hover */
}

/* Overlay Styling */
.card-body1 {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px;
    color: white;
    text-align: center;
    transition: transform 0.3s ease;
    background: rgba(0, 0, 0, 0.4);
    transform: translateY(50px);
}

.card-estate:hover .card-body1 {
    transform: translateY(0px);
    /* Lift effect on hover */
}

/* Title Styling */
.card-title1 {
    font-size: 18px;
    font-weight: bold;
    color: white;
    text-shadow: 0 0 5px black;
    margin-bottom: 5px;
}

/* Description Styling */
.card-text1 {
    font-size: 14px;
    margin-bottom: 10px;
    color: white;
}

/* Button Styling */
.card-link1 {
    display: inline-block;
    padding: 5px 10px;
    background-color: white;
    /* Blue button */
    color: black;
    border-radius: 5px;
    text-decoration: none;
    font-size: 14px;
    font-weight: bold;
}

.card-link1:hover {
    background-color: rgb(204, 201, 201);
    /* Darker blue on hover */
}

/* Load More Button */
.Load-more-btn {
    background-color: #007bff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.Load-more-btn:hover {
    background-color: #0056b3;
}