.left-dashboard-padding {
    
}


.Verification-card-grid {
    display: flex;
    flex-wrap: wrap;
}
.verfication-product-card-m {
    margin: 0 10px;
}
.verfication-product-card-w {
    max-width: 240px !important;
}
.verfication-product-card {
    width: 100%;
}

@media (max-width:491px) {
    .left-dashboard-padding {
        padding: 0 !important;
    }


    .Verification-card-grid {
        display: flex;
        flex-wrap: wrap;
    }
    .verfication-product-card-m {
        margin: auto;
    }
    .verfication-product-card-w {
        max-width: 175px !important;
        padding: 0% !important;
    }
    .verfication-product-card {
        grid-template-columns:unset;
        height: unset;
    }
    .verification-card-button {
        height: auto;
    }
}