.bgColor{
    background: linear-gradient(270deg,#0f1c54,#2b224c);
}
.careerBtn{
    width:30%;
   padding-bottom: 2px;
    color:rgb(6, 14, 131);
    border:1px solid rgb(6 14 131 / 35%);
    font-weight: bold;
    border-radius: 5px;
}
.careerBtn:hover{
   
    background: linear-gradient(270deg,#0f1c54,#2b224c);
    border:1px solid rgb(6 14 131 / 35%);
    color:white;
   

}
