.product-card{
    width: 300px;

@media(max-width: 420px) {
    width: 100%;
}
}
.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap; /* Allow items to wrap to the next line on smaller screens */
}

.tab-buttons {
  display: flex;
  gap: 10px; /* Adjust the spacing between buttons as needed */
}

/* Add media query for mobile responsiveness */
@media (max-width: 768px) {
  .tab-buttons {
    flex-direction: column; /* Stack buttons vertically on smaller screens */
    justify-content: space-around;
  }
  .h-tab-buttons,  .RE-tab-buttons {
    flex-direction: row;
    width: 100%;
  }
  .h-tab-buttons {
  /* justify-content: space-evenly; */
  margin-top: 10px;
  }
  .h-bytn1 {
    margin-top: 20px;
  }
  .RE-tab-buttons {
    overflow-x: scroll;
    & ::-webkit-scrollbar {
      display: none ;
    }
  }

  .header-container {
    text-align: center; /* Center align on smaller screens */
  }
}

/* .desk-star {
  margin-top: 3px;
} */
.mob-star {
  display: none;
}


@media (max-width: 768px) {
  .desk-star {
    display: none;
  }
  .mob-star {
    display: flex;
    margin-top: 3px;
  }
  .mob-v-space {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    & p {
      transform: unset;
    }
  }
    .product-content1{
        border: none !important;
    }
}


.price-bot-mob {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

@media (max-width:768px) {
  .price-bot-mob {
    margin-bottom: 0;
  }
}


.button-containerhoms {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .bytn1 {
    position: relative;
    padding: 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 16px;
    transition: color 0.3s ease-in-out;
    color: rgb(2, 2, 2);
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  }
  
  /* Add the ::before pseudo-element for the bottom border */
  .bytn1::before {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color:  rgb(40, 32, 59);
    color: blue;
    transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
  }
  
  /* Add a class to indicate the active state (e.g., your "active" class) */
  .active {
    color: rgb(55, 44, 80) !important; /* Change text color when active */
  }
  .titlefont{
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-style: normal;
    font-feature-settings: "abvf";
    text-transform: uppercase;
    color: #062544;
    font-size: 16px;
    font-weight: bold;
  }
  /* Style the ::before pseudo-element for the active button */
  .active::before {
    width: 100%; /* Expand the width to create the sliding effect */
    left: 0; /* Reset the left position */
  }
  @media (min-width: 460px) and  (max-width: 2480px) {
   .top-row{
    display: none !important;
   }
   .serchmob{
    display: none;
    border-bottom: 1px solid navy !important;
    /* width: 150% !important; */
   }
   .catedivfil{
    display: flex;
    gap:10px;
    justify-content: end;
   }
}
@media (max-width: 460px){
  .top-row1{
    display:none !important ;
   }
   .serchmob1{
    display: none !important;
   }
   
   
}
/* Add your existing CSS styles here */

.card-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 cards per row on desktop */
  gap: 16px;
}

.ad-card {
  background: rgba(255, 255, 255, 0.15);
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  backdrop-filter: blur(10px); /* Glassmorphism effect */
}

.ad-card:hover {
  transform: translateY(-4px);
}

.glassmorphism {
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: 100%;
}


.ad-image {
  height: 150px;
  max-width: 100%;
  width: 350px; /* Fixed height for the image */
  overflow: hidden;
  border-radius: 16px 16px 0 0;
}

.ad-image img {
  max-width: 100%;
  height: 150px;
  width: 350px;
  filter: brightness(1.2);
  /* Add other CSS filter properties like blur, contrast, etc. if desired */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  object-fit: cover; /* Maintain image aspect ratio */
}




.viewsub{
  transform: translate(10%,-120%);
  position: absolute;
  visibility: hidden;
}
.glassmorphism:hover .viewsub{
  visibility: visible;
  
}
.imghover-scale {
  transition: transform 0.5s ease-in-out;
}

.glassmorphism:hover .imghover-scale {
  transform: scale(1.1); /* Increase the image scale on hover */
}
.ad-details {
  margin-top: auto;
}

.ad-details h2 {
  font-size: 1.5rem;
  margin: 0;
}

.ad-details p {
  margin: 4px 0;
}

.ad-price {
  color: rgb(6, 37, 68);
}

.wishlist-button {
  background: linear-gradient(270deg,#0f1c54,#2b224c);
  color: white;
  border: none;
  padding: 0px 10px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 12px;
  font-size: 14px;
font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif, Courier, monospace;
}
.wishlist-button:hover{
  background-color: #065fb8;
}
.myjob123 {
  background-color: gold;
  color: black;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 12px;
}
.remove-button {
  background-color: rgb(2, 105, 207);
  color: white;
  border: none;
  padding: 0px 10px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 22px;
  font-size: 14px;
font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif, Courier, monospace;
}
.condiv{
  display: flex;
}

.wtrmrk {
  position: absolute;
  top: 30%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  color: rgba(255, 255, 255, 0.5); /* Adjust watermark color and opacity */
  font-size: 20px; /* Adjust watermark font size */
  pointer-events: none; /* Make the watermark non-clickable */
}
/* Add responsive styles for smaller screens */

.removemob{
  color: red;
}
.heartmob{
  color: black;
}

  

@media (max-width: 768px) {
  .card-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 cards per row on mobile */
  }
  .condiv{
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .widhdiv{
display: flex;
  }
  .removemob{
    color: red;
  }
  .heartmob{
    color: black;
  }
  #wishbbt{
    display: none;
  }
  .conpir{
    transform: translate(0,-20%);
    font-weight: 500;
  }
}

@media (min-width: 768px) and (max-width: 1991px) {
  #wishlibyn{
    display: none;
  }
  .conpir{
    transform: translate(0,-10%);
    font-weight: bold;
    color: gray;
  }
}



.youtubemagic-homeads {
  width: 85%;
  margin-right : -170px
}

@media (max-width : 1200px) {
.youtubemagic-homeads {
  margin-right : -60px
}
}

@media (max-width : 768px) {
  .youtubemagic-homeads {
    margin-right : -30px
  }
  }