
@media (min-width:450px){
    .shadowing{
        margin-left: 10rem;
        margin-right: 10rem;
    
    }
    .Post-text{
        text-align: center;
        color: rgb(70, 70, 70);
        font-weight: 900;
        font-family: Georgia, 'Times New Roman', Times, serif;
    }
    
}

@media (max-width:450px){
   .Post-text{
    text-align: center;
    color: rgba(235, 11, 11, 0.89);
    
   } 
}

