.cardcomp{
    display: flex;
    justify-content: center;
    padding-bottom: 1cm;
    padding-top: 1cm;
}


.wrappercard {
    width: 400px;
    background: #ffffff;
    border-radius: 16px;
    border: 1px solid #0f081a;
    padding: 20px;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.05);
  }
  
  .wrappercard header {
    height: 45px;
    display: flex;
    align-items: center;
    border: 1px solid #dedbe2;
    border-radius: 30px;
    position: relative;
  }
  
  header label {
    height: 100%;
    z-index: 2;
    width: 25%;
    display: flex;
    cursor: pointer;
    font-size: 18px;
    position: relative;
    align-items: center;
    justify-content: center;
    transition: color 0.3s ease;
  }
  
  #tab-1:checked header .tab-1,
  #tab-2:checked header .tab-2,
  #tab-3:checked header .tab-3,
  #tab-4:checked header .tab-4 {
    color: #fff;
  }
  
  header label:nth-child(2) {
    width: 25%;
  }
  header label{
    padding-top: 14px ;
    font-size: small;
    font-family: Georgia, 'Times New Roman', Times, serif;
  }
  header .slider{
    position: absolute;
    /* padding-top: 10px; */
    height: 75%;
    border-radius: inherit;
    background: linear-gradient(145deg, #d5a3ff 0%, #77a5f8 100%);
    transition: all 0.3s ease;
  }
  
  #tab-1:checked ~ header .slider{
    left: 0%;
    width: 75px;
    transform: translateX(10%);
  }
  
  #tab-2:checked ~ header .slider{
    left: 25%;
    width: 75px;
    transform: translateX(5%);
  }
  
  #tab-3:checked ~ header .slider {
    left: 50%;
    width: 95px;
    transform: translateX(-2%);
  }
  #tab-4:checked ~ header .slider {
    left: 100%;
    width: 75px;
    transform: translateX(-110%);
  }
  
  .wrappercard input[type="radio"] {
    display: none;
  }
  
  .card-area {
    width:100%;
    overflow: hidden;
  }
  
  .card-area .cardsd{
    display: flex;
    width: 400%;
  }
  
  .cardsd .rowsd{
    width: 25%;
    gap: 20px;
    /* margin-right: 3cm; */
  }
  
  .cardsd .row-1{
    transition: all 0.3s ease;
  }
  
  #tab-1:checked ~ .card-area .cardsd .row-1{
    margin-left: 0%;
  }
  
  #tab-2:checked ~ .card-area .cardsd .row-1 {
    margin-left: -25%;
  }
  
  #tab-3:checked ~ .card-area .cardsd .row-1 {
    margin-left: -50%;
  }
  #tab-4:checked ~ .card-area .cardsd .row-1 {
    margin-left: -75%;
  }
  
  .rowsd .price-details {
    text-align: center;
    border-bottom: 1px solid rgb(40, 211, 40);
  }
  
  .price-details .price {
    font-size: 20px;
    font-weight: 600;
    position: relative;
    font-family: "Noto Sans", sans-serif;
    color: black;
  }
  .rowsd.features li{
    display: flex;
    font-size: 15px;
    list-style-type: none;
    margin-bottom: 10px;
    align-items: center;
  }
  .features li i{
    background: linear-gradient(#d5a3ff 0%, #77a5f8 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .detail li i {
    padding-right: 10px;
  }
  
  .wrappercard button {
    width: 100%;
    border-radius: 25px;
    border: none;
    outline: none;
    height: 50px;
    font-size: 18px;
    color: #fff;
    cursor: pointer;
    margin-top: 20px;
    background-image:linear-gradient(145deg, #d5a3ff 0%, #77a5f8 100%);
    transition:transform 0.3s ease;
  }
  
  .wrappercard button:hover {
    transform: scale(0.98);
  }
  
  
  .orinalPrice {
    color: #949494;
    text-decoration: line-through;
    font-size: 30px;
    font-weight: 600;
    position: relative;
    font-family: "Noto Sans", sans-serif;
  }
