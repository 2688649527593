.Table {
    border-collapse: collapse;
    width: 100%;
  }
  
  .Table th,
  .Table td {
    padding: 8px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .Table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .Table td:first-child {
    width: 50px;
    text-align: center;
  }
  
  /* .Table input[type="checkbox"] {
    color: aqua;
  }
   */


   /* Responsive table styles for mobile devices */
@media (max-width: 768px) {
  .tableresponsive {
    width: 400px;
    min-width: 380px !important;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .tableresponsive table {
    width: 100%;
    margin-bottom: 0; /* Remove margin to avoid unnecessary space */
    border-collapse: collapse;
    white-space: nowrap; /* Prevent text wrapping */
  }

  .tableresponsive th,
  .tableresponsive td {
    padding: 8px; /* Adjust padding for better mobile spacing */
    text-align: center; /* Center text for better readability */
  }

  
}
