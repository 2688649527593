/* AddComment.css */

/* Global styles */
body {
    background: #eee;
  }
  
  /* Comment styles */
  .date {
    font-size: 11px;
  }
  
  .comment-text,
  .fs-12 {
    font-size: 12px;
  }
  
  /* Container styles */
  .paddingleft {
    padding-left: 10px;
  }
  
  /* Remove box shadow */
  .shadow-none {
    box-shadow: none;
  }
  
  /* User name styles */
  .name {
    color: #007bff;
  }
  
  /* Hover styles */
  .cursor:hover {
    color: blue;
  }
  
  /* Cursor pointer */
  .cursor {
    cursor: pointer;
  }
  
  /* Textarea styles */
  .textarea {
    resize: none;
  }
  