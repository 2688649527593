.new-banner {
  width: 50%;
  height: 8rem;
  margin: 0 25%;
  background-color: #3498db;
  background-image: linear-gradient(200deg, #023350 0%, #c7c41f 74%);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  text-align: center;
  position: relative;
  overflow: hidden;
  border-radius: 0 0 2rem 2rem ;
  border-top: 5px solid rgb(255, 0, 0);
  border-left: 2px solid red;
  border-right: 2px solid red;
}

.content-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
}

.free-tag {
  margin-left: 1rem;
  background-color: rgb(255, 0, 0);
  color: #fffb00;
  font-weight: bold;
  font-size: 18px;
  padding: 6px 20px;
  border-radius: 0 1rem 0 1rem ;
  animation: blink 1s infinite;
}

.banner-content {
  flex-grow: 1;
  margin: 0 20px;
}

.title {
  font-size: 24px;
  font-family: Georgia, 'Times New Roman', Times, serif;
  margin-bottom: 10px;
  color: rgb(255, 255, 255);
}

.clicklink {
  padding: 8px 20px;
  font-size: 16px;
  background-color: #f3ef0a;
  color: #ff0606;
  border: none;
  font-weight: bold;
  border-radius: 1rem 0 1rem 0;
  cursor: pointer;
  transition: transform 0.3s ease;  
  margin-right: 1rem;
}

.clicklink:hover {
  transform: scale(1.1);
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Responsive styles for devices with a width up to 767px (e.g., mobile phones) */
@media (max-width: 767px) {
  .new-banner {
    width: 90%; /* Adjust the width for mobile devices */
    margin: 0 5%; /* Adjust the margins for better spacing on mobile devices */
    height: auto; /* Let the height adjust to the content on mobile devices */
   border-radius: 0 0 1rem 1rem;
  }
  .title {
    font-size: 14px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    margin-bottom: 10px;
    color: rgb(255, 255, 255);
  }
  .free-tag {
    font-size: 12px; /* Adjust the font size of the "Free" tag on mobile devices */
    margin-left: 5px; /* Remove the left margin on mobile devices */
    margin-bottom: 3rem; /* Add a bottom margin for better spacing on mobile devices */
    border-radius: 0.5rem 0 0.5rem  0;
    padding: 5px 10px;
  }

  .clicklink {
    font-size: 12px;
    margin-right: 5px; /* Adjust the font size of the button on mobile devices */
 width: max-content;
 margin-top: 3rem;
 border-radius: 0.5rem 0 0.5rem  0;
 background-color: #000135;
 color: #ffffff;
 font-weight: 300;
 padding: 5px 10px;
  }
}
