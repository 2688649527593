/* support.jsx */ 

.hover-on-Contact:hover{
    background-color: white!important;
    color:#0f3854
}



.footcat:hover{
    color:navy;
    text-decoration: underline;
    
    text-underline-offset: 3px;
    /* border-radius: 3px; */

    /* padding: 0px 3px; */
    /* border:1px solid white; */
    border-color:white !important;
    
    

}
.footcat{
    color:grey;
    font-size:10px;
    font-family: "Poppins", sans-serif !important;

}

.FootcatBg{
    background: linear-gradient(270deg,#ffffff,#ffffff);
}

.heading{
    color:grey;
    font-size:12px;
    font-family: "Poppins", sans-serif !important;

}
.heading1{
    color:gray;
    font-size:12px;
    font-family: "Poppins", sans-serif !important;

}
.heading:hover{
    color:navy;
}

.heading-display {
    display: flex;
    align-items: center;
    white-space: noWrap;
    flex-wrap: wrap;
    padding-top: 5px;
}

.FootcatBg-mob{
    padding-top: 7px;
  }