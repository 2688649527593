.popup-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px;
    height: 399px;
    background-color: #fff; /* Replace with the actual background color from the image */
    border-radius: 5px; /* Adjust or remove as needed */
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); /* Adjust or remove as needed */
    z-index: 999;
}

.btn-close{
    color: red;
}
.search-btn-furniture {
    color:  white;
    background-color:  navy;
    margin-left:  1.5cm;
    padding: 6px 10px;
    border-radius: 20px;
}
.mgntp-mnd {
    margin-top: 50px;
    margin-left: 5rem;
    margin-right: 5rem;
}
@media (max-width : 1200px) {
.mgntp-mnd {
    margin-left: 0rem;
    margin-right: 0rem;
}
}
.filter-btn-furniture {
    padding:6px 10px;
    border:solid black 1.5px;
    border-radius:20px;
    margin-left:1cm;
}
@media (max-width : 480px) {
    .tildiv {
        align-items: center;
    }
    .search-btn-furniture,
    .filter-btn-furniture  {
        margin-left:  0px;
        margin: 3px;

    }
    .filetr2234 {
        /* margin-top: -5%; */
        margin-bottom: 10px;
    }
    .mgntp-mnd {
        margin-top: 0px;
    }
}