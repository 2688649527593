.allcatpost{
    display: grid;
    grid-template-columns: repeat(5,1fr);
    row-gap: 15px;
    border-top: 2px solid gray;
    border-bottom: 2px solid gray;
    column-gap: 20px;
    background: "Whitesmoke",
   
}
@media (min-width: 455px) and (max-width: 1767px) {
.allcatpost{

    padding-left: 8rem;
    padding-right: 8rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 20px;
   
}
 
}
@media(max-width:450px) {
    .allcatpost{
        display: grid;
        grid-template-columns: repeat(4,1fr);
        gap: none;
    }
}
.catim{
    border: 2px solid rgb(9, 44, 77);
    border-radius: 30px 10px;
    display: flex;
    justify-content: center;
    height: auto;
  
}
.catimmm{
    border: 2px solid rgb(9, 44, 77);
    border-radius: 30px 10px;
    display: flex;
    justify-content: center;
    height: auto;
}
.catimmm:hover .categoryHover{
    transform: scale(1.20);
    transition: 0.5s;
}
.catimmm:hover{
    border: 2px solid rgb(33, 219, 219);
}
.categoryHover{
    width: 120px;
    height: 60px;
}
.catim:hover .categoryHover{
    transform: scale(1.20);
    transition: 0.5s;
}
.catim:hover{
    border: 2px solid rgb(33, 219, 219);
}
.categoryHover{
    width: 120px;
    height: 100px;
}