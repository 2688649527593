/* src/components/ConfirmationPage.css */
.confirmation-page {
    text-align: center;
    margin: 50px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    animation: fadeIn 1s ease-in-out; /* Add animation */
  }
  .cancel-failed-page {
    text-align: center;
    padding: 20px;
}

.failure-icon {
    color: #ff0000; /* Red color for failure */
    font-size: 48px;
}
  
  .success-icon {
    color: #4caf50;
    font-size: 3em;
    margin-bottom: 20px;
    animation: bounceIn 1s ease-in-out; /* Add icon animation */
  }
  
  .confirmation-page h1 {
    color: #e97924;
    animation: slideIn 1s ease-in-out; /* Add title animation */
  }
  
  .confirmation-page p {
    margin: 10px 0;
    animation: fadeInUp 1s ease-in-out; /* Add paragraph animation */
  }
  
  /* Define keyframes for animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes bounceIn {
    from {
      transform: scale(0.5);
    }
    to {
      transform: scale(1);
    }
  }
  
  @keyframes slideIn {
    from {
      transform: translateY(-50px);
    }
    to {
      transform: translateY(0);
    }
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  /* Add this to your Confim.css or any relevant CSS file */
.animated-button {
    background-color: #4caf50; /* Green background */
    border: none;
    color: white; /* White text */
    padding: 15px 32px; /* Padding */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 8px; /* Optional: Add border radius for a rounded button */
    transition: background-color 0.3s ease; /* Add a transition for a smooth effect */
}

.animated-button:hover {
    background-color: #45a049; /* Change color on hover */
}
