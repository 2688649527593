/* Manufacturing.css */
.containerMnf {
    text-align: center;
    padding: 20px;
    position: relative;
}

.headingMnf {
    font-size: 2rem;
    font-weight: bold;
    font-style: italic;
    margin-bottom: 20px;
}

.cardsContainerMnf {
    display: flex;
    gap: 20px;
    padding: 20px;
    overflow-x: auto;
    scroll-behavior: smooth;
    white-space: nowrap;
    position: relative;
}

/* Arrows for navigation */
.arrowMnf {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    cursor: pointer;
    padding: 10px;
    font-size: 20px;
    z-index: 10;
    border-radius: 50%;
}

.arrowMnf:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

.leftArrowMnf {
    left: 10px;
}

.rightArrowMnf {
    right: 10px;
}

/* Card styling */
.cardMnf {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 10px;
    text-align: center;
    transition: transform 0.3s;
    flex: 0 0 auto;
    width: 150px; /* Same width for all cards */
    height: 225px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.cardMnf:hover {
    transform: translateY(-5px);
}

/* Image styling */
.cardImgMnf {
    width: 100%; /* Make all images equal width */
    height: 75%; /* Image covers 75% of the card height */
    object-fit: cover; /* Ensures images fill the space properly */
    border-radius: 8px;
}

/* Title styling inside the card */
.cardTitleMnf {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.titleTopMnf {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
}

.titleBottomMnf {
    font-size: 1rem;
    font-weight: normal;
    color: #777;
}

/* Responsive design */
@media (max-width: 768px) {
    .cardsContainerMnf {
        flex-wrap: nowrap;
        justify-content: flex-start;
        overflow-x: auto;
    }

    .cardMnf {
        width: 200px;
        height: 260px;
    }

    .cardImgMnf {
        height: 75%;
    }

    .titleTopMnf {
        font-size: 1rem;
    }

    .titleBottomMnf {
        font-size: 0.9rem;
    }
}
