.card-container1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
  padding: 20px;
  margin-left: 5rem;
  margin-right: 5rem;
}

.Careercard1 {
  width: 180px;
  border: 2px solid gray;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  position: relative;
  transition: transform 0.3s;
}

.Careercard1:hover {
  transform: scale(1.1);
  background-color: #cff9ff;
}

.Careercard-content1 {
  padding: 16px;
  position: relative;
  z-index: 1;
  color: #000;
}

.Careercard1:hover .Careercard-content1 {
  color: #fff;
}

.Careercard1:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s; 
}

.Careercard1:hover:before {
  opacity: 0.7;
}

.Careercard-image1 img {
  width: 100%;

  height: auto;

  object-fit: cover;

  border-bottom: 1px solid black;
}

.Careercard-content1 {
  padding: 8px;

  position: relative;

  z-index: 1;

  color: #000000;
}

.Careercard-title1 {
  font-size: 16px;

  font-weight: bold;
}



.Careercard-button1 {
  margin-top: auto;

  padding: 5px 16px;

  border: none;

  background-color: rgb(4, 31, 49);

  color: white;

  font-size: 14px;

  cursor: pointer;

  float: right;

  margin-bottom: 10px;

  border-radius: 5px;
}

.Careercard-button1:hover {
  background-color: #0056b3;
}

.career-pop {
  min-width: 700px;
  max-width: 1000px;
}


@media (max-width: 768px) {
  .card-container1 {
    margin-left: 0.5rem;

    margin-right: 0.5rem;
  }

  .Careercard1 {
    width: 100%;

    max-width: 500px;
  }
  .career-pop {
    min-width: 80%;
    max-width: 80%;
  }
}

@media (max-width: 480px) {
  .career-pop {
    min-width: 95%;
    max-width: 95%;
  }
}
