/*! CSS Used from: https://preview.colorlib.com/theme/oakberry/css/A.animate.css+flaticon.css+tiny-slider.css+glightbox.min.css+aos.css+style.css,Mcc.GVIUmIpB3f.css.pagespeed.cf.wuDyiIMmfX.css */
*,
*::before,
*::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

h1 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}

h1 {
    font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {
    h1 {
        font-size: 2.5rem;
    }
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

a {
    color: #0d6efd;
    text-decoration: underline;
}

a:hover {
    color: #0a58ca;
}

.container-xl {
    --bs-gutter-x: 1.5rem;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) / 2);
    padding-left: calc(var(--bs-gutter-x) / 2);
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 1200px) {
    .container-xl {
        max-width: 1140px;
    }
}

@media (min-width: 1400px) {
    .container-xl {
        max-width: 1320px;
    }
}

.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x) / -2);
    margin-left: calc(var(--bs-gutter-x) / -2);
}

.row>* {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) / 2);
    padding-left: calc(var(--bs-gutter-x) / 2);
    margin-top: var(--bs-gutter-y);
}

@media (min-width: 768px) {
    .col-md-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.33333%;
    }

    .col-md-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 66.66667%;
    }
}

@media (min-width: 992px) {
    .col-lg-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 83.33333%;
    }
}

@media (min-width: 1200px) {
    .col-xl-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 66.66667%;
    }
}

.btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .btn {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

.btn:hover {
    color: #212529;
}

.btn:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.btn:disabled {
    pointer-events: none;
    opacity: 0.65;
}

.d-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
}

.justify-content-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
}

.align-items-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
}

.py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.py-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
}

a {
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
    color: #5ea51d;
    text-decoration: none;
}

a:hover,
a:focus {
    text-decoration: none;
    color: #5ea51d;
    outline: none !important;
}

h1 {
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 400;
}

.btn {
    padding: 9px 12px;
    cursor: pointer;
    border-radius: 0;
    -webkit-box-shadow: 0px 24px 36px -11px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: 0px 24px 36px -11px rgba(0, 0, 0, 0.09);
    box-shadow: 0px 24px 36px -11px rgba(0, 0, 0, 0.09);
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.btn:hover,
.btn:active,
.btn:focus {
    outline: none;
}

.btn.btn-black {
    background: #000000 !important;
    border: 1px solid #000000 !important;
    color: #fff !important;
}

.btn.btn-black:hover {
    border: 1px solid #000000;
    background: transparent;
    color: #000000;
}

.ftco-no-pt {
    padding-top: 0 !important;
}

.ftco-no-pb {
    padding-bottom: 0 !important;
}

.ftco-intro {
    color: rgba(255, 255, 255, 0.8);
    z-index: 0;
    position: relative;
    padding: 6em 0;
}

.ftco-intro .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    opacity: .9;
    background: #5ea51d;
    z-index: -1;
}

@media (max-width: 767.98px) {
    .ftco-intro {
        background-position: center center !important;
    }
}

.ftco-intro h1 {
    color: #fff;
    font-weight: 600;
}

.img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}