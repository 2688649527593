

.ad-card-awi {
  flex: 0 0 auto;
  width: 250px;
  margin-right: 20px;
  scroll-snap-align: start; 
}

.ad-card-awi:last-child {
  margin-right: 0;
}




.glassmorphism1{

    display: flex;
    flex-direction: column;
    height: 100%;
  
  }
  
  .ad-image1 {
    max-height: 100%;
    width: 250px; /* Fixed height for the image */
    /*  height: 350px;Fixed height for the image */
    overflow: hidden;
    border-radius: 16px 16px 0 0;
  
  }
  
  .ad-image1 img {
  
    height: 250px;
    width: 250px;
    filter: brightness(1.2);
    /* Add other CSS filter properties like blur, contrast, etc. if desired */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    object-fit: cover; /* Maintain image aspect ratio */
  }
  .viewsub1 {
    position: absolute;
    bottom: -120%; /* Initially position the card below the ul */
    left: 0;
    width: 100%;
    height: 100%;
    background-color: whitesmoke; /* Background color of the card */
    border-radius: 5px; /* Border radius for rounded corners */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Shadow for the card */
    padding: 10px; /* Padding for content inside the card */
    transition: bottom 0.6s ease; /* Transition for smooth animation */
  }
  
  .glassmorphism1:hover .viewsub1 {
    bottom: -50%; /* Move the card to top when the parent is hovered */
  }
  
   
@media (max-width:480px){
  .ad-card-awi {
    width: 175px;

  }

  
} 

  
  .scroll-container {
    display: flex;
    /* flex-wrap: nowrap; */
    overflow-x: auto;
    /* overflow-y: hidden; */
    /* -webkit-overflow-scrolling: touch; */
    /* cursor: grab;  */
    max-height: 300px;
  border-bottom: 0.5px solid #ddd;
  scroll-behavior: smooth;    /*Add smooth scrolling behavior */
    margin-bottom: 20px;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
    
  }
  .scroll-container::-webkit-scrollbar{
  width: 0px;   /*it hides the scrollbar*/
  }
  .arrow-styling24{
    display: flex;
    float: right;
    margin-top: -35px;
  }
  .arrow-int-24{
  width: 12px;
  height: 12px;
  background-color: black;
  margin: 0 2px;
  clip-path: polygon(0 0, 0% 100%, 100% 50%);
  opacity: 0;
  animation: fadeoutt 1.8s ease-in-out infinite;
  }
  .arrow-int-24:nth-child(2){
    animation-delay: 0.2s;
  }
  
  .arrow-int-24:nth-child(3){
    animation-delay: 0.4s;
  }
  
  
  @keyframes fadeoutt {
  0%,
  40%,
  100% {
    opacity:0;
  }
  20%{
    opacity:1
  }
  }