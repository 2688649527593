/* CityMap.css (or your preferred CSS file) */

/* Default styling for larger screens */
.city-div{
height: 500px;
width: 100%;
background-color: white;


}

.city-map {
  position: relative;
  width: 100%;
  height: 200px;
  display: flex;
justify-content: center;
 /* margin-left: 400px; */
  /*margin-top: -200px; */
  
}

.city-map img {
  height: 200%;
  object-fit: cover;
  margin-top: 30px;
background:remove;
}



/* Default city marker positions */
.city-marker {
  position: absolute;
  /* background-color: #fff; */
  /* padding: 2px; */
  /* border: 1px solid #000; */
  /* border-radius: 10px; */
  font-size: 15px;
  font-weight: bold;
}

/* Default city marker positions */
.mumbai {
  top: 120%; /* Adjust the top position as needed */
  left: 42.5%; /* Adjust the left position as needed */
}

.delhi {
  top: 59%; /* Adjust the top position as needed */
  left: 46%; /* Adjust the left position as needed */
}

/* Adjust the positions for the new city markers as needed */
.bangalore {
  top: 159%;
  left: 45%;
}

.hyderabad {
  top: 125%;
  left: 47%;
}

.chennai {
  top: 165%;
  left: 47.5%;
}

.pune {
  top: 125%;
  left: 43.15%;
}

.ahmedabad {
  top: 96%;
  left: 42.5%;
}

.jaipur {
  top: 70%;
  left: 44.75%;
}

.indore {
  top: 96%;
  left: 44.8%;
}

.noida {
  top: 64%;
  left: 46.4%;
}

.lucknow {
  top: 70%;
  left: 48.5%;
}

.chandigarh {
  top: 41.8%;
  left: 46.5%;
}

.vadodara {
  top: 110%;
  left: 47.75%;
}

.kolkata {
  top: 100%;
  left: 55%;
}
/* Additional city marker positions for larger screens */
/* ... (Other city marker positions) ... */

@media (max-width: 992px) {


  .city-map img {
    height: 200%; /* Adjust the image height as needed */
    object-fit: cover;
    margin-top: 30px;
  }

  /* Center city marker in the middle of the map */
  .city-marker {
    position: absolute;
    /* background-color: #fff; */
    /* padding: 2px; */
    /* border: 1px solid #000; */
    /* border-radius: 10px; */
    font-size: 15px;
    font-weight: bold;
  }


  .mumbai {
    top: 120%; /* Adjust the top position as needed */
    left: 38.1%; /* Adjust the left position as needed */
  }
  
  .delhi {
    top: 59%; /* Adjust the top position as needed */
    left: 44%; /* Adjust the left position as needed */
  }
  
  /* Adjust the positions for the new city markers as needed */
  .bangalore {
    top: 159.4%;
    left: 42.8%;
  }
  
  .hyderabad {
    top: 125%;
    left: 45.4%;
  }
  
  .chennai {
    top: 165%;
    left: 46%;
  }
  
  .pune {
    top: 125%;
    left: 38.5%;
  }
  
  .ahmedabad {
    top: 96%;
    left: 38.1%;
  }
  
  .jaipur {
    top: 70%;
    left: 41%;
  }
  
  .indore {
    top: 96%;
    left: 41%;
  }
  
  .noida {
    top: 63.75%;
    left: 44.5%;
  }
  
  .lucknow {
    top: 70%;
    left: 47.5%;
  }
  
  .chandigarh {
    top: 42%;
    left: 44.5%;
  }
  
  .vadodara {
    top: 110%;
    left: 46.6%;
  }
  
  .kolkata {
    top: 100%;
    left: 57.5%;
  }
  /* Additional city marker positions for mobile view */
  /* ... (Other city marker positions) ... */
}







/* Media query for mobile view */
@media (max-width: 768px) {

  .city-map {
    position: relative;
    width: 100%;
    height: 200px;
    display: flex;
  justify-content: center;
   /* margin-left: 400px; */
    /*margin-top: -200px; */
    
  }
  
  .city-map img {
    height: 200%;
    object-fit: cover;
 
  
  }
  
  
  
  /* Default city marker positions */
  .city-marker {
    position: absolute;
    /* background-color: #fff; */
    /* padding: 2px; */
    /* border: 1px solid #000; */
    /* border-radius: 10px; */
    font-size: 15px;
    font-weight: bold;
  }


  .mumbai {
    top: 120%; /* Adjust the top position as needed */
    left: 35%; /* Adjust the left position as needed */
  }
  
  .delhi {
    top: 59%; /* Adjust the top position as needed */
    left: 43%; /* Adjust the left position as needed */
  }
  
  /* Adjust the positions for the new city markers as needed */
  .bangalore {
    top: 159%;
    left: 41%;
  }
  
  .hyderabad {
    top: 125%;
    left: 43.8%;
  }
  
  .chennai {
    top: 165%;
    left: 44.5%;
  }
  
  .pune {
    top: 125%;
    left: 36.15%;
  }
  
  .ahmedabad {
    top: 96%;
    left: 35%;
  }
  
  .jaipur {
    top: 70%;
    left: 40.75%;
  }
  
  .indore {
    top: 96%;
    left: 40.8%;
  }
  
  .noida {
    top: 63.75%;
    left: 42.85%;
  }
  
  .lucknow {
    top: 70%;
    left: 48.5%;
  }
  
  .chandigarh {
    top: 42%;
    left: 44.35%;
  }
  
  .vadodara {
    top: 110%;
    left: 44.6%;
  }
  
  .kolkata {
    top: 100%;
    left: 58.5%;
  }
  /* Additional city marker positions for mobile view */
  /* ... (Other city marker positions) ... */
}

@media (max-width: 576px) {
  .city-map {
    position: relative;
    width: 100%;
    height: 200px;
    display: flex;
  justify-content: center;
   /* margin-left: 400px; */
    /*margin-top: -200px; */
    
  }
  
  .city-map img {
    height: 200%;
    object-fit: cover;
 
  
  }
  
  
  
  /* Default city marker positions */
  .city-marker {
    position: absolute;
    /* background-color: #fff; */
    /* padding: 2px; */
    /* border: 1px solid #000; */
    /* border-radius: 10px; */
    font-size: 15px;
    font-weight: bold;
  }
  
  /* Default city marker positions */
 
  .mumbai {
    top: 120%; /* Adjust the top position as needed */
    left: 30.3%; /* Adjust the left position as needed */
  }
  
  .delhi {
    top: 59%; /* Adjust the top position as needed */
    left: 40%; /* Adjust the left position as needed */
  }
  
  /* Adjust the positions for the new city markers as needed */
  .bangalore {
    top: 159%;
    left: 38%;
  }
  
  .hyderabad {
    top: 125%;
    left: 43.8%;
  }
  
  .chennai {
    top: 165%;
    left: 43.5%;
  }
  
  .pune {
    top: 125%;
    left: 31.35%;
  }
  
  .ahmedabad {
    top: 96%;
    left: 31%;
  }
  
  .jaipur {
    top: 70%;
    left: 36.75%;
  }
  
  .indore {
    top: 96%;
    left: 36.8%;
  }
  
  .noida {
    top: 63.75%;
    left: 41%;
  }
  
  .lucknow {
    top: 70%;
    left: 49.5%;
  }
  
  .chandigarh {
    top: 42%;
    left: 42.5%;
  }
  
  .vadodara {
    top: 110%;
    left: 44.6%;
  }
  
  .kolkata {
    top: 100%;
    left: 63.5%;
  }
}



@media (max-width: 376px) {
  .city-map {
    position: relative;
    width: 100%;
    height: 200px;
    display: flex;
  justify-content: center;
   /* margin-left: 400px; */
    /*margin-top: -200px; */
    
  }
  
  .city-map img {
    height: 200%;
    object-fit: cover;
 
  
  }
  
  
  
  /* Default city marker positions */
  .city-marker {
    position: absolute;
    /* background-color: #fff; */
    /* padding: 2px; */
    /* border: 1px solid #000; */
    /* border-radius: 10px; */
    font-size: 15px;
    font-weight: bold;
  }
  
  /* Default city marker positions */
  .mumbai {
    top: 120%; /* Adjust the top position as needed */
    left: 20%; /* Adjust the left position as needed */
  }
  
  .delhi {
    top: 59%; /* Adjust the top position as needed */
    left: 33.8%; /* Adjust the left position as needed */
  }
  
  /* Adjust the positions for the new city markers as needed */
  .bangalore {
    top: 159%;
    left: 31.75%;
  }
  
  .hyderabad {
    top: 125%;
    left: 38%;
  }
  
  .chennai {
    top: 165%;
    left: 40%;
  }
  
  .pune {
    top: 125%;
    left: 21.15%;
  }
  
  .ahmedabad {
    top: 96%;
    left: 20%;
  }
  
  .jaipur {
    top: 70%;
    left: 31.75%;
  }
  
  .indore {
    top: 96%;
    left: 31.75%;
  }
  
  .noida {
    top: 64.5%;
    left: 35.4%;
  }
  
  .lucknow {
    top: 70%;
    left: 46.5%;
  }
  
  .chandigarh {
    top: 41.8%;
    left: 38.5%;
  }
  
  .vadodara {
    top: 110%;
    left: 42.75%;
  }
  
  .kolkata {
    top: 100%;
    left: 69%;
  }
  
  }