.my {
    margin-left: 20px;
    font-size: 14px;
    color: white !important;
    /* color:#000428 !important; */
    font-family: "Poppins", sans-serif !important;
    /* text-decoration: underline; */

}

.mycategories {

    color: '#0f3854 !important'; 
    font-family: "Poppins", sans-serif !important;
    /* text-decoration: underline !important; */

}

