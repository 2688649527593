/* Global Reset */
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,body {
  width: 100%;
  height: 100%;
}

/* body {
  font-family: Arial, sans-serif;
  background-color: #f8f8f8;
} */

/* Full-width Image Box */
.image-box {
  width: 100%;
  height: 450px; /* Default height for laptops */
  overflow: hidden;
  margin-bottom: 20px;
}

.image-box img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain image aspect ratio */
}

/* Main Layout */
.main {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 10px;
}

/* Left Section (Filters) */
.leftctr {
  flex: 1;
  min-width: 280px;
  max-width: 340px;
  padding: 15px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.Categories {
  margin-bottom: 20px;
}

.headinghb {
  margin-bottom: 10px;
  font-size: 18px;
  color: #333;
}

.category-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.category-button {
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
}

.category-button.selected {
  background-color: #d9e0f7;
  color: #333;
}

.scrollable-list {
  max-height: 150px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.scrollable-list li {
  padding: 8px 10px;
  cursor: pointer;
}

.scrollable-list li:hover,
.scrollable-list .selected-location {
  background-color: #d9e0f7;
}

/* Budget Section */
.input-group {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.lbl {
  min-width: 40px;
}

.inputbgt {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.apply-btn {
  width: 100%;
  padding: 8px;
  background-color: #d9e0f7;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.apply-btn:hover {
  background-color: #b9c9f5;
}

/* Right Section (Ads Grid) */
.rightctr {
  display: flex;
  justify-content: center;
  flex: 2;
  padding: 25px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.ads-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
}

.ad-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  text-align: center;
  padding: 10px;
}

.featured-badge {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #ffcc00;
  color: white;
  padding: 5px;
  border-radius: 3px;
  font-size: 12px;
}

.ad-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-bottom: 1px solid #ddd;
}

.ad-price {
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
}

.ad-title {
  font-size: 14px;
  margin-top: 5px;
}

.ad-details {
  font-size: 12px;
  color: #555;
}

.main-content{
  width: 100%;
}

/* Responsive Breakpoints */

/* For Laptops and Desktops */
@media (max-width: 1200px) {
  .main {
    gap: 15px;
  }

  .ads-grid {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }

  .ad-image {
    height: 130px;
  }
}

/* For Tablets */
@media (max-width: 768px) {
  .main {
    flex-direction: column;
  }

  .leftctr {
    max-width: 100%;
  }

  .ads-grid {
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  }

  .ad-image {
    height: 120px;
  }

  .image-box {
    height: 300px;
  }
}

/* For Mobile Devices */
@media (max-width: 480px) {
  .main {
    padding: 5px;
    gap: 10px;
  }

  .leftctr {
    padding: 10px;
  }

  .category-buttons {
    gap: 5px;
  }

  .apply-btn {
    font-size: 14px;
    padding: 6px;
  }

  .ads-grid {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  .ad-image {
    height: 100px;
  }

  .image-box {
    height: 200px;
  }

  .tildiv {
    align-items: center;
}
.search-btn-furniture,
.filter-btn-furniture  {
    margin-left:  0px;
    margin: 3px;

}
.filetr2234 {
    margin-top: -15%;
    /* margin-bottom: 10px; */
}
.mgntp-mnd {
    margin-top: 0px;
}

.furnifil{
  width: 200px;
}
}
