.travelslide-awi-24 {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    /* align-items: center; */
    margin: 20px 10px;
}

.carousel-inner24-awi-25 {
    width: 70%;
    height: 350px;
}

.search-form-awi-25 {
    width: 25%;
    height: 100%;
    border-radius: 30px 30px 30px 30px;
    padding: 20px 30px;
    border: none;
    box-shadow: 0 0 10px gray;
    background-color: rgb(238, 238, 238);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
}


.form-group1 {
    margin-bottom: 20px;
}

.buttonno11 {
    background-color: #003e7c;
    margin-top: 15px;
    color: #fff;
    padding: 8px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 15rem;
}

.buttonno11:hover {
    background-color: #415f7e;
    color: #fff;
}

.error-message {
    color: #ff0000;
    font-size: 12px;
    margin-top: 10px;
}




@media (min-width:780px) and (max-width:1000px) {
    .travelslide-awi-24 {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;

    }

    .carousel-inner24-awi-25 {
        width: 100%;
        height: 100%;
    }

    .search-form-awi-25 {
        width: 50%;
        height: 100%;

    }
}

@media (min-width:550px) and (max-width:780px) {
    .travelslide-awi-24 {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;

    }

    .carousel-inner24-awi-25 {
        width: 100%;
        order: 1;
        height: 100%;
    }

    .search-form-awi-25 {
        order: 2;
        width: 50%;
        height: 100%;

    }
}

@media (max-width:550px) {
    .travelslide-awi-24 {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;

    }

    .carousel-inner24-awi-25 {
        width: 100%;
        order: 1;
        height: 100%;
        margin-left: -20px;
    }

    .search-form-awi-25 {
        order: 2;
        width: 100%;
        height: 100%;
    }

}