.app-container1 {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .tags-column {
    width: 22%;
    background-color: #ebebeb;;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    border: 1px solid gray;
  }
  .tag {
    cursor: pointer;
    padding: 8px 15px;
    border: 1px solid #ccc;
    background-color: white;
  }
  .tag:hover {
    background-color:  #bdbdbd;;
    color: rgb(0, 0, 0);
  }
  .tag.selected {
    background-color: #002955;
    color: rgb(255, 255, 255);
  }
  .content-container1 {
    width:78%;
    padding: 20px;
  }
  .content {
    margin-top: 20px;
  }
  .placeholder-content {
    text-align: left;
    font-style: italic;
    color: #004483;
    font-size: 24px;
  }
  .banner {
    width: 100%;
    height: 50px;
    background-color: #007bff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  .banner-content {
    padding: 0 20px;
    color: white;
  }
  .fildi{
    width: 500px;
    border-radius: 20px;
    border: 2px solid #002955;
    background-color: white;
  }
  .fildi2 {
    position: relative;
    width: 100%;
    height: max-content;
    background: white; 
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    margin-bottom: -150px;
  }
  
  .fildi2-content {
    background: white; 
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  


  .search-btn{
    background-color: #002955;
    color: white;
    padding: 5px 15px;
    border-radius: 10px;
  }
  .search-btn:hover{
    background-color: #0064cf;
   
  }
  .filter-btn{
    background-color: #002955;
    color: white;
    padding: 5px 15px;
    border-radius: 10px;
  }
  .filter-btn:hover{
    background-color: #0064cf;
   
  }
@media(max-width: 450px) {
  .app-container1 {
    display: flex;
    flex-direction: column;
  }
  .tags-column {
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid gray;
  }
  .content-container1 {
    
    width: 100%;
    margin: 0px;
   
  }
}
  
.wrapper-slid-awi-23{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 10rem;
}



  @media (min-width:550px) and (max-width:900px){
    .wrapper-slid-awi-23{
      height: 9rem;
      width: 100%;
     }
     
  }  
  @media (min-width:250px) and (max-width:550px){
    .wrapper-slid-awi-23{
      height: 9rem;
      width: 100%;
     }
     
  }
  .btn-allcat-awi-24{
    border-radius: 10px;
    height: 10%;
    font-size: 14px;
  }












  
    