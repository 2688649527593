#ali {
  position: absolute;
}

.profile-card-main-div {
  display: flex;
  justify-content: center;
  font-size: 25px;
  min-width: 550px;
  width: 40%;
  max-width: 750px;
  margin: auto;
  margin-top: 3rem;
  & .profile-card-sub-div {
    border: 1px solid #00000030;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    width: 100%;
    & .profile-card-img-div {
      width: 30%;
      padding: 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      & .profile-card-img {
        width: 100%;
        height: 10rem;
      }
    }
    & .profile-card-text-div {
      width: 70%;
      clip-path: polygon(10% 0%, 100% 0, 100% 100%, 0% 100%);
      background: aliceblue;
      padding: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      & h4 {
        color: darkblue;
      }
      & i {
        /* font-size: 100%; */
      }
    }
  }
}

.rating-comment-main {
    min-height: 1rem;
    width: 750px;
    box-shadow: 0 0 2px gray;
}

.rating-comment{
    display: flex;
    align-items: center;
}
.rating-icon-div {
    width: 20%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.rating-cont-div {
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.rating-icon {
    width: 100px;
    height: 100px;
    background: gold;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 10%;
    & h2 {
        color: white;
        margin: 0px;
    }
}

.prof-card-rating {
    position: absolute;
    top: 85%;
  }
.rating-textarea {
border: 0.5px solid gray;
width: 95%;
min-height: 80%;
}
.ad-card-width-card{
  width: 350px;
}
@media (max-width: 768px) {
  .profile-card-main-div {
    min-width: 80%;
    max-width: 80%;
  }
  .rating-comment-main  {
    width: 85%;
  }
  .rating-icon-div {
    width: 30%;
  }
  .rating-cont-div {
    width: 70%;
  }
  .ad-card-width-card{
    width: 250px;
  }
}
@media (max-width: 480px) {
  .ad-card-width-card{
    width: 200px;
  }
  .profile-card-main-div {
    min-width: 97%;
    max-width: 97%;
  }
  .rating-comment-main  {
    width: 70%;
  }
  .rating-comment{
    flex-direction: column;
}
  .rating-icon-div {
    width: 100%;
    margin: 5% 0;
  }
  .rating-cont-div {
    width: 100%;
  }
  .rating-icon {
    margin-top: unset;
  }
  .rating-textarea {
    border: unset;
    border-top: 0.1px solid gray;
    width: 100%;
    }
}
