/*! CSS Used from: https://templates.hibootstrap.com/fela/default/assets/css/bootstrap.min.css */
*,::after,::before{box-sizing:border-box;}
h2{margin-top:0;margin-bottom:.5rem;font-weight:500;line-height:1.2;}
h2{font-size:calc(1.325rem + .9vw);}
@media (min-width:1200px){
h2{font-size:2rem;}
}
.youTubeFont{
    font-family: "Poppins", sans-serif !important;

}
p{margin-top:0;margin-bottom:1rem;}
a{color:#0d6efd;text-decoration:underline;}
a:hover{color:#0a58ca;}
::-moz-focus-inner{padding:0;border-style:none;}
.container{width:100%;padding-right:var(--bs-gutter-x,.75rem);padding-left:var(--bs-gutter-x,.75rem);margin-right:auto;margin-left:auto;}
@media (min-width:576px){
.container{max-width:540px;}
}
@media (min-width:768px){
.container{max-width:720px;}
}
@media (min-width:992px){
.container{max-width:960px;}
}
@media (min-width:1200px){
.container{max-width:1140px;}
}
@media (min-width:1400px){
.container{max-width:1320px;}
}
.row{--bs-gutter-x:1.5rem;--bs-gutter-y:0;display:flex;flex-wrap:wrap;margin-top:calc(-1 * var(--bs-gutter-y));margin-right:calc(-.5 * var(--bs-gutter-x));margin-left:calc(-.5 * var(--bs-gutter-x));}
.row>*{flex-shrink:0;width:100%;max-width:100%;padding-right:calc(var(--bs-gutter-x) * .5);padding-left:calc(var(--bs-gutter-x) * .5);margin-top:var(--bs-gutter-y);}
@media (min-width:768px){
.col-md-4{flex:0 0 auto;width:33.33333333%;}
.col-md-8{flex:0 0 auto;width:66.66666667%;}
}
@media (min-width:992px){
.col-lg-5{flex:0 0 auto;width:41.66666667%;}
.col-lg-7{flex:0 0 auto;width:58.33333333%;}
}
.btn{display:inline-block;font-weight:400;line-height:1.5;color:#212529;text-align:center;text-decoration:none;vertical-align:middle;cursor:pointer;-webkit-user-select:none;-moz-user-select:none;user-select:none;background-color:transparent;border:1px solid transparent;padding:.375rem .75rem;font-size:1rem;border-radius:.25rem;transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;}
@media (prefers-reduced-motion:reduce){
.btn{transition:none;}
}
.btn:hover{color:#212529;}
.btn:focus{outline:0;box-shadow:0 0 0 .25rem rgba(13,110,253,.25);}
.btn:disabled{pointer-events:none;opacity:.65;}
.align-items-center{align-items:center!important;}
.order-1{order:1!important;}
.order-2{order:2!important;}
@media (min-width:768px){
.order-md-1{order:1!important;}
.order-md-2{order:2!important;}
}
@media (min-width:992px){
.order-lg-1{order:1!important;}
.order-lg-2{order:2!important;}
}
/*! CSS Used from: https://templates.hibootstrap.com/fela/default/assets/css/flaticon.css */
i[class^=flaticon-]:before{font-family:flaticon!important;font-style:normal;font-weight:400!important;font-variant:normal;text-transform:none;line-height:1;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;}
.flaticon-play-1:before{content:"\f128";}
/*! CSS Used from: https://templates.hibootstrap.com/fela/default/assets/css/aos.css */
[data-aos][data-aos][data-aos-delay="200"]{transition-delay:0;}
[data-aos][data-aos][data-aos-delay="200"].aos-animate{transition-delay:.2s;}
body[data-aos-duration="400"] [data-aos]{transition-duration:.4s;}
[data-aos][data-aos][data-aos-duration="1200"]{transition-duration:1.2s;}
body[data-aos-easing=ease] [data-aos]{transition-timing-function:ease;}
[data-aos^=fade][data-aos^=fade]{opacity:0;transition-property:opacity,transform;}
[data-aos^=fade][data-aos^=fade].aos-animate{opacity:1;transform:translateZ(0);}
[data-aos=fade-right]{transform:translate3d(-100px,0,0);}
/*! CSS Used from: https://templates.hibootstrap.com/fela/default/assets/css/style.css */
*{-webkit-box-sizing:border-box;box-sizing:border-box;}
:focus,.btn:focus{outline:0;-webkit-box-shadow:none;box-shadow:none;}
a{cursor:pointer;}
a{color:#000;text-transform:inherit;text-decoration:none;-webkit-transition:all .3s ease;transition:all .3s ease;}
a:hover,a :focus{text-decoration:none;-webkit-box-shadow:none;box-shadow:none;color:#000;}
a:focus{outline:0 solid;}
h2{font-weight:600;line-height:1.3;color:#000;font-family:poppins,sans-serif;}
p{font-size:16px;line-height:1.8;color:#666;margin-bottom:15px;}
p:last-child{margin-bottom:0;}
/* .ptb-100{padding-top:100px;padding-bottom:100px;} */
.play-now .ripple,.play-now .ripple:before,.play-now .ripple:after{position:absolute;top:50%;left:50%;height:85px;width:85px;-webkit-transform:translateX(-50%) translateY(-50%);transform:translateX(-50%) translateY(-50%);-webkit-transform-origin:center;transform-origin:center center;border-radius:50%;-webkit-box-shadow:0 0 0 0 #fff;-ms-box-shadow:0 0 0 0 #fff;-o-box-shadow:0 0 0 0 #fff;box-shadow:0 0 0 0 #fff;-webkit-animation:ripple 3s infinite;animation:ripple 3s infinite;}
.play-now{position:relative;height:85px;width:85px;text-align:center;line-height:92px;background-color:#fff;color:#fff;z-index:1;font-size:28px;padding-left:5px;display:block;-webkit-transform-origin:center;transform-origin:center center;border-radius:50%;margin:0 auto;}
.play-now i{color:#ec3323;font-size:24px;}
.play-now .ripple:before{-webkit-animation-delay:.9s;animation-delay:.9s;content:"";position:absolute;}
.play-now .ripple:after{-webkit-animation-delay:.6s;animation-delay:.6s;content:"";position:absolute;}
.btn{display:inline-block;line-height:26px;font-weight:400;border-radius:5px;text-align:center;-webkit-transition:all .7s ease-in-out;transition:all .7s ease-in-out;position:relative;z-index:1;border:1px solid transparent;padding:14px 35px;border-radius:5px;color:#fff;overflow:hidden;white-space:nowrap;}
.btn:before{position:absolute;top:0;left:0;content:"";width:50%;height:0;visibility:hidden;opacity:0;z-index:-1;-webkit-transition:all .4s ease-in-out;transition:all .4s ease-in-out;}
.btn:after{position:absolute;bottom:0;right:0;content:"";width:50%;height:0;visibility:hidden;opacity:0;z-index:-1;-webkit-transition:all .4s ease-in-out;transition:all .4s ease-in-out;}
.btn:hover{color:#fff;}
.btn:hover:after,.btn:hover:before{width:100%;height:100%;visibility:visible;opacity:1;}
.btn.style2{background-color:#ec3323;color:#fff;}
.btn.style2:after,.btn.style2:before{background:#002172;}
.btn.style2:hover{color:#fff;}
.content-title{position:relative;}
.content-title h2{font-size:40px;font-weight:700;margin:0;}
.content-title p{margin:12px 0 0;}
.content-title span{display:inline-block;font-weight:600;margin:0 0 10px;position:relative;z-index:1;line-height:1;}
.content-title.style1 span{color:#ec3323;}
.bg-seashell{background-color:#f9f9f9;}
.bg-f{background-position:center center;background-size:cover;background-repeat:no-repeat;background-color:#ddd;}
.video-bg-1{background-image:url(https://templates.hibootstrap.com/fela/default/assets/img/promo-video-bg.jpg);}
.video-wrap .video-content{padding:70px;position:relative;z-index:1;}
.video-wrap .video-content:after{position:absolute;top:50%;left:50%;-webkit-transform:translate(-50%,-50%);transform:translate(-50%,-50%);content:"";width:100%;height:100%;background:rgba(255,255,255,.86);z-index:-1;}
.video-wrap .video-content .content-title{margin-bottom:25px;}
/*! CSS Used from: https://templates.hibootstrap.com/fela/default/assets/css/responsive.css */
@media only screen and (max-width:767px){
p{font-size:14px;}
.btn{font-size:14px;}
.ptb-100{padding-top:50px;padding-bottom:50px;}
.btn{font-size:14px;}
.btn{padding:12px 25px;}
.content-title.style1 span{font-size:14px;}
.content-title h2{font-size:24px;line-height:1.3;}
.video-wrap.style1 .video-content{padding:40px 20px;margin-top:30px;}
}
@media only screen and (min-width:768px) and (max-width:991px){
.ptb-100{padding-top:70px;padding-bottom:70px;}
.content-title h2{font-size:35px;}
.video-wrap .video-content{padding:40px;}
}
@media only screen and (min-width:992px) and (max-width:1199px){
.content-title h2{font-size:35px;}
}
@media only screen and (min-width:1200px){
.content-title p{padding-right:25px;}
}
@media only screen and (min-width:1200px) and (max-width:1399px){
.content-title h2{font-size:40px;}
}
@media only screen and (min-width:1600px){
.content-title h2{font-size:48px;}
}
/*! CSS Used keyframes */
@-webkit-keyframes ripple{70%{-webkit-box-shadow:0 0 0 20px transparent;box-shadow:0 0 0 20px transparent;}100%{-webkit-box-shadow:0 0 0 0 transparent;box-shadow:0 0 0 0 transparent;}}
@keyframes ripple{70%{-webkit-box-shadow:0 0 0 20px transparent;box-shadow:0 0 0 20px transparent;}100%{-webkit-box-shadow:0 0 0 0 transparent;box-shadow:0 0 0 0 transparent;}}
/*! CSS Used fontfaces */
@font-face{font-family:flaticon;src:url(https://templates.hibootstrap.com/fela/default/assets/fonts/flaticon.ttf?ff35a818e27d2ea60a2ebd2fe31a2872) format("truetype"),url(https://templates.hibootstrap.com/fela/default/assets/fonts/flaticon.woff?ff35a818e27d2ea60a2ebd2fe31a2872) format("woff"),url(https://templates.hibootstrap.com/fela/default/assets/fonts/flaticon.woff2?ff35a818e27d2ea60a2ebd2fe31a2872) format("woff2"),url(https://templates.hibootstrap.com/fela/default/assets/fonts/flaticon.eot?ff35a818e27d2ea60a2ebd2fe31a2872#iefix) format("embedded-opentype"),url(https://templates.hibootstrap.com/fela/default/assets/fonts/flaticon.svg?ff35a818e27d2ea60a2ebd2fe31a2872#flaticon) format("svg");}