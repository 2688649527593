/*! CSS Used from: https://templates.scriptsbundle.com/carspot/demos/css/bootstrap.css */
.custom-btn {
    width: 100%;
    height: 30%;
    color: #fff;
    margin-top: -1px;
    border-radius: 5px;
    padding: 10px 25px;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    font-size:18px;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
     box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
     7px 7px 20px 0px rgba(0,0,0,.1),
     4px 4px 5px 0px rgba(0,0,0,.1);
    outline: none;
  }
  .btn-15 {
   
    /* background: #000428 ; */
    background: linear-gradient(0deg, rgba(6, 14, 131, 1) 0%, rgba(12, 25, 180, 1) 100%) !important;
    border: none;
    z-index: 1;
  }
  .btn-15:after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    right: 0;
    z-index: -1;
    background: #2b5876 ;
    background: linear-gradient(0deg, #2b5876 0%,  #4e4376 100%) !important;
    border-radius: 5px;
     box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
     7px 7px 20px 0px rgba(0,0,0,.1),
     4px 4px 5px 0px rgba(0,0,0,.1);
    transition: all 0.3s ease;
  }
  .btn-15:hover {
    color: #fff;
  }
  .btn-15:hover:after {
    left: 0;
    width: 100%;
  }
  .btn-15:active {
    top: 2px;
  }
input[type=email] {
    border: 1px solid #5d5d5d3d !important;
    border-radius: 5px !important;
    padding: 8px 15px;
    font-size: 14px;
    color: #757575;
    font-style: normal !important;

    border-radius: 0;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
}
.input[type=text] {
    border: 1px solid red !important;
    padding: 8px 15px;
    font-size: 14px;
    color: #757575;
    font-style: monospace !important;
    border-radius: 0;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
}
.Sinupbtn{
    border:1px solid black;
}
section {
    display: block;
}

a {
    background-color: transparent;
}

a:active,
a:hover {
    outline: 0;
}

b {
    font-weight: bold;
}

button,
input {
    margin: 0;
    font: inherit;
    color: inherit;
}

button {
    overflow: visible;
}

button {
    text-transform: none;
}

button {
    -webkit-appearance: button;
    cursor: pointer;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    padding: 0;
    border: 0;
}

input {
    line-height: normal;
}

input[type="checkbox"] {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
}

@media print {

    *,
    *:before,
    *:after {
        color: #000 !important;
        text-shadow: none !important;
        background: transparent !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    a[href]:after {
        content: " (" attr(href) ")";
    }

    a[href^="#"]:after {
        content: "";
    }

    p,
    h2 {
        orphans: 3;
        widows: 3;
    }

    h2 {
        page-break-after: avoid;
    }
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

input,
button {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

a {
    color: #337ab7;
    text-decoration: none;
}

a:hover,
a:focus {
    color: #23527c;
    text-decoration: underline;
}

a:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

h2 {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
}

h2 {
    margin-top: 20px;
    margin-bottom: 10px;
}

h2 {
    font-size: 30px;
}

p {
    margin: 0 0 10px;
}

.text-right {
    text-align: right;
}

ul {
    margin-top: 0;
    margin-bottom: 10px;
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}

@media (min-width: 992px) {
    .container {
        width: 970px;
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}

.row {
    margin-right: -15px;
    margin-left: -15px;
}

.col-sm-5,
.col-md-6,
.col-sm-7,
.col-sm-8,
.col-xs-12 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.col-xs-12 {
    float: left;
}

.col-xs-12 {
    width: 100%;
}

@media (min-width: 768px) {

    .col-sm-5,
    .col-sm-7,
    .col-sm-8 {
        float: left;
    }

    .col-sm-8 {
        width: 66.66666667%;
    }

    .col-sm-7 {
        width: 58.33333333%;
    }

    .col-sm-5 {
        width: 41.66666667%;
    }

    .col-sm-offset-2 {
        margin-left: 16.66666667%;
    }
}

@media (min-width: 992px) {
    .col-md-6 {
        float: left;
    }

    .col-md-6 {
        width: 50%;
    }

    .col-md-offset-3 {
        margin-left: 25%;
    }
}

label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: bold;
}

input[type="checkbox"] {
    margin: 4px 0 0;
    margin-top: 1px \9;
    line-height: normal;
}

input[type="checkbox"]:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

.form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.form-control:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
}

.form-control::-moz-placeholder {
    color: #999;
    opacity: 1;
}

.form-control:-ms-input-placeholder {
    color: #999;
}

.form-control::-webkit-input-placeholder {
    color: #999;
}

.form-control::-ms-expand {
    background-color: transparent;
    border: 0;
}

.form-group {
    margin-bottom: 15px;
}

.help-block {
    display: block;
    margin-top: 5px;
    margin-bottom: 10px;
    color: #737373;
}

.btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}

.btn:focus,
.btn:active:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

.btn:hover,
.btn:focus {
    color: #333;
    text-decoration: none;
}

.btn:active {
    background-image: none;
    outline: 0;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}

.btn-lg {
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 6px;
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-block+.btn-block {
    margin-top: 5px;
}

.container:before,
.container:after,
.row:before,
.row:after {
    display: table;
    content: " ";
}

.container:after,
.row:after {
    clear: both;
}

/*! CSS Used from: https://templates.scriptsbundle.com/carspot/demos/css/style.css */
a {
    -webkit-transition: all .25s ease-in-out;
    -moz-transition: all .25s ease-in-out;
    -ms-transition: all .25s ease-in-out;
    -o-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out;
    cursor: pointer;
}

a:hover,
a:focus {
    text-decoration: none;
    o-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

a,
b,
div,
ul,
li {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    -moz-outline-: none;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

b {
    font-weight: 600;
}

.section-padding {
    padding: 90px 0;
}

.no-top {
    padding-top: 0;
}

.gray {
    background-color: #f6f6f6;
}

h2 {
    position: relative;
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
    margin: 0px;
    background: none;
    line-height: 1.6em;
}

h2 {
    font-size: 24px;
}

p {
    line-height: 1.6em;
    font-size: 16px;
}

.btn {
    font-size: 14px;
    letter-spacing: 1px;
    padding: 10px 15px;
    border-radius: 1px;
    -webkit-transition: all .25s ease-in-out;
    -moz-transition: all .25s ease-in-out;
    -ms-transition: all .25s ease-in-out;
    -o-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out;
    font-weight: 600;
    font-family: 'Source Sans Pro', sans-serif;
    text-transform: uppercase;
}

.form-control {
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 1px;
    box-shadow: none;
    color: #555;
    font-size: 14px;
    height: auto;
    padding: 15px 12px;
    transition: all 0.2s cubic-bezier(0, 0, 0.58, 1) 0s;
}

ul {
    margin-left: 0;
}

.btn-lg {
    padding: 12px 52px;
    letter-spacing: 1.3px;
}

.skin-minimal .list li label {
    padding-left: 10px;
    font-weight: 500;
    color: #232323;
    font-size: 16px;
}

.skin-minimal .list li label:hover {
    text-decoration: none;
}

label {
    font-size: 14px;
    font-weight: 600;
    color: #232323;
}

.text-right {
    text-align: right;
    float: right;
}

@media (min-width:767px) {
    .col-md-6:nth-child(2n+1) {
        clear: none;
    }
}

@media (min-width:992px) {
    .col-md-6:nth-child(2n+1) {
        clear: left;
    }
}

@media (min-width:1200px) {
    .col-md-6:nth-child(2n+1) {
        clear: none;
    }
}

.form-grid {
    padding: 0 30px;
}

.form-grid .skin-minimal .list li label {
    padding-left: 10px;
}

.form-grid .skin-minimal .list li label a {
    color: #777;
}

.form-grid .btn-social {
    margin-bottom: 15px;
}

.btn-social {
    overflow: hidden;
    padding-left: 60px;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
}

.btn-social>*:first-child {
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    bottom: 0;
    font-size: 1.6em;
    left: 0;
    line-height: 42px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 45px;
}

.btn-facebook {
    color: #fff;
    background-color: #3b5998;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-facebook:focus {
    color: #fff;
    background-color: #2d4373;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-facebook:hover {
    color: #fff;
    background-color: #2d4373;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-facebook:active {
    color: #fff;
    background-color: #2d4373;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-facebook:active:hover,
.btn-facebook:active:focus {
    color: #fff;
    background-color: #23345a;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-facebook:active {
    background-image: none;
}

.btn-google {
    color: #fff;
    background-color: #dd4b39;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-google:focus {
    color: #fff;
    background-color: #c23321;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-google:hover {
    color: #fff;
    background-color: #c23321;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-google:active {
    color: #fff;
    background-color: #c23321;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-google:active:hover,
.btn-google:active:focus {
    color: #fff;
    background-color: #a32b1c;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-google:active {
    background-image: none;
}

h2.no-span {
    color: #232323;
    display: table;
    font-size: 16px;
    margin: 10px 0;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
}

h2.no-span b {
    padding: 0 20px;
}

h2.no-span:before,
h2.no-span:after {
    border-top: 1px solid #ccc;
    content: '';
    display: table-cell;
    position: relative;
    top: 0.8em;
    width: 45%;
}

.form-control:focus {
    border-color: inherit;
    box-shadow: none;
}

/*! CSS Used from: https://templates.scriptsbundle.com/carspot/demos/css/font-awesome.css */
.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fa-facebook:before {
    content: "\f09a";
}

.fa-google:before {
    content: "\f1a0";
}

/*! CSS Used from: https://templates.scriptsbundle.com/carspot/demos/skins/minimal/minimal.css */
.icheckbox_minimal {
    display: inline-block;
    display: inline;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    width: 18px;
    height: 18px;
    background: url(https://templates.scriptsbundle.com/carspot/demos/skins/minimal/minimal.png) no-repeat;
    border: none;
    cursor: pointer;
}

.icheckbox_minimal {
    background-position: 0 0;
}

@media (-o-min-device-pixel-ratio: 5/4),
(-webkit-min-device-pixel-ratio: 1.25),
(min-resolution: 120dpi),
(min-resolution: 1.25dppx) {
    .icheckbox_minimal {
        background-image: url(https://templates.scriptsbundle.com/carspot/demos/skins/minimal/minimal@2x.png);
        -webkit-background-size: 200px 20px;
        background-size: 200px 20px;
    }
}

/*! CSS Used from: https://templates.scriptsbundle.com/carspot/demos/css/responsive-media.css */
@media (min-width: 320px) and (max-width: 767px) {
    .help-block {
        text-align: left;
    }
}

/*! CSS Used from: https://templates.scriptsbundle.com/carspot/demos/css/colors/defualt.css */
a:hover,
a:focus {
    color: #E52D27;
}

a,
.skin-minimal .list li label:hover {
    color: #E52D27;
}

.btn-theme {
    color: #ffffff;
    background-color: #E52D27;
    border-color: #E52D27;
}

.btn-theme:hover,
.btn-theme:focus,
.btn-theme:active {
    color: #ffffff;
    background-color: #B31217;
    border-color: #B31217;
}

.btn-theme:active {
    background-image: none;
}

/*! CSS Used fontfaces */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJbecmNE.woff2) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJnecmNE.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJfecg.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z11lFc-K.woff2) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1JlFc-K.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1xlFQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z11lFc-K.woff2) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1JlFc-K.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1xlFQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: monospace !important;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPZ7qsDJT9g.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: monospace !important;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPZ7jsDJT9g.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: monospace !important;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPZ7rsDJT9g.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: monospace !important;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPZ7ksDJT9g.woff2) format('woff2');
    unicode-range: U+0370-03FF;
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: monospace !important;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPZ7osDJT9g.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: monospace !important;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPZ7psDJT9g.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: monospace !important;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPZ7nsDI.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK3dSBYKcSV-LCoeQqfX1RYOo3qNa7lqDY.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK3dSBYKcSV-LCoeQqfX1RYOo3qPK7lqDY.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK3dSBYKcSV-LCoeQqfX1RYOo3qNK7lqDY.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK3dSBYKcSV-LCoeQqfX1RYOo3qO67lqDY.woff2) format('woff2');
    unicode-range: U+0370-03FF;
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK3dSBYKcSV-LCoeQqfX1RYOo3qN67lqDY.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK3dSBYKcSV-LCoeQqfX1RYOo3qNq7lqDY.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK3dSBYKcSV-LCoeQqfX1RYOo3qOK7l.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwmhduz8A.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwkxduz8A.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwmxduz8A.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwlBduz8A.woff2) format('woff2');
    unicode-range: U+0370-03FF;
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwmBduz8A.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwmRduz8A.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwlxdu.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'FontAwesome';
    src: url('https://templates.scriptsbundle.com/carspot/demos/fonts/fontawesome-webfont.eot?v=4.7.0');
    src: url('https://templates.scriptsbundle.com/carspot/demos/fonts/fontawesome-webfont.eot#iefix&v=4.7.0') format('embedded-opentype'), url('https://templates.scriptsbundle.com/carspot/demos/fonts/fontawesome-webfont.woff2?v=4.7.0') format('woff2'), url('https://templates.scriptsbundle.com/carspot/demos/fonts/fontawesome-webfont.woff?v=4.7.0') format('woff'), url('https://templates.scriptsbundle.com/carspot/demos/fonts/fontawesome-webfont.ttf?v=4.7.0') format('truetype'), url('https://templates.scriptsbundle.com/carspot/demos/fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: normal;
    font-style: normal;
}