.tech-slide-awi-26 {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-evenly;
    align-items: center;
    margin: 20px 10px;
}

.carousel-innertech24-awi-26 {
    width: 75%;
    height: 100%;
    margin: 10px;
}

.search-form-awi-26 {
    width: 25%;
    height: 100%;
    border-radius: 10px;
    padding: 20px 30px;
    border: 5px double #2e1e1e;
    background-color: rgba(red, green, blue, alpha);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
}

.form-section {
    margin-bottom: 20px;
}



.buttonno12 {
    background-color: #003e7c;
    margin-top: 15px;
    color: #fff;
    padding: 8px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 15rem;
}

.buttonno12:hover {
    background-color: #415f7e;
    color: #fff;
}

.error-notice {
    color: #ff0000;
    font-size: 12px;
    margin-top: 10px;
}

@media (min-width:780px) and (max-width:1000px) {
    .tech-slide-awi-26 {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }

    .carousel-innertech24-awi-26 {
        width: 100%;
        height: 100%;
    }

    .search-form-awi-26 {
        width: 50%;
        height: 100%;
    }
}

@media (min-width:550px) and (max-width:780px) {
    .tech-slide-awi-26 {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }

    .carousel-innertech24-awi-26 {
        width: 100%;
        order: 1;
        height: 100%;
    }

    .search-form-awi-26 {
        order: 2;
        width: 50%;
        height: 100%;
    }
}

@media (max-width:550px) {
    .tech-slide-awi-26 {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }

    .carousel-innertech24-awi-26 {
        width: 100%;
        order: 1;
        height: 100%;
        margin-left: -20px;
    }

    .search-form-awi-26 {
        order: 2;
        width: 100%;
        height: 100%;
    }
}