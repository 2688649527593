/*! CSS Used from: https://www.spruko.com/demo/autolist/Autolist/assets/plugins/bootstrap/css/bootstrap.min.css */
*,::after,::before{box-sizing:border-box;}
.blgBtn{
   
   padding-bottom: 2px;
    color:rgb(6, 14, 131);
    border:1px solid rgb(6 14 131 / 35%);
    font-weight: bold;
   
}
.blgBtn:hover{
   
    background: linear-gradient(270deg,#0f1c54,#2b224c);
    border:1px solid rgb(6 14 131 / 35%);
    color:white;
   

}
h3{margin-top:0;margin-bottom:.5rem;font-weight:500;line-height:1.2;}
h3{font-size:calc(1.3rem + .6vw);}
@media (min-width:1200px){
h3{font-size:1.75rem;}
}
p{margin-top:0;margin-bottom:1rem;}
button{border-radius:0;}
button:focus:not(:focus-visible){outline:0;}
button,input{margin:0;font-family:inherit;font-size:inherit;line-height:inherit;}
button{text-transform:none;}
[type=button],button{-webkit-appearance:button;}
::-moz-focus-inner{padding:0;border-style:none;}
.container{width:100%;padding-right:var(--bs-gutter-x,.75rem);padding-left:var(--bs-gutter-x,.75rem);margin-right:auto;margin-left:auto;}
@media (min-width:576px){
.container{max-width:540px;}
}
@media (min-width:768px){
.container{max-width:720px;}
}
@media (min-width:992px){
.container{max-width:960px;}
}
@media (min-width:1200px){
.container{max-width:1140px;}
}
@media (min-width:1400px){
.container{max-width:1320px;}
}
.row{--bs-gutter-x:1.5rem;--bs-gutter-y:0;display:flex;flex-wrap:wrap;margin-top:calc(-1 * var(--bs-gutter-y));margin-right:calc(-.5 * var(--bs-gutter-x));margin-left:calc(-.5 * var(--bs-gutter-x));}
.row>*{flex-shrink:0;width:100%;max-width:100%;padding-right:calc(var(--bs-gutter-x) * .5);padding-left:calc(var(--bs-gutter-x) * .5);margin-top:var(--bs-gutter-y);}
@media (min-width:768px){
.col-md-12{flex:0 0 auto;width:100%;}
}
@media (min-width:992px){
.col-lg-5{flex:0 0 auto;width:41.66666667%;}
.col-lg-7{flex:0 0 auto;width:58.33333333%;}
}
@media (min-width:1200px){
.col-xl-6{flex:0 0 auto;width:50%;}
}
.form-control{display:block;width:100%;padding:.375rem .75rem;font-size:1rem;font-weight:400;line-height:1.5;color:#212529;background-color:#fff;background-clip:padding-box;border:1px solid #ced4da;-webkit-appearance:none;-moz-appearance:none;appearance:none;border-radius:.25rem;transition:border-color .15s ease-in-out,box-shadow .15s ease-in-out;}
@media (prefers-reduced-motion:reduce){
.form-control{transition:none;}
}
.form-control:focus{color:#212529;background-color:#fff;border-color:#86b7fe;outline:0;box-shadow:0 0 0 .25rem rgba(13,110,253,.25);}
.form-control::-moz-placeholder{color:#6c757d;opacity:1;}
.form-control::placeholder{color:#6c757d;opacity:1;}
.form-control:disabled{background-color:#e9ecef;opacity:1;}
.input-group{position:relative;display:flex;flex-wrap:wrap;align-items:stretch;width:100%;}
.input-group>.form-control{position:relative;flex:1 1 auto;width:1%;min-width:0;}
.input-group>.form-control:focus{z-index:3;}
.input-group .btn{position:relative;z-index:2;}
.input-group .btn:focus{z-index:3;}
.input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu){border-top-right-radius:0;border-bottom-right-radius:0;}
.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback){margin-left:-1px;border-top-left-radius:0;border-bottom-left-radius:0;}
.btn{display:inline-block;font-weight:400;line-height:1.5;color:#212529;text-align:center;text-decoration:none;vertical-align:middle;cursor:pointer;-webkit-user-select:none;-moz-user-select:none;user-select:none;background-color:transparent;border:1px solid transparent;padding:.375rem .75rem;font-size:1rem;border-radius:.25rem;transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;}
@media (prefers-reduced-motion:reduce){
.btn{transition:none;}
}
.btn:hover{color:#212529;}
.btn:focus{outline:0;box-shadow:0 0 0 .25rem rgba(13,110,253,.25);}
.btn:disabled{pointer-events:none;opacity:.65;}
.btn-primary{color:#fff;background-color:#0d6efd;border-color:#0d6efd;}
.btn-primary:hover{color:#fff;background-color:#0b5ed7;border-color:#0a58ca;}
.btn-primary:focus{color:#fff;background-color:#0b5ed7;border-color:#0a58ca;box-shadow:0 0 0 .25rem rgba(49,132,253,.5);}
.btn-primary:active{color:#fff;background-color:#0a58ca;border-color:#0a53be;}
.btn-primary:active:focus{box-shadow:0 0 0 .25rem rgba(49,132,253,.5);}
.btn-primary:disabled{color:#fff;background-color:#0d6efd;border-color:#0d6efd;}
.btn-lg{padding:.5rem 1rem;font-size:1.25rem;border-radius:.3rem;}
.border-top{border-top:1px solid #dee2e6!important;}
.mt-1{margin-top:.25rem!important;}
.me-2{margin-right:.5rem!important;}
.mb-0{margin-bottom:0!important;}
.mb-2{margin-bottom:.5rem!important;}
.bg-white{--bs-bg-opacity:1;background-color:rgba(var(--bs-white-rgb),var(--bs-bg-opacity))!important;}
/*! CSS Used from: https://www.spruko.com/demo/autolist/Autolist/assets/css/style.css */
*,::after,::before{box-sizing:border-box;}
section{display:block;}
h3{margin-top:0;margin-bottom:0.66em;}
p{margin-top:0;margin-bottom:1rem;}
button{border-radius:0;margin:0;font-family:inherit;font-size:inherit;line-height:inherit;}
button:focus{outline:1px dotted;outline:5px auto -webkit-focus-ring-color;}
input{margin:0;font-family:inherit;font-size:inherit;line-height:inherit;}
button,input{overflow:visible;}
button{text-transform:none;}
button,html [type=button]{-webkit-appearance:button;}
[type=button]::-moz-focus-inner,button::-moz-focus-inner{padding:0;border-style:none;}
h3{margin-bottom:0.3em;font-weight:500;line-height:1.1;font-family:"Poppins", sans-serif;}
h3{font-size:1.5rem;font-family:"Poppins", sans-serif;}
.container{width:100%;padding-right:0.75rem;padding-left:0.75rem;margin-right:auto;margin-left:auto;}
@media (min-width: 576px){
.container{max-width:540px;}
}
@media (min-width: 768px){
.container{max-width:720px;}
}
@media (min-width: 992px){
.container{max-width:960px;}
}
@media (min-width: 1280px){
.container{max-width:1200px;}
}
@media print{
*,::after,::before{text-shadow:none!important;box-shadow:none!important;}
h3,p{orphans:3;widows:3;}
h3{page-break-after:avoid;}
.container{min-width:992px!important;}
}
.btn{display:inline-block;font-weight:400;text-align:center;white-space:nowrap;vertical-align:middle;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;border:1px solid transparent;padding:0.375rem 0.75rem;font-size:0.9375rem;line-height:1.84615385;border-radius:3px;transition:color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;}
.btn:focus,.btn:hover{text-decoration:none;}
.btn:focus{outline:0;box-shadow:0 0 0 2px rgba(194, 197, 199, 0.25);}
.btn:disabled{opacity:0.65;}
@media screen and (prefers-reduced-motion: reduce){
.btn{transition:none;}
}
.btn-lg{padding:0.5rem 1rem;font-size:1.125rem;line-height:1.625;border-radius:3px;}
.btn{cursor:pointer;font-weight:400;letter-spacing:0.03em;font-size:0.8125rem;min-width:2.375rem;}
.btn-lg{font-size:1.09rem;min-width:2.75rem;font-weight:400;}
.input-lg{height:46px!important;padding:10px 16px;font-size:18px;line-height:1.3333333;border-radius:3px;}
.form-control{display:block;width:100%;padding:0.375rem 0.75rem;font-size:0.9375rem;line-height:1.6;color:#080e1b;height:38px;background-color:#fff;background-clip:padding-box;border:1px solid #d8dde6;border-radius:3px;transition:border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;color:#6d7e9c;opacity:1;}
.form-control::-ms-expand{background-color:transparent;border:0;}
.form-control:focus{color:#6f6e6e;background-color:#fff;border-color:#d8dde6;outline:0;box-shadow:none;}
.form-control::-webkit-input-placeholder,.form-control::-moz-placeholder,.form-control:-ms-input-placeholder,.form-control::-ms-input-placeholder{color:#a7b4c9;opacity:1;}
.form-control:disabled{background-color:#f1f5fd;opacity:1;}
@media screen and (prefers-reduced-motion: reduce){
.form-control{transition:none;}
}
.row{display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;margin-right:-0.75rem;margin-left:-0.75rem;}
.input-group{position:relative;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;-ms-flex-align:stretch;align-items:stretch;width:100%;}
.col-md-12,.col-lg-5,.col-lg-7,.col-xl-6{position:relative;width:100%;min-height:1px;padding-right:0.75rem;padding-left:0.75rem;}
@media (min-width: 768px){
.col-md-12{-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
}
@media (min-width: 992px){
.col-lg-5{-ms-flex:0 0 41.66666667%;flex:0 0 41.66666667%;max-width:41.66666667%;}
.col-lg-7{-ms-flex:0 0 58.33333333%;flex:0 0 58.33333333%;max-width:58.33333333%;}
}
@media (min-width: 1280px){
.col-xl-6{-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
}
@media print{
.container{max-width:none;}
}
@media (max-width: 992px){
.sub-newsletter{margin-bottom:1rem;text-align:center;display:block!important;}
}
.sptb2{padding-top:3rem;padding-bottom:3rem;}
.sub-newsletter{display:inline-block;}
:focus{outline:0!important;}
input{-webkit-appearance:none;-moz-appearance:none;}
.bg-white{background-color:#fff!important;}
.border-top{border-top:1px solid #d8dde6!important;}
.br-ts-0{border-top-left-radius:0!important;}
.br-bs-0{border-bottom-left-radius:0!important;}
.mb-0{margin-bottom:0!important;}
.mt-1{margin-top:0.25rem!important;}
.me-2{margin-right:0.5rem;}
.mb-2{margin-bottom:0.5rem!important;}
/*! CSS Used from: https://www.spruko.com/demo/autolist/Autolist/assets/css/icons.css */
/*! @import https://www.spruko.com/demo/autolist/Autolist/assets/iconfonts/font-awesome/css/font-awesome.min.css */
.fa{display:inline-block;font:normal normal normal 14px/1 FontAwesome;font-size:inherit;text-rendering:auto;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;}
.fa-paper-plane-o:before{content:"\f1d9";}
/*! end @import */
/*! CSS Used from: https://www.spruko.com/demo/autolist/Autolist/assets/colorskins/color-skins/color13.css ; media=all */
@media all{
.btn-primary{color:#fff;background-color:#e72a1a!important;border-color:#e72a1a!important;}
.btn-primary:hover{color:#fff;background-color:#da1b0b;border-color:#da1b0b;}
.btn-primary:focus{box-shadow:0 0 0 2px rgba(231, 42, 26, 0.5);}
.btn-primary:disabled{color:#fff;background-color:#ea3728;border-color:#ea3728;}
}
/*! CSS Used from: Embedded */
*{-webkit-touch-callout:none;-webkit-user-select:none;-khtml-user-select:none;-moz-user-select:-moz-none;-ms-user-select:none;user-select:none;}
/*! CSS Used fontfaces */
@font-face{font-family:'Poppins';font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z11lFc-K.woff2) format('woff2');unicode-range:U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;}
@font-face{font-family:'Poppins';font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1JlFc-K.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Poppins';font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1xlFQ.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:'Poppins';font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJbecmNE.woff2) format('woff2');unicode-range:U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;}
@font-face{font-family:'Poppins';font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJnecmNE.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Poppins';font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJfecg.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:'Poppins';font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z11lFc-K.woff2) format('woff2');unicode-range:U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;}
@font-face{font-family:'Poppins';font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1JlFc-K.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Poppins';font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1xlFQ.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:'Poppins';font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z11lFc-K.woff2) format('woff2');unicode-range:U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;}
@font-face{font-family:'Poppins';font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1JlFc-K.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Poppins';font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1xlFQ.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:'Poppins';font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z11lFc-K.woff2) format('woff2');unicode-range:U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;}
@font-face{font-family:'Poppins';font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1JlFc-K.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Poppins';font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1xlFQ.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:'FontAwesome';src:url('https://www.spruko.com/demo/autolist/Autolist/assets/iconfonts/font-awesome/fonts/fontawesome-webfont.eot?v=4.7.0');src:url('https://www.spruko.com/demo/autolist/Autolist/assets/iconfonts/font-awesome/fonts/fontawesome-webfont.eot#iefix&v=4.7.0') format('embedded-opentype'),url('https://www.spruko.com/demo/autolist/Autolist/assets/iconfonts/font-awesome/fonts/fontawesome-webfont.woff2?v=4.7.0') format('woff2'),url('https://www.spruko.com/demo/autolist/Autolist/assets/iconfonts/font-awesome/fonts/fontawesome-webfont.woff?v=4.7.0') format('woff'),url('https://www.spruko.com/demo/autolist/Autolist/assets/iconfonts/font-awesome/fonts/fontawesome-webfont.ttf?v=4.7.0') format('truetype'),url('https://www.spruko.com/demo/autolist/Autolist/assets/iconfonts/font-awesome/fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');font-weight:normal;font-style:normal;}