.account-title::before {
  position: absolute;
  content: "";
  bottom: -2px;
  left: 0px;
  width: 100px !important;
  height: 2px;
  background: var(--primary);
}

.account-title h3 {
  font-size: 20px;
  margin-bottom: -10px !important;
}

.account-card{
box-shadow: 1px 2px 6px 3px lightgray;
width: 360px;
height: 350px;
}
.account-card-list li {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  padding-bottom: 2px;
  margin-bottom: 2px;
  border-bottom: 1px solid var(--border);
}
.print-buttonStart{
margin-right: 70px;
margin-top: 10px;
}
.succcard{
background-color: green;
}
.print-button1 {
position: relative;
bottom: 10px;
right: 10px;
padding: 2px 10px;
background-color: #007bff;
color: white;
float: right;
border: none;
border-radius: 5px;
cursor: pointer;
}

/* Popup.css */
.popup {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
padding: 35px;
background-color: rgba(0, 0, 0, 0.5);
display: flex;
justify-content: center;
align-items: center;
max-height: 1000px;/* Full height */
max-width:1000px ;
}

.popup-content1 {
background-color: white;
padding: 20px;
border-radius: 5px;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
/* Full width */
height: 400px; /* Full height */
width: 400px;
/* Enable scrolling if content exceeds viewport */
}

.close {
position: absolute;
top: 10px;
right: 10px;
font-size: 20px;
cursor: pointer;
}

.print-button {
position: absolute;
bottom: 10px;
right: 10px;
padding: 10px 20px;
background-color: #007bff;
color: white;
margin-right: -9px;
border: none;
border-radius: 5px;
cursor: pointer;
}

.print-button:hover {
background-color: #0056b3;
}
/* .popupData{
width: 400px;
height: 400px;
} */

@media print {
body * {
  visibility: hidden;  
}

.popupData *{
  visibility: visible;
  width: 500px;
}
.receipt-main{
  max-width: 1000px;
  border: none;
  position: absolute;
  top: -80%;
}
.receipt-left h3{
  float: left;
}
.popup,
.popup * {
  visibility: visible;  
}
.print-button,.close {
  display: none;
}
.popupData th {
  background-color: #fbc403;
  color: #363636;
  border: none;
}
}
.popupData {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid black;
  color: #363636;
}
.popupData td,
.popupData th {
  border: 1px solid black;
  color: #363636;
}
.popupData td {
  line-height: 25px;
  padding-left: 15px;
  color: #363636;
}
.popupData th {
  background-color: #fbc403;
  color: #363636;
  border: none;
}

/* reciept profile css start */
.text-danger strong {
  color: #9f181c;
}
.receipt-main {
background: #ffffff none repeat scroll 0 0;
border-bottom: 12px solid #333333;
border-top: 12px solid #9f181c;
margin-top: 50px;
margin-bottom: 50px;
padding: 40px 30px !important;
position: relative;
box-shadow: 0 1px 21px #acacac;
color: #333333;
font-family: open sans;
}
.receipt-main p {
color: #333333;
font-family: open sans;
line-height: 1.42857;
}
.receipt-footer h1 {
font-size: 15px;
font-weight: 400 !important;
margin: 0 !important;
}
.receipt-main::after {
background: #414143 none repeat scroll 0 0;
content: "";
height: 5px;
left: 0;
position: absolute;
right: 0;
top: -13px;
}
.receipt-main thead {
background: #414143 none repeat scroll 0 0;
}
.receipt-main thead th {
color:#fff;
}
.receipt-right h5 {
font-size: 16px;
font-weight: bold;
margin: 0 0 7px 0;
}
.receipt-right p {
font-size: 12px;
margin: 0px;
}
.receipt-right p i {
text-align: center;
width: 18px;
}
.receipt-main td {
padding: 9px 20px !important;
}
.receipt-main th {
padding: 13px 20px !important;
}
.receipt-main td {
font-size: 13px;
font-weight: initial !important;
}
.receipt-main td p:last-child {
margin: 0;
padding: 0;
}	
.receipt-main td h2 {
font-size: 20px;
font-weight: 900;
margin: 0;
text-transform: uppercase;
}
.receipt-header-mid .receipt-left h1 {
font-weight: 100;
margin: 34px 0 0;
text-align: right;
text-transform: uppercase;
}
.receipt-header-mid {
margin: 24px 0;
overflow: hidden;
}

#container {
background-color: #dcdcdc;
}
/* reciept profile css end */