.scienceslide-awi-24 {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin: 20px 10px;
}

.carousel-innerscience24-awi-24 {
    width: 75%;
    height: 100%;
    margin: 10px;
}

.search-scienceform-awi-24 {
    width: 25%;
    height: 100%;
    border-radius: 0px 0px 30px 30px;
    padding: 20px 30px;
    border: 2px solid #2e1e1e;
    background-color: rgb(238, 238, 238);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
}

.form-group-science {
    margin-bottom: 20px;
}

.button-scienceno1 {
    background-color: #003e7c;
    margin-top: 15px;
    color: #fff;
    padding: 8px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 15rem;
}

.button-scienceno1:hover {
    background-color: #415f7e;
    color: #fff;
}

.error-sciencemessage {
    color: #ff0000;
    font-size: 12px;
    margin-top: 10px;
}

@media (min-width:780px) and (max-width:1000px) {
    .scienceslide-awi-24 {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }

    .carousel-innerscience24-awi-24 {
        width: 100%;
        height: 100%;
    }

    .search-scienceform-awi-24 {
        width: 50%;
        height: 100%;
    }
}

@media (min-width:550px) and (max-width:780px) {
    .scienceslide-awi-24 {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }

    .carousel-innerscience24-awi-24 {
        width: 100%;
        order: 1;
        height: 100%;
    }

    .search-scienceform-awi-24 {
        order: 2;
        width: 50%;
        height: 100%;
    }
}

@media (max-width:550px) {
    .scienceslide-awi-24 {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }

    .carousel-innerscience24-awi-24 {
        width: 100%;
        order: 1;
        height: 100%;
        margin-left: -20px;
    }

    .search-scienceform-awi-24 {
        order: 2;
        width: 100%;
        height: 100%;
    }
}