.Requirements-m-d {
    width:100%;
    box-shadow: 0px 1px 3px gray;
}
.Requirements-form {
    width: 75%;
    margin: auto;
    height: 100%;
}
.Requirements-form-span {
    width: 100%;
    display: flex;
    padding: 30px 0 0;
    border-bottom: 0.1px solid lightgray;
    & input, textarea {
        border: 1px solid #5d5d5d3d !important;
        padding: 8px 15px;
        font-size: 14px;
        color: #757575;
        font-style: normal !important;
        border-radius: 5px;
    }
    & i {
        color: red;
    }
}
.Requirements-form-span-h {
    width: 40%;
}
.Requirements-form-span-i {
    width: 60%;
    & span{
        display: flex;
        flex-direction: column;
    }
}
.Requirements-form-button {
    width: 100%;
    padding: 5px;
    margin: 25px 0 50px;
    color: white;
    font-weight: 600;
    background-color: rgb(60, 71, 80);
    cursor: pointer;
    touch-action: unset;
}


@media (max-width:990px) {
    .Requirements-form {
        width: 90%;
    }
}

@media (max-width:575px) {
    .Requirements-form {
        width: 95%;
    }
    .Requirements-form-span {
        flex-direction: column;
        align-items: center;
    }
    .Requirements-form-span-h,
    .Requirements-form-span-i {
        width: 85%;
    }
}