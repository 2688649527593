.visible{
    position: fixed;
    z-index: 10;
    
}

/* #btnads{
    position: fixed;
    border-radius:180px;
    transition: 0.3s opacity ease-in-out;
    border-image: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
} */
.menu 
{
  position: relative;
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.menu .toggle 
{
  position: absolute;
  width: 70px;
  height: 70px;
  background: linear-gradient(60deg, #3e5d7a, #478688, #3e324e, #a166ab, #5073b8, #1098ad, #346861, #353246);
  color: #35c783;
  border-radius: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  cursor: pointer;
  transition: 0.5s;

}
.menu .toggle.active 
{
  transform: rotate(315deg);
  box-shadow: 0 0 0 40px #fff;
  background: linear-gradient(60deg, #b8b0a8, #a14d3c, #ef4e7b, #c632e0, #437ef3, #1098ad, #07b39b, #6fba82);
  color: #eeecec;
  border-radius: 50%;
  width: 60px;
  height: 60px;

}
.menu li 
{
  position: absolute;
  left: 20px;
  list-style: none;
  transform: rotate(calc(360deg / 8 * var(--i))) translateX(50px);
  transform-origin: 130px;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
  z-index: 10;
}
.menu.active li 
{
  visibility: visible;
  opacity: 1;
}
.menu li a 
{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 55px;
  font-size: 1.75em;
  color: #262433;
  transform: rotate(calc(360deg / -8 * var(--i))); 
  border-radius: 50%;
}
.menu.active li.active 
{
  transform: rotate(calc(360deg / 8 * var(--i))) translateX(40px);
} 
.indicator 
{
  position: absolute;
  left: calc(50% + 2.5px);
  transform-origin: right;
  width: 100px;
  height: 1px;
  background: transparent;
  pointer-events: none;
  transition: 0.5s;
}
.indicator::before 
{
  content: '';
  position: absolute;
  top: -27.5px;
  left: 72px;
  width: 45px;
  height: 45px;
  background: #262433;
  box-shadow: 0 0 0 6px #262433;
  border-radius: 50%;
  transition: 0.5s;
  opacity: 0;
}
.menu.active .indicator::before 
{
  opacity: 1;
  top: -20.5px;
  left: 15.2px;
  background: #29fd53;
  box-shadow: 0 0 0 5px #262433;
}
.menu li:nth-child(2).active ~ .indicator 
{
  transform: translateX(-103px) rotate(0deg);
}
.menu li:nth-child(3).active ~ .indicator 
{
  transform: translateX(-103px) rotate(45deg);
}
.menu li:nth-child(4).active ~ .indicator 
{
  transform: translateX(-103px) rotate(90deg);
}
.menu li:nth-child(5).active ~ .indicator 
{
  transform: translateX(-103px) rotate(135deg);
}
.menu li:nth-child(6).active ~ .indicator 
{
  transform: translateX(-103px) rotate(180deg);
}
.menu li:nth-child(7).active ~ .indicator 
{
  transform: translateX(-103px) rotate(225deg);
}
.menu li:nth-child(8).active ~ .indicator 
{
  transform: translateX(-103px) rotate(270deg);
}
.menu li:nth-child(9).active ~ .indicator 
{
  transform: translateX(-103px) rotate(315deg);
}
/* .visible{
 position: absolute;
 top: 50%;
 left: 50%;
 transform: translate(-50,-50%);
} */



.custom-popup {
  position: fixed;
  top: 10%;
  left: 5%;
  width: 90%;
  height: max-content;
  background-color: whitesmoke;
  border: 1px solid gray;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 9999;
  overflow: auto;
  padding: 20px;
}

.close-buttonno {
  position: absolute;
  top: 4px;
  right: 4px;
  background-color: red; /* Red color for the close button */
  color: white;
  border: none;
  border-radius: 5%;
  width: 20px;
  height: 20px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-button:hover {
  background-color: rgb(104, 1, 1); /* Darker red color on hover */
}
