.popup1234 {
    position: fixed;
    bottom: 20px; /* Adjust as needed */
    right: 20px;
    background-color: rgba(0, 0, 0, 0.8); /* Black with transparency */
    color: #fff; /* Text color */
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    max-width: 300px; /* Adjust as needed */
    z-index: 9; /* Ensure it's on top of other elements */
    transition: opacity 0.3s ease; /* Add transition for smooth appearance */
  }
  .mass{
    color: aliceblue;
    font-size: 70%;
  }
  .popup1234 .close {
    float: right;
    cursor: pointer;
    color: #fff; /* Close button color */
  }
  
  .popup1234 .close:hover {
    color: #ccc; /* Close button color on hover */
  }

  
/*INSIDE NOTIFICATION*/
.notification-sty-head{
  font-size: 24px;
  font-family: "Roboto";
  font-weight: 600;
  font-style: normal;
  color: rgb(78, 77, 77);
}

.notification-sty-button1{
  font-size: 18px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: 600;
  font-style: normal;
  color: white(78, 77, 77);
  border-radius: 10px;
  background-color:#0b2d5e;
  padding: 6px 14px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}
.notification-sty-button2 {
  font-size: 18px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: bolder;
  font-style: normal;
  color: rgb(78, 77, 77); /* Corrected the color format */
  border-radius: 10px;
  /* background-color: #0dc0d4; */
  background-color: rgb(8,33,68);
  padding: 6px 14px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Decreased the shadow */
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s; /* Added transition */
}

.notification-sty-button2:hover {
  /* background-color: #16e8f4; */
  color: white; /* Changed text color on hover */
  /* box-shadow: 0px 0px 15px #16e8f4; */
   /* Increased shadow on hover */
   background-color: rgb(8,50,100);
   box-shadow: 0px 0px 15px rgb(8,50,100);
}
.notification-sty-quote{
  font-family: "Roboto";
  font-weight: bolder;
  font-style: normal;
  color: black;
  margin-top: 5px;
}

.notification-sty-img{
  width: 10%;
  height: 10%;
  margin-top: 20px;
}











.notification-table24 {
  display: flex;
  justify-content: center;
  
}

.notification-table24 table {
  width: 60%;
  border-collapse: collapse;

}



.notification-table24 tr {
  transition: background-color 0.3s, border 0.3s, color 0.3s, box-shadow 0.3s, transform 0.3s ease; /* Adjusted transition */
}
.notification-table24 tr:nth-child(odd) {
  background-color:  #f0f0f0;
}

.notification-table24 tr:nth-child(even) {
  background-color:  #dad7d7;
}

.notification-table24 tr:hover {
  background-color: #b9cdf5;
  border: 1px solid #ccc;
  color: #333; /* Change text color on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
}
.notification-table24 tr:hover ~ tr {
  transition: all 0.3s; /* Added transition to other rows */
  transform: translateY(5px); /* Moved other rows down on hover */
}

.notification-table24 tr:hover:first-child {
  transform: translateY(-4px); /* Moved first row up on hover */
}


.notification-table24 td {
  padding: 10px;
  border: 1px solid transparent;
  color: black;
  transition: all 0.3s;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Added box-shadow */
}
.notification-table24 td:first-child {
  width: 30px;

}

 .blink-dot {
  color: #0b2d5e;
  animation: blinker 1.5s infinite alternate;
  transform: translate(-50%, -50%);
  margin: 10px 20px 0px 5px;
  font-size: 20px;
  padding: 2px;
}
@keyframes blinker {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 5;
  }
}
 .notification-sty-iconDel{
  font-size:20px;
   color:red;
float: right;
 }
  