/*! CSS Used from: Embedded */
._2yqpe button,._2yqpe input{font-family:Roboto,Arial,Helvetica,sans-serif;}
header{display:block;}
svg:not(:root){overflow:hidden;}
button,input{font-family:sans-serif;font-size:100%;line-height:1.15;margin:0;}
button,input{overflow:visible;}
button{text-transform:none;}
button,html [type=button]{-webkit-appearance:button;}
[type=button]::-moz-focus-inner,button::-moz-focus-inner{border-style:none;padding:0;}
[type=button]:-moz-focusring,button:-moz-focusring{outline:1px dotted ButtonText;}
button,input{font-family:Arial,Helvetica,sans-serif;}
.rui-10_kq{fill:rgba(0,47,52,.64);}
.rui-4K4Y7{fill:#002f34;}
.rui-39-wj{display:inline-flex;justify-content:center;align-items:center;box-sizing:border-box;border-style:none;background:none;outline:none;text-transform:lowercase;font-weight:700;cursor:pointer;padding:0 12px;position:relative;overflow:hidden;line-height:normal;text-decoration:none;}
.rui-39-wj:first-letter,.rui-39-wj :first-letter{text-transform:uppercase;}
.rui-39-wj.rui-1JPTg{font-size:16px;height:48px;}
.rui-39-wj.rui-3-PNI{color:#002f34;border-bottom:2px solid #002f34;padding:0;height:auto;}
.rui-39-wj.rui-3-PNI:not(.rui-38Vok):hover{border-bottom-color:transparent;}
.rui-39-wj.rui-3-PNI:not(.rui-38Vok):active{color:#406367;border-bottom-color:#406367;}
.rui-39-wj:active:after{transform:scale(0);opacity:1;transition:0s;}
.rui-1rYgw{display:inline-flex;justify-content:center;align-items:center;border-style:none;background:none;outline:none;cursor:pointer;position:relative;overflow:hidden;text-decoration:none;border-radius:50%;width:40px;height:40px;padding:0;}
.rui-1rYgw.rui-82PI3:after,.rui-1rYgw.rui-82PI3:before{content:"";width:100%;height:100%;opacity:0;position:absolute;}
.rui-1rYgw.rui-82PI3:before{transition:opacity .2s ease-in;background-color:#c8f8f6;z-index:-1;}
.rui-1rYgw.rui-82PI3:hover:before{opacity:1;}
.rui-1rYgw.rui-82PI3:after{background-image:radial-gradient(circle,#fff 10%,transparent 10.01%);pointer-events:none;background-repeat:no-repeat;background-position:50%;transform:scale(15);transition:transform .5s,opacity .5s;}
.rui-1rYgw.rui-82PI3:active:after{transform:scale(0);opacity:1;transition:0s;}
.rui-3edbr{position:fixed;top:0;left:0;right:0;bottom:0;z-index:10;display:flex;align-items:center;justify-content:center;background:rgba(0,0,0,.8);transform:translateZ(0);-webkit-transform:translateZ(0);}
/*! CSS Used from: Embedded */
._2qkRs{background-color:#fff;height:68px;position:fixed;width:100%;z-index:15;}
._1oKlx{padding:0 8px;display:flex;height:68px;align-items:center;box-sizing:border-box;background-color:rgba(0,47,52,.03);}
._1oKlx ._1gRon{margin-right:16px;}
._1oKlx ._2yRzF{margin-left:auto;}
/*! CSS Used from: https://www.olx.in/chunks/legacy/mobile-LocationOnboarding.olx.2f557c52b3ba4b44d7d0.css */
.p1IZ5{height:48px;width:100%;position:relative;}
.p1IZ5 .J1MTI{display:flex;align-items:center;background:#fff;box-sizing:border-box;padding:0 8px;border:1px solid #003034;border-radius:4px;height:43px;}
.p1IZ5 .J1MTI ._2MKB5{height:40px;width:100%;outline:0;border-width:0;font-size:16px;color:#002f34;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;background:#fff;padding:0 0 0 12px;}
._2qkRs{background-color:#fff;height:68px;position:absolute;width:100%;z-index:15;margin-top:50px;}
._1oKlx{padding:0 8px;display:flex;height:68px;align-items:center;box-sizing:border-box;background-color:rgba(0,47,52,.03);}
._1oKlx ._1gRon{margin-right:16px;}
._1oKlx ._2yRzF{margin-left:auto;}
/*! CSS Used from: https://www.olx.in/chunks/legacy/mobile-overlaySearch.olx.e4f6360529a8a7917b07.css */
._2FSAi{height:100%;width:100%;background-color:#fff;position:absolute;top:0;left:0;display:flex;}
._2FSAi ._339_O{position:absolute;width:100%;top:96px;height:calc(100% - 200px);left:-8px;}
._2FSAi ._2MfV_{overflow:scroll;display:flex;flex-direction:column;flex:1;background-color:rgba(0,47,52,.03);padding:8px;margin-top:68px;border-bottom:1px solid rgba(14,4,5,.2);}
._3_ZNh{height:48px;width:100%;margin:0 0 8px;position:relative;}
._3_ZNh ._2c3V9{width:100%;position:absolute;left:0;}
._3_ZNh ._16-NA{height:48px;display:flex;align-items:center;margin:0 0 8px;}
._3_ZNh ._16-NA ._3taHC{height:40px;width:100%;outline:0;font-size:16px;color:#002f34;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;background:#fff;-webkit-appearance:none;line-height:normal;border:2px solid #002f34;border-radius:4px;padding:0 48px;}
._3_ZNh ._16-NA ._3taHC:focus{border-color:#23e5db;}
._3_ZNh ._16-NA .l6yok{position:absolute;left:8px;display:flex;}
._3Oa0E{padding:8px;}
._3Oa0E .jjKL-{display:flex;align-items:center;height:40px;}
._3Oa0E .jjKL- span{color:rgba(0,47,52,.64);font-size:12px;text-transform:uppercase;}
._2t8Qg{display:flex;align-items:center;height:48px;cursor:pointer;padding:8px;box-sizing:border-box;}
._2t8Qg:hover{background-color:#c8f8f6;}
._2t8Qg ._3LpJm{margin:0 16px 0 0;}
._2t8Qg ._1XOsN{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
._2t8Qg ._1XOsN span{color:#002f34;font-size:14px;}
.p1IZ5{height:48px;width:100%;position:relative;}
.p1IZ5 .J1MTI{display:flex;align-items:center;background:#fff;box-sizing:border-box;padding:0 8px;border:1px solid #003034;border-radius:4px;height:43px;}
.p1IZ5 .J1MTI ._2MKB5{height:40px;width:100%;outline:0;border-width:0;font-size:16px;color:#002f34;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;background:#fff;padding:0 0 0 12px;}
/*! CSS Used fontfaces */
@font-face{font-family:'Roboto';font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu72xKOzY.woff2) format('woff2');unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:'Roboto';font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu5mxKOzY.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:'Roboto';font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu7mxKOzY.woff2) format('woff2');unicode-range:U+1F00-1FFF;}
@font-face{font-family:'Roboto';font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4WxKOzY.woff2) format('woff2');unicode-range:U+0370-03FF;}
@font-face{font-family:'Roboto';font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu7WxKOzY.woff2) format('woff2');unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:'Roboto';font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu7GxKOzY.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Roboto';font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4mxK.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:'Roboto';font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fCRc4EsA.woff2) format('woff2');unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:'Roboto';font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fABc4EsA.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:'Roboto';font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fCBc4EsA.woff2) format('woff2');unicode-range:U+1F00-1FFF;}
@font-face{font-family:'Roboto';font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fBxc4EsA.woff2) format('woff2');unicode-range:U+0370-03FF;}
@font-face{font-family:'Roboto';font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fCxc4EsA.woff2) format('woff2');unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:'Roboto';font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fChc4EsA.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Roboto';font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fBBc4.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:'Roboto';font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfCRc4EsA.woff2) format('woff2');unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:'Roboto';font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfABc4EsA.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:'Roboto';font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfCBc4EsA.woff2) format('woff2');unicode-range:U+1F00-1FFF;}
@font-face{font-family:'Roboto';font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfBxc4EsA.woff2) format('woff2');unicode-range:U+0370-03FF;}
@font-face{font-family:'Roboto';font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfCxc4EsA.woff2) format('woff2');unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:'Roboto';font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfChc4EsA.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Roboto';font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfBBc4.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}