.popup-overlay {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease-in-out;
}
@media (min-width: 768px) and (max-width: 1991px) {

.popupf {
  background: white;
  padding: 20px;
  border-radius: 8px;
  backdrop-filter: blur(10px);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  width: 30%; /* Adjust width as needed */
  transition: all 0.3s ease-in-out;
  max-width: 400px; /* Set a maximum width if necessary */
}
}
@media (max-width: 768px) {
  .popupf {
    background: white;
    padding: 20px;
    border-radius: 8px;
    backdrop-filter: blur(10px);
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    width: 100%; /* Adjust width as needed */
    transition: all 0.3s ease-in-out;
    max-width: 400px; /* Set a maximum width if necessary */
  }
}
.popup-overlay:hover {
  background-color: rgba(0, 0, 0, 0.7); /* Change the overlay color on hover */
}


/* StartSellingPage.css */

.start-selling-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4;
}

.start-selling-content {
  text-align: center;
}

h1 {
  font-size: 2rem;
  color: #333;
}

p {
  font-size: 1.2rem;
  color: #555;
  margin: 10px 0;
}

.start-selling-button {
  padding: 12px 24px;
  font-size: 1.2rem;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.start-selling-button:hover {
  background-color: #0056b3;
}

