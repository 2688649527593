/* Base styles for all devices */
.custom-banner {
    width: 30%;
    background-color: #f2f2f2;
    padding: 20px;
    background-image: linear-gradient(200deg, #024268 0%, #cf6034 74%);
    border-top: 5px solid rgb(255, 0, 0);
    border-left: 2px solid red;
    border-right: 2px solid red;
    border-radius: 0 0 2rem 2rem;
    margin-top: 5rem;
    height: auto;
    position: relative;
}

.custom-content-wrapper {
    text-align: center;
}

.custom-free-tag {
    background-color: #ff0000;
    color: #ffee00;
    padding: 5px 10px;
    border-radius: 5px;
    display: inline-block;
    border-radius: 0 0.5rem 0 0.5rem;
    position: absolute;
    left: 10px;
    top: 10px;
    animation: blink 1s infinite;
}

.custom-banner-content {
    margin-top: 20px;
}

.custom-title {
    font-size: 20px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    color: white;
}

.custom-clicklink {
    background-color: #01152c;
    color: #ffffff;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    text-decoration: none;
    position: absolute;
    right: 10px;
    bottom: 10px;
}

/* Responsive styles for devices with a width up to 767px (e.g., mobile phones) */
@media (max-width: 767px) {
    .custom-banner {
        width: 100%; /* Make the banner full width on small devices */
        margin-top: 3rem; /* Adjust the top margin for better spacing on small devices */
    }

    .custom-free-tag {
        font-size: 12px; /* Adjust the font size of the "Free" tag on small devices */
        top: 5px; /* Adjust the top position of the "Free" tag on small devices */
        left: 5px; /* Adjust the left position of the "Free" tag on small devices */
    }

    .custom-banner-content {
        margin-top: 10px; /* Adjust the top margin for better spacing on small devices */
    }

    .custom-title {
        font-size: 18px; /* Adjust the font size of the title on small devices */
    }

    .custom-clicklink {
        font-size: 12px; /* Adjust the font size of the button on small devices */
        bottom: 5px; /* Adjust the bottom position of the button on small devices */
        right: 5px; /* Adjust the right position of the button on small devices */
    }
}

/* Responsive styles for devices with a width between 768px and 991px (e.g., tablets) */
@media (min-width: 768px) and (max-width: 991px) {
    .custom-banner {
        width: 50%; /* Adjust the banner width for tablets */
    }

    /* You can add additional tablet-specific styles here */
}

/* Responsive styles for devices with a width between 992px and 1199px (e.g., small laptops) */
@media (min-width: 992px) and (max-width: 1199px) {
    .custom-banner {
        width: 40%; /* Adjust the banner width for small laptops */
    }

    /* You can add additional small laptop-specific styles here */
}

/* Responsive styles for devices with a width of 1200px and above (e.g., larger laptops and desktops) */







.detailFont{
    font-family: "Poppins", sans-serif !important;
}

.productButton{
    border:1px solid #004e92;
    background-color: #004e92;
    padding:5px;
    border-radius:5px;
    color:white !important;
}



.gm-err-container {
    height: 100%;
    width: 100%;
    display: table;
    background-color: #e8eaed;
    position: relative;
    left: 0;
    top: 0;
}

.gm-err-content {
    border-radius: 1px;
    padding-top: 0;
    padding-left: 10%;
    padding-right: 10%;
    position: static;
    vertical-align: middle;
    display: table-cell;
}

.gm-err-icon {
    text-align: center;
}

.gm-err-title {
    margin: 5px;
    margin-bottom: 20px;
    color: #3c4043;
    font-family: Roboto, Arial, sans-serif;
    text-align: center;
    font-size: 24px;
}

.gm-err-message {
    margin: 5px;
    color: #3c4043;
    font-family: Roboto, Arial, sans-serif;
    text-align: center;
    font-size: 12px;
}

/*! CSS Used from: https://demo.joinwebs.com/classiera/pearl/wp-includes/css/dist/block-library/style.min.css?ver=5.9.3 ; media=all */
@media all {

    h3,
    h4,
    h5 {
        overflow-wrap: break-word;
    }

    ul {
        overflow-wrap: break-word;
    }

    p {
        overflow-wrap: break-word;
    }
}

/*! CSS Used from: https://demo.joinwebs.com/classiera/pearl/wp-content/cache/autoptimize/css/autoptimize_single_7b27042ae10826fc454490baed753d2d.css?ver=1 ; media=all */
@media all {

    aside,
    section {
        display: block;
    }

    a {
        background-color: transparent;
    }

    a:active,
    a:hover {
        outline: 0;
    }

    img {
        border: 0;
    }

    button,
    input,
    textarea {
        margin: 0;
        font: inherit;
        color: inherit;
    }

    button {
        overflow: visible;
    }

    button {
        text-transform: none;
    }

    button {
        -webkit-appearance: button;
        cursor: pointer;
    }

    button::-moz-focus-inner,
    input::-moz-focus-inner {
        padding: 0;
        border: 0;
    }

    input {
        line-height: normal;
    }

    input[type=checkbox],
    input[type=radio] {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0;
    }

    textarea {
        overflow: auto;
    }

    @media print {

        *,
        *:before,
        *:after {
            color: #000 !important;
            text-shadow: none !important;
            background: 0 0 !important;
            -webkit-box-shadow: none !important;
            box-shadow: none !important;
        }

        a,
        a:visited {
            text-decoration: underline;
        }

        a[href]:after {
            content: " (" attr(href) ")";
        }

        a[href^="#"]:after {
            content: "";
        }

        img {
            page-break-inside: avoid;
        }

        img {
            max-width: 100% !important;
        }

        p,
        h3 {
            orphans: 3;
            widows: 3;
        }

        h3 {
            page-break-after: avoid;
        }
    }

    * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    *:before,
    *:after {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    input,
    button,
    textarea {
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
    }

    a {
        color: #337ab7;
        text-decoration: none;
    }

    a:hover,
    a:focus {
        color: #23527c;
        text-decoration: underline;
    }

    a:focus {
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px;
    }

    img {
        vertical-align: middle;
    }

    .img-responsive,
    .carousel-inner>.item>img {
        display: block;
        max-width: 100%;
        height: auto;
    }

    .sr-only {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0;
    }

    [role=button] {
        cursor: pointer;
    }

    h3,
    h4,
    h5 {
        font-family: inherit;
        font-weight: 500;
        line-height: 1.1;
        color: inherit;
    }

    h5 .small {
        font-weight: 400;
        line-height: 1;
        color: #777;
    }

    h3 {
        margin-top: 20px;
        margin-bottom: 10px;
    }

    h4,
    h5 {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    h5 .small {
        font-size: 75%;
    }

    h3 {
        font-size: 24px;
    }

    h4 {
        font-size: 18px;
    }

    h5 {
        font-size: 14px;
    }

    p {
        margin: 0 0 10px;
    }

    .small {
        font-size: 85%;
    }

    .text-uppercase {
        text-transform: uppercase;
    }

    .text-capitalize {
        text-transform: capitalize;
    }

    .text-danger {
        color: #a94442;
    }

    ul {
        margin-top: 0;
        margin-bottom: 10px;
    }

    .list-unstyled {
        padding-left: 0;
        list-style: none;
    }

    .container {
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }

    @media (min-width:768px) {
        .container {
            width: 750px;
        }
    }

    @media (min-width:992px) {
        .container {
            width: 970px;
        }
    }

    @media (min-width:1200px) {
        .container {
            width: 1170px;
        }
    }

    .row {
        margin-right: -15px;
        margin-left: -15px;
    }

    .col-sm-3,
    .col-md-4,
    .col-sm-6,
    .col-sm-7,
    .col-md-8,
    .col-sm-9,
    .col-sm-12,
    .col-md-12,
    .col-lg-12 {
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
    }

    @media (min-width:768px) {

        .col-sm-3,
        .col-sm-6,
        .col-sm-7,
        .col-sm-9,
        .col-sm-12 {
            float: left;
        }

        .col-sm-12 {
            width: 100%;
        }

        .col-sm-9 {
            width: 75%;
        }

        .col-sm-7 {
            width: 58.33333333%;
        }

        .col-sm-6 {
            width: 50%;
        }

        .col-sm-3 {
            width: 25%;
        }
    }

    @media (min-width:992px) {

        .col-md-4,
        .col-md-8,
        .col-md-12 {
            float: left;
        }

        .col-md-12 {
            width: 100%;
        }

        .col-md-8 {
            width: 66.66666667%;
        }

        .col-md-4 {
            width: 33.33333333%;
        }
    }

    @media (min-width:1200px) {
        .col-lg-12 {
            float: left;
        }

        .col-lg-12 {
            width: 100%;
        }
    }

    label {
        display: inline-block;
        max-width: 100%;
        margin-bottom: 5px;
        font-weight: 700;
    }

    input[type=radio],
    input[type=checkbox] {
        margin: 4px 0 0;
        margin-top: 1px \9;
        line-height: normal;
    }

    input[type=radio]:focus,
    input[type=checkbox]:focus {
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px;
    }

    .form-control {
        display: block;
        width: 100%;
        height: 34px;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.42857143;
        color: #555;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
        -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
        -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
        transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    }

    .form-control:focus {
        border-color: #66afe9;
        outline: 0;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
    }

    .form-control::-moz-placeholder {
        color: #999;
        opacity: 1;
    }

    .form-control:-ms-input-placeholder {
        color: #999;
    }

    .form-control::-webkit-input-placeholder {
        color: #999;
    }

    .form-control::-ms-expand {
        background-color: transparent;
        border: 0;
    }

    textarea.form-control {
        height: auto;
    }

    .form-group {
        margin-bottom: 15px;
    }

    .radio,
    .checkbox {
        position: relative;
        display: block;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .radio label,
    .checkbox label {
        min-height: 20px;
        padding-left: 20px;
        margin-bottom: 0;
        font-weight: 400;
        cursor: pointer;
    }

    .radio input[type=radio],
    .checkbox input[type=checkbox] {
        position: absolute;
        margin-top: 4px \9;
        margin-left: -20px;
    }

    .help-block {
        display: block;
        margin-top: 5px;
        margin-bottom: 10px;
        color: #737373;
    }

    @media (min-width:768px) {
        .form-inline .form-group {
            display: inline-block;
            margin-bottom: 0;
            vertical-align: middle;
        }

        .form-inline .form-control {
            display: inline-block;
            width: auto;
            vertical-align: middle;
        }
    }

    .form-horizontal .radio,
    .form-horizontal .checkbox {
        padding-top: 7px;
        margin-top: 0;
        margin-bottom: 0;
    }

    .form-horizontal .radio,
    .form-horizontal .checkbox {
        min-height: 27px;
    }

    .form-horizontal .form-group {
        margin-right: -15px;
        margin-left: -15px;
    }

    @media (min-width:768px) {
        .form-horizontal .control-label {
            padding-top: 7px;
            margin-bottom: 0;
            text-align: right;
        }
    }

    .btn {
        display: inline-block;
        padding: 6px 12px;
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.42857143;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        -ms-touch-action: manipulation;
        touch-action: manipulation;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-image: none;
        border: 1px solid transparent;
        border-radius: 4px;
    }

    .btn:focus,
    .btn:active:focus {
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px;
    }

    .btn:hover,
    .btn:focus {
        color: #333;
        text-decoration: none;
    }

    .btn:active {
        background-image: none;
        outline: 0;
        -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    }

    .btn.disabled {
        cursor: not-allowed;
        filter: alpha(opacity=65);
        -webkit-box-shadow: none;
        box-shadow: none;
        opacity: .65;
    }

    .btn-primary {
        color: #fff;
        background-color: #337ab7;
        border-color: #2e6da4;
    }

    .btn-primary:focus {
        color: #fff;
        background-color: #286090;
        border-color: #122b40;
    }

    .btn-primary:hover {
        color: #fff;
        background-color: #286090;
        border-color: #204d74;
    }

    .btn-primary:active {
        color: #fff;
        background-color: #286090;
        border-color: #204d74;
    }

    .btn-primary:active:hover,
    .btn-primary:active:focus {
        color: #fff;
        background-color: #204d74;
        border-color: #122b40;
    }

    .btn-primary:active {
        background-image: none;
    }

    .btn-primary.disabled:hover,
    .btn-primary.disabled:focus {
        background-color: #337ab7;
        border-color: #2e6da4;
    }

    .btn-sm {
        padding: 5px 10px;
        font-size: 12px;
        line-height: 1.5;
        border-radius: 3px;
    }

    .btn-block {
        display: block;
        width: 100%;
    }

    .nav {
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
    }

    .nav>li {
        position: relative;
        display: block;
    }

    .nav>li>a {
        position: relative;
        display: block;
        padding: 10px 15px;
    }

    .nav>li>a:hover,
    .nav>li>a:focus {
        text-decoration: none;
        background-color: #eee;
    }

    .nav-tabs {
        border-bottom: 1px solid #ddd;
    }

    .nav-tabs>li {
        float: left;
        margin-bottom: -1px;
    }

    .nav-tabs>li>a {
        margin-right: 2px;
        line-height: 1.42857143;
        border: 1px solid transparent;
        border-radius: 4px 4px 0 0;
    }

    .nav-tabs>li>a:hover {
        border-color: #eee #eee #ddd;
    }

    .nav-tabs>li.active>a,
    .nav-tabs>li.active>a:hover,
    .nav-tabs>li.active>a:focus {
        color: #555;
        cursor: default;
        background-color: #fff;
        border: 1px solid #ddd;
        border-bottom-color: transparent;
    }

    .tab-content>.tab-pane {
        display: none;
    }

    .tab-content>.active {
        display: block;
    }

    .breadcrumb {
        padding: 8px 15px;
        margin-bottom: 20px;
        list-style: none;
        background-color: #f5f5f5;
        border-radius: 4px;
    }

    .breadcrumb>li {
        display: inline-block;
    }

    .breadcrumb>li+li:before {
        padding: 0 5px;
        color: #ccc;
        content: "/\00a0";
    }

    .breadcrumb>.active {
        color: #777;
    }

    .alert {
        padding: 15px;
        margin-bottom: 20px;
        border: 1px solid transparent;
        border-radius: 4px;
    }

    .alert-success {
        color: #3c763d;
        background-color: #dff0d8;
        border-color: #d6e9c6;
    }

    .alert-danger {
        color: #a94442;
        background-color: #f2dede;
        border-color: #ebccd1;
    }

    .media {
        margin-top: 15px;
    }

    .media:first-child {
        margin-top: 0;
    }

    .media,
    .media-body {
        overflow: hidden;
        zoom: 1;
    }

    .media-body {
        width: 10000px;
    }

    .media-object {
        display: block;
    }

    .media-left {
        padding-right: 10px;
    }

    .media-left,
    .media-body {
        display: table-cell;
        vertical-align: top;
    }

    .media-heading {
        margin-top: 0;
        margin-bottom: 5px;
    }

    .carousel {
        position: relative;
    }

    .carousel-inner {
        position: relative;
        width: 100%;
        overflow: hidden;
    }

    .carousel-inner>.item {
        position: relative;
        display: none;
        -webkit-transition: .6s ease-in-out left;
        -o-transition: .6s ease-in-out left;
        transition: .6s ease-in-out left;
    }

    .carousel-inner>.item>img {
        line-height: 1;
    }

    @media all and (transform-3d),
    (-webkit-transform-3d) {
        .carousel-inner>.item {
            -webkit-transition: -webkit-transform .6s ease-in-out;
            -o-transition: -o-transform .6s ease-in-out;
            transition: transform .6s ease-in-out;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            -webkit-perspective: 1000px;
            perspective: 1000px;
        }

        .carousel-inner>.item.next {
            left: 0;
            -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
        }

        .carousel-inner>.item.active.left {
            left: 0;
            -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
        }

        .carousel-inner>.item.next.left,
        .carousel-inner>.item.active {
            left: 0;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }

    .carousel-inner>.active,
    .carousel-inner>.next {
        display: block;
    }

    .carousel-inner>.active {
        left: 0;
    }

    .carousel-inner>.next {
        position: absolute;
        top: 0;
        width: 100%;
    }

    .carousel-inner>.next {
        left: 100%;
    }

    .carousel-inner>.next.left {
        left: 0;
    }

    .carousel-inner>.active.left {
        left: -100%;
    }

    .carousel-control {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 15%;
        font-size: 20px;
        color: #fff;
        text-align: center;
        text-shadow: 0 1px 2px rgba(0, 0, 0, .6);
        background-color: rgba(0, 0, 0, 0);
        filter: alpha(opacity=50);
        opacity: .5;
    }

    .carousel-control.left {
        background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, .0001) 100%);
        background-image: -o-linear-gradient(left, rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, .0001) 100%);
        background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, .5)), to(rgba(0, 0, 0, .0001)));
        background-image: linear-gradient(to right, rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, .0001) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#00000000', GradientType=1);
        background-repeat: repeat-x;
    }

    .carousel-control.right {
        right: 0;
        left: auto;
        background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, .0001) 0%, rgba(0, 0, 0, .5) 100%);
        background-image: -o-linear-gradient(left, rgba(0, 0, 0, .0001) 0%, rgba(0, 0, 0, .5) 100%);
        background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, .0001)), to(rgba(0, 0, 0, .5)));
        background-image: linear-gradient(to right, rgba(0, 0, 0, .0001) 0%, rgba(0, 0, 0, .5) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1);
        background-repeat: repeat-x;
    }

    .carousel-control:hover,
    .carousel-control:focus {
        color: #fff;
        text-decoration: none;
        filter: alpha(opacity=90);
        outline: 0;
        opacity: .9;
    }

    .clearfix:before,
    .clearfix:after,
    .container:before,
    .container:after,
    .row:before,
    .row:after,
    .form-horizontal .form-group:before,
    .form-horizontal .form-group:after,
    .nav:before,
    .nav:after {
        display: table;
        content: " ";
    }

    .clearfix:after,
    .container:after,
    .row:after,
    .form-horizontal .form-group:after,
    .nav:after {
        clear: both;
    }

    .pull-right {
        float: right !important;
    }

    .hidden {
        display: none !important;
    }

    .visible-xs,
    .visible-sm {
        display: none !important;
    }

    @media (max-width:767px) {
        .visible-xs {
            display: block !important;
        }
    }

    @media (min-width:768px) and (max-width:991px) {
        .visible-sm {
            display: block !important;
        }
    }
}

/*! CSS Used from: https://demo.joinwebs.com/classiera/pearl/wp-content/cache/autoptimize/css/autoptimize_single_8b73d5de28934481a881ac3dd96fd7f8.css?ver=1 ; media=all */
@media all {
    h4>a {
        color: #232323;
    }

    h5>a {
        color: #232323;
    }

    .btn {
        -webkit-transition: all .5s ease;
        -moz-transition: all .5s ease;
        -ms-transition: all .5s ease;
        -o-transition: all .5s ease;
        transition: all .5s ease;
    }

    * {
        margin: 0;
        padding: 0;
    }

    @media screen and (min-width:1199px) {
        .container {
            width: 1200px;
        }
    }

    .text-danger {
        color: #fc1f01;
    }

    h3,
    h4,
    h5 {
        font-family: 'Ubuntu', sans-serif;
        color: #232323;
        font-weight: 700;
    }

    a {
        text-decoration: none !important;
    }

    .border {
        border: 1px solid #e0e0e0;
    }

    .border-bottom {
        border-bottom: 1px solid #e0e0e0;
    }

    .btn {
        transition: all .5s ease;
        -webkit-transition: all .5s ease;
        -moz-transition: all .5s ease;
        -ms-transition: all .5s ease;
        -o-transition: all .5s ease;
    }

    .btn {
        padding: 0;
        border: 0 none;
        color: #232323;
    }

    .btn:focus,
    .btn:active:focus {
        outline: 0 none;
    }

    .btn-primary {
        background: #232323;
        color: #fff;
    }

    .btn-primary:hover,
    .btn-primary:focus,
    .btn-primary:active,
    .btn-primary:active:hover {
        background: #b6d91a;
    }

    .btn-primary:active {
        background: #b6d91a;
        box-shadow: none;
        -webkit-box-shadow: none;
    }

    .btn-md {
        padding: 10px 16px;
    }

    .btn-sm {
        padding: 8px 10px;
    }

    .btn.sharp {
        border-radius: 0;
    }

    .btn-style-one {
        font-size: 12px;
        line-height: 1.60;
    }

    .btn-style-one:hover {
        -webkit-box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, .2);
        -moz-box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, .2);
        box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, .2);
    }

    .form-control-xs {
        height: 30px;
        line-height: 30px;
    }

    .form-control-sm {
        height: 40px;
    }

    .form-control {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        color: #6c6c6c;
        text-transform: inherit;
        box-shadow: none;
    }

    .inner-addon {
        position: relative;
    }

    .inner-addon.left-addon input {
        padding-left: 30px;
    }

    input[type=radio],
    input[type=checkbox] {
        position: absolute !important;
        z-index: -1;
        visibility: hidden;
        opacity: 0;
    }

    input[type=radio]+label {
        display: block;
    }

    input[type=checkbox]+label:before,
    input[type=radio]+label:before {
        display: inline-block;
        font-family: 'Font Awesome\ 5 Free';
        font-style: normal;
        font-weight: 400;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        padding-right: 8px;
        width: 23px;
        color: #6c6c6c;
    }

    input[type=radio]+label:before {
        content: "\f111";
        color: #6c6c6c;
    }

    input[type=radio]:checked+label:before {
        content: "\f192";
        color: #b6d91a;
    }

    input[type=checkbox]+label:before {
        content: "\f0c8";
        color: #6c6c6c;
    }

    input[type=checkbox]:checked+label:before {
        content: "\f14a";
        color: #b6d91a;
    }

    .radio label,
    .checkbox label {
        padding-left: 0;
    }

    .checkbox label a {
        font-weight: 700;
        color: #232323;
    }

    #ad-address span {
        display: table;
        padding-top: 15px;
    }

    #ad-address span i,
    #ad-address span a {
        display: table-cell;
    }

    #ad-address span i {
        background: #232323;
        color: #fff;
        font-size: 20px;
        min-width: 40px;
        text-align: center;
        vertical-align: middle;
        transition: all .5s ease;
        -webkit-transition: all .5s ease;
        -moz-transition: all .5s ease;
        -ms-transition: all .5s ease;
        -o-transition: all .5s ease;
        padding: 10px 0;
    }

    #ad-address span a {
        transition: all .5s ease;
        -webkit-transition: all .5s ease;
        -moz-transition: all .5s ease;
        -ms-transition: all .5s ease;
        -o-transition: all .5s ease;
        font-size: 16px;
        padding-left: 10px;
    }

    #ad-address span a:hover,
    #ad-address span a:focus {
        color: #b6d91a;
    }

    #ad-address span:hover i {
        background: #b6d91a;
    }
}

/*! CSS Used from: https://demo.joinwebs.com/classiera/pearl/wp-content/cache/autoptimize/css/autoptimize_single_e02b3c4da8686784cacb1836bc391e96.css?ver=1 ; media=all */
@media all {
    .classiera_entry_content:after {
        display: block;
        content: "";
        clear: both;
    }

    .classiera_entry_content>* {
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .sidebar .widget-box .widget-content ul li>a,
    .sidebar .widget-box .author-info a,
    .author-info .media-heading a,
    .single-post-page .single-post .single-post-title .post-category span a,
    .single-post-page .single-post .description .tags a {
        -webkit-transition: all .5s ease;
        -moz-transition: all .5s ease;
        -ms-transition: all .5s ease;
        -o-transition: all .5s ease;
        transition: all .5s ease;
    }

    .sidebar .widget-box {
        border: 1px solid #e0e0e0;
        margin-bottom: 30px;
    }

    .sidebar .widget-box .widget-title {
        border-bottom: 1px solid #e0e0e0;
        background: #fafafa;
        padding: 20px 15px;
    }

    .sidebar .widget-box .widget-title.price {
        margin-bottom: -10px;
    }

    .sidebar .widget-box .widget-content {
        padding: 20px 15px;
    }

    .sidebar .widget-box .widget-content.widget-content-post {
        padding: 0 15px;
    }

    .sidebar .widget-box .widget-content.widget-content-post>.widget-content-post-area {
        padding-bottom: 20px;
        padding-top: 20px;
    }

    .sidebar .widget-box .widget-content.widget-content-post>.widget-content-post-area>h5 {
        text-transform: uppercase;
        letter-spacing: 1px;
        margin-bottom: 20px;
    }

    .sidebar .widget-box .widget-content ul {
        list-style: none;
    }

    .sidebar .widget-box .widget-content ul li {
        margin-bottom: 20px;
    }

    .sidebar .widget-box .widget-content ul li>a {
        color: #6c6c6c;
        font-size: 13px;
        display: flex;
        flex-direction: row;
        align-items: baseline;
    }

    .sidebar .widget-box .widget-content ul li>a i {
        margin-right: 10px;
        color: inherit;
    }

    .sidebar .widget-box .widget-content ul li>a:hover {
        color: #b6d91a;
    }

    .sidebar .widget-box .author-info {
        border-bottom: 1px solid #e0e0e0;
        padding-bottom: 20px;
    }

    .sidebar .widget-box .author-info p {
        margin-bottom: 5px;
    }

    .sidebar .widget-box .author-info a {
        color: #232323;
        font-weight: 600;
    }

    .sidebar .widget-box .author-info a:hover {
        color: #b6d91a;
    }

    .sidebar .widget-box .post-price {
        margin: 0;
    }

    .sidebar .widget-box .contact-details h5 {
        letter-spacing: 1px;
        margin-bottom: 20px;
        margin-top: 0;
    }

    .sidebar .widget-box .contact-details .c-detail li {
        margin-bottom: 10px !important;
        text-transform: none !important;
        letter-spacing: 1px;
        font-size: 16px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .sidebar .widget-box .contact-details .c-detail li a {
        letter-spacing: 1px;
        -ms-word-wrap: break-word;
        word-wrap: break-word;
        font-size: 16px !important;
        word-break: break-all;
    }

    .sidebar .widget-box .user-make-offer-message {
        padding-bottom: 20px;
    }

    .sidebar .widget-box .user-make-offer-message .nav-tabs {
        border-bottom: none;
    }

    .sidebar .widget-box .user-make-offer-message .nav>li {
        margin-bottom: 10px;
    }

    .sidebar .widget-box .user-make-offer-message .nav>li.btnWatch button {
        background: 0 0;
        margin-right: 2px;
        padding-top: 3px !important;
        padding-bottom: 4px !important;
    }

    .sidebar .widget-box .user-make-offer-message .nav>li.btnWatch button:hover i {
        color: #fff !important;
    }

    .sidebar .widget-box .user-make-offer-message .nav>li>a,
    .sidebar .widget-box .user-make-offer-message .nav>li.btnWatch button {
        border: 2px solid #b6d91a;
        border-radius: 0;
        text-transform: uppercase;
        font-size: 12px;
        color: #b6d91a;
        padding: 7px 23px;
    }

    .sidebar .widget-box .user-make-offer-message .nav>li>a i,
    .sidebar .widget-box .user-make-offer-message .nav>li.btnWatch button i {
        color: #b6d91a;
        margin-right: 10px;
    }

    .sidebar .widget-box .user-make-offer-message .nav>li>a:hover,
    .sidebar .widget-box .user-make-offer-message .nav>li.btnWatch button:hover {
        color: #fff !important;
        background: #b6d91a !important;
    }

    .sidebar .widget-box .user-make-offer-message .nav>li>a:hover i,
    .sidebar .widget-box .user-make-offer-message .nav>li button:hover i {
        color: #fff !important;
    }

    .sidebar .widget-box .user-make-offer-message .nav>li.active>a {
        background: #b6d91a;
        color: #fff !important;
    }

    .sidebar .widget-box .user-make-offer-message .nav>li.active>a i {
        color: #fff !important;
    }

    .sidebar .widget-box .user-make-offer-message .tab-content form {
        border: 1px solid #e0e0e0;
        border-radius: 3px;
        padding: 20px 15px;
    }

    .sidebar .widget-box .user-make-offer-message .tab-content form .form-group {
        margin-bottom: 10px;
    }

    .sidebar .widget-box .user-make-offer-message .tab-content form label {
        font-size: 14px;
        color: #232323;
    }

    .sidebar .widget-box .user-make-offer-message .tab-content form input {
        border-radius: 2px;
        font-size: 12px;
    }

    .sidebar .widget-box .user-make-offer-message .tab-content form textarea {
        min-height: 100px;
        font-size: 12px;
    }

    .sidebar .widget-box .user-make-offer-message .radio {
        margin-bottom: 20px;
    }

    .sidebar .widget-box .user-make-offer-message .radio label {
        color: #6c6c6c !important;
        font-size: 14px;
    }

    .sidebar .widget-box .user-make-offer-message .otherMSG {
        margin-bottom: 15px;
        display: none;
    }

    .sidebar .widget-box ul {
        list-style: none;
        padding: 15px;
    }

    .sidebar .widget-box ul li>a:hover,
    .sidebar .widget-box ul li>a:focus {
        color: #b6d91a;
    }

    .sidebar .widget-box .price h3.post-price {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .sidebar .widget-box .price h3.post-price span.ad_type_display {
        background: #b6d91a;
        color: #fff;
        padding: 5px 10px;
        border-radius: 2px;
        margin-left: 10px;
        font-size: 14px;
        line-height: 1;
    }

    .inner-page-content {
        padding-bottom: 80px;
    }

    .inner-page-content .breadcrumb {
        background: #fafafa;
        border: 1px solid #e0e0e0;
        margin-top: 30px;
    }

    .inner-page-content .breadcrumb>li {
        font-size: 12px;
    }

    .inner-page-content .breadcrumb>li a {
        font-size: inherit;
        color: #6c6c6c;
    }

    .inner-page-content .breadcrumb>li a:hover,
    .inner-page-content .breadcrumb>li a:hover i {
        color: #b6d91a;
    }

    .inner-page-content .breadcrumb>li.active {
        color: #b6d91a;
    }

    .inner-page-content .breadcrumb>li+li:before {
        content: "\f101";
        font-family: 'Font Awesome\ 5 Free';
        font-weight: 600;
        color: #aaa;
    }

    .border-section {
        padding: 30px;
        position: relative;
    }

    .border-section .border-section-heading {
        position: absolute;
        font-size: 16px;
        top: -20px;
        left: 35px;
        padding: 0 15px;
        background: #fff;
    }

    .border-section .border-section-heading i {
        margin-right: 10px;
        letter-spacing: 1px;
    }

    .border-section .user-comments {
        padding-top: 20px;
        padding-bottom: 40px;
        margin-bottom: 30px;
    }

    .border-section .comment-form .comment-form-heading {
        margin-bottom: 30px;
    }

    .border-section .comment-form .comment-form-heading h4 {
        font-size: 16px;
        margin-top: 0;
    }

    .border-section .comment-form .comment-form-heading p {
        margin-bottom: 0;
    }

    .border-section .comment-form form .form-group {
        margin-bottom: 20px;
    }

    .border-section .comment-form form .form-group .form-control {
        width: 100%;
        border-radius: 0;
        padding-left: 15px;
        color: #aaa;
        text-transform: none;
    }

    .border-section .comment-form form .form-group textarea {
        width: 100%;
        height: 100px;
        padding: 15px;
        color: #aaa;
        border: 1px solid #e0e0e0;
    }

    .author-info .media-object {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        max-width: inherit;
        object-fit: cover;
        -o-object-fit: cover;
    }

    .author-info .media-heading {
        margin-bottom: 10px;
    }

    .author-info .media-heading a:hover {
        color: #b6d91a;
    }

    .author-info p {
        font-size: 13px;
        color: #aaa;
        margin-bottom: 10px;
        line-height: 13px;
    }

    .author-info span {
        display: inline-block;
        margin-left: 1px;
        margin-right: 10px;
        font-size: 13px;
    }

    .author-info span.small {
        margin-right: 0;
    }

    .single .inner-page-content {
        padding-bottom: 0 !important;
    }

    .single-post-page .single-post .border-section {
        margin-bottom: 45px;
    }

    .single-post-page .single-post .single-post-title>.post-price {
        border-radius: 3px;
        background: #fafafa;
        text-align: center;
        margin-bottom: 15px;
        border: 1px solid #e0e0e0;
        padding: 13px 0;
        margin-top: 15px;
    }

    .single-post-page .single-post .single-post-title>.post-price>h4 {
        color: #232323;
        font-size: 20px;
        font-weight: 500;
        margin: 0;
        letter-spacing: 1px;
    }

    .single-post-page .single-post .single-post-title h4 a {
        color: #232323;
        letter-spacing: 1px;
        font-weight: 500;
        line-height: 24px;
    }

    .single-post-page .single-post .single-post-title .post-category {
        color: #6c6c6c;
        margin-bottom: 30px;
    }

    .single-post-page .single-post .single-post-title .post-category i {
        padding-right: 5px;
    }

    .single-post-page .single-post .single-post-title .post-category span {
        padding-left: 5px;
        display: inline-block;
        margin-right: 15px;
    }

    .single-post-page .single-post .single-post-title .post-category span a {
        word-break: break-all;
        margin-right: 2px;
        color: #6c6c6c;
    }

    .single-post-page .single-post .single-post-title .post-category span a:hover {
        color: #b6d91a;
    }

    .single-post-page .single-post #single-post-carousel {
        border: 1px solid #e0e0e0;
        padding: 5px;
        position: relative;
        margin-bottom: 40px;
    }

    .single-post-page .single-post #single-post-carousel .carousel-inner {
        width: 100%;
        background: #f0f0f0;
    }

    .single-post-page .single-post #single-post-carousel .carousel-inner .item {
        height: 400px;
    }

    .single-post-page .single-post #single-post-carousel .carousel-inner .item>img {
        position: absolute;
        object-fit: cover;
        -o-object-fit: cover;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        margin: auto;
    }

    .single-post-page .single-post #single-post-carousel .single-post-carousel-controls {
        position: absolute;
        bottom: 60px;
        right: 60px;
        z-index: 10;
        width: 55px;
    }

    .single-post-page .single-post #single-post-carousel .single-post-carousel-controls .carousel-control {
        opacity: .8;
    }

    .single-post-page .single-post #single-post-carousel .single-post-carousel-controls .carousel-control span {
        border-radius: 4px;
        display: inline-block;
        background: #232323;
        width: 40px;
        height: 40px;
        line-height: 40px;
    }

    .single-post-page .single-post #single-post-carousel .num {
        background: rgba(35, 35, 35, .8);
        border-radius: 3px;
        padding: 8px 16px;
        color: #fff;
        display: inline-block;
        position: absolute;
        bottom: 20px;
        left: 15px;
    }

    .single-post-page .single-post #single-post-carousel .num i {
        color: #fff;
        margin-right: 10px;
    }

    .single-post-page .single-post .details .post-details ul {
        margin-bottom: 0;
    }

    .single-post-page .single-post .details .post-details ul li {
        width: 50%;
        padding: 0 15px;
        display: inline-block;
        float: left;
    }

    .single-post-page .single-post .details .post-details ul li:nth-of-type(even) {
        padding-right: 0;
    }

    .single-post-page .single-post .details .post-details ul li p {
        border-bottom: 1px dashed #e0e0e0;
        color: #232323;
        padding-bottom: 7px;
        margin-bottom: 7px;
        vertical-align: middle;
    }

    .single-post-page .single-post .details .post-details ul li p span {
        max-width: 60%;
        text-align: right;
        color: #8e8e8e;
    }

    .single-post-page .single-post .details .post-details ul li i.IDIcon {
        color: #8e8e8e;
        font-size: 10px;
    }

    .single-post-page .single-post .description p {
        margin-bottom: 30px;
        line-height: 1.5;
    }

    .single-post-page .single-post .description .tags {
        border-top: 1px solid #e0e0e0;
        word-break: break-word;
        padding-top: 20px;
        font-size: 13px;
    }

    .single-post-page .single-post .description .tags span {
        color: #232323;
        display: inline-block;
        margin-right: 15px;
        font-size: inherit;
    }

    .single-post-page .single-post .description .tags a {
        color: #6c6c6c;
        font-size: inherit;
        margin-right: 15px;
    }

    .single-post-page .single-post .description .tags a:hover {
        color: #232323;
    }

    .single-post-title>h4 {
        position: relative;
        padding-right: 130px;
        word-break: break-word;
    }

    .details_adv_map {
        width: 100%;
        height: 400px;
        border: none;
    }

    .leaflet-control-attribution a {
        display: none !important;
    }

    .comment-form {
        position: relative;
    }

    .classiera--loader {
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, .8);
        left: 0;
        top: 0;
        text-align: center;
        position: absolute;
        z-index: 2;
        display: none;
    }

    .classiera--loader img {
        width: 80px;
        height: 80px;
        position: absolute;
        margin: auto;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    .comment-success,
    .comment-error-login,
    .comment-error {
        display: none;
    }

    .author-info .media-heading i {
        color: #b6d91a;
        margin-left: 5px;
    }

    .nav-tabs>li.active>a:hover,
    .nav-tabs>li.active>a:focus {
        border-top: none;
        border-bottom: none !important;
        border-left: none;
        color: #232323;
        pointer-events: none;
    }
}

/*! CSS Used from: https://demo.joinwebs.com/classiera/pearl/wp-content/cache/autoptimize/css/autoptimize_single_8452290d7deb4af0f874314c2912f5af.css?ver=1 ; media=all */
@media all {

    .fas,
    .far {
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        line-height: 1;
    }

    .fa-ul {
        list-style-type: none;
        margin-left: 2.5em;
        padding-left: 0;
    }

    .fa-ul>li {
        position: relative;
    }

    .fa-li {
        left: -2em;
        position: absolute;
        text-align: center;
        width: 2em;
        line-height: inherit;
    }

    .fa-camera:before {
        content: "\f030";
    }

    .fa-check-circle:before {
        content: "\f058";
    }

    .fa-chevron-left:before {
        content: "\f053";
    }

    .fa-chevron-right:before {
        content: "\f054";
    }

    .fa-envelope:before {
        content: "\f0e0";
    }

    .fa-exclamation-triangle:before {
        content: "\f071";
    }

    .fa-file-alt:before {
        content: "\f15c";
    }

    .fa-folder-open:before {
        content: "\f07c";
    }

    .fa-hashtag:before {
        content: "\f292";
    }

    .fa-heart:before {
        content: "\f004";
    }

    .fa-home:before {
        content: "\f015";
    }

    .fa-map-marker-alt:before {
        content: "\f3c5";
    }

    .fa-tags:before {
        content: "\f02c";
    }

    .sr-only {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    .far {
        font-family: 'Font Awesome 5 Free';
        font-weight: 400;
    }

    .fas {
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
    }
}

/*! CSS Used from: https://demo.joinwebs.com/classiera/pearl/wp-content/cache/autoptimize/css/autoptimize_single_914c77f1dc2aa9ee1ec522ffb2952c6d.css?ver=1 ; media=all */
@media all {
    @media only screen and (max-width:640px) {
        .breadcrumb {
            margin-bottom: 10px;
        }

        .single-post-page .single-post #single-post-carousel .carousel-inner .item {
            height: 200px;
        }

        .single-post-page .single-post .details .post-details ul li {
            display: block;
            padding: 0;
            width: 100%;
        }

        .author-info.widget-content-post-area {
            margin-bottom: 15px;
        }
    }

    @media only screen and (min-width:768px) and (max-width:959px) {
        .inner-page-content .col-md-8 {
            margin-bottom: 30px;
        }
    }

    @media only screen and (min-width:960px) and (max-width:1024px) {
        .author-info.widget-content-post-area {
            margin-bottom: 20px;
        }
    }
}

/*! CSS Used from: https://demo.joinwebs.com/classiera/pearl/wp-content/cache/autoptimize/css/autoptimize_single_6f5bab0a9f63d4224316045301dcadd3.css?ver=1 ; media=all */
@media all {

    .leaflet-pane,
    .leaflet-tile,
    .leaflet-marker-icon,
    .leaflet-tile-container,
    .leaflet-layer {
        position: absolute;
        left: 0;
        top: 0;
    }

    .leaflet-container {
        overflow: hidden;
    }

    .leaflet-tile,
    .leaflet-marker-icon {
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
        -webkit-user-drag: none;
    }

    .leaflet-marker-icon {
        display: block;
    }

    .leaflet-container .leaflet-marker-pane img {
        max-width: none !important;
    }

    .leaflet-container.leaflet-touch-zoom {
        -ms-touch-action: pan-x pan-y;
        touch-action: pan-x pan-y;
    }

    .leaflet-tile {
        filter: inherit;
        visibility: hidden;
    }

    .leaflet-pane {
        z-index: 999;
    }

    .leaflet-tile-pane {
        z-index: 200;
    }

    .leaflet-overlay-pane {
        z-index: 400;
    }

    .leaflet-shadow-pane {
        z-index: 500;
    }

    .leaflet-marker-pane {
        z-index: 600;
    }

    .leaflet-tooltip-pane {
        z-index: 650;
    }

    .leaflet-popup-pane {
        z-index: 700;
    }

    .leaflet-control {
        position: relative;
        z-index: 800;
        pointer-events: visiblePainted;
        pointer-events: auto;
    }

    .leaflet-top,
    .leaflet-bottom {
        position: absolute;
        z-index: 1000;
        pointer-events: none;
    }

    .leaflet-top {
        top: 0;
    }

    .leaflet-right {
        right: 0;
    }

    .leaflet-bottom {
        bottom: 0;
    }

    .leaflet-left {
        left: 0;
    }

    .leaflet-control {
        float: left;
        clear: both;
    }

    .leaflet-right .leaflet-control {
        float: right;
    }

    .leaflet-top .leaflet-control {
        margin-top: 10px;
    }

    .leaflet-bottom .leaflet-control {
        margin-bottom: 10px;
    }

    .leaflet-left .leaflet-control {
        margin-left: 10px;
    }

    .leaflet-right .leaflet-control {
        margin-right: 10px;
    }

    .leaflet-fade-anim .leaflet-tile {
        will-change: opacity;
    }

    .leaflet-zoom-animated {
        -webkit-transform-origin: 0 0;
        -ms-transform-origin: 0 0;
        transform-origin: 0 0;
    }

    .leaflet-interactive {
        cursor: pointer;
    }

    .leaflet-popup-pane,
    .leaflet-control {
        cursor: auto;
    }

    .leaflet-marker-icon,
    .leaflet-tile-container {
        pointer-events: none;
    }

    .leaflet-marker-icon.leaflet-interactive {
        pointer-events: visiblePainted;
        pointer-events: auto;
    }

    .leaflet-container {
        background: #ddd;
        outline: 0;
    }

    .leaflet-container a {
        color: #0078a8;
    }

    .leaflet-container {
        font: 12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
    }

    .leaflet-bar {
        box-shadow: 0 1px 5px rgba(0, 0, 0, .65);
        border-radius: 4px;
    }

    .leaflet-bar a,
    .leaflet-bar a:hover {
        background-color: #fff;
        border-bottom: 1px solid #ccc;
        width: 26px;
        height: 26px;
        line-height: 26px;
        display: block;
        text-align: center;
        text-decoration: none;
        color: #000;
    }

    .leaflet-bar a {
        background-position: 50% 50%;
        background-repeat: no-repeat;
        display: block;
    }

    .leaflet-bar a:hover {
        background-color: #f4f4f4;
    }

    .leaflet-bar a:first-child {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    .leaflet-bar a:last-child {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        border-bottom: none;
    }

    .leaflet-bar a.leaflet-disabled {
        cursor: default;
        background-color: #f4f4f4;
        color: #bbb;
    }

    .leaflet-touch .leaflet-bar a {
        width: 30px;
        height: 30px;
        line-height: 30px;
    }

    .leaflet-control-zoom-in,
    .leaflet-control-zoom-out {
        font: bold 18px 'Lucida Console', Monaco, monospace;
        text-indent: 1px;
    }

    .leaflet-control-zoom-out {
        font-size: 20px;
    }

    .leaflet-touch .leaflet-control-zoom-in {
        font-size: 22px;
    }

    .leaflet-touch .leaflet-control-zoom-out {
        font-size: 24px;
    }

    .leaflet-container .leaflet-control-attribution {
        background: #fff;
        background: rgba(255, 255, 255, .7);
        margin: 0;
    }

    .leaflet-control-attribution {
        padding: 0 5px;
        color: #333;
    }

    .leaflet-control-attribution a {
        text-decoration: none;
    }

    .leaflet-control-attribution a:hover {
        text-decoration: underline;
    }

    .leaflet-container .leaflet-control-attribution {
        font-size: 11px;
    }

    .leaflet-touch .leaflet-control-attribution,
    .leaflet-touch .leaflet-bar {
        box-shadow: none;
    }

    .leaflet-touch .leaflet-bar {
        border: 2px solid rgba(0, 0, 0, .2);
        background-clip: padding-box;
    }
}

/*! CSS Used from: Embedded */
.sidebar .widget-box .user-make-offer-message .nav>li>a:hover,
.sidebar .widget-box .user-make-offer-message .nav>li.btnWatch button:hover,
.sidebar .widget-box .user-make-offer-message .nav>li.active>a,
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:active:hover,
#ad-address span:hover i,
span.ad_type_display {
    background: #00BBE4 !important;
}

.sidebar .widget-box .widget-content ul li>a:hover,
.sidebar .widget-box .user-make-offer-message .nav>li>a,
.sidebar .widget-box .user-make-offer-message .nav>li.btnWatch button,
.sidebar .widget-box .user-make-offer-message .nav>li>a i,
.sidebar .widget-box .user-make-offer-message .nav>li.btnWatch button i,
.inner-page-content .breadcrumb>li a:hover,
.inner-page-content .breadcrumb>li a:hover i,
.inner-page-content .breadcrumb>li.active,
.author-info .media-heading a:hover,
.single-post-page .single-post .single-post-title .post-category span a:hover,
input[type=radio]:checked+label:before,
input[type='checkbox']:checked+label:before,
#ad-address span a:hover,
#ad-address span a:focus,
.sidebar .widget-box .author-info a:hover,
.author-info .media-heading i {
    color: #00BBE4 !important;
}

.sidebar .widget-box .user-make-offer-message .nav>li>a,
.sidebar .widget-box .user-make-offer-message .nav>li.btnWatch button,
.nav-tabs>li.active>a:hover,
.nav-tabs>li.active>a:focus {
    border-color: #00BBE4 !important;
}

.btn-primary,
#ad-address span i,
.single-post-page .single-post #single-post-carousel .single-post-carousel-controls .carousel-control span {
    background: #39444C;
}

h4>a,
h5>a,
.checkbox label a,
.sidebar .widget-box .author-info a,
.sidebar .widget-box .user-make-offer-message .tab-content form label,
.single-post-page .single-post .single-post-title>.post-price>h4,
.single-post-page .single-post .single-post-title h4 a,
.single-post-page .single-post .details .post-details ul li p,
.single-post-page .single-post .description .tags span,
.single-post-page .single-post .description .tags a:hover {
    color: #39444C;
}

/*! CSS Used from: Embedded */
h3,
h3 span {
    font-family: ubuntu;
    line-height: 24px;
    font-weight: 700;
    font-style: normal;
    color: #232323;
    font-size: 24px;
    font-display: swap;
}

h4,
h4 a {
    font-family: ubuntu;
    line-height: 18px;
    font-weight: 700;
    font-style: normal;
    color: #232323;
    font-size: 18px;
    font-display: swap;
}

h5,
h5 a,
h5 span {
    font-family: ubuntu;
    line-height: 24px;
    font-weight: normal;
    font-style: normal;
    color: #232323;
    font-size: 14px;
    font-display: swap;
}

div,
a,
img,
i,
ul,
li,
form,
label,
aside,
section,
.help-block {
    font-family: "Open Sans";
    line-height: 24px;
    font-weight: normal;
    font-style: normal;
    color: #6c6c6c;
    font-size: 14px;
    font-display: swap;
}

/*! CSS Used fontfaces */
@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCs6KVjbNBYlgoKcg72j00.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCs6KVjbNBYlgoKew72j00.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCs6KVjbNBYlgoKcw72j00.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
}

@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCs6KVjbNBYlgoKfA72j00.woff2) format('woff2');
    unicode-range: U+0370-03FF;
}

@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCs6KVjbNBYlgoKcQ72j00.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCs6KVjbNBYlgoKfw72.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCv6KVjbNBYlgoCxCvjvWyNL4U.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCv6KVjbNBYlgoCxCvjtGyNL4U.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCv6KVjbNBYlgoCxCvjvGyNL4U.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
}

@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCv6KVjbNBYlgoCxCvjs2yNL4U.woff2) format('woff2');
    unicode-range: U+0370-03FF;
}

@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCv6KVjbNBYlgoCxCvjvmyNL4U.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCv6KVjbNBYlgoCxCvjsGyN.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Font Awesome 5 Free';
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: url(//demo.joinwebs.com/classiera/pearl/wp-content/themes/classiera/css/../webfonts/fa-regular-400.eot);
    src: url(//demo.joinwebs.com/classiera/pearl/wp-content/themes/classiera/css/../webfonts/fa-regular-400.eot?#iefix) format("embedded-opentype"), url(//demo.joinwebs.com/classiera/pearl/wp-content/themes/classiera/css/../webfonts/fa-regular-400.woff2) format("woff2"), url(//demo.joinwebs.com/classiera/pearl/wp-content/themes/classiera/css/../webfonts/fa-regular-400.woff) format("woff"), url(//demo.joinwebs.com/classiera/pearl/wp-content/themes/classiera/css/../webfonts/fa-regular-400.ttf) format("truetype"), url(//demo.joinwebs.com/classiera/pearl/wp-content/themes/classiera/css/../webfonts/fa-regular-400.svg#fontawesome) format("svg");
}

@font-face {
    font-family: 'Font Awesome 5 Free';
    font-style: normal;
    font-weight: 900;
    font-display: block;
    src: url(//demo.joinwebs.com/classiera/pearl/wp-content/themes/classiera/css/../webfonts/fa-solid-900.eot);
    src: url(//demo.joinwebs.com/classiera/pearl/wp-content/themes/classiera/css/../webfonts/fa-solid-900.eot?#iefix) format("embedded-opentype"), url(//demo.joinwebs.com/classiera/pearl/wp-content/themes/classiera/css/../webfonts/fa-solid-900.woff2) format("woff2"), url(//demo.joinwebs.com/classiera/pearl/wp-content/themes/classiera/css/../webfonts/fa-solid-900.woff) format("woff"), url(//demo.joinwebs.com/classiera/pearl/wp-content/themes/classiera/css/../webfonts/fa-solid-900.ttf) format("truetype"), url(//demo.joinwebs.com/classiera/pearl/wp-content/themes/classiera/css/../webfonts/fa-solid-900.svg#fontawesome) format("svg");
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4taVIGxA.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4kaVIGxA.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4saVIGxA.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4jaVIGxA.woff2) format('woff2');
    unicode-range: U+0370-03FF;
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4iaVIGxA.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4vaVIGxA.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4uaVIGxA.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4gaVI.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


.wishlist-button1 {
    background: linear-gradient(270deg,#0f1c54,#2b224c);
    color: white;
    border: none;
    padding: 3px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 15px;
    font-size: 14px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif, Courier, monospace;
  }
 
  .remove-button1 {
    background-color: rgb(2, 105, 207);
    color: white;
    border: none;
    padding: 3px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 15px;
    font-size: 14px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif, Courier, monospace;
  }

/* Container for the entire form */
.form-container-job {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 500px; 
    overflow-y: auto;
   
  }
  
  /* Style for the form fields */
  .jobdd {
    display: flex;
    flex-direction: column;

   
  }
  
  /* Label style */
  .jobdd label {
    text-align: left;
    margin-bottom: 1px;
    margin-top: 5px;
  }
  
  /* Text input style */
  .form-input {
    width: 100%;
  }
  
  /* File input style */
  .form-file-input {
    display: flex;
    align-items: center;

  }
  
  /* Custom button style */
  .custom-btns {
    background-color: #0077b6;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  /* Error message style */
  .error-message {
    color: red;
  }
  