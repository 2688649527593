.block-icon {
    position: relative;
    display: inline-flex;
  }
  
  .icon-tag {
    position: absolute;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    width: 12px !important;
    height: 12px !important;
  }
  .padingmanage{
    padding-left: 40px;
  }