/*! CSS Used from: https://templates.scriptsbundle.com/carspot/demos/css/bootstrap.css */
.categoriesLink{
    font-family: "Poppins", sans-serif;
}
.catList{
    font-family: "Poppins", sans-serif !important;
    color:white !important;

}
.bgcolor{
    background: linear-gradient(270deg,#0f1c54,#2b224c);
}

section{display:block;}
a{background-color:transparent;}
a:active,a:hover{outline:0;}
h1{margin:.67em 0;font-size:2em;}
@media print{
*,*:before,*:after{color:#000!important;text-shadow:none!important;background:transparent!important;-webkit-box-shadow:none!important;box-shadow:none!important;}
a,a:visited{text-decoration:underline;}
a[href]:after{content:" (" attr(href) ")";}
p{orphans:3;widows:3;}
}
*{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;}
*:before,*:after{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;}
a{color:#337ab7;text-decoration:none;}
a:hover,a:focus{color:#23527c;text-decoration:underline;}
a:focus{outline:5px auto -webkit-focus-ring-color;outline-offset:-2px;}
h1{font-family:inherit;font-weight:500;line-height:1.1;color:inherit;}
h1{margin-top:20px;margin-bottom:10px;}
h1{font-size:36px;}
p{margin:0 0 10px;}
.container{padding-right:15px;padding-left:15px;margin-right:auto;margin-left:auto;}
@media (min-width: 768px){
.container{width:750px;}
}
@media (min-width: 992px){
.container{width:970px;}
}
@media (min-width: 1200px){
.container{width:1170px;}
}
.row{margin-right:-15px;margin-left:-15px;}
.col-sm-6,.col-md-6,.col-xs-12{position:relative;min-height:1px;padding-right:15px;padding-left:15px;}
.col-xs-12{float:left;}
.col-xs-12{width:100%;}
@media (min-width: 768px){
.col-sm-6{float:left;}
.col-sm-6{width:50%;}
}
@media (min-width: 992px){
.col-md-6{float:left;}
.col-md-6{width:50%;}
}
.btn{display:inline-block;padding:6px 12px;margin-bottom:0;font-size:14px;font-weight:normal;line-height:1.42857143;text-align:center;white-space:nowrap;vertical-align:middle;-ms-touch-action:manipulation;touch-action:manipulation;cursor:pointer;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;background-image:none;border:1px solid transparent;border-radius:4px;}
.btn:focus,.btn:active:focus{outline:5px auto -webkit-focus-ring-color;outline-offset:-2px;}
.btn:hover,.btn:focus{color:#333;text-decoration:none;}
.btn:active{background-image:none;outline:0;-webkit-box-shadow:inset 0 3px 5px rgba(0, 0, 0, .125);box-shadow:inset 0 3px 5px rgba(0, 0, 0, .125);}
.btn-primary{color:#fff;background-color:#337ab7;border-color:#2e6da4;}
.btn-primary:focus{color:#fff;background-color:#286090;border-color:#122b40;}
.btn-primary:hover{color:#fff;background-color:#286090;border-color:#204d74;}
.btn-primary:active{color:#fff;background-color:#286090;border-color:#204d74;}
.btn-primary:active:hover,.btn-primary:active:focus{color:#fff;background-color:#204d74;border-color:#122b40;}
.btn-primary:active{background-image:none;}
.btn-lg{padding:10px 16px;font-size:18px;line-height:1.3333333;border-radius:6px;}
.container:before,.container:after,.row:before,.row:after{display:table;content:" ";}
.container:after,.row:after{clear:both;}
/*! CSS Used from: https://templates.scriptsbundle.com/carspot/demos/css/style.css */
a{-webkit-transition:all .25s ease-in-out;-moz-transition:all .25s ease-in-out;-ms-transition:all .25s ease-in-out;-o-transition:all .25s ease-in-out;transition:all .25s ease-in-out;cursor:pointer;}
a:hover,a:focus{text-decoration:none;o-transition:all 0.3s ease-out;-ms-transition:all 0.3s ease-out;-moz-transition:all 0.3s ease-out;-webkit-transition:all 0.3s ease-out;transition:all 0.3s ease-out;}
a,div{-webkit-tap-highlight-color:rgba(0, 0, 0, 0);-webkit-tap-highlight-color:transparent;-moz-outline-:none;}
.no-padding{padding:0;}
h1{position:relative;font-family:'Poppins', sans-serif;font-weight:normal;margin:0px;background:none;line-height:1.6em;}
h1{font-size:36px;}
p{line-height:1.6em;font-size:16px;}
.btn{font-size:14px;letter-spacing:1px;padding:10px 15px;border-radius:1px;-webkit-transition:all .25s ease-in-out;-moz-transition:all .25s ease-in-out;-ms-transition:all .25s ease-in-out;-o-transition:all .25s ease-in-out;transition:all .25s ease-in-out;font-weight:600;font-family:'Source Sans Pro', sans-serif;text-transform:uppercase;}
.btn-lg{padding:12px 52px;letter-spacing:1.3px;}
@media (min-width:767px){
.col-md-6:nth-child(2n+1){clear:none;}
.col-sm-6:nth-child(2n+1){clear:left;}
}
@media (min-width:992px){
.col-sm-6:nth-child(2n+1){clear:none;}
.col-md-6:nth-child(2n+1){clear:left;}
}
@media (min-width:1200px){
.col-md-6:nth-child(2n+1),.col-sm-6:nth-child(2n+1){clear:none;}
}
.background-3{background-image:url(https://templates.scriptsbundle.com/carspot/demos/images/left-img.jpg);background-position:center center;background-repeat:no-repeat;background-size:cover;height:100%;left:0;top:0;position:absolute;width:50%;}
.background-3::before{content:"";height:100%;left:0;position:absolute;top:0;width:100%;}
.background-4{background-image:url(https://templates.scriptsbundle.com/carspot/demos/images/right-img.jpg);background-position:center center;background-repeat:no-repeat;background-size:cover;height:100%;right:0;top:0;position:absolute;width:50%;}
.background-4::before{background:rgba(0, 0, 0, 0.7) none repeat scroll 0 0;content:"";height:100%;left:0;position:absolute;top:0;width:100%;}
.section-container-left{position:relative;padding:140px 0;}
.section-container-right{position:relative;padding:140px 0 0 70px;}
.section-container-left h1,.section-container-right h1{color:#fff;font-size:36px;position:relative;}
.section-container-left p,.section-container-right p{margin-top:20px;margin-bottom:35px;padding:0 70px 0 0;color:#fff;}
.buysell-section{position:relative;clear:both;overflow:hidden;}
/*! CSS Used from: https://templates.scriptsbundle.com/carspot/demos/css/responsive-media.css */
@media (min-width: 768px) and (max-width: 1279px){
.section-container-right{padding:140px 0 140px 24px;}
.section-container-left{padding:140px 0 140px 24px;}
.section-container-left h1,.section-container-right h1{font-size:26px;}
}
@media (min-width: 320px) and (max-width: 767px){
.section-container-left h1,.section-container-right h1{font-size:22px;}
.background-3{width:100%;}
.background-4{width:0;}
.section-container-left,.section-container-right{padding:70px 20px;}
.section-container-right{display:none;}
.section-container-left p,.section-container-right p{padding:0;}
}
/*! CSS Used from: https://templates.scriptsbundle.com/carspot/demos/css/colors/defualt.css */
a:hover,a:focus{color:#E52D27;}
a{color:#E52D27;}
.btn-theme{color:#ffffff;background-color:#E52D27;border-color:#E52D27;}
.btn-theme:hover,.btn-theme:focus,.btn-theme:active{color:#ffffff;background-color:#B31217;border-color:#B31217;}
.btn-theme:active{background-image:none;}
.background-3::before{background:rgba(120, 2, 6, 0.8);background:-moz-linear-gradient(to left, rgba(120, 2, 6, 0.8), rgba(6, 17, 97, 0.8));background:-webkit-linear-gradient(to left, rgba(120, 2, 6, 0.8), rgba(6, 17, 97, 0.8));background:-webkit-gradient(linear, 0 0, 100% 0, from(rgba(120, 2, 6, 0.8)), to(rgba(6, 17, 97, 0.8)));background:-o-linear-gradient(to left, rgba(120, 2, 6, 0.8), rgba(6, 17, 97, 0.8));background:linear-gradient(to left, rgba(120, 2, 6, 0.8), rgba(6, 17, 97, 0.8));}
/*! CSS Used fontfaces */
@font-face{font-family:'Poppins';font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJbecmNE.woff2) format('woff2');unicode-range:U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;}
@font-face{font-family:'Poppins';font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJnecmNE.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Poppins';font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJfecg.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:'Poppins';font-style:normal;font-weight:500;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z11lFc-K.woff2) format('woff2');unicode-range:U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;}
@font-face{font-family:'Poppins';font-style:normal;font-weight:500;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1JlFc-K.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Poppins';font-style:normal;font-weight:500;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1xlFQ.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:'Poppins';font-style:normal;font-weight:600;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z11lFc-K.woff2) format('woff2');unicode-range:U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;}
@font-face{font-family:'Poppins';font-style:normal;font-weight:600;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1JlFc-K.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Poppins';font-style:normal;font-weight:600;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1xlFQ.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:'Source Sans Pro';font-style:italic;font-weight:400;src:url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPZ7qsDJT9g.woff2) format('woff2');unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:'Source Sans Pro';font-style:italic;font-weight:400;src:url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPZ7jsDJT9g.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:'Source Sans Pro';font-style:italic;font-weight:400;src:url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPZ7rsDJT9g.woff2) format('woff2');unicode-range:U+1F00-1FFF;}
@font-face{font-family:'Source Sans Pro';font-style:italic;font-weight:400;src:url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPZ7ksDJT9g.woff2) format('woff2');unicode-range:U+0370-03FF;}
@font-face{font-family:'Source Sans Pro';font-style:italic;font-weight:400;src:url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPZ7osDJT9g.woff2) format('woff2');unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:'Source Sans Pro';font-style:italic;font-weight:400;src:url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPZ7psDJT9g.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Source Sans Pro';font-style:italic;font-weight:400;src:url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPZ7nsDI.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:'Source Sans Pro';font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK3dSBYKcSV-LCoeQqfX1RYOo3qNa7lqDY.woff2) format('woff2');unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:'Source Sans Pro';font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK3dSBYKcSV-LCoeQqfX1RYOo3qPK7lqDY.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:'Source Sans Pro';font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK3dSBYKcSV-LCoeQqfX1RYOo3qNK7lqDY.woff2) format('woff2');unicode-range:U+1F00-1FFF;}
@font-face{font-family:'Source Sans Pro';font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK3dSBYKcSV-LCoeQqfX1RYOo3qO67lqDY.woff2) format('woff2');unicode-range:U+0370-03FF;}
@font-face{font-family:'Source Sans Pro';font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK3dSBYKcSV-LCoeQqfX1RYOo3qN67lqDY.woff2) format('woff2');unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:'Source Sans Pro';font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK3dSBYKcSV-LCoeQqfX1RYOo3qNq7lqDY.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Source Sans Pro';font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK3dSBYKcSV-LCoeQqfX1RYOo3qOK7l.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:'Source Sans Pro';font-style:normal;font-weight:600;src:url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwmhduz8A.woff2) format('woff2');unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:'Source Sans Pro';font-style:normal;font-weight:600;src:url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwkxduz8A.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:'Source Sans Pro';font-style:normal;font-weight:600;src:url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwmxduz8A.woff2) format('woff2');unicode-range:U+1F00-1FFF;}
@font-face{font-family:'Source Sans Pro';font-style:normal;font-weight:600;src:url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwlBduz8A.woff2) format('woff2');unicode-range:U+0370-03FF;}
@font-face{font-family:'Source Sans Pro';font-style:normal;font-weight:600;src:url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwmBduz8A.woff2) format('woff2');unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:'Source Sans Pro';font-style:normal;font-weight:600;src:url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwmRduz8A.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Source Sans Pro';font-style:normal;font-weight:600;src:url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwlxdu.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}


@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Montserrat:wght@700&display=swap");

:root {
  /* Colors */
  --brand-color: hsl(46, 100%, 50%);
  --black: hsl(0, 0%, 0%);
  --white: hsl(0, 0%, 100%);
  /* Fonts */
  --font-title: "Montserrat", sans-serif;
  --font-text: "Lato", sans-serif;
}

/* RESET */

/* Box sizing rules */
.gridcard,
.gridcard::before,
.gridcard::after {
  box-sizing: border-box;
}

/* Remove default margin */


/* GLOBAL STYLES */
.cardcat{
  
  height:240px !important;
}


.flow > * + * {
  margin-top: var(--flow-space, 1em);
}

/* CARD COMPONENT */
.gridcard{
    display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      grid-gap: 20px;
      place-items: center;

}
.cardcat {
  width: 150px;
  max-width: 21.875rem;
  height: 28.125rem;
  overflow: hidden;
  border-radius: 0.625rem;
  box-shadow: 0.25rem 0.25rem 0.5rem rgba(0, 0, 0, 0.25);
}
.card__background:hover{
    opacity: 0.5;
}

.cardcat > * {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.card__background {
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}

.card__content {
  --flow-space: 0.9375rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: flex-end;
  height: 55%;
  padding: 12% 1.25rem 1.875rem;
  background: linear-gradient(
    180deg,
    hsla(0, 0%, 0%, 0) 0%,
    hsla(0, 0%, 0%, 0.3) 10%,
    hsl(0, 0%, 0%) 100%
  );
}

.card__content--container {
  --flow-space: 1.25rem;
}

.card__title{
  transform: translate(-0%, -350%);
  margin-top: -60px;
  z-index: 1;
  position: absolute;
  width: fit-content;
  width: -moz-fit-content;
  color: #000;
  font-weight: 700px;
  transition: opacity 0.3s;
  opacity: 0;
  /* Prefijo necesario para Firefox  */
}
.cardcat:hover .card__title{
    opacity:1;
    z-index: 1;
}

.card__title::after {
  position: absolute;
  height: 3.3125rem;
  width: calc(100% + .25rem);
  bottom: calc((1.25rem - 0.5rem) * -1);
  left: -1.25rem;
  color: #000;
}

.card__button {
  padding: 0.75em 1.6em;
  width: fit-content;
  width: -moz-fit-content; /* Prefijo necesario para Firefox  */
  font-variant: small-caps;
  font-weight: bold;
  border-radius: 0.45em;
  border: none;
  background-color: var(--brand-color);
  font-family: var(--font-title);
  font-size: 1.125rem;
  color: var(--black);
}

.card__button:focus {
  outline: 2px solid black;
  outline-offset: -5px;
}

@media (any-hover: hover) and (any-pointer: fine) {
  .card__content {
    transform: translateY(62%);
    transition: transform 500ms ease-out;
    transition-delay: 500ms;
  }

  .card__title::after {
    opacity: 0;
    transform: scaleX(0);
    transition: opacity 1000ms ease-in, transform 500ms ease-out;
    transition-delay: 500ms;
    transform-origin: right;
  }

  .card__background {
    transition: transform 500ms ease-in;
  }

  .card__content--container > :not(.card__title),
  .card__button {
    opacity: 0;
    transition: transform 500ms ease-out, opacity 500ms ease-out;
  }

  .cardcat:hover,
  .cardcat:focus-within {
    transform: scale(1.05);
    transition: transform 500ms ease-in;
  }

  .cardcat:hover .card__content,
  .cardcat:focus-within .card__content {
    transform: translateY(0);
    transition: transform 500ms ease-in;
  }

  .cardcat:focus-within .card__content {
    transition-duration: 0ms;
  }

  .cardcat:hover .card__background,
  .cardcat:focus-within .card__background {
    transform: scale(1.3);
  }

  .cardcat:hover .card__content--container > :not(.card__title),
  .cardcat:hover .card__button,
  .cardcat:focus-within .card__content--container > :not(.card__title),
  .cardcat:focus-within .card__button {
    opacity: 1;
    transition: opacity 500ms ease-in;
    transition-delay: 1000ms;
  }

  .cardcat:hover .card__title::after,
  .cardcat:focus-within .card__title::after {
    opacity: 1;
    transform: scaleX(1);
    transform-origin: left;
    transition: opacity 500ms ease-in, transform 500ms ease-in;
    transition-delay: 500ms;
  }
}

.carousel{
    display: flex;
    flex-direction: row;
}

/* RealEstateDropdown.css */
.dropdown {
  position: relative;
  display: inline-block;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif, Helvetica, sans-serif;
  font-size: 14px;
 
}

.link {
  text-decoration: none;
  color: #333;
  padding: 10px 20px;
}
.dropdown-content a {
  display: block;
  border-top: 1px solid rgb(196, 196, 196); 
 
}
.dropdown-content {
  width: 16rem;
  display: none;
  position: absolute;
  line-height: 8px;
  background-color: #ffffff;
  border: 1px solid rgb(196, 196, 196);
  border-top: 2px solid gray;
  font-size: 14px;
}

.dropdown-content a:hover {
  background-color: #ececec;
}

.dropdown:hover .dropdown-content {
  display: block;
  font-size: 14px;
  font-weight: 500;

}
.dropdown-indicator {
  position: absolute;
  top: -20px; /* Adjust the vertical position to display above the dropdown */
  left: 3%;
  transform: translateX(-50%);
  font-size: 14px;
  cursor: pointer;
}
.dropdown-indicator1 {
  position: absolute;
  top: -20px; /* Adjust the vertical position to display above the dropdown */
  left: 3%;
  transform: translateX(-50%);
  font-size: 18.5px;
  cursor: pointer;
}
/* Basic styles for the dropdown container */
.custom-dropdown-container {
  position: relative;
}

/* Styles for the link */
.custom-dropdown-link {
  color: #333;
  text-decoration: none;
}

/* Styles for the dropdown content */
.custom-dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 5px;
  min-width: 150px; /* Adjust the width as needed */
}

/* Styles for the dropdown options */
.custom-dropdown-option {
  cursor: pointer;
  padding: 5px;
}

/* Styles for the dropdown options when hovered */
.custom-dropdown-option:hover {
  background-color: #ddd;
}
.subdiv{
  font-size: 12px;
}
.maindiv{
  font-weight: bold;
  border-bottom: 1px solid black;
 
}

.wholeone123 {

  margin-top: -10px;
  padding: 0;
  box-sizing: border-box;
  border: none;
  outline: none;
  text-decoration: none;
  list-style: none;
  font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
  cursor: pointer;
}

#slide23 {
  /* width: max-content; */
  margin-top: 20px;
  display: flex;

}
.item23 {
  width: 180px;
  height: 250px;
  background-position: 50% 50%;
  
  /* transition: 0.8s; */
  background-size: cover;
  /* position: absolute; */
  /* z-index: 1; */
  /* top: 0; */
  transform: translate(0, 5%);
  border-radius: 5px;
  /* box-shadow: 0 30px 50px #505050; */
}
.item23:nth-child(1){
  display: none;
}
.item23:nth-child(2) {
  left: 0;
  /* top: 0; */
  transform: translate(0, 5%);
  border-radius: 20px;
  width: 800px;
  height: 250px;
  box-shadow: none;
  margin-right: 20px;

}
.item23:nth-child(3) {
  left: 60%;
  margin-right: 20px;
  filter: brightness(50%);
  border-radius: 20px;
}
.item23:nth-child(4) {
  left: calc(60% + 200px);
  margin-right: 20px;
  filter: brightness(50%);
  border-radius: 20px;

}
.item23:nth-child(5) {
  left: calc(60% + 450px);
  margin-right: 20px;
  filter: brightness(50%);
  border-radius: 20px;

}
.item23:nth-child(6) {
  left: calc(80% + 650px);
  margin-right: 20px;
  display: none;
  filter: brightness(50%);

  opacity: 0;
}
.item23:nth-child(7) {
  left: calc(80% + 850px);
  margin-right: 20px;
  display: none;
  filter: brightness(50%);

  opacity: 0;
}
.item23:nth-child(8) {
  left: calc(80% + 1050px);
  margin-right: 20px;
  display: none;
  filter: brightness(50%);

  opacity: 0;
}
/* ----------------------------------------------------------------------- */
.item23:nth-child(9) {
  left: calc(80% + 1150px);
  margin-right: 20px;
  display: none;
  filter: brightness(50%);

  opacity: 0;
}
.item23:nth-child(10) {
  left: calc(80% + 1250px);
  margin-right: 20px;
  display: none;
  filter: brightness(50%);

  opacity: 0;
}
.item23:nth-child(11) {
  left: calc(80% + 1350px);
  margin-right: 20px;
  display: none;
  filter: brightness(50%);

  opacity: 0;
}
/* ----------------------------------------------------------------------- */


.item23 .content234 {
  position: absolute;
  top: 50%;
  left: 100px;
  width: 300px;
  text-align: left;
  padding: 0;
  color: #eee;
  transform: translate(0, -50%);
  display: none;
}
.item23:nth-child(2) .content234 {
  display: block;
  z-index: 11111;
}
.item23 .name2344 {
  font-size: 40px;
  font-weight: bold;
  opacity: 0;
  color: rgb(255, 123, 0);
  animation: showcontent 1s ease-in-out 1 forwards;
}
.item23 .des122 {
  font-size: 14px;
  margin: 20px 0;
  opacity: 0;
  color: yellow;
  animation: showcontent 1s ease-in-out 0.3s 1 forwards;
}
.item23 button {
  padding: 10px 20px;
  border: none;
  opacity: 0;
  animation: showcontent 1s ease-in-out 0.6s 1 forwards;
}
.item23 button:hover {
color: burlywood;
}
.divlef{
  background-color: #505050;
}
.divright{
  background-color: #505050;
}
@keyframes showcontent {
  from {
    opacity: 0;
    transform: translate(0, 100px);
    filter: blur(33px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
    filter: blur(0);
  }
}
#prev{
  float: left;
  margin-left: 10px;
}
#next{
  float: right;

}
.buttons123 {
  position: relative;
  bottom: 30px;
  z-index: 2;
  text-align: center;
  width: 100%;
  transform: translate(0, -380%);
  
}
.buttons123 button {
  width: 40px;
  height: 40px;
  color: white;
  border-radius: 50%;
  border: 2px solid #ffffff;
  transition: 0.5s;
  background:linear-gradient(270deg,#0f1c54,#2b224c);
}
.buttons123 button:hover {
  background-color: #bac383;
}

