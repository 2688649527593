.row {
  margin: 0;
  padding: 0;
}

.text-center {
  margin-bottom: 1rem;
}

.col-lg-1,
.col-lg-10 {
  padding: 0;
}

.gallery-container {
  display: flex;
  flex-wrap: wrap;
  /* Ensures images wrap to the next line */
  justify-content: center;
  /* Adds space between tiles */
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
}

.gallery-container h5 {
  margin: 0;
  padding: 1rem 0;
  font-size: 18px;
  text-transform: uppercase;
}

.gallery-grid {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px;
}

.ReactGridGallery {
  display: grid;
  grid-template-columns: auto auto auto auto;
  /* Space between grid items */
  margin: 0 auto;
  /* Center the grid container */
  padding: 10px;
  /* Add padding around the gallery */
  background-color: #f5f5f5;
  /* Optional: Gallery background */
}

.ReactGridGallery_tile {
  position: relative;
  /* Ensures the tile can position child elements properly */
  overflow: hidden;
  /* Prevents image overflow */
  background-color: #ffffff;
  /* Background color for the tile */
  display: flex;
  width: auto;
  justify-content: center;
  /* Centers the image horizontally */
  align-items: center;
  /* Centers the image vertically */
  max-width: 500px;
}

.ReactGridGallery_tile img {
  object-fit: cover;
  /* Make the image responsive to the tile */
  height: 100%;
  width: 100vw;
  object-position: center;
  /* Make the image responsive to the tile */
  transition: transform 0.3s ease;
  /* Adds smooth scaling effect */
}


/* Responsive adjustments */
@media (max-width: 1024px) {
  .ReactGridGallery {
    grid-template-columns: fit-content fit-content;
    /* Two columns for medium screens */
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .ReactGridGallery {
    grid-template-columns: auto auto;
    /* Two columns for medium screens */
  }
}