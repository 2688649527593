
.pro-button1{
  display: flex;
}
.FilterBy button{
  vertical-align: middle;
}
.pro-button1 button{
  padding: 11px 11px;
  vertical-align: middle;
  border-radius: 4px;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.catedivfil{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media only screen and (max-width: 600px) {
  .button-containerhoms1{
    margin-top: 20px;
    margin-left: 60px;
    width: 230px;
    align-items: center;
    background-color: aliceblue;
    justify-items: center;
    display: grid;
    grid-template-columns: repeat(1,220px);
    vertical-align: center;
  }
}

@media only screen and (max-width: 600px) {
   
  .pro-Search1{
    width: 150px;
    font-size: 12px;
    margin-left: 10px
  };
  .catedivfil{
    display: flex;
  };
  .pro-button1 button{
    padding: 8px 9px;
    border-radius: 4px;
    align-items: center;
    text-align: center;
   
  }
}

.white-space-bytn1 {
  & .bytn1 {
    white-space: nowrap;
  }
}
.input-search-allcat {
  width: 70% !important;
  margin: 10px auto;
}
/* @media (max-width : 768px) {
  .input-search-allcat {
    width: auto;
  }
} */