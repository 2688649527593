/* Add these CSS styles for the card container */
.card3 {
  background: whitesmoke;
  border: 2px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 280px !important; /* Set the card width to 250px */
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 0 150px 20px 0 !important; /* Add spacing between cards */
  transition: transform 0.3s;
  height: 350px;
}

/* Card content */
.feedback-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.emoji-container {
  margin-top: 10px;
  font-size: 20px;
}
.person-icon {
  font-size: 60px;
  margin: 0;
}


.role {
  font-weight: bold;
  margin-top: 10px;
}

.rating {
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-top: 5px;
}

.star-icon {
  color: gold;
  font-size: 20px;
  margin-right: 5px;
}

profile-title {
  font-size: 1.2em;
  margin: 10px 0;
}

.description {
  font-size: 0.9em;
  margin: 5px 0;
}

details {
  margin-top: 10px;
}

created-at {
  font-size: 0.8em;
  color: #777;
  margin: 10px 0;
}
.feedslider{
  margin: auto 6rem;
}
/* Responsive design */
@media (max-width: 768px) {
  .card3 {
    width: auto;
    margin: 1rem !important;
  }
  .feedslider{
    margin: auto 2rem;
  }
}
@media (max-width: 480px) {
  .main-capation h2{
    font-size: 20px;
  }
 
  .feedslider{
    overflow-x: hidden;
  }
  .card2011{
    margin-left: 15px;
  }
}

/* Star rating styles */
.star-rating {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

/* Add the following CSS for star ratings based on the 'item.rating' value */
.rating .star-icon:nth-child(n + 6) {
  color: transparent; /* Hide empty stars */
}


.rating .star-icon:nth-child(-n + 5) {
  color: gold; /* Fill stars based on the rating */
}