
.ad-tabs-container {
    margin: 50px auto;
    max-width: 800px;
    }
    
    .ad-tabs-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    }
    
    .download-button {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    }
    
    .ad-tab {
    margin-bottom: 20px;
    }
    
    .ad-tab h2 {
    margin-bottom: 10px;
    }
    
    .ad-tab li {
    margin-bottom: 10px;
    list-style: none;
    }
    
    .view-details-button {
    background-color: #008CBA;
    border: none;
    color: white;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
    border-radius: 5px;
    margin-right: 10px;
    }
    
    .ad-details-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    }
    
    .modal-content {
    background-color: #fefefe;
    border: 1px solid #888;
    border-radius: 5px;
    padding: 20px;
    max-width: 500px;
    }
    
    .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    font-weight: bold;
    color: #888;
    cursor: pointer;
    }
    
    .modal-content h2 {
    margin-bottom: 10px;
    }
    
    .modal-content table {
    margin-bottom: 10px;
    border-collapse: collapse;
    width: 100%;
    }
    
    .modal-content table td {
    padding: 5px;
    border: 1px solid #ddd;
    }
    
    .modal-content table td:first-child {
    font-weight: bold;
    width: 100px;
    }
    
    .modal-content button {
    background-color: #008CBA;
    border: none;
    color: white;
    padding: 5px 10px;
    text-align: center;
    text-decoration:none;
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
    border-radius: 5px;
    }
    
    
    
    
    

/* {for order page component} */
.orders-page {
    background-color: #f8f8f8;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .orders-page h2 {
    margin: 0;
    font-size: 24px;
  }
  
  .listing-enquiries {
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 20px;
  }
  
  .listing-enquiries h3 {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
    color: red;
  }
  
  .listing-enquiries ul {
    list-style: none;
    padding: 0;
    margin: 0;
    
  }
  
  .listing-enquiries li {
    font-size: 16px;
    margin-bottom: 5px;
  }
  
  .listing-enquiries li:first-child {
    margin-top: 5px;
  }
  
  /* premiumcard */
  .premium{
    background-color: #4CAF50;
  }