/* JobsMainfilter.css */

/* .main-filter {
  position: absolute;
  left: 0;
  top: 0;
  height: 40%;
  width: 250px; 
  background-color: #fff;
  padding: 20px;
  border-right: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  margin-top: 50px;
  z-index: 1000;
  border-radius: 15px;
} */
.main-filter {
  /* position: absolute; */
  left: 0;
  top: 0;
  height: 50%;
  min-width: 200px; /* Set your desired minimum width */
  max-width: 250px; /* Set your desired maximum width */
  background-color: #fff;
  padding: 20px;
  border-right: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  margin-top: 50px;
  z-index: 1000;
  border-radius: 15px;
}



h1 {
  font-size: 18px;
  margin-bottom: 20px;
}

.dropdown11,
.sub-filter,
.checkbox-filter,
.find-button {
  margin-bottom: 20px;
  width: 100%;
}

label {
  font-weight: bold;
  display: block;
}

select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.checkbox-options label {
  display: block;
  margin-bottom: 8px;
}

.checkbox-options input[type="checkbox"] {
  margin-right: 8px;
}

.find-button button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 20px;
  width: 200px;
}

.find-button button:hover {
  background-color: #0056b3;
}

.job-filter {
  width: 75%;
  margin: auto;
}
.job-filter-head {
  visibility: hidden;
}

@media (max-width : 480px) {
  .job-filter {
    width: 95%;
  }
  .job-filter-head {
    visibility: visible;
  }
}