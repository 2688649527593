@media (max-width: 480px) {
  .sliderow2 {
    padding: 20px;
    margin-bottom: -40px;
    /* margin-left: -2.5%;
    margin-right: -4.2%; */
  }
  .trending2001{
    margin-left: 10px;
  }
  .trending2002{
    margin-left: 5px;
  }
}

@media (min-width: 450px) and (max-width: 1991px) {
  .sliderow2 {
    padding: 20px;
    margin-bottom: -40px;
    margin-left: -2.5%;
    margin-right: -4.2%;
  }
  
}

/* .product-content{
    /* background-color: #e6f8f8; */
/* padding: 0%; */
/* } */
.product-type {
  top: 0;
  left: 0;
  margin-left: -8px;
  margin-top: -14px;
}
#tredpost{
  background-image: linear-gradient( rgb(16, 25, 51) 70%, rgb(255, 255, 255) 20%);
}

/* AdvertisementBanner.css */
.advertisement-banner {
  width: 10%;
  min-width: 130px; /* Set a minimum width to ensure content is visible */
  background-color: #ffffff;
  border: 1px solid #000000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 370px;
}

.logo-container {
  margin-bottom: 10px;
}

.logo1 {
  width: 150px;
  height: 115px;
  border-bottom: 1px solid #000000;
}

.buttonbuy {
  background-color: #001a36;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 1rem;
  animation: blink 1.5s infinite;
}
.banheading {
  font-size: 28px;
  line-height: 40px;
  color: #001a36;
  font-family: Georgia, 'Times New Roman', Times, serif;
}
.bancontent {
  color: red;

  font-weight: 500;
  line-height: 25px;
}
@media screen and (max-width: 768px) {
  .advertisement-banner {
    display: none;
  }
  .product-card1{
    display: flex;
    flex-direction: column;
  }
}
.product-card1{
  display: flex;
  flex-direction: column;
}
.onimage{
  transform: translate(0,-130%);
}

.product-img1:hover{
/* background-color: #fff; */
box-shadow: 0 30px 50px #505050;
}
.imgtred{
  opacity: 1;
  /* transform: scale(1.1); */
  transition: 0.6ms;
}
.imgtred:hover{
  transform: scale(1.1);
}
.tredview{
  visibility: hidden;
}
.imgtred:hover{
  transform:scale(1.2);
}

