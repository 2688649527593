.carousel-inner1{
    width: 70%;
    height: 350px;
  
}
.servicesslide{
    width: 100%;
    display: "flex";
    flex-direction: "row";
    margin: "20px auto";
}

.search-form {
    width: 23%;
    height: max-content;
    border-radius: 0px 0px 30px 30px;
    padding: 20px 30px;
    border: 2px solid #838383;
    background-color: rgb(238, 238, 238);
  }
  
  
  @media (max-width: 768px) {
    .search-form {
      width: 80%;
      margin-left: 10%;
      margin-right: 10%;
      margin-top: 10px;
    }
    .carousel-inner1{
        width: 100%;
        height: 200px;
    }
    .servicesslide{
        display: "flex";
        flex-direction: "column";
    }
    .buttonno {
      background-color: #002347;
      margin-top: 5px;
      color: #fff;
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      width: 15rem;
    } 

    
  }
  .form-group {
    margin-bottom: 20px;
  } 
  .buttonno {
    background-color: #002347;
    margin-top: 5px;
    color: #fff;
    padding: 8px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 15rem;
  } 

  .buttonno2 {
      background-color: #970678;
      margin-top: 5px;
      color: #fff;
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      width: 15rem;
    } 

  .buttonno:hover {
    background-color: #415f7e;
    color: #fff;
  }
  .error-message {
    color: #ff0000;
    font-size: 12px;
    margin-top: 10px;
  }
  
.services-search-form {
  & a, button {
    width: 100%;
  }
}