.ads-total-data-container {
    margin: 20px;
  }
  
  .ads-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .ads-table th, .ads-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    min-width: 100px;
  }
  
  .ads-table th {
    background-color: #f2f2f2;
    color:black
  }
  
  .ads-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
    color:black

  }
  
  .product-image {
    max-width: 220px; /* Adjust the maximum width as needed */
    max-height: 220px; /* Adjust the maximum height as needed */
  }
  .rt345 {
    display: block;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    color: navy;
    padding: 20px;
    z-index: 1000;
  }
  
  .rt345-content {
    width: 400px; /* Adjust the width as needed */
    max-height: 400px; /* Adjust the max-height as needed */
    overflow-y: auto;
    color: navy;
  }
  
  .close {
    float: right;
    font-size: 1.5em;
    cursor: pointer;
  }
  .business-profile-container {
    /* display: grid;
    grid-template-columns:repeat(3,1fr);
    justify-content: center; */
    width: 95%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: start;
    gap: 20px;
  }
  
  .business-profile-card {
    border: 1px solid #ccc;
    padding: 20px;
    box-sizing: border-box;
    max-width: 300px;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    transition: box-shadow 0.3s ease-in-out;
  }
  
  .business-profile-card:hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  }
  
  .header {
    text-align: center;
    margin-bottom: 10px;
  }
  
  .details {
    margin-bottom: 15px;
  }
  
  .document-row {
    display: flex;
    flex-direction: column;
  }
  
  .document-item {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 5px;
  }
  
  .verification-status {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  /* Add more styling as needed */
  .business-profile-card.deleted {
    filter: blur(5px);
    background-color: rgba(255, 0, 0, 0.5);  /* Apply blur effect for deleted cards */
    opacity: 0.5;
     /* Adjust opacity for better visual indication */
  }
  .ads-table.deleted {
    filter: blur(5px);
    background-color: rgba(255, 0, 0, 0.5);  /* Apply blur effect for deleted cards */
    opacity: 0.5;
     /* Adjust opacity for better visual indication */
  }

  .Businessadminverify-card {
    width: 340px;
    min-height: 180px;
    padding: 2px;
    box-shadow: 0 1px 4px black;
    border-radius: 10px;
  }

  .Businessadminverify-card:hover {
    transform: translateY(-3px);
    .fa-admin-trash {
      visibility: visible;
    }
  }
  .fa-admin-trash {
    color: red;
    cursor: pointer;
    visibility: hidden;
  }
