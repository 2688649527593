// @mixin mobile {
//   @media screen and (max-width: 480px) {
//     @content;
//   }
// }
// @mixin tablet {
//   @media screen and (max-width: 768px) {
//     @content;
//   }
// }
// @mixin laptop {
//   @media screen and (max-width: 1200px) {
//     @content;
//   }
// }

// .formContainer {
//   background-color: #a7bcff;
//   height: 100vh;
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   .formWrapper {
//     background-color: white;
//     padding: 20px 60px;
//     border-radius: 10px;
//     display: flex;
//     flex-direction: column;
//     gap: 10px;
//     align-items: center;

//     .logo {
//       color: #5d5b8d;
//       font-weight: bold;
//       font-size: 24px;
//     }

//     .title {
//       color: #5d5b8d;
//       font-size: 12px;
//     }

//     form {
//       display: flex;
//       flex-direction: column;
//       gap: 15px;

//       input {
//         padding: 15px;
//         border: none;
//         width: 250px;
//         border-bottom: 1px solid #a7bcff;
//         &::placeholder {
//           color: rgb(175, 175, 175);
//         }
//       }

//       button {
//         background-color: #7b96ec;
//         color: white;
//         padding: 10px;
//         font-weight: bold;
//         border: none;
//         cursor: pointer;
//       }

//       label {
//         display: flex;
//         align-items: center;
//         gap: 10px;
//         color: #8da4f1;
//         font-size: 12px;
//         cursor: pointer;

//         img {
//           width: 32px;
//         }
//       }
//     }
//     p {
//       color: #5d5b8d;
//       font-size: 12px;
//       margin-top: 10px;
//     }
//   }
// }

// .home {
//   background-color: #a7bcff;
//   height: 100vh;
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   .container {
//     background-color: #454a79;
//     border: 1px solid whitesmoke;
//     border-radius: 10px;
//     width: 90%;
//     height: 80%;
//     display: flex;
//     // overflow-x: scroll;
//     @include tablet {
//       width: 90%;
//     }

//     .sidebar {
//       flex: 1;
//       background-color: #3e3c61;
//       position: relative;

//       .navbar {
//         display: flex;
//         align-items: center;
//         background-color: #2f2d52;
//         height: 50px;
//         padding: 10px;
//         justify-content: space-between;
//         color: #ddddf7;

//         .logo {
//           font-weight: bold;
//           @include tablet {
//             display: none;
//           }
//         }

//         .user {
//           display: flex;
//           gap: 10px;

//           img {
//             background-color: #ddddf7;
//             height: 24px;
//             width: 24px;
//             border-radius: 50%;
//             object-fit: cover;
//           }

//           button {
//             background-color: #5d5b8d;
//             color: #ddddf7;
//             font-size: 10px;
//             border: none;
//             cursor: pointer;
//             @include tablet {
//               position: absolute;
//               bottom: 10px;
//             }
//           }
//         }
//       }
//       .search {
//         border-bottom: 1px solid gray;
//         padding-bottom: 12px;

//         .searchForm {
//           padding: 10px;

//           input {
//             background-color: transparent;
//             border: none;
//             color: white;
//             outline: none;

//             &::placeholder {
//               color: lightgray;
//             }
//           }
//         }
//       }

//       .userChat {
//         padding: 10px;
//         display: flex;
//         align-items: center;
//         border-bottom: 1px solid whitesmoke;
//         gap: 10px;
//         color: white;
//         cursor: pointer;

//         &:hover {
//           background-color: #2f2d52;
//         }

//         img {
//           width: 50px;
//           height: 50px;
//           border-radius: 50%;
//           object-fit: cover;
//           margin-right: 10px;
//         }

//         .userChatInfo {
//           span {
//             font-size: 18px;
//             font-weight: 500;
//           }
//           p {
//             font-size: 14px;
//             color: lightgray;
//           }
//         }
//       }
//     }
// .chat {
//   flex: 2;

//   .chatInfo {
//     height: 50px;
//     background-color: #5d5b8d;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     padding: 10px;
//     color: lightgray;
//   }

//   .chatIcons {
//     display: flex;
//     gap: 10px;

//     img {
//       height: 24px;
//       cursor: pointer;
//     }
//   }

//   .messages {
//     background-color: #ddddf7;
//     padding: 10px;
//     height: calc(100% - 160px);
//     overflow-y: scroll;

//     .message {
//       display: flex;
//       gap: 20px;
//       margin-bottom: 20px;

//       .messageInfo {
//         display: flex;
//         flex-direction: column;
//         color: gray;
//         font-weight: 300;

//         img {
//           width: 40px;
//           height: 40px;
//           border-radius: 50%;
//           object-fit: cover;
//         }
//       }
//       .messageContent {
//         max-width: 80%;
//         display: flex;
//         flex-direction: column;
//         gap: 10px;

//         p {
//           background-color: white;
//           padding: 10px 20px;
//           border-radius: 0px 10px 10px 10px;
//           max-width: max-content;
//         }

//         img {
//           width: 50%;
//         }
//       }

//       &.owner {
//         flex-direction: row-reverse;

//         .messageContent {
//           align-items: flex-end;
//           p {
//             background-color: #8da4f1;
//             color: white;
//             border-radius: 10px 0px 10px 10px;
//           }
//         }
//       }
//     }
//   }

//   .input {
//     height: 50px;
//     background-color: white;
//     padding: 10px;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;

//     input {
//       width: 100%;
//       border: none;
//       outline: none;
//       color: #2f2d52;
//       font-size: 18px;

//       &::placeholder {
//         color: lightgray;
//       }
//     }

//     .send {
//       display: flex;
//       align-items: center;
//       gap: 10px;

//       img {
//         height: 24px;
//         cursor: pointer;
//       }

//       button {
//         border: none;
//         padding: 10px 15px;
//         color: white;
//         background-color: #8da4f1;
//         cursor: pointer;
//       }
//     }
//   }
// }
// }
// }

.home {
  width: 80%;
  height: 100vh !important;
  padding: 2% 3%;
  margin-top: 10px;
  margin-left: 10%;
  margin-right: 10%;
}

.chat-container {
  display: flex;
  flex-direction: row !important;
  border: 1px solid rgb(224, 224, 224);
  border-radius: 5px;
  height: 90% !important;
  overflow: hidden;
  .sidebar {
    overflow-x: hidden;
    position: relative;
    overflow-y: scroll;
    width: 40%;
    border-right: 1px solid rgb(224, 224, 224);
    background-color: white;
    
    .navbar {
      padding: 10px !important;
      display: flex;
      align-items: center;
      background-color: #a3afbd;
      color: rgb(255, 255, 255);
      box-shadow: none;
      border-bottom: 1px solid rgb(224, 224, 224);
      position: sticky;
      top: 0;
      .search-icon {
        cursor: pointer;
        font-size: 24px;
        color: #fff;
      }
      .search-bar {
        display: flex;
        align-items: center;
        position: relative;
      }

      .search-icon {
        cursor: pointer;
      }

      .search-input-container {
        display: flex;
        align-items: center;
        position: absolute;
        top: -6px;
        right: 25px;
        opacity: 0;
        transition: opacity 0.3s;
        width: 200px;
      }

      .search-input-container.active {
        opacity: 1;
      }

      .search-input-container input {
        width: 150px; /* Adjust the width as needed */
        margin-right: 10px;
        padding: 5px;
        // border: 1px solid #ccc;
        border-radius: 4px;
      }

      .search-input-container.active button {
        opacity: 1;
        transition: opacity 0.3s 0.3s; /* Delay opacity transition to match input animation */
      }

      .search-input-container button {
        opacity: 0;
      }
    }

    .search {
      border-bottom: 1px solid rgb(224, 224, 224);
      .searchForm {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 10px;
        input {
          background-color: transparent;
          border: none;
          outline: none;
          height: 100%;
          &::placeholder {
            color: lightgray;
          }
        }
        button {
          font-size: 14px;
          padding: 3px;
        }
      }
    }

    .userChatActive {
      background-color: #015b92 !important;
      color: white !important;
   

      .userChatInfo {
        span {
          font-size: 14px;
          font-weight: 500;
          color: white;
          text-shadow: none;
        }
        p {
          font-size: 12px;
          color: white !important;
          text-shadow: none;
          margin-bottom: 0px;
        
        }
      }
    }
   
    .userChatActive:hover {
      background-color: #006685 !important;
    }

    .userChat {
      padding: 15px 20px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid whitesmoke;
      gap: 10px;
      color: white;
      cursor: pointer;
      justify-content: space-between;
      &:hover {
        background-color: #eaeeef;
      }

      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        object-fit: cover;
        margin-right: 10px;
        text-transform: uppercase;
      }

      .userChatInfo {
        width: 100%;
        span {
          font-size: 14px;
          font-weight: medium;
          text-transform: capitalize;
        }
        p {
          font-size: 12px;
          color: rgb(211, 195, 195);
          margin-bottom: 0px;
        }
      }
    }
  }
  .chat {
    width: 60%;
    .user-avatar {
      width: 35px;
      height: 35px;
      border-radius: 10%;
      object-fit: cover;
      margin-left: 10px;
      margin-right: 10px;
    }
    .chatInfo {
      padding: 10px !important;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: black;
      box-shadow: none;
      border-bottom: 2px double rgb(199, 199, 199);
      position: sticky;
      top: 0;
    }

    .chatIcons {
      display: flex;
      gap: 10px;
      color: #2f2d52;
      img {
        height: 24px;
        cursor: pointer;
      }
    }

    .messages {
      background-color: #e9e9e98c;
      padding: 10px;
      overflow-y: scroll;
      height: calc(100% - 125px);
      // height: 80%;
      .message {
        display: flex;
        gap: 20px;
        margin-bottom: 10px;

        .messageInfo {
          display: flex;
          flex-direction: column;
          color: gray;
          font-weight: 300;

          img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            object-fit: cover;
          }
        }
        .messageContent {
          max-width: 70%;
          display: flex;
          flex-direction: column;
          gap: 10px;

          p {
            background-color: white;
            padding: 10px 20px;
            border-radius: 0px 10px 10px 10px;
            max-width: max-content;
            position: relative;
            color: black;
            font-size: 16px;
            padding-bottom: 20px;
            min-width: 100px;
            sub {
              margin-left: 10px;
              font-size: 8px;
              position: absolute;
              bottom: 10px;
              right: 10px;
              color: rgb(185, 185, 185);
            }
          }

          img {
            width: 50%;
          }
        }

        &.owner {
          flex-direction: row-reverse;

          .messageContent {
            align-items: flex-end;
            p { 
              position: relative;
              background-color: #0084b8;
              color: white;
              border-radius: 10px 0px 10px 10px;
              padding-bottom: 10px;
              min-width: 100px;
              sub {
                margin-left: 10px;
                font-size: 10px;
                position: absolute;
                bottom: 10px;
                right: 10px;
                color: rgb(255, 255, 255);
              }
            }
          }
        }
      }
    }

    .input {
      background-color: white;
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      box-shadow: 0px -5px 5px #e0e0e0;

      input {
        width: 100%;
        border: none;
        outline: none;
        color: #7e7e7e;
        font-size: 16px;
        padding-left: 20px;

        &::placeholder {
          color: lightgray;
        }
      }

      .send {
        display: flex;
        align-items: center;
        gap: 10px;

        button {
          border: none;
          padding: 10px 15px;
          color: white;
          background-color: #008cff !important;
          cursor: pointer;
        }
      }
    }
  }
}

.empty-content {
  font-size: 40px;
  color: #bdbdbd;
  font-weight: bolder;
  font-family: Arial, Helvetica, sans-serif;
  height: inherit;
  position: absolute;
  top: 50%; /* Push the top of the div to the vertical center */
  left: 50%; /* Push the left of the div to the horizontal center */
  transform: translate(-50%, -50%);
}


@media (max-width: 768px) {
  .home {
    width: auto;
    height: 60vh !important;
    margin: 5px;

  }
  .chat-container{
    display: flex;
    flex-direction: column !important;
    border: 1px solid rgb(224, 224, 224);
    border-radius: 5px;
    height: 90% !important;
    overflow: hidden;
    .sidebar {
      overflow-x: hidden;
      position: relative;
      overflow-y: auto;
      max-height: 60vh;
      width: 100%;
      border-right: 1px solid rgb(224, 224, 224);
      background-color: white;
      .navbar {
        padding: 10px !important;
        display: flex;
        align-items: center;
        background-color: #a3afbd;
        color: rgb(255, 255, 255);
        box-shadow: none;
        border-bottom: 1px solid rgb(224, 224, 224);
        position: sticky;
        top: 0;
        .search-icon {
          cursor: pointer;
          font-size: 24px;
          color: #fff;
        }
        .search-bar {
          display: flex;
          align-items: center;
          position: relative;
        }
  
        .search-icon {
          cursor: pointer;
        }
  
        .search-input-container {
          display: flex;
          align-items: center;
          position: absolute;
        
          right: 25px;
          opacity: 0;
          transition: opacity 0.3s;
          width: 200px;
        }
  
        .search-input-container.active {
          opacity: 1;
        }
  
        .search-input-container input {
          width: 150px; /* Adjust the width as needed */
          margin-right: 10px;
          padding: 5px;
          border-radius: 4px;
        }
  
        .search-input-container.active button {
          opacity: 1;
          transition: opacity 0.3s 0.3s; /* Delay opacity transition to match input animation */
        }
  
        .search-input-container button {
          opacity: 0;
        }
      }
  
      .search {
        border-bottom: 1px solid rgb(224, 224, 224);
        .searchForm {
          width: 100%;
          display: flex;
          align-items: center;
          padding: 10px;
          input {
            background-color: transparent;
            border: none;
            outline: none;
            height: 100%;
            &::placeholder {
              color: lightgray;
            }
          }
          button {
            font-size: 14px;
            padding: 3px;
          }
        }
      }
  
      .userChatActive {
        background-color: #015b92 !important;
        color: white !important;
        .userChatInfo {
          span {
            font-size: 14px;
            font-weight: 500;
            color: white;
            text-shadow: none;
          }
          p {
            font-size: 12px;
            color: white !important;
            text-shadow: none;
            margin-bottom: 0px;
          
          }
        }
      }
     
      .userChatActive:hover {
        background-color: #006685 !important;
      }
      .userChat {
        padding: 15px 20px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid whitesmoke;
        gap: 10px;
        color: white;
        cursor: pointer;
        justify-content: space-between;
        &:hover {
          background-color: #eaeeef;
        }
  
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          object-fit: cover;
          margin-right: 10px;
    
        }
  
        .userChatInfo {
          width: 100%;
          span {
            font-size: 14px;
            font-weight: medium;
            text-transform: capitalize;
          }
          p {
            font-size: 12px;
            color: rgb(211, 195, 195);
            margin-bottom: 0px;
          }
        }
      }
    }

.chat{
  width: 100%;
  height: 60vh !important;
  .messages {
    background-color: #e9e9e98c;
    padding: 10px;
    overflow-y: scroll;
    height: calc(100% - 190px);

    .message {
      display: flex;
      gap: 20px;
      margin-bottom: 10px;

      .messageInfo {
        display: flex;
        flex-direction: column;
        color: gray;
        font-weight: 300;

        img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          object-fit: cover;
        }
      }
      .messageContent {
        max-width: 80%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        p {
          background-color: white;
          padding: 10px 20px;
          border-radius: 0px 10px 10px 10px;
          max-width: max-content;
          position: relative;
          color: black;
          font-size: 14px;
          padding-bottom: 20px;
          min-width: 100px;
          sub {
            margin-left: 10px;
            font-size: 8px;
            position: absolute;
            bottom: 10px;
            right: 10px;
            color: rgb(185, 185, 185);
          }
        }

        img {
          width: 50%;
        }
      }

      &.owner {
        flex-direction: row-reverse;

        .messageContent {
          align-items: flex-end;
          p { 
            position: relative;
            background-color: #0084b8;
            color: white;
            border-radius: 10px 0px 10px 10px;
            padding-bottom: 10px;
            min-width: 100px;
            sub {
              margin-left: 10px;
              font-size: 10px;
              position: absolute;
              bottom: 10px;
              right: 10px;
              color: rgb(255, 255, 255);
            }
          }
        }
      }
    }
  }

}

  }

 
}
