/* CarsSlider.css */

.carslider-container {
    width: 80%;
    margin: 0 auto; /* Center the slider horizontally */
    overflow: hidden;
    position: relative;
    margin-bottom: 20px;
  }
  
  .carimage-slider {
    display: flex;
    width: 100%; /* Occupy the full width of the container */
    transition: transform 0.5s ease-in-out;
  }
  
  .carslide {
    flex: 0 0 100%; /* Each slide takes up the full width of the container */
  }
  
  .carslide img {
    width: 100%; /* Make the image fill the slide */
    height: 400px; /* Maintain aspect ratio */
  }
@media (max-width:450px){
    .carslide img {
        width: 100%; /* Make the image fill the slide */
        height: 200px; /* Maintain aspect ratio */
      }
}
  