/*! CSS Used from: https://hola9.com/static/mainClassified/libs/line-awesome/css/line-awesome.min.css */
.la,.las{-moz-osx-font-smoothing:grayscale;-webkit-font-smoothing:antialiased;display:inline-block;font-style:normal;font-variant:normal;text-rendering:auto;line-height:1;}
.la-check:before{content:"\f00c";}
.la-times:before{content:"\f00d";}
.la,.las{font-family:'Line Awesome Free';font-weight:900;}
/*! CSS Used from: https://hola9.com/static/mainClassified/libs/bootstrap/css/bootstrap.min.css */
*,::after,::before{box-sizing:border-box;}
h1,h2,h3{margin-top:0;margin-bottom:.5rem;font-weight:500;line-height:1.2;}
h1{font-size:calc(1.375rem + 1.5vw);}
@media (min-width:1200px){
h1{font-size:2.5rem;}
}
h2{font-size:calc(1.325rem + .9vw);}
@media (min-width:1200px){
h2{font-size:2rem;}
}
h3{font-size:calc(1.3rem + .6vw);}
@media (min-width:1200px){
h3{font-size:1.75rem;}
}
p{margin-top:0;margin-bottom:1rem;}
ul{padding-left:2rem;}
ul{margin-top:0;margin-bottom:1rem;}
a{color:#0d6efd;text-decoration:underline;}
a:hover{color:#0a58ca;}
img{vertical-align:middle;}
table{caption-side:bottom;border-collapse:collapse;}
th{text-align:inherit;text-align:-webkit-match-parent;}
tbody,th,thead,tr{border-color:inherit;border-style:solid;border-width:0; }
label{display:inline-block;}
input{margin:0;font-family:inherit;font-size:inherit;line-height:inherit;}
::-moz-focus-inner{padding:0;border-style:none;}
.container{width:100%;padding-right:var(--bs-gutter-x,.75rem);padding-left:var(--bs-gutter-x,.75rem);margin-right:auto;margin-left:auto;}
@media (min-width:576px){
.container{max-width:540px;}
}
@media (min-width:768px){
.container{max-width:720px;}
}
@media (min-width:992px){
.container{max-width:960px;}
}
@media (min-width:1200px){
.container{max-width:1140px;}
}
@media (min-width:1400px){
.container{max-width:1320px;}
}
.row{--bs-gutter-x:1.5rem;--bs-gutter-y:0;display:flex;flex-wrap:wrap;margin-top:calc(var(--bs-gutter-y) * -1);margin-right:calc(var(--bs-gutter-x)/ -2);margin-left:calc(var(--bs-gutter-x)/ -2);}
.row>*{flex-shrink:0;width:100%;max-width:100%;padding-right:calc(var(--bs-gutter-x)/ 2);padding-left:calc(var(--bs-gutter-x)/ 2);margin-top:var(--bs-gutter-y);}
.col-6{flex:0 0 auto;width:50%;}
@media (min-width:992px){
.col-lg-3{flex:0 0 auto;width:25%;}
}
.table-responsive{overflow-x:auto;-webkit-overflow-scrolling:touch;}
.btn{display:inline-block;font-weight:400;line-height:1.5;color:#212529;text-align:center;text-decoration:none;vertical-align:middle;cursor:pointer;-webkit-user-select:none;-moz-user-select:none;user-select:none;background-color:transparent;border:1px solid transparent;padding:.375rem .75rem;font-size:1rem;border-radius:.25rem;transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;}
@media (prefers-reduced-motion:reduce){
.btn{transition:none;}
}
.btn:hover{color:#212529;}
.btn:focus{outline:0;box-shadow:0 0 0 .25rem rgba(13,110,253,.25);}
.btn:disabled{pointer-events:none;opacity:.65;}
/*! CSS Used from: https://hola9.com/static/mainClassified/css/style.css */
div{border:0;font-size:100%;margin:0;outline:0;padding:0;vertical-align:baseline;}
span{border:0;font-size:100%;margin:0;outline:0;padding:0;vertical-align:baseline;}
h1{border:0;font-size:100%;margin:0;outline:0;padding:0;vertical-align:baseline;clear:both;color:#2d2d2d;font-family:"Jost";font-weight:700;font-size:24px;}
h2{border:0;font-size:100%;margin:0;outline:0;padding:0;vertical-align:baseline;clear:both;color:#2d2d2d;font-family:"Jost";font-weight:700;font-size:20px;}
h3{border:0;font-size:100%;margin:0;outline:0;padding:0;vertical-align:baseline;clear:both;color:#2d2d2d;font-family:"Jost";font-weight:700;font-size:18px;}
p{border:0;font-size:100%;margin:0;outline:0;padding:0;line-height:1.44;vertical-align:baseline;}
a{border:0;font-size:100%;margin:0;outline:0;padding:0;vertical-align:baseline;color:#2d2d2d;text-decoration:none;transition:all 0.3s ease-in-out 0s;-moz-transition:all 0.3s ease-in-out 0s;-o-transition:all 0.3s ease-in-out 0s;-webkit-transition:all 0.3s ease-in-out 0s;-ms-transition:all 0.3s ease-in-out 0s;}
a:hover{color:#23d3d3;text-decoration:none;}
a:focus{text-decoration:none;color:inherit;outline:none;}
ul{border:0;font-size:100%;margin:0;outline:0;padding:0;vertical-align:baseline;}
li{border:0;font-size:100%;margin:0;outline:0;padding:0;vertical-align:baseline;}
label{border:0;font-size:100%;margin:0;outline:0;padding:0;vertical-align:baseline;}
table{border:0;font-size:100%;margin:0;outline:0;padding:0;vertical-align:baseline;}
tbody{border:0;font-size:100%;margin:0;outline:0;padding:0;vertical-align:baseline;}
thead{border:0;font-size:100%;margin:0;outline:0;padding:0;vertical-align:baseline;}
tr{border:0;font-size:100%;margin:0;outline:0;padding:0;vertical-align:baseline;}
th{border:0;font-size:100%;margin:0;outline:0;padding:0;vertical-align:baseline;}
img{border:none;max-width:100%;height:auto;}
input{transition:all 0.3s ease-in-out 0s;-moz-transition:all 0.3s ease-in-out 0s;-o-transition:all 0.3s ease-in-out 0s;-webkit-transition:all 0.3s ease-in-out 0s;-ms-transition:all 0.3s ease-in-out 0s;}
.btn{border-radius:20px;-webkit-backface-visibility:hidden;-moz-backface-visibility:hidden;-webkit-transform:translate3d(0, 0, 0);-moz-transform:translate3d(0, 0, 0);background-color:#23d3d3;height:40px;line-height:40px;font-weight:500;padding:0 25px;color:#ffffff;outline:0;outline-offset:0;}
.btn:hover{color:#ffffff;opacity:0.7;}
.btn:focus{box-shadow:none;color:#ffffff;}
.btn{border-radius:20px;-webkit-backface-visibility:hidden;-moz-backface-visibility:hidden;-webkit-transform:translate3d(0, 0, 0);-moz-transform:translate3d(0, 0, 0);background-color:#23d3d3;height:40px;line-height:40px;font-weight:500;padding:0 25px;color:#ffffff;outline:0;outline-offset:0;}
.btn:hover{color:#ffffff;}
.btn:focus{box-shadow:none;color:#ffffff;}
.field-check{line-height:normal;margin-bottom:20px;display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;margin-left:-15px;margin-right:-15px;}
.field-check label{position:relative;display:inline-block;line-height:normal;padding-left:26px;cursor:pointer;-webkit-box-flex:0;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%;padding:0 15px;}
.field-check label:before{content:'';height:16px;width:16px;border-radius:3px;-webkit-backface-visibility:hidden;-moz-backface-visibility:hidden;-webkit-transform:translate3d(0, 0, 0);-moz-transform:translate3d(0, 0, 0);border:solid 1px #dddddd;display:block;position:absolute;left:0;top:2px;border-width:2px;position:static;display:inline-block;vertical-align:-3px;margin-right:8px;border-color:#8d8d8d;}
.field-check label span{position:absolute;border-radius:3px;-webkit-backface-visibility:hidden;-moz-backface-visibility:hidden;-webkit-transform:translate3d(0, 0, 0);-moz-transform:translate3d(0, 0, 0);text-align:center;height:16px;width:16px;top:3px;background-color:#2d2d2d;left:0;line-height:14px;opacity:0;visibility:hidden;transition:all 0.3s;top:6px;left:19px;transition:inherit;}
.field-check label input:checked ~ span{opacity:1;visibility:visible;text-align:center;height:16px;width:16px;top:3px;background-color:#2d2d2d;left:15px;line-height:14px;border-radius:3px;-webkit-backface-visibility:hidden;-moz-backface-visibility:hidden;-webkit-transform:translate3d(0, 0, 0);-moz-transform:translate3d(0, 0, 0);}
.field-check label input:checked ~ span i{font-size:12px;color:#ffffff;}
.field-check input{position:absolute;opacity:0;visibility:hidden;}
.member-menu{background-color:#1d1d1d;margin-bottom:30px;}
.member-menu li{display:inline-block;list-style:none;padding:0 15px;}
.member-menu li a{font-size:15px;font-weight:500;color:#ffffff;text-transform:uppercase;height:60px;line-height:60px;display:block;font-family:"Jost";}
.member-menu li:first-child{padding-left:0;}
.member-menu li.active a{color:#23d3d3;}
.member-wrap{margin-bottom:60px;}
.member-wrap h1{font-size:28px;font-weight:500;margin-bottom:30px;}
.member-wrap h3{font-size:15px;font-weight:500;color:#8d8d8d;text-transform:uppercase;margin-bottom:20px;}
.member-wrap label{font-family:"Jost";font-size:15px;font-weight:500;color:#2d2d2d;}
.owner-content{background-color:#f9f9f9;padding-bottom:60px;}
.owner-content .member-wrap{margin-bottom:0;}
.member-wrap-top{display:-webkit-box;display:-ms-flexbox;display:flex;justify-content:space-between;align-items:center;margin-bottom:30px;}
.member-wrap-top h2{font-size:28px;font-weight:500;}
.member-wrap-top p{font-size:16px;font-weight:500;font-family:"Jost";}
.member-wrap-top p a{color:#23d3d3;}
.member-wrap-top p a:hover{opacity:0.6;}
.upgrade-box{border-radius:16px;-webkit-backface-visibility:hidden;-moz-backface-visibility:hidden;-webkit-transform:translate3d(0, 0, 0);-moz-transform:translate3d(0, 0, 0);background-color:#ffd54f;padding:30px 50px;position:relative;margin-bottom:30px;}
.upgrade-box h1{font-size:36px;font-weight:500;margin-bottom:5px;line-height:49px;}
.upgrade-box p{font-size:18px;font-weight:500;margin-bottom:20px;font-family:"Jost";color:#2d2d2d;}
.upgrade-box img{position:absolute;top:0;right:64px;bottom:0;height:100%;}
.upgrade-box .close{position:absolute;top:25px;right:25px;}
.upgrade-box .close i{font-size:24px;color:#5d5d5d;}
.upgrade-box .close i:hover{color:#23d3d3;}
.member-statistical{margin-bottom:30px;}
.member-statistical .item{border-radius:10px;-webkit-backface-visibility:hidden;-moz-backface-visibility:hidden;-webkit-transform:translate3d(0, 0, 0);-moz-transform:translate3d(0, 0, 0);border:solid 1px #eeeeee;background-color:#ffffff;padding:25px;text-align:center;}
.member-statistical .item h3{font-size:15px;font-weight:500;line-height:1.6;color:#5d5d5d;margin-bottom:6px;}
.member-statistical .item .number{font-size:36px;font-weight:500;line-height:1.61;font-family:"Jost";color:#2d2d2d;}
.member-statistical .item .line{border-radius:5px;-webkit-backface-visibility:hidden;-moz-backface-visibility:hidden;-webkit-transform:translate3d(0, 0, 0);-moz-transform:translate3d(0, 0, 0);background-color:#23d3d3;display:block;width:100px;height:5px;margin:0 auto;}
.member-statistical .item.blue .line{background-color:#23d3d3;}
.member-statistical .item.green .line{background-color:#52e707;}
.member-statistical .item.yellow .line{background-color:#ffd13d;}
.member-statistical .item.purple .line{background-color:#5f4fff;}
.member-place-list{width:100%;border:1px solid #eeeeee;margin-bottom:30px;overflow:inherit;background-color:#ffffff;}
.member-place-list .field-check{margin-bottom:0;}
.member-place-list th{padding:30px 20px 20px;font-size:15px;font-weight:500;font-family:Jost;color:#5d5d5d;text-transform:uppercase;line-height:1.6;}
.member-place-list th:nth-child(1){padding-left:30px;}
/*! CSS Used from: https://hola9.com/static/mainClassified/css/responsive.css */
@media only screen and (min-width: 1200px){
.container{max-width:1200px;}
}
@media only screen and (max-width: 1200px){
.member-place-list th{padding:15px 10px;}
}
@media only screen and (max-width: 992px){
.container{padding-left:25px;padding-right:25px;}
.field-check label{-ms-flex:0 0 33%;flex:0 0 33%;max-width:33%;}
.member-menu ul{width:700px;}
.member-menu > .container{overflow-x:scroll;}
.member-place-list th,.member-place-list th:nth-child(1){padding:15px 10px;}
.member-statistical{margin-bottom:0;}
.member-statistical .item{margin-bottom:30px;}
}
@media only screen and (max-width: 767px){
.table-responsive,.table-responsive thead,.table-responsive tbody,.table-responsive th,.table-responsive tr{display:block;}
.table-responsive > thead > tr{position:absolute;top:-9999px;left:-9999px;}
.member-wrap-top{flex-wrap:wrap;}
.member-wrap-top h2{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;margin-bottom:10px;}
.upgrade-box img{display:none;}
.upgrade-box{padding:30px;}
.upgrade-box .close{right:15px;top:15px;}
.owner-content{padding-bottom:0;}
}
@media only screen and (max-width: 575px){
.field-check{margin-bottom:0;}
.field-check label{-webkit-box-flex:0!important;-ms-flex:0 0 50%!important;flex:0 0 50%!important;max-width:50%!important;}
.upgrade-box h1{font-size:24px;line-height:normal;}
.upgrade-box p{font-size:16px;}
.member-statistical .item{padding:20px 10px;}
.member-statistical .item h3{font-size:14px;}
}
/*! CSS Used from: https://hola9.com/static/css/bootstrap.min.css */
*,::after,::before{box-sizing:border-box;}
main{display:block;}
h1,h2,h3{margin-top:0;margin-bottom:.5rem;}
p{margin-top:0;margin-bottom:1rem;}
ul{margin-top:0;margin-bottom:1rem;}
a{color:#007bff;text-decoration:none;background-color:transparent;-webkit-text-decoration-skip:objects;}
a:hover{color:#0056b3;text-decoration:underline;}
img{vertical-align:middle;border-style:none;}
table{border-collapse:collapse;}
th{text-align:inherit;}
label{display:inline-block;margin-bottom:.5rem;}
input{margin:0;font-family:inherit;font-size:inherit;line-height:inherit;}
input{overflow:visible;}
input[type=checkbox]{box-sizing:border-box;padding:0;}
h1,h2,h3{margin-bottom:.5rem;font-family:inherit;font-weight:500;line-height:1.2;color:inherit;}
h1{font-size:2.5rem;}
h2{font-size:2rem;}
h3{font-size:1.75rem;}
.container{width:100%;padding-right:15px;padding-left:15px;margin-right:auto;margin-left:auto;}
@media (min-width:576px){
.container{max-width:540px;}
}
@media (min-width:768px){
.container{max-width:720px;}
}
@media (min-width:992px){
.container{max-width:960px;}
}
@media (min-width:1200px){
.container{max-width:1140px;}
}
.row{display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;margin-right:-15px;margin-left:-15px;}
.col-6,.col-lg-3{position:relative;width:100%;min-height:1px;padding-right:15px;padding-left:15px;}
.col-6{-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
@media (min-width:992px){
.col-lg-3{-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%;}
}
.table-responsive{display:block;width:100%;overflow-x:auto;-webkit-overflow-scrolling:touch;-ms-overflow-style:-ms-autohiding-scrollbar;}
.btn{display:inline-block;font-weight:400;text-align:center;white-space:nowrap;vertical-align:middle;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;border:1px solid transparent;padding:.375rem .75rem;font-size:1rem;line-height:1.5;border-radius:.25rem;transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;}
@media screen and (prefers-reduced-motion:reduce){
.btn{transition:none;}
}
.btn:focus,.btn:hover{text-decoration:none;}
.btn:focus{outline:0;box-shadow:0 0 0 .2rem rgba(0,123,255,.25);}
.btn:disabled{opacity:.65;}
.close{float:right;font-size:1.5rem;font-weight:700;line-height:1;color:#000;text-shadow:0 1px 0 #fff;opacity:.5;}
.close:focus,.close:hover{color:#000;text-decoration:none;opacity:.75;}
@media print{
*,::after,::before{text-shadow:none!important;box-shadow:none!important;}
a:not(.btn){text-decoration:underline;}
thead{display:table-header-group;}
img,tr{page-break-inside:avoid;}
h2,h3,p{orphans:3;widows:3;}
h2,h3{page-break-after:avoid;}
.container{min-width:992px!important;}
}
/*! CSS Used from: https://hola9.com/static/css/main.css */
h1,h2,h3{font-size:36px;font-weight:700;font-family:'Montserrat', sans-serif;}
a{-webkit-transition:all 0.2s linear;-moz-transition:all 0.2s linear;-o-transition:all 0.2s linear;transition:all 0.2s linear;}
a:hover{text-decoration:none;}
p{font-weight:400;font-family:'Open Sans', sans-serif;margin:0px;font-size:14px;}
ul{list-style:outside none none;margin:0;padding:0;}
ul li{list-style:none;}
.btn{font-size:14px;padding:7px 20px;cursor:pointer;font-weight:400;-webkit-transition:all 0.2s linear;-moz-transition:all 0.2s linear;-o-transition:all 0.2s linear;transition:all 0.2s linear;display:inline-block;}
.btn:focus,.btn:active{box-shadow:none;outline:none;color:#fff;}
label{display:inline;}
/*! CSS Used from: Embedded */
.upgrade-box h1{font-size:25px;font-weight:500;margin-bottom:5px;line-height:49px;margin:10px 370px;}
.upgrade-box img{position:absolute;top:30px;left:40px;bottom:0;height:150px;border-radius:75px;width:150px;}
/*! CSS Used fontfaces */
@font-face{font-family:'Line Awesome Free';font-style:normal;font-weight:400;font-display:auto;src:url(https://hola9.com/static/mainClassified/libs/line-awesome/fonts/la-regular-400.eot);src:url(https://hola9.com/static/mainClassified/libs/line-awesome/fonts/la-regular-400.eot#iefix) format("embedded-opentype"),url(https://hola9.com/static/mainClassified/libs/line-awesome/fonts/la-regular-400.woff2) format("woff2"),url(https://hola9.com/static/mainClassified/libs/line-awesome/fonts/la-regular-400.woff) format("woff"),url(https://hola9.com/static/mainClassified/libs/line-awesome/fonts/la-regular-400.ttf) format("truetype"),url(https://hola9.com/static/mainClassified/libs/line-awesome/fonts/la-regular-400.svg#lineawesome) format("svg");}
@font-face{font-family:'Line Awesome Free';font-style:normal;font-weight:900;font-display:auto;src:url(https://hola9.com/static/mainClassified/libs/line-awesome/fonts/la-solid-900.eot);src:url(https://hola9.com/static/mainClassified/libs/line-awesome/fonts/la-solid-900.eot#iefix) format("embedded-opentype"),url(https://hola9.com/static/mainClassified/libs/line-awesome/fonts/la-solid-900.woff2) format("woff2"),url(https://hola9.com/static/mainClassified/libs/line-awesome/fonts/la-solid-900.woff) format("woff"),url(https://hola9.com/static/mainClassified/libs/line-awesome/fonts/la-solid-900.ttf) format("truetype"),url(https://hola9.com/static/mainClassified/libs/line-awesome/fonts/la-solid-900.svg#lineawesome) format("svg");}
@font-face{font-family:'Jost';src:url('https://hola9.com/static/mainClassified/fonts/jost/Jost-Light.woff2') format('woff2'),         url('https://hola9.com/static/mainClassified/fonts/jost/Jost-Light.woff') format('woff');font-weight:300;font-style:normal;}
@font-face{font-family:'Jost';src:url('https://hola9.com/static/mainClassified/fonts/jost/Jost-Bold.woff2') format('woff2'),         url('https://hola9.com/static/mainClassified/fonts/jost/Jost-Bold.woff') format('woff');font-weight:700;font-style:normal;}
@font-face{font-family:'Jost';src:url('https://hola9.com/static/mainClassified/fonts/jost/Jost-Medium.woff2') format('woff2'),         url('https://hola9.com/static/mainClassified/fonts/jost/Jost-Medium.woff') format('woff');font-weight:500;font-style:normal;}
@font-face{font-family:'Montserrat';font-style:normal;font-weight:500;src:url(https://fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2) format('woff2');unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:'Montserrat';font-style:normal;font-weight:500;src:url(https://fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2) format('woff2');unicode-range:U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:'Montserrat';font-style:normal;font-weight:500;src:url(https://fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2) format('woff2');unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:'Montserrat';font-style:normal;font-weight:500;src:url(https://fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Montserrat';font-style:normal;font-weight:500;src:url(https://fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:'Montserrat';font-style:normal;font-weight:700;src:url(https://fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2) format('woff2');unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:'Montserrat';font-style:normal;font-weight:700;src:url(https://fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2) format('woff2');unicode-range:U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:'Montserrat';font-style:normal;font-weight:700;src:url(https://fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2) format('woff2');unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:'Montserrat';font-style:normal;font-weight:700;src:url(https://fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Montserrat';font-style:normal;font-weight:700;src:url(https://fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:'Open Sans';font-style:normal;font-weight:400;font-stretch:100%;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4taVIGxA.woff2) format('woff2');unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:'Open Sans';font-style:normal;font-weight:400;font-stretch:100%;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4kaVIGxA.woff2) format('woff2');unicode-range:U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:'Open Sans';font-style:normal;font-weight:400;font-stretch:100%;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4saVIGxA.woff2) format('woff2');unicode-range:U+1F00-1FFF;}
@font-face{font-family:'Open Sans';font-style:normal;font-weight:400;font-stretch:100%;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4jaVIGxA.woff2) format('woff2');unicode-range:U+0370-03FF;}
@font-face{font-family:'Open Sans';font-style:normal;font-weight:400;font-stretch:100%;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4iaVIGxA.woff2) format('woff2');unicode-range:U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;}
@font-face{font-family:'Open Sans';font-style:normal;font-weight:400;font-stretch:100%;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4vaVIGxA.woff2) format('woff2');unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:'Open Sans';font-style:normal;font-weight:400;font-stretch:100%;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4uaVIGxA.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Open Sans';font-style:normal;font-weight:400;font-stretch:100%;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4gaVI.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}