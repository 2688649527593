.Pets-cat-main-24{
    display: flex;
    flex-direction: column; 
}
.Pets-cat-main-resp-awi-24{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }
.Pets-cat-submain-aqwi-24{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
 
}
.filtering-aqwi{
        position: relative;
        top: 150px;
        left: 100px;
        width: 100%;
}
  .Pets-cat-main-resp-cont-1-awi-24{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 80%;
    width: 90%;
  }

  .Pets-cat-main-resp-cont-2-awi-24{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
@media (min-width:750px) and (max-width:1100px){
    .Pets-cat-main-resp-awi-24{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
      }
      .Pets-cat-submain-aqwi-24{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
     align-items: center;
    }
      .Pets-cat-main-resp-cont-1-awi-24{
       order:2;
       width:90%;
      }
      .Pets-cat-main-resp-cont-2-awi-24{
     
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        order:1;
        width:80%;
       }
       .filtering-aqwi{
        display:none;
     }
}
@media (min-width:450px) and (max-width:750px) {
    .Pets-cat-main-resp-awi-24{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
      }
      .Pets-cat-submain-aqwi-24{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
     align-items: center;
    }
      .Pets-cat-main-resp-cont-1-awi-24{
       order:2;
       width:90%;
      }
      .Pets-cat-main-resp-cont-2-awi-24{
     
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
          
        order:1;
        width:80%;
       }
       .filtering-aqwi{
        display:none;
     }
}
@media (max-width:500px){
    .Pets-cat-main-resp-awi-24{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
      }
      .Pets-cat-submain-aqwi-24{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
     align-items: center;
    }
      .Pets-cat-main-resp-cont-1-awi-24{
       order:2;
       width:100%;
      }
      .Pets-cat-main-resp-cont-2-awi-24{
     
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
          
        order:1;
        width:80%;
       }
       .filtering-aqwi{
        display:none;
     }
}
