/* Individual top seller card */
.topcard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-radius: 15px;
  box-shadow: 4px 4px 8px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s;
  margin: 10px;
  width: 200px;
  /* border: 1px solid #2980b9; */
  background: white;
  color: #fff;
}

.topcard:hover {
  transform: scale(1.05);
}

/* Profile icon */
.profile-icon {
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
}
@media (max-width: 480px) {
  .mainwrapper {
    overflow-x: hidden;
  }
  .topcard{
    width: 190px;
  }
 
}
/* User ID and See More button container */
.card-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-top: 15px; */
}

/* User ID */
.user-id {
  font-weight: bold;
  text-align: center;
}

/* See More button */
.seemore {
  text-decoration: none;
  color: white !important;
  padding: 8px 16px;
  background-color: #1a1a1a;
  border-radius: 5px;
  text-align: center;
  /* margin-top: 15px; */
  transition: background-color 0.3s;
}

.seemore:hover {
  background-color: #0355c0;
}

/* Remove Link underline */
.card-link {
  text-decoration: none;
}
