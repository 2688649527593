/* .servicesslide{
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 20px auto;
}
.carousel-inner1{
  width: 80%;
  height: 350px;
}
.search-form {
  width: 20%;
  margin-left: 20px;
  height: max-content;
  border-radius: 0px 0px 30px 30px;
  padding: 20px 30px;
  min-height: 340px !important;
  border: 2px solid #2e1e1e;
  background-color: rgb(238, 238, 238);
}


@media (max-width: 768px) {
  .search-form {
    width: 98% !important;
 margin-right: 5px !important;
 margin-left: 5px !important;
 margin-top: -5px !important;
 margin-bottom: 5px !important;
    border: 1px solid #838383;
  }
  .carousel-inner1{
      width: 100%;
      height: 200px;
  }
  .servicesslide{
      display: "flex";
      flex-direction: "column";
  }
  .buttonno1 {
    background-color: #002347;
    margin-top: 5px;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 15rem;
  } 
}
.form-group {
  margin-bottom: 20px;
} 
.buttonno1 {
  background-color: #003e7c;
  margin-top: 15px;
  color: #fff;
  padding: 8px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 15rem;
} 
.buttonno1:hover {
  background-color: #415f7e;
  color: #fff;
}
.error-message {
  color: #ff0000;
  font-size: 12px;
  margin-top: 10px;
}


.search-form-awi-24{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} */




.servicesslide-awi-24{
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  /* align-items: center; */
  margin: 20px 10px;
}
.carousel-inner24-awi-24{
  width: 75%;
  height: 100%;
margin: 10px;
}
.search-form-awi-24 {
  width: 25%;
  height: 100%;
  border-radius: 0px 0px 30px 30px;
  padding: 20px 30px;
  border: 2px solid #2e1e1e;
  background-color: rgb(238, 238, 238);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
}


.form-group {
  margin-bottom: 20px;
} 
.buttonno1 {
  background-color: #003e7c;
  margin-top: 15px;
  color: #fff;
  padding: 8px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 15rem;
} 
.buttonno1:hover {
  background-color: #415f7e;
  color: #fff;
}
.error-message {
  color: #ff0000;
  font-size: 12px;
  margin-top: 10px;
}




@media (min-width:780px) and (max-width:1000px){
  .servicesslide-awi-24{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  
  }
   .carousel-inner24-awi-24{
    width: 100%;
    height: 100%;
  }
  .search-form-awi-24 {
    width: 50%;
    height: 100%;

  }
}
  @media (min-width:550px) and (max-width:780px){
.servicesslide-awi-24{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

}
    .carousel-inner24-awi-24{
      width: 100%;
      order: 1;
      height: 100%;
    }
    .search-form-awi-24 {
      order: 2;
      width: 50%;
      height: 100%;
  
    }
  }
    @media (max-width:550px){
      .servicesslide-awi-24{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;

      }
          .carousel-inner24-awi-24{
            width: 100%;
            order: 1;
            height: 100%;
            margin-left: -20px;
          }
          .search-form-awi-24 {
            order: 2;
            width: 100%;
            height: 100%;
          }

}


