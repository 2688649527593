/* .custom-btn {
    width: 130px;
    height: 40px;
    color: #fff;
    border-radius: 5px;
    padding: 10px 25px;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
     box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
     7px 7px 20px 0px rgba(0,0,0,.1),
     4px 4px 5px 0px rgba(0,0,0,.1);
    outline: none;
  }

  .btn-4 {
    background: rgb(0,172,238) !important;
    background: linear-gradient(0deg, rgba(0,172,238,1) 0%, rgba(2,126,251,1) 100%);
    width: 130px;
    height: 40px;
    line-height: 42px;
    padding: 0;
    border: none;
    
  }
  .btn-4 span {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
  }
  .btn-4:before,
  .btn-4:after {
    position: absolute;
    content: "";
    right: 0;
    top: 0;
    background: rgba(2,126,251,1);
    transition: all 0.3s ease;
  }
  .btn-4:before {
    height: 0%;
    width: 2px;
  }
  .btn-4:after {
    width: 0%;
    height: 2px;
  }
  .btn-4:hover{
    background: transparent;
    box-shadow: none;
  }
  .btn-4:hover:before {
    height: 50%;
  }
  .btn-4:hover:after {
    width: 10%;
  }
  .btn-4 span:hover{
    color: rgba(2,126,251,1);
  }
  .btn-4 span:before,
  .btn-4 span:after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
     background: rgba(2,126,251,1);
    transition: all 0.3s ease;
  }
  .btn-4 span:before {
    width: 2px;
    height: 0%;
  }
  .btn-4 span:after {
    width: 0%;
    height: 2px;
  }
  .btn-4 span:hover:before {
    height: 100%;
  }
  .btn-4 span:hover:after {
    width: 100%;
  }
  .capitalizer{
    text-transform: capitalize;
  } */
  .btnhead{
    font-family: "Poppins", sans-serif !important;
  
  }
  .topHead{
    font-family: "Poppins", sans-serif !important;
  
  }
    .nav-link {
      color: #004e92;
      position: relative;
      text-decoration: none;
      font-family: "Poppins", sans-serif !important;
    }
    
    .nav-link::before {
      content: '';
      position: absolute;
      width: 90%;
      height: 2px;
      border-radius: 4px;
      background-color: #004e92;
      bottom: 0;
      left: 0;
      transform-origin: right;
      transform: scaleX(0);
      transition: transform .3s ease-in-out;
    }
    
    .nav-link:hover::before {
      transform-origin: left;
      transform: scaleX(1);
    }
  
    .btnhead {
      background: #2b5876 ;
      background: linear-gradient(0deg, #2b5876 0%,  #4e4376 100%) !important;
      color: white;
      font-size: 14px;
      padding: 7px;
      border: none;
      cursor: pointer;
      border-radius: 5px;
      text-align: center;
      font-weight:500;
    }
    
     .btnhead:hover {
      background: #000428 ; 
      background: linear-gradient(0deg, #000428  0%,  #004e92 100%) !important;
      /* color: white; */
    }
    
   /* post add button */
   #testbutton {
    width: 120px;
    height:40px;
    border-radius:180px;
    position:relative;
    left:calc(50% - 55px);
    top:calc(30% - 25px);
    background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
    cursor:pointer;
    line-height:19px;
  }
  
  #testbutton:before {
    content:'+Post Ads';
    z-index:1;
    position:absolute;
    display:block;
    width:80%;
    height:70%;
    top:5%;
    left:10%;
    transition: 0.3s opacity ease-in-out;
    filter:blur(15px);
    opacity:0;
    background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
  }
  
  #testbutton:hover:before {
    opacity:1;
    transition: 0.3s opacity ease-in-out;
    filter:blur(25px);
    background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);  
  }
  
  #testbutton:after {
    content:'+ PostAds';
    text-align:center;
    line-height:30px;
    font-size:14px;
    color:rgb(255, 255, 255);
    font-weight: 700;
    z-index:5;
    position:absolute;
    display:block;
    border-radius:100px;
    width:92%;
    height:80%;
    top:10%;
    left:4%;  
    background-color:rgb(1, 24, 71);
  }
  .cart{
    font-size: 150%;
    color: rgb(223, 38, 115);
  }
  .countcard{
    transform: translate(280%, 15%);
  }
  .cartt:hover{
      transform: scale(0.7);
      color: red;
  }
/* forbussinessbtn */

/* CSS */
.button-92 {
  --c: #fff;
  /* text color */
  background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) var(--_p,100%)/300% no-repeat,
  #1d0c54;
  /* background color */
  color: #0000;
  border: none;
  transform: perspective(500px) rotateY(calc(40deg*var(--_i,-1)));
  text-shadow: calc(var(--_i,-1)* 0.08em) -.01em 0   var(--c),
    calc(var(--_i,-1)*-0.08em)  .01em 2px #0004;
  outline-offset: .1em;
  transition: 0.3s;
}

.button-92:hover,
.button-92:focus-visible {
  --_p: 0%;
  --_i: 1;
  border-right:solid  rgb(224, 85, 131) 3px;
  border-left:solid  rgb(224, 85, 131) 10px;
  /* background-color: rgb(230, 204, 60); */
}

.button-92:active {
  text-shadow: none;
  color: var(--c);
  background-color: #0d5594;
  box-shadow: inset 0 0 9e9q #0005;
  transition: 0s;
}

.button-92 {
  /* font-weight:700; */
  font-size: 1rem;
  margin: 0;
  cursor: pointer;
  padding: .3em .5em;
  border-radius: 6%;
  border-left:solid  rgb(224, 85, 131) 3px;
  border-right:solid rgb(224, 85, 131) 10px;
}
    /* Vibration animation */
    @keyframes vibrate {
      0% { transform: translateX(0); }
      20% { transform: translateX(-2px) rotate(-2deg); }
      40% { transform: translateX(2px) rotate(2deg); }
      60% { transform: translateX(-2px) rotate(-2deg); }
      80% { transform: translateX(2px) rotate(2deg); }
      100% { transform: translateX(0); }
    }
    
    /* Hover effect */
    


    .popuptext:hover{
      color: #004e92;
    }
    .popuptext{
     color: #06233f;
     text-transform: uppercase;
     font-weight: bold;
    }
    .popupr{
      position: fixed;
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgb(39, 37, 37);
      text-decoration: underline greenyellow;
      color: rgb(160, 253, 21);
      font-weight: 500;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
      /* Position the popup in the center of the screen */
      top: 90%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    @media (max-width:450px) {
      .popupr{
        font-size: 70%;
      }
    }


    .bellbell{
      font-size: 24px;
      color: #384a74;
    }
    .bellbell:hover{
      color: #f79533;
    }




    .app{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10%;
    }

    
    .progreesivebar{
      position: relative;
      overflow: hidden;
      width: 150px;
      height: 35px;
      border: 3px solid black;
      border-radius: 5px;
      background-color: rgb(244, 242, 237);

    }
    .progressPercent{
      font-weight: 600;
      font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    position: absolute;
    left: 50%;
    top: 50%;
    transform:translate(-50%,-50%);
    color:red;
    text-shadow:-1px 0 #555, 0 1px #555, 1px0 #555,-1px 0 ;
    }


    .btn-24{
      display: block;
      margin: 5rem auto;
      border: none;
      outline: none;
      width: 80px;
      height:100px;
      background-color: #dbe2e7;
     transition:box-shadow 0.5s;
     font-size:5px;
     cursor: pointer;
    }