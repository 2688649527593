.stylo {
  overflow: hidden;
  position: relative;
  display: inline-block;
}

.stylo::before,
.stylo::after {
 content: '';
  position: absolute;
  width: 100%;
  left: 0;
}
.stylo::before {
  background-color: #004e92 !important;
  height: 2px;
  bottom: 0;
  transform-origin: 100% 50%;
  transform: scaleX(0);
  transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
}
.stylo::after {
  content: attr(data-replace);
  height: 100%;
  top: 0;
  transform-origin: 100% 50%;
  transform: translate3d(200%, 0, 0);
  transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
  color: #004e92 !important;
}
.custom-select2{
  border-top-left-radius: 20px;
}
.stylo:hover::before {
  transform-origin: 0% 50%;
  transform: scaleX(1);
}
.stylo:hover::after {
  transform: translate3d(0, 0, 0);
}

.stylo span {
  display: inline-block;
  transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
}

.stylo:hover span {
  transform: translate3d(-200%, 0, 0);
}

.custom-btnm {
  width: 100%;
  height:45px;
    color: #fff;
    /* margin-top: -1px; */
    border-radius: 5px;
    /* padding: 10px 25px; */
    font-family: "Poppins", sans-serif !important;
    font-weight: 500;
    font-size:18px;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
     box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
     7px 7px 20px 0px rgba(0,0,0,.1),
     4px 4px 5px 0px rgba(0,0,0,.1);
    outline: none;
  }
  .btn-15 {
    background: #2b5876 !important;
    background: linear-gradient(0deg, #2b5876 0%,  #4e4376 100%);
    border: none;
    z-index: 1;
  }
  .sizeicon{
    font-size: 50px;
    cursor: all-scroll;
  }
  .btn-15:after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    right: 0;
    z-index: -1;
    background: #2b5876;
    background: linear-gradient(0deg, #2b5876 0%,  #4e4376 100%);
    border-radius: 5px;
     box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
     7px 7px 20px 0px rgba(0,0,0,.1),
     4px 4px 5px 0px rgba(0,0,0,.1);
    transition: all 0.3s ease;
  }
  .btn-15:hover {
    color: #fff;
  }
  .btn-15:hover:after {
    left: 0;
    width: 100%;
  }
  .btn-15:active {
    top: 2px;
  }
  
.text-center{
  height:50px;
  color:rgba(0, 0, 0, 0.572);
  border:1px solid white;
  background-color:transparent;
   padding:10px;
  /*padding-left:30px; */
  font-size:16px !important;
  border-radius:10px;
  
}
/* .inpstyle{
  border-radius: 10px !important;
  
} */
  
.custom-select2 .ant-select-selector {
  height: 45px !important;
  padding:5px 14px !important ;
  border-radius: 6px;  /* z-index: 10; */
}

.custom-select2 .ant-select-selection-search-input{
  position: absolute !important;
  top: 6px;
  padding-left: 2px !important;
}

.searchdi{
margin-left: 3cm;
}
.serchb:hover{
 background-color: #f3f1f2;
 color :black
}
.animate-charcter
{
   /* text-transform: uppercase; */
   background-image: linear-gradient(
    -225deg,
    #1111a8 0%,
    #020263 29%,
    #000046 67%,
    #010125 100%
);

  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 5s linear infinite;
  display: inline-block;
      font-size: 190px;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}



@import url('https://fonts.googleapis.com/css?family=Roboto:700');


.containerani ,p {
  text-shadow: 0 0 7px rgba(255,255,255,.3), 0 0 3px rgba(255,255,255,.3);
}
.containerani {
  color: #e5e5e5;
  /* font-size: 3.26rem; */
  text-transform: uppercase;
  /* height: 100vh; */
  display: flex;
}

.animationnew {
  height:50px;
  overflow:hidden;
  margin-left: 1rem;
}
.anyclass{
  /* font-weight:bolder; */
}

.animationnew > div > div {
  padding: 0.25rem 0.75rem;
  height:2.81rem;
  margin-bottom: 2.81rem;
  display:inline-block;
}

.animationnew div:first-child {
  animation: text-animation 10s infinite;
}

.first h2 {
 color:#0284cf;
}
.second h2 {
  color:#016897;
}
.third h2 {
  color:#01445f;
}

@keyframes text-animation {
  0% {margin-top: -0.20rem;}
  10% {margin-top: -0.20rem;}
  20% {margin-top: -2.92rem;}
  30% {margin-top: -2.92rem;}
  40% {margin-top: -5.80rem;}
  60% {margin-top: -5.80rem;}
  70% {margin-top: -2.92rem;}
  80% {margin-top: -2.92rem;}
  90% {margin-top: -0.20rem;}
  100% {margin-top: -0.20rem;}
}


@keyframes moveUpDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px); /* Adjust distance to move up */
  }
  100% {
    transform: translateY(0);
  }
}
.animationnew2001 {
  height:40px;
  overflow:hidden;
  margin-left: 1rem;
}
.anyclass{
  /* font-weight:bolder; */
}

.animationnew2001 > div > div {
  padding: 0.25rem 0.75rem;
  height:2.81rem;
  margin-bottom: 2.81rem;
  display:inline-block;
}

.animationnew2001 div:first-child {
  animation: text-animation 10s infinite;
}

.downloadbtn{
  width:120px;
  height:35px;
  padding-left: -15px;
  object-fit: contain;
  border-radius: 5px;
  border: solid 0.5px transparent;
  background-color: #fff;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
 border-right: none;
  position: relative;
  font-size: 16px;
  border-color: #111;
  font-weight: 550;
 z-index: 1;
 cursor: pointer;
}

.phoneicon{
 margin-top: -50px;
margin-left: -29px;
cursor: pointer;
}




.arrow-styling24{
  display: flex;
  float: right;
  margin-top: -35px;
}
.arrow-int-24{
width: 12px;
height: 12px;
background-color: black;
margin: 0 2px;
clip-path: polygon(0 0, 0% 100%, 100% 50%);
opacity: 0;
animation: fadeoutt 1.8s ease-in-out infinite;
}
.arrow-int-24:nth-child(2){
  animation-delay: 0.2s;
}

.arrow-int-24:nth-child(3){
  animation-delay: 0.4s;
}


@keyframes fadeoutt {
0%,
40%,
100% {
  opacity:0;
}
20%{
  opacity:1
}
}



