/* body{
  overflow-x: hidden;
} */

html,body{
  overflow-x: hidden!important;
  overflow: visible;
  overflow-y: auto !important;
  /* background-color: white !important; */
  /* background-color: #F1F5FE !important; */
  /* background-color: #2e2ead !important; */
  
  
  /* overflow-y: scroll !important; */
}
.App {
  text-align: center;
} 

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: hsl(193, 95%, 68%);
}
.hoverup{
  background-color: rgb(95, 110, 117);
}
.hoverup:hover{
  background-color: #3b6277;
  color: #ffffff;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
