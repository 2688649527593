/* General container styling */
.filmain1-tech {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
}

/* Inner container for the search bar */
.fildi1-tech {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f9f9f9;
    /* Light gray background */
    padding: 15px 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    width: 90%;
    max-width: 600px;
    background: rgb(22, 173, 143);
    background: linear-gradient(146deg, rgba(22, 173, 143, 0.6839985994397759) 2%, rgba(37, 126, 133, 0.5467436974789917) 44%, rgba(40, 153, 156, 0.6083683473389356) 100%);
}

/* Input field styling */
.fildi1-tech .search-input-tech {
    width: 100%;
    padding: 10px 15px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
    box-sizing: border-box;
    text-transform: uppercase;
    outline: none;
    transition: border-color 0.3s ease;
}

.fildi1-tech .search-input-tech:focus {
    border-color: #007bff;
    /* Blue border on focus */
}

/* Button container styling */
.tildiv1-tech {
    display: flex;
    justify-content: center;
    gap: 15px;
    width: 100%;
}

/* Button styles */
.search-btn1-tech,
.filter-btn1-tech {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background-color: #007bff;
    /* Primary blue */
    color: white;
    border: none;
    padding: 10px 15px;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.search-btn1-tech:hover,
.filter-btn1-tech:hover {
    background-color: #0056b3;
    /* Darker blue on hover */
    transform: scale(1.05);
}

/* Popup styling */
.fildi21-tech {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -20%);
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    z-index: 1000;
}

/* Responsive design */
@media screen and (max-width: 768px) {
    .fildi1-tech {
        width: 100%;
        padding: 10px;
    }



    .fildi21-tech {
        width: 90%;
        top: 15%;
        padding: 15px;
    }
}

@media screen and (max-width: 480px) {
    .fildi1-tech input {
        font-size: 14px;
    }

    .search-btn1-tech,
    .filter-btn1-tech {
        font-size: 12px;
    }
}