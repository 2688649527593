.popup {
    position: absolute;
    width: fit-content;
    height: fit-content;
    justify-content: center;
    align-items: center;
    /* background-color: rgb(165, 212, 250); */
    background-image: linear-gradient(to left,  rgb(92, 151, 170),  rgb(159, 191, 202));
}
.poptr:hover{
   color: rgb(240, 149, 38);
}
#bussinessdash {
    /* background-color: rgb(215, 239, 240); */
    background-image: linear-gradient(to left, #3e95f1, #f8f4f4);

}
table{
  width: 10cm;
}
/* .Usertable:hover{
    background-color: rgb(33, 81, 97);
    border-radius: 10px;
    border:solid aqua 1px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
} */
/* Add these styles to your existing CSS file or create a new one */
.table-container123 {
  overflow-x: auto;
  margin-left: 60px;
}

.table123 {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.table123 th, .table123 td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

@media screen and (max-width: 600px) {
  .table123 {
    overflow-x: auto;
    display: block;
  }

  .table123 th, .table123 td {
    display: block;
    width: 100%;
    box-sizing: border-box;
  }
}

.bbuttonmanage {
    border-radius: 4px;
    background-color: rgb(34, 110, 197);
    border-radius: 15px;
    border: none;
    color: #FFFFFF;
    text-align: center;
    /*font-size: 28px;
    /* padding: 20px; */
    width: auto;
    transition: all 0.5s;
    cursor: pointer;
    margin: 2px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}
  
  .bbuttonmanage span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
  }
  
  .bbuttonmanage span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
  }
  
  .bbuttonmanage:hover span {
    padding-right: 25px;
  }
  
  .bbuttonmanage:hover span:after {
    opacity: 1;
    right: 0;
  }


  .profile{
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
 /* image border */
 .image-container {
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}

.image-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  border: 8px solid rgb(28, 68, 199);
  animation: border-pulse 2s ease-out infinite;
}

@keyframes border-pulse {
  0% {
    transform: scale(0.6);
    opacity: 0.7;
    -webkit-transform: scale(0.6);
    -moz-transform: scale(0.6);
    -ms-transform: scale(0.6);
    -o-transform: scale(0.6);
}
  50% {
    transform: scale(0.6);
    opacity: 0.0;
    -webkit-transform: scale(0.6);
    -moz-transform: scale(0.6);
    -ms-transform: scale(0.6);
    -o-transform: scale(0.6);
}
  100% {
    transform: scale(0.6);
    opacity: 0.7;
    -webkit-transform: scale(0.6);
    -moz-transform: scale(0.6);
    -ms-transform: scale(0.6);
    -o-transform: scale(0.6);
}
}
 

@media screen and (max-width: 500px) {

    .popup {

        margin-left: -30px;

        margin-right: 20px;

    }




}
@media (max-width:450px){
  .table1{
    width: 80%;
  }
}