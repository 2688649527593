/*! CSS Used from: https://www.spruko.com/demo/autolist/Autolist/assets/plugins/bootstrap/css/bootstrap.min.css */
*,
::after,
::before {
    box-sizing: border-box;
}

h2,
h3,
h5 {
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
}

h2 {
    font-size: calc(1.325rem + .9vw);
}

@media (min-width:1200px) {
    h2 {
        font-size: 2rem;
    }
}

h3 {
    font-size: calc(1.3rem + .6vw);
}

@media (min-width:1200px) {
    h3 {
        font-size: 1.75rem;
    }
}

h5 {
    font-size: 1.25rem;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

ul {
    padding-left: 2rem;
}

ul {
    margin-top: 0;
    margin-bottom: 1rem;
}

small {
    font-size: .875em;
}

a {
    color: #0d6efd;
    text-decoration: underline;
}

a:hover {
    color: #0a58ca;
}

img {
    vertical-align: middle;
}

button {
    border-radius: 0;
}

button:focus:not(:focus-visible) {
    outline: 0;
}

button,
input,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button {
    text-transform: none;
}

[type=button],
button {
    -webkit-appearance: button;
}

::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

textarea {
    resize: vertical;
}

.list-unstyled {
    padding-left: 0;
    list-style: none;
}

.container {
    width: 100%;
    padding-right: var(--bs-gutter-x, .75rem);
    padding-left: var(--bs-gutter-x, .75rem);
    margin-right: auto;
    margin-left: auto;
}

@media (min-width:576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width:768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width:992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width:1200px) {
    .container {
        max-width: 1140px;
    }
}

@media (min-width:1400px) {
    .container {
        max-width: 1320px;
    }
}

.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x));
}

.row>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y);
}

@media (min-width:768px) {
    .col-md-12 {
        flex: 0 0 auto;
        width: 100%;
    }
}

@media (min-width:992px) {
    .col-lg-4 {
        flex: 0 0 auto;
        width: 33.33333333%;
    }

    .col-lg-8 {
        flex: 0 0 auto;
        width: 66.66666667%;
    }
}

@media (min-width:1200px) {
    .col-xl-4 {
        flex: 0 0 auto;
        width: 33.33333333%;
    }

    .col-xl-8 {
        flex: 0 0 auto;
        width: 66.66666667%;
    }
}

.form-control {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion:reduce) {
    .form-control {
        transition: none;
    }
}

.form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
}

.form-control::-moz-placeholder {
    color: #6c757d;
    opacity: 1;
}

.form-control::placeholder {
    color: #6c757d;
    opacity: 1;
}

.form-control:disabled {
    background-color: #e9ecef;
    opacity: 1;
}

textarea.form-control {
    min-height: calc(1.5em + .75rem + 2px);
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}

.input-group>.form-control {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
}

.input-group>.form-control:focus {
    z-index: 3;
}

.input-group .btn {
    position: relative;
    z-index: 2;
}

.input-group .btn:focus {
    z-index: 3;
}

.input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion:reduce) {
    .btn {
        transition: none;
    }
}

.btn:hover {
    color: #212529;
}

.btn:focus {
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
}

.btn:disabled {
    pointer-events: none;
    opacity: .65;
}

.btn-primary {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
}

.btn-primary:hover {
    color: #fff;
    background-color: #0b5ed7;
    border-color: #0a58ca;
}

.btn-primary:focus {
    color: #fff;
    background-color: #0b5ed7;
    border-color: #0a58ca;
    box-shadow: 0 0 0 .25rem rgba(49, 132, 253, .5);
}

.btn-primary:active {
    color: #fff;
    background-color: #0a58ca;
    border-color: #0a53be;
}

.btn-primary:active:focus {
    box-shadow: 0 0 0 .25rem rgba(49, 132, 253, .5);
}

.btn-primary:disabled {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
}

.card-body {
    flex: 1 1 auto;
    padding: 1rem 1rem;
}

.card-title {
    margin-bottom: .5rem;
}

.card-header {
    padding: .5rem 1rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, .03);
    border-bottom: 1px solid rgba(0, 0, 0, .125);
}

.card-header:first-child {
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}

.badge {
    display: inline-block;
    padding: .35em .65em;
    font-size: .75em;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
}

.badge:empty {
    display: none;
}

.list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: .25rem;
}

.list-group-item {
    position: relative;
    display: block;
    padding: .5rem 1rem;
    color: #212529;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .125);
}

.list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

.list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
}

.list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff;
}

.list-group-item+.list-group-item {
    border-top-width: 0;
}

.clearfix::after {
    display: block;
    clear: both;
    content: "";
}

.d-flex {
    display: flex !important;
}

.border-top {
    border-top: 1px solid #dee2e6 !important;
}

.border-bottom-0 {
    border-bottom: 0 !important;
}

.w-100 {
    width: 100% !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mt-1 {
    margin-top: .25rem !important;
}

.mt-2 {
    margin-top: .5rem !important;
}

.mt-3 {
    margin-top: 1rem !important;
}

.mt-5 {
    margin-top: 3rem !important;
}

.me-2 {
    margin-right: .5rem !important;
}

.me-3 {
    margin-right: 1rem !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-1 {
    margin-bottom: .25rem !important;
}

.mb-2 {
    margin-bottom: .5rem !important;
}

.ms-0 {
    margin-left: 0 !important;
}

.ms-1 {
    margin-left: .25rem !important;
}

.ms-2 {
    margin-left: .5rem !important;
}

.ms-3 {
    margin-left: 1rem !important;
}

.ms-auto {
    margin-left: auto !important;
}

.p-0 {
    padding: 0 !important;
}

.p-5 {
    padding: 3rem !important;
}

.text-primary {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
    --bs-text-opacity: 1;
    color: #6c757d !important;
}

.bg-primary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

@media (min-width:992px) {
    .mb-lg-0 {
        margin-bottom: 0 !important;
    }
}

/*! CSS Used from: https://www.spruko.com/demo/autolist/Autolist/assets/css/style.css */
*,
::after,
::before {
    box-sizing: border-box;
}

section {
    display: block;
}

h2,
h3,
h5 {
    margin-top: 0;
    margin-bottom: 0.66em;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

ul {
    margin-top: 0;
    margin-bottom: 1rem;
}

small {
    font-size: 80%;
}

a {
    color: #8492a6;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}

img {
    vertical-align: middle;
    border-style: none;
}

button {
    border-radius: 0;
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}

input,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button,
input {
    overflow: visible;
}

button {
    text-transform: none;
}

button,
html [type=button] {
    -webkit-appearance: button;
}

[type=button]::-moz-focus-inner,
button::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

textarea {
    overflow: auto;
    resize: vertical;
}

h2,
h3,
h5 {
    margin-bottom: 0.3em;
    font-weight: 500;
    line-height: 1.1;
    font-family: "Poppins", sans-serif;
}

h2 {
    font-size: 1.75rem;
    font-family: "Poppins", sans-serif;
}

h3 {
    font-size: 1.5rem;
    font-family: "Poppins", sans-serif;
}

h5 {
    font-size: 1rem;
    font-family: "Poppins", sans-serif;
}

small {
    font-size: 87.5%;
    font-weight: 400;
}

.fs-12 {
    font-size: 12px !important;
}

.fs-14 {
    font-size: 14px !important;
}

.fs-16 {
    font-size: 16px !important;
}

.container {
    width: 100%;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width: 1280px) {
    .container {
        max-width: 1200px;
    }
}

@media print {

    *,
    ::after,
    ::before {
        text-shadow: none !important;
        box-shadow: none !important;
    }

    a:not(.btn) {
        text-decoration: underline;
    }

    img {
        page-break-inside: avoid;
    }

    h2,
    h3,
    p {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }

    .container {
        min-width: 992px !important;
    }

    .badge {
        border: 1px solid #000;
    }
}

.avatar {
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    display: inline-block;
    position: relative;
    text-align: center;
    color: #fff;
    font-weight: 600;
    vertical-align: bottom;
    font-size: 0.875rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.brround {
    border-radius: 50%;
}

@media (max-width: 1080px) {
    .avatar {
        max-width: inherit;
    }
}

.badge-info {
    color: #fff;
    background-color: #1da1f3;
}

.badge-light {
    color: #3d4e67;
    background-color: #f1f5fd;
}

.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 100%;
    font-weight: 300;
    line-height: 1.2;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 3px;
}

.badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
}

.badgetext {
    float: right;
}

.badge-default {
    background: #e9eff7;
    color: #7e8188;
}

.btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 0.9375rem;
    line-height: 1.84615385;
    border-radius: 3px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn:focus,
.btn:hover {
    text-decoration: none;
}

.btn:focus {
    outline: 0;
    box-shadow: 0 0 0 2px rgba(194, 197, 199, 0.25);
}

.btn:disabled {
    opacity: 0.65;
}

@media screen and (prefers-reduced-motion: reduce) {
    .btn {
        transition: none;
    }
}

.btn {
    cursor: pointer;
    font-weight: 400;
    letter-spacing: 0.03em;
    font-size: 0.8125rem;
    min-width: 2.375rem;
}

.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.5rem;
    position: relative;
    height: 100%;
}

.card {
    position: relative;
    margin-bottom: 1.5rem;
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    border-radius: 4px;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #d8dde6;
    border-radius: 5px;
    box-shadow: 0 0 40px 0 rgba(234, 238, 249, 0.5);
}

@media print {
    .card {
        box-shadow: none;
        border: none;
    }
}

.card-body> :last-child {
    margin-bottom: 0;
}

@media print {
    .card-body {
        padding: 0;
    }
}

.card-header {
    background: 0 0;
    padding: 0.5rem 1.5rem;
    display: -ms-flexbox;
    display: flex;
    min-height: 3.5rem;
    -ms-flex-align: center;
    align-items: center;
    padding: 1.5rem 1.5rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid #d8dde6;
}

.card-header .card-title {
    margin-bottom: 0;
}

@media print {
    .card-header {
        display: none;
    }
}

.card-title {
    font-size: 1.125rem;
    line-height: 1.2;
    font-weight: 500;
    margin-bottom: 1.5rem;
}

.card-title:only-child {
    margin-bottom: 0;
}

.media-body {
    flex: 1;
    -ms-flex: 1;
}

.form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 0.9375rem;
    line-height: 1.6;
    color: #080e1b;
    height: 38px;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d8dde6;
    border-radius: 3px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    color: #6d7e9c;
    opacity: 1;
}

.form-control::-ms-expand {
    background-color: transparent;
    border: 0;
}

.form-control:focus {
    color: #6f6e6e;
    background-color: #fff;
    border-color: #d8dde6;
    outline: 0;
    box-shadow: none;
}

.form-control::-webkit-input-placeholder,
.form-control::-moz-placeholder,
.form-control:-ms-input-placeholder,
.form-control::-ms-input-placeholder {
    color: #a7b4c9;
    opacity: 1;
}

.form-control:disabled {
    background-color: #f1f5fd;
    opacity: 1;
}

@media screen and (prefers-reduced-motion: reduce) {
    .form-control {
        transition: none;
    }
}

.form-group {
    display: block;
    margin-bottom: 1rem;
}

.form-group {
    display: block !important;
}

textarea.form-control {
    height: auto;
}

.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -0.75rem;
    margin-left: -0.75rem;
}

.input-group {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
}

a:active,
a:focus,
a:hover {
    outline: 0;
    text-decoration: none;
}

.col-md-12,
.col-lg-4,
.col-lg-8,
.col-xl-4,
.col-xl-8 {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
}

@media (min-width: 768px) {
    .col-md-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media (min-width: 992px) {
    .col-lg-4 {
        -ms-flex: 0 0 33.33333333%;
        flex: 0 0 33.33333333%;
        max-width: 33.33333333%;
    }

    .col-lg-8 {
        -ms-flex: 0 0 66.66666667%;
        flex: 0 0 66.66666667%;
        max-width: 66.66666667%;
    }
}

@media (min-width: 1280px) {
    .col-xl-4 {
        -ms-flex: 0 0 33.33333333%;
        flex: 0 0 33.33333333%;
        max-width: 33.33333333%;
    }

    .col-xl-8 {
        -ms-flex: 0 0 66.66666667%;
        flex: 0 0 66.66666667%;
        max-width: 66.66666667%;
    }
}

img {
    max-width: 100%;
}

@media print {
    .container {
        max-width: none;
    }
}

@media (max-width: 767px) {
    .avatar {
        max-width: inherit;
    }
}

@media (max-width: 992px) {
    .item7-card-desc a {
        font-size: 12px;
    }
}

@media (max-width: 320px) {
    .item7-card-desc a {
        font-size: 11px;
    }
}

.product-tags a {
    float: left;
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 5px 10px;
    border-radius: 2px;
    color: #1a1a1a;
    font-size: 12px;
    border: 1px solid #d8dde6;
}

.product-tags li a:hover {
    border-radius: 2px;
    color: #fff !important;
}

.sptb {
    padding-top: 5rem;
    padding-bottom: 5rem;
}

@media (max-width: 991px) {
    .sptb {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
}

@media (max-width: 767px) {
    .item7-card-desc.d-flex {
        display: block !important;
    }
}

:focus {
    outline: 0 !important;
}

.vertical-scroll {
    margin-bottom: 0;
}

.vertical-scroll .item2 {
    border-bottom: 1px solid #d8dde6 !important;
    padding: 12px 25px;
}

input,
textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
}

.list-unstyled {
    padding-left: 0;
    list-style: none;
}

.list-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
}

.list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    margin-bottom: 0px;
    background-color: #fff;
}

.list-group-item:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.list-group-item:focus,
.list-group-item:hover {
    z-index: 1;
    text-decoration: none;
}

.list-group-item:disabled {
    color: #7c90b1;
    background-color: #fff;
}

.list-catergory ul li {
    line-height: 2;
}

.media {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-top: 15px;
}

.media,
.media-body {
    zoom: 1;
    overflow: hidden;
}

.media-object {
    display: block;
}

.media img {
    width: 45px;
    height: 45px;
}

.media-body {
    display: table-cell;
    vertical-align: top;
}

.media-body {
    display: list-item;
    vertical-align: top;
}

.footerimg img {
    width: 35px;
    height: 35px;
}

.item-list .list-group-item {
    border: 0;
    margin-bottom: 0;
    border-bottom: 1px solid #d8dde6;
}

.item-list .list-group-item i {
    margin-right: 5px;
    width: 25px;
    height: 25px;
    border-radius: 2px;
    font-size: 12px;
    text-align: center;
    line-height: 25px;
    color: #fff !important;
}

.item7-card-desc a:first-child {
    margin-right: 1rem;
}

.item7-card-img {
    position: relative;
    overflow: hidden;
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
}

.item7-card-text span {
    position: absolute;
    bottom: 10px;
    left: 10px;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.bg-secondary {
    background-color: #162946 !important;
}

.bg-success {
    background-color: #05a01f !important;
}

.bg-info {
    background-color: #1da1f3 !important;
}

.bg-warning {
    background-color: #ffa22b !important;
    color: #fff;
}

.bg-danger {
    background-color: #ff382b !important;
}

.bg-blue {
    background-color: #4049ec !important;
    color: #fff !important;
}

.border-top {
    border-top: 1px solid #d8dde6 !important;
}

.border-bottom-0 {
    border-bottom: 0 !important;
}

.clearfix::after {
    display: block;
    clear: both;
    content: "";
}

.br-bs-3 {
    border-bottom-left-radius: 3px !important;
}

.br-be-3 {
    border-bottom-right-radius: 3px !important;
}

.br-ts-0 {
    border-top-left-radius: 0 !important;
}

.br-bs-0 {
    border-bottom-left-radius: 0 !important;
}

.d-flex {
    display: -ms-flexbox !important;
    display: flex !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.ms-0 {
    margin-left: 0 !important;
}

.mt-1 {
    margin-top: 0.25rem !important;
}

.mb-1 {
    margin-bottom: 0.25rem !important;
}

.ms-1 {
    margin-left: 0.25rem !important;
}

.mt-2 {
    margin-top: 0.5rem;
}

.me-2 {
    margin-right: 0.5rem;
}

.mb-2 {
    margin-bottom: 0.5rem !important;
}

.ms-2 {
    margin-left: 0.5rem;
}

.mt-3 {
    margin-top: 0.75rem !important;
}

.me-3 {
    margin-right: 0.75rem !important;
}

.ms-3 {
    margin-left: 0.75rem !important;
}

.mt-5 {
    margin-top: 1.5rem !important;
}

.ms-auto {
    margin-left: auto !important;
}

@media (min-width: 992px) {
    .mb-lg-0 {
        margin-bottom: 0 !important;
    }
}

.p-0 {
    padding: 0 !important;
}

.p-5 {
    padding: 1.5rem !important;
}

.font-weight-semibold {
    font-weight: 500 !important;
}

.text-success {
    color: #05a01f !important;
}

.text-info {
    color: #1da1f3 !important;
}

.text-warning {
    color: #ffa22b !important;
}

.text-danger {
    color: #ff382b !important;
}

.text-dark {
    color: #162946 !important;
}

.text-muted {
    color: #6d7e9c !important;
}

.leading-Automatic {
    line-height: 1.8 !important;
}

.text-blue {
    color: #3ba2ff !important;
}

.text-pink {
    color: #ff2b88 !important;
}

.text-yellow {
    color: #ffa22b !important;
}

.w-100 {
    width: 100% !important;
}

/*! CSS Used from: https://www.spruko.com/demo/autolist/Autolist/assets/css/icons.css */
/*! @import https://www.spruko.com/demo/autolist/Autolist/assets/iconfonts/font-awesome/css/font-awesome.min.css */
.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fa-star:before {
    content: "\f005";
}

.fa-user:before {
    content: "\f007";
}

.fa-clock-o:before {
    content: "\f017";
}

.fa-map-marker:before {
    content: "\f041";
}

.fa-check-circle-o:before {
    content: "\f05d";
}

.fa-calendar:before {
    content: "\f073";
}

.fa-thumbs-o-up:before {
    content: "\f087";
}

.fa-comment-o:before {
    content: "\f0e5";
}

.fa-calendar-o:before {
    content: "\f133";
}

.fa-car:before {
    content: "\f1b9";
}

/*! end @import */
/*! @import https://www.spruko.com/demo/autolist/Autolist/assets/iconfonts/simple-line-icons/simple-line-icons.css */
.icon-check {
    font-family: 'simple-line-icons';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-check:before {
    content: "\e080";
}

/*! end @import */
/*! CSS Used from: https://www.spruko.com/demo/autolist/Autolist/assets/colorskins/color-skins/color13.css ; media=all */
@media all {
    a:hover {
        color: #e72a1a;
        text-decoration: none;
    }

    .btn-primary {
        color: #fff;
        background-color: #e72a1a !important;
        border-color: #e72a1a !important;
    }

    .btn-primary:hover {
        color: #fff;
        background-color: #da1b0b;
        border-color: #da1b0b;
    }

    .btn-primary:focus {
        box-shadow: 0 0 0 2px rgba(231, 42, 26, 0.5);
    }

    .btn-primary:disabled {
        color: #fff;
        background-color: #ea3728;
        border-color: #ea3728;
    }

    .badge-primary {
        color: #fff;
        background-color: #e72a1a;
    }

    .bg-primary {
        background-color: #e72a1a !important;
    }

    .text-primary {
        color: #e72a1a !important;
    }

    a.text-dark:focus,
    a.text-dark:hover {
        color: #e72a1a !important;
    }

    .avatar {
        background: #e72a1a no-repeat center/cover;
    }

    .product-tags li a:hover {
        background: #e72a1a;
        border-color: #e72a1a;
    }

    .bg-secondary {
        background-color: #8d0bb7 !important;
    }
}

/*! CSS Used from: Embedded */
textarea {
    -webkit-touch-callout: default;
    -webkit-user-select: auto;
    -khtml-user-select: auto;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

* {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: -moz-none;
    -ms-user-select: none;
    user-select: none;
}

/*! CSS Used fontfaces */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z11lFc-K.woff2) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1JlFc-K.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1xlFQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJbecmNE.woff2) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJnecmNE.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJfecg.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z11lFc-K.woff2) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1JlFc-K.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1xlFQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z11lFc-K.woff2) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1JlFc-K.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1xlFQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z11lFc-K.woff2) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1JlFc-K.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1xlFQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'FontAwesome';
    src: url('https://www.spruko.com/demo/autolist/Autolist/assets/iconfonts/font-awesome/fonts/fontawesome-webfont.eot?v=4.7.0');
    src: url('https://www.spruko.com/demo/autolist/Autolist/assets/iconfonts/font-awesome/fonts/fontawesome-webfont.eot#iefix&v=4.7.0') format('embedded-opentype'), url('https://www.spruko.com/demo/autolist/Autolist/assets/iconfonts/font-awesome/fonts/fontawesome-webfont.woff2?v=4.7.0') format('woff2'), url('https://www.spruko.com/demo/autolist/Autolist/assets/iconfonts/font-awesome/fonts/fontawesome-webfont.woff?v=4.7.0') format('woff'), url('https://www.spruko.com/demo/autolist/Autolist/assets/iconfonts/font-awesome/fonts/fontawesome-webfont.ttf?v=4.7.0') format('truetype'), url('https://www.spruko.com/demo/autolist/Autolist/assets/iconfonts/font-awesome/fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'simple-line-icons';
    src: url('https://www.spruko.com/demo/autolist/Autolist/assets/iconfonts/simple-line-icons/fonts/Simple-Line-Icons.eot?v=2.4.0');
    src: url('https://www.spruko.com/demo/autolist/Autolist/assets/iconfonts/simple-line-icons/fonts/Simple-Line-Icons.eot?v=2.4.0#iefix') format('embedded-opentype'), url('https://www.spruko.com/demo/autolist/Autolist/assets/iconfonts/simple-line-icons/fonts/Simple-Line-Icons.woff2?v=2.4.0') format('woff2'), url('https://www.spruko.com/demo/autolist/Autolist/assets/iconfonts/simple-line-icons/fonts/Simple-Line-Icons.ttf?v=2.4.0') format('truetype'), url('https://www.spruko.com/demo/autolist/Autolist/assets/iconfonts/simple-line-icons/fonts/Simple-Line-Icons.woff?v=2.4.0') format('woff'), url('https://www.spruko.com/demo/autolist/Autolist/assets/iconfonts/simple-line-icons/fonts/Simple-Line-Icons.svg?v=2.4.0#simple-line-icons') format('svg');
    font-weight: normal;
    font-style: normal;
}