.wrapper {
  font-family: Georgia, 'Times New Roman', Times, serif;
  width: 6rem;
  height: 8rem;
  border: 1px solid rgb(185, 185, 185);
  box-shadow: 0px 2px 3px 1px rgb(202, 202, 202);
}

.mainwrapper h3 {
  text-align: center;
    font-size: 25px;
    font-family:'areal';
    color:#454545,
}

.mainwrapper {
  margin-left: 7rem;
  margin-right: 7rem;
  background-color: "#FFFFFF";
}

@media (max-width: 768px) {
  .mainwrapper {
    margin: auto 2rem;
  }
}

@media (max-width: 480px) {
  .wrapper {
    width: 100%;
    height: auto;
  }

  .mainwrapper {
    margin: auto 1rem;
    overflow-x: hidden;
  }

  .mainwrapper h3 {
    font-size: 20px;
  }
  .wrapper2001{
    width: 94%;
    overflow-x: hidden;
    margin: auto;
  }

  .mainwrapper p {
    font-size: small;
  }
}



/* From Uiverse.io by gharsh11032000 */
.animated-button-mnf {
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 16px 36px;
  border: 4px solid;
  border-color: transparent;
  font-size: 16px;
  background-color: inherit;
  border-radius: 100px;
  font-weight: 600;
  color: greenyellow;
  box-shadow: 0 0 0 2px greenyellow;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.animated-button-mnf svg {
  position: absolute;
  width: 24px;
  fill: greenyellow;
  z-index: 9;
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.animated-button-mnf .arr-1-mnf {
  right: 16px;
}

.animated-button-mnf .arr-2-mnf {
  left: -25%;
}

.animated-button-mnf .circle-mnf {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  background-color: greenyellow;
  border-radius: 50%;
  opacity: 0;
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.animated-button-mnf .text-mnf {
  position: relative;
  z-index: 1;
  transform: translateX(-12px);
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.animated-button-mnf:hover {
  box-shadow: 0 0 0 12px transparent;
  color: #212121;
  border-radius: 12px;
}

.animated-button-mnf:hover .arr-1-mnf {
  right: -25%;
}

.animated-button-mnf:hover .arr-2-mnf {
  left: 16px;
}

.animated-button-mnf:hover .text-mnf {
  transform: translateX(12px);
}

.animated-button-mnf:hover svg {
  fill: #212121;
}

.animated-button-mnf:active {
  scale: 0.95;
  box-shadow: 0 0 0 4px greenyellow;
}

.animated-button-mnf:hover .circle-mnf {
  width: 220px;
  height: 220px;
  opacity: 1;
}

