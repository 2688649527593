.title  
{  
        margin-bottom: 20px;  
        padding:10px  
} 
.owlFont{
        font-family: "Poppins", sans-serif !important;
        font-size: 14px !important;
     
}
.container-fluid{
        padding:10px;
} 
.img  
{  
        height: 100px !important;
        width:100% ;
        border: 1px solid rgba(128, 128, 128, 0) !important;
        border-radius: 4px;
        /* object-fit: contain !important; */
}  
.btn-sm{
        color: rgb(6, 14, 131);
        background: linear-gradient(270deg,#0f1c54,#2b224c);
        border:1px solid rgb(6 14 131 / 35%);
        font-weight: 600;
        border-radius: 3px;
        font-size: 13px;
        padding: 6px 10px;
        font-family: "Poppins", sans-serif !important;
        height:35px;
    }
    .btn-sm:hover{
        box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%) !important;
    }

    .owl1:hover{
        /* transform: translateX(-10px); */
        transform: scale(1.03);
  box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);

}
.text1{
        font-size: 18px;
        font-family:sans-serif;
        color:black;
        width:70%;

}
.textt{
      
        font-family:sans-serif;
        color:black;
        width:70%;
    

}
.text-center{
        font-size: 24px;
        font-weight: bold;
        margin-top: 0px;
}
#wishlistId{
        font-size: 150%;
        color: rgb(31, 21, 41);
}
#wishlistId:hover{
        color: rgb(230, 52, 52);
}

.sliderowl{
        padding: 20px;
        margin-bottom: -40px;
        margin-left:-2.5%;
        margin-right:-4.2%;
        
}
/* .product-card{
        background: red;
} */

      
     



   